import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './styles.css'

function CreatePostLink({ linkType, postText, setPostText, setTextLink, setLinkHTML, setEmbedLink, linkHTML, inspAuthor, setInspAuthor, isInsp }:
  { linkType: string, postText: string, setPostText: any, setTextLink: any, setLinkHTML: any, setEmbedLink: any, linkHTML: string, inspAuthor: string, setInspAuthor: (p: string) => void, isInsp: boolean }) {

  // const [postTextComplete, setPostTextComplete] = useState(postText);
  const { t, i18n } = useTranslation();
  // useEffect(() => {
  //   setPostTextComplete(postText)
  // }, [postText])

  const containsUrl = (postText: string): string | boolean => {
    let finalWord: boolean | string = false;
    postText.split(" ").forEach((word: string) => {
      if (isValidUrl(word)) { finalWord = word }
    })
    return finalWord;
  }

  const isValidUrl = (url: string): boolean => {
    return /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
  }

  const isIFrame = (s: string): boolean | string => {
    try {
      const matches = s.match(/<iframe(?: [^>]*?)?(?:\/>|>.*?<\/iframe>)/)
      return matches![0]
    } catch (e) {
      return false
    }
  }

  return (
    <>
      <div className="containerPostCreateImage">

        <div className="containerPostCreateLink">
          <textarea
            name=""
            id=""
            className="containerPostCreateLink-inputText"
            placeholder={t('label_writeSomething')}
            value={postText}
            maxLength={2200}
            onChange={(e) => {
              const { value } = e.target
              setPostText(value)
            }}
          ></textarea>
          {postText.length > 0 && <div className="CharCounter__wrapper">
            <span className="CharCounter__text">{postText.length}/2200</span>
          </div>}
        </div>
        {isInsp && (
          <div className="containerPostCreateLink">
            <textarea
              className="containerPostCreateLink-inputText"
              placeholder={t('label_inspAuthor')}
              value={inspAuthor}
              maxLength={2200}
              onChange={(e) => {
                const { value } = e.target
                setInspAuthor(value)
              }}
            />
            {inspAuthor.length > 0 && <div className="CharCounter__wrapper">
              <span className="CharCounter__text">{inspAuthor.length}/2200</span>
            </div>}
          </div>
        )}

        <textarea
          name=""
          id=""
          className="containerPostCreateLink-inputText link"
          placeholder={(linkType === 'video') ? (
            t('label_copyEmbed')
          ) : (
            t('label_copyLink')
          )}
          value={linkHTML}
          maxLength={2200}
          onChange={(e) => {
            setEmbedLink(undefined);
            setLinkHTML(undefined);
            setTextLink(undefined)
            const { value } = e.target
            const url = containsUrl(value)
            const iFrame = isIFrame(value)

            // setPostTextComplete(value);

            if (url) {
              setTextLink(url);
              setLinkHTML(url as string);
              // setPostText(value.replace(url as string, ""));
            } else if (iFrame) {
              setEmbedLink(iFrame);
              setLinkHTML(iFrame as string);
              // setPostText(value.replace(iFrame as string, ""));
            }
            setLinkHTML(value as string);
            if (value === '') {
              setEmbedLink(undefined);
              setLinkHTML(undefined);
              setTextLink(undefined)
            }
          }}
        ></textarea>


      </div>
    </>
  );
}

export default CreatePostLink;