import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import { connect, useSelector, useDispatch } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { useTranslation } from "react-i18next";
import ModalCreateEvents from "../Events/ModalCreateEvents";
import IPaymentInfo from "../../interfaces/user/IPaymentINfo";
import useClubsList, { getRole } from "../../hooks/Clubs/useClubsList";
import EventCard from "../BaseComponents/Events/EventCard";
import { ContextSuccess } from "../../Context";
import styled from "styled-components";
import SearchInput from "../BaseComponents/SearchInput";
import useEventsPanel from "../../hooks/Events/useEventsPanel";
import Filter, { IEventFilter, MockEventFilter } from "../BaseComponents/Filter";

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const currentSuccess = useContext(ContextSuccess);

  const [selected, setSelected] = useState<number>(0); // 0 -> All Events, 1 -> My Events, 3 -> Friends' Events
  const [showModal, setShowModal] = useState(false);
  const [valueFilter, setValueFilter] = useState("");

  const [eventFilter, setEventFilter] = useState<IEventFilter>({ ...MockEventFilter });

  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

  const { reloadList, myClubs } = useClubsList({
    role: getRole(userRoles),
    clubType: 'CLUB',
    searchName: ''
  });

  const {
    currentCards,
    handleTabChange,
    reload,
  } = useEventsPanel({
    searchItem: valueFilter,
    eventFilter,
  });

  const handleTab = (tabId: number) => {
    setSelected(tabId);

    if (tabId === 0) {
      handleTabChange('ALL');
    } else if (tabId === 1) {
      handleTabChange('MY');
    } else if (tabId === 2) {
      handleTabChange('FRIENDS')
    }
  }

  const handleButtonCreate = () => {
    if (userRoles.includes('HEALER') && (!userPayment.hasActiveSubscription && myClubs.filter((c) => c.role === 'ADVISOR').length === 0)) {
      currentSuccess?.setModalPayInfo("To create new events you need an active subscription or be assigned as a team or community's guide by your organization admin")
    }
    else {
      setShowModal(true);
    }
  }

  return (
    <WrapEvents>

      <TabsEvents>
        <div
          className={
            selected === 0
              ? "section-column-selected pointer"
              : "section-column pointer"
          }
          id="left-rad"
          onClick={() => handleTab(0)}
        >
          <div
            className={"section-label robotocondensed-normal-t-blue-16px"}
          >
            {t('bulletin__allEvents')}
          </div>
        </div>
        <div
          className={
            selected === 1 ? "section-column-selected pointer" : "section-column pointer"
          }
          onClick={() => handleTab(1)}
        >
          <div className={"section-label robotocondensed-normal-t-blue-16px"}>
            {t('bulletin__myEvents')}
          </div>
        </div>
        <div
          className={
            selected === 2
              ? "section-column-selected pointer"
              : "section-column pointer"
          }
          id="right-rad"
          onClick={() => handleTab(2)}
        >
          <div className={"section-label robotocondensed-normal-t-blue-16px"}>
            {t('bulletin__friendsEvents')}
          </div>
        </div>
      </TabsEvents>

      <Row>
        <SearchInput
          filteredItem={valueFilter}
          setFilteredItem={setValueFilter}
          backgroundColor={'var(--t-blue)'}
          border={'0px'}
          placeholder={"Search"}
        />
        <Filter from={'BULLETIN'} type={'EV'} eventFilter={eventFilter} setEventFilter={setEventFilter} />
      </Row>

      {((userRoles.includes('HEALER') ||
        userRoles.includes("SECOND#ADVISOR")) &&
        selected === 0) && (
          <button
            className="btnCreateEvent"
            onClick={handleButtonCreate}
          >
            {'+ Create Event'}
          </button>
        )}

      <ContainerList>
        <ListEvents>
          {currentCards.map((event) => (
            <EventCard data={event} reload={reload} />
          ))}
        </ListEvents>
      </ContainerList>

      <ModalCreateEvents showModal={showModal} setShowModal={setShowModal} reloadEvents={reload} />
    </WrapEvents>
  );
}

export default Index;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  gap: 10px;
  align-items: center;
`;

const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 9vh);
  padding-bottom: 75px;
`;

const ListEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  gap: 8px;
  padding-bottom: 60px;
`;

const WrapEvents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
  flex-direction: column;
`;

const TabsEvents = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: 'Inter';
  align-items: center;
  gap: 5px;
  background-color: var(--simple-gray-2);
  border-radius: 8px;
`;