import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClubs } from "../../store/actions/clubActions";
import { getUserFriends, getUserPossibleConnections, getUserPendingRequests, getUserSentRequests, rejectFriendRequest, saveNewFriend, cancelFriendRequest, cancelFriendRequestEmail, deleteFriend, createFriendRequest } from "../../store/actions/connectionActions";
import { getUser } from "../../store/actions/userActions";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import ModalConnections from "./ModalConnections";
import OutsideClickHandler from "react-outside-click-handler";
import "./styles.css";
import "./styles.scoped.css";
import { useTranslation } from "react-i18next";
import ModalConnection, { ConnectionAction } from '../../components/ModalConnection'
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import { RootState } from "../../store/store";
import { rolesType } from "@vibe/sdk/interfaces/AccessManager/signUpUser";
import { UserConnection } from "@vibe/sdk/dist/interfaces/Roster/getUserConnections";
import { MockConnection } from "../../interfaces/user/Connection";
import useUsersList, { UseUserRole } from "../../hooks/users/useUsersList";
import { getRole } from "../../hooks/Clubs/useClubsList";
import { ContextSuccess } from "../../Context";
import ButtonSpinner from "../BaseComponents/ButtonSpinner";
import styled from "styled-components";
import { sortAlphabeticallyOnProperty } from "../../containers/Signup/SignupHealerInd";
import { IconClicker } from "../Home/HeaderHome/styles";
import SelectItem from "../../interfaces/SelectItem";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";

function Index({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentSuccess = useContext(ContextSuccess);

  const { username = '' } = useParams();

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

  const [activeSort, setActiveSort] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0); //0 -> All Seekers, 1 -> My Seekers, 2 -> My Friends, 3 -> Friend Requests, 4 -> Sent Requests

  const [valueFilter, setValueFilter] = useState("");
  const [selectOrder, setSelectOrder] = useState<'DESC' | 'ASC'>("ASC");
  const [showModalConnection, setShowModalConnection] = useState<boolean>(false);
  const [infoConnection, setInfoConnection] = useState<UserConnection>({ ...MockConnection });
  const [connections, setConnections] = useState<UserConnection[]>([]);
  const [searchRole, setSearchRole] = useState<UseUserRole>('SEEKER');

  const {
    reloadList,
    allFilteredUsers,
    allSystemUsers,
    allOrgUsers,
    myFriends,
    myFriendRequests,
    mySentRequests,
  } = useUsersList({
    searchName: valueFilter,
    role: getRole(userRoles),
    searchRole: searchRole,
  });

  useEffect(() => {
    if (userRoles.includes('INDIVIDUAL')) setActiveTab(1);
  }, [])

  useEffect(() => {
    if (userRoles.includes('SUPER')) {
      setConnections(sortAlphabeticallyOnProperty(allSystemUsers, 'name', selectOrder));
      return;
    }

    if (activeTab === 0) {
      setConnections(sortAlphabeticallyOnProperty(allOrgUsers.filter(u => u.username !== userInfo?.username), 'name', selectOrder));
      return;
    }
    if (activeTab === 1) {
      setConnections(sortAlphabeticallyOnProperty(allOrgUsers.filter(u => u.username !== userInfo?.username), 'name', selectOrder));
      return;
    }
    if (activeTab === 2) {
      setConnections(sortAlphabeticallyOnProperty(myFriends, 'name', selectOrder));
      return;
    }
    if (activeTab === 3) {
      setConnections(sortAlphabeticallyOnProperty(myFriendRequests, 'name', selectOrder));
      return;
    }
    if (activeTab === 4) {
      setConnections(sortAlphabeticallyOnProperty(mySentRequests, 'name', selectOrder));
      return;
    }
  }, [allSystemUsers, allOrgUsers, myFriends, myFriendRequests, mySentRequests, activeTab, selectOrder])

  useEffect(() => {
    if (activeTab > 1) setSearchRole('ALL');
    else if (activeTab === 0) setSearchRole('HEALER');
    else setSearchRole('SEEKER');
  }, [activeTab])

  useEffect(() => {
    //@todo set the correct list according to tabs
  }, [location])

  function handleChangeSearch(e: any) {
    setValueFilter(e.target.value)
  }

  const onClickMyConnections = (connection: UserConnection) => {
    setInfoConnection(connection)
    setShowModalConnection(true)
  }

  function handleCloseModalDelete() {
    setShowModalConnection(false)
  }

  const selectConnection = (connection: UserConnection) => {
    return connection.username === username ? true : undefined;
  }

  const getCountClubs = (mutualClubs: number) => {
    return mutualClubs === 1 ?
      (mutualClubs || 0) + ' ' + t('connections__mutualClub')
      :
      (mutualClubs || 0) + ' ' + t('connections__mutualClubs')
  }

  const getConnRole = (roles: rolesType[]) => {
    if (roles.includes('HEALER') && roles.includes('SEEKER'))
      return 'Healer & Seeker';
    return roles.includes('HEALER') ? 'Healer' : 'Seeker';
  }

  const getTabName = () => {
    if (activeTab > 1) {
      return activeTab === 2 ? 'Friends' : 'Requests';
    }

    return activeTab === 0 ? 'Healers' : 'Seekers';
  }

  const handleConnections = (data: ConnectionAction, username: string) => {
    //@todo correct reload after dispatch and correct functions to use sdk
    if (data === 'ACCEPT') {
      setLoadingAction(true);
      dispatch<any>(saveNewFriend(userInfo?.username || '', username))
        .then(() => {
          currentSuccess?.setSuccessData(true, true, 'You are friends now!');
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'DELETE') {
      setLoadingAction(true);
      dispatch<any>(deleteFriend(userInfo?.username || '', username))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'CANCEL') {
      setLoadingAction(true);
      dispatch<any>(cancelFriendRequest(userInfo?.username || '', username))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'REJECT') {
      setLoadingAction(true);
      dispatch<any>(rejectFriendRequest(username, userInfo?.username || ''))
        .then(() => {
          currentSuccess?.setSuccessData(true, true, 'You have rejected the friend request');
          reloadList();
          setLoadingAction(false);
        });
      return;
    }
    if (data === 'REQUEST') {
      setLoadingAction(true);
      dispatch<any>(createFriendRequest(userInfo?.username || '', username))
        .then(() => {
          reloadList();
          setLoadingAction(false);
        });
      return;
    }

    currentSuccess?.setSuccessData(false, true, 'No connection action supported.');
  }

  const sort: SelectItem[] = [{
    value: 'ASC', 
    label: t('options__az')
  }, 
  {
    value: 'DESC', 
    label: t('options__za')
  }]

  return (
    <WrapConnections>

      {userRoles.includes('SUPER') ? (
        <TabsConnections>
          <div
            className={`tabC tabMyConnection ${activeTab === 0 && 'tabActive'}`}
            onClick={() => setActiveTab(0)}
          >
            All Healers
          </div>
          <div
            className={`tabC tabPendingConnection ${activeTab === 1 && 'tabActive'}`}
            onClick={() => setActiveTab(1)}
          >
            All Seekers
          </div>
        </TabsConnections>
      ) : (
        <TabsConnections>
          {(!userRoles.includes('INDIVIDUAL')) &&
            <div
              className={`tabC tabMyConnection ${activeTab === 0 && 'tabActive'}`}
              onClick={() => setActiveTab(0)}
            >
              {userRoles.includes('HEALER') ? 'My Healers' : 'Healers'}
            </div>
          }
          <div
            className={`tabC ${userRoles.includes('INDIVIDUAL') ? 'tabMyConnection' : 'tabMyFriends'} ${activeTab === 1 && 'tabActive'}`}
            onClick={() => setActiveTab(1)}
          >
            {userRoles.includes('HEALER') ? 'My Seekers' : 'Seekers'}
          </div>
          <div
            className={`tabC tabPendingConnection ${activeTab > 1 && 'tabActive'}`}
            onClick={() => setActiveTab(2)}
          >
            Friends
          </div>
        </TabsConnections>
      )}

      <SearchConn>
        <div className="inputSearch">
          <IconSearch
            icon="search"
            size='sm'
            color={WHITE}
          />
          <input type="text" placeholder={t('comm__search')} onChange={(e) => handleChangeSearch(e)} />
        </div>
        <Clicker>
          <IconClicker
            size='lg'
            icon="sort-alpha-up"
            onClick={() => setActiveSort(past =>!past)}
          />
          {activeSort && <OutsideClickHandler
            onOutsideClick={() => {
              setActiveSort(false);
            }}
          >
            <Container>
              {sort.map(d => (
                <ItemList onClick={() => setSelectOrder(d.value as "DESC" | "ASC")}>
                  <IconRadio theme={{ main: selectOrder === d.value ? 'iconCheck' : '' }}>
                    {selectOrder === d.value &&
                      <RadioIcon
                        icon={faCircle}
                        size='lg'
                        color={'var(--absolute-zero)'}
                      />
                    }
                  </IconRadio>
                  {d.label}
                </ItemList>
              ))}
            </Container>
          </OutsideClickHandler>}
        </Clicker>
      </SearchConn>

      {activeTab > 1 && (
        <div className='pendingConnectionsSelectBar'>
          <div className='ConnectionsPendingWrap'>
            <p
              className={activeTab === 2 ? 'pendingConActive pointer' : 'pointer'}
              onClick={() => setActiveTab(2)}
            >
              My<br />Friends
            </p>
            <div className={`ConnectionsPendingLine ${activeTab === 2 ? 'active' : ''}`}></div>
          </div>

          <div className='ConnectionsPendingWrap'>
            <p
              className={activeTab === 3 ? 'pendingConActive pointer' : 'pointer'}
              onClick={() => setActiveTab(3)}
            >
              Friend Requests
            </p>
            <div className={`ConnectionsPendingLine ${activeTab === 3 ? 'active' : ''}`}></div>
          </div>

          <div className='ConnectionsPendingWrap'>
            <p
              className={activeTab === 4 ? 'pendingConActive pointer' : 'pointer'}
              onClick={() => setActiveTab(4)}
            >
              Sent Requests
            </p>
            <div className={`ConnectionsPendingLine ${activeTab === 4 ? 'active' : ''}`}></div>
          </div>
        </div>
      )}

      <ContainerList>
        <ListConn>
          <p className="countConnections">{connections.length} {getTabName()}</p>
          {connections.map((connection: UserConnection) => (
            <div className={`${activeTab > 2 ? 'cardConnectionButtons' : 'cardConnection'} ${(selectConnection(connection)) && 'cardActive'}`}>
              <div className="infoCard" onClick={() => onClickMyConnections(connection)}>
                <div className="imageConnection">
                  <img className="round-avatar" src={connection.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                </div>

                <div className="infoConnection">
                  <label htmlFor="" className={`nameConnection ${(selectConnection(connection)) && 'cardActive'}`}>{connection.name}</label>
                  <p className={`countClubs ${(selectConnection(connection)) && 'cardActive'}`}>
                    {getConnRole(connection.role)} - {getCountClubs(connection.mutualClubs)}
                  </p>
                </div>
              </div>

              {activeTab === 3 && (
                <div className="buttonGridContainer">
                  <ButtonSpinner
                    loading={loadingAction}
                    handleClick={() => handleConnections('ACCEPT', connection.username)}
                    text={'Accept'}
                    hasIcon={false}
                    color={'var(--electric-yellow-2)'}
                  />
                  <ButtonSpinner
                    loading={loadingAction}
                    handleClick={() => handleConnections('REJECT', connection.username)}
                    text={'Reject'}
                    hasIcon={false}
                  />
                </div>
              )}

              {activeTab === 4 && (
                <ButtonSpinner
                  loading={loadingAction}
                  handleClick={() => handleConnections('CANCEL', connection.username)}
                  text={'Cancel'}
                  hasIcon={false}
                />
              )}
            </div>
          ))}
        </ListConn>
      </ContainerList>
      <ModalConnection
        showModal={showModalConnection}
        onHide={handleCloseModalDelete}
        info={infoConnection}
        handleButtons={handleConnections}
        loadingAction={loadingAction}
      />
    </WrapConnections>
  );
}

export default Index;

const RadioIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
`;

const ItemList = styled.div`
  display: flex;
  justify-content: start;
  padding: 3px 0px 3px 0;
  align-items: center;
  font-family: 'Inter';
  font-size: 14px;
  gap: 8px;
  cursor: pointer;
`;

const Clicker = styled.div`
    position: relative;
    z-index: 1;
`;

const Container = styled.div`
    background-color: white;
    border-radius: 8px;
    position: absolute;
    width: 150px;
    max-height: 150px;
    padding: 12px;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 100%;
    margin-top: 10px;
    right: 0;
    z-index: 1000001;
`;

const ListConn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100%);
  height: 100%;
  overflow: scroll;
  padding-bottom: 210px;
`;

const WrapConnections = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
  flex-direction: column;
`;

const TabsConnections = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: 'Inter';
  font-size: 14px;
  align-items: center;
  gap: 5px;
  background-color: var(--simple-gray-2);
  border-radius: 8px;
`;

const SearchConn = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`;

const IconSearch = styled(FontAwesomeIcon)`
  margin: 0px 10px;
`;

const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const IconRadio = styled.div.attrs(props => ({
  className: props.theme.main,
}))`
  border-radius: 12px;
  border: 2px solid #999796;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.iconCheck{
      background-color: white;
      border: 2px solid #2A4CD3;
  }
`