import styled from "styled-components";
import { TitleHome } from "../../SearchHome/styles";

export const ButtonSeeAll = styled.div`
  color: #2A4CD3;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Strong/100 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: 0.1px;
  display: none;
  @media only screen and (max-width: 770px) {
    display: block;
  }
`

export const TitleResultsHome = styled(TitleHome)`
  text-align: start;
  font-size: 46px;
  @media only screen and (max-width: 770px) {
    font-size: 28px;
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  @media only screen and (max-width: 770px) {
    gap: 18px;
    overflow: hidden;
  }
`;

export const ResultsTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: #1A1A1A;
  font-family: 'Source Serif 4';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: flex-start;
  text-align: left;

  @media only screen and (max-width: 770px) {
    font-size: 20.2px;
    width: calc(100% - 24px);
  }
`;

export const RowResultsHome = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  @media only screen and (max-width: 770px) {
    gap: 10px;
    flex: 1;
    display: flex;
    overflow: auto;
    width: calc(100% - 0px);
  }
`;

export const FilterColorRounded = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 6px 12px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--m-3-sys-light-on-surface-variant);
  background-color: ${({active}:{active: boolean}) => active ? "var(--filter-rounded-on)" : "white"};
  cursor: pointer;

  color: var(--m-3-sys-light-on-surface-variant);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0.2px;

  :hover{
    background-color: ${({active}:{active: boolean}) => active ? "var(--m-3-state-layers-light-on-secondary-container-opacity-016)" : "var(--m-3-state-layers-light-on-surface-variant-opacity-012)"};
  }

  @media only screen and (max-width: 770px) {
    border-radius: 6px;
    padding: 6px 8px;
  }
`;

export const LabelShowingResults = styled.span`
  color: var(--default-dark);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
`;

export const ContainerContentResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  width: 100%;
`;

export const ColumnResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const RowFiltersContainer = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  color: #282A2F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.1px;

  @media only screen and (max-width: 770px) {
    justify-content: space-between;
    width: calc(100% - 17px);
  }
`;

export const LoadMoreResults = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  color: var(--default-dark);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  cursor: pointer;
`;

export const GridHomeTab = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // grid-template-rows: repeat(${({rows}:{rows: string}) => rows}, 1fr);
  // grid-gap: 16px;

  &.resultHealers > div{
    max-width: 315px;
  }

  @media only screen and (max-width: 770px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    & .cardMods{
      width: calc(100% - 24px);
    }
  }
`;

export const WrapperResultsHomeSearch = styled.div`
  height: 40px;
  width: 100%;
`;

export const ContainerFilter = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 15px);
`
export const ContFilter = styled.div`
  display: none;
  gap: 12px;
  align-items: center;
  font-family: 'Inter';
  font-size: 14px;
  cursor: pointer;

  @media only screen and (max-width: 770px) {
    display: flex;
  }
`