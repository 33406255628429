import "./styles.scoped.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabSchoolRoaster from './TabSchoolRoaster';
import { getCheckInEvent, getCheckOutEvent } from "../../store/actions/eventActions";
import { getUsersWithLimit } from "../../store/actions/userActions";
import { getClubMembers, getClubsImAdv } from "../../store/actions/clubActions";
import { getUserFriends, getUserPossibleConnections } from "../../store/actions/connectionActions";
import ModalConnections from "../ConnectionSelectBar/ModalConnections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { t } from "i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import { BROWN } from "../SuperCenter/Modalities/Modals/components";
import { GetEventModeratorsResponse } from "@vibe/sdk/dist/interfaces/Events/getEventModerators/getEventModeratorsResponse";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { RootState } from "../../store/store";

interface EventParticipatingProps {
    id: string;
    event: EventsResponse;
    getEventModerators: any;
    moderators: any[];
    ChatServiceInstance: ChatServiceInterface
}

function Index({ id, event, getEventModerators, moderators, ChatServiceInstance }: EventParticipatingProps) {

    const userRoles = useSelector((state: any) => state.getUser.userRoles)
    const user = useSelector((state: RootState) => state.getUser.userInfo)
    //const users = useSelector((state: any) => state.getUser.usersLimit || []);
    //const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
    //const clubMembers = useSelector((state: any) => state.getClub.clubMembers || []);
    const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
    const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
    //const clubsOrg = useSelector((state: any) => state.getClub.getClubsOrg || []);

    const dispatch = useDispatch()
    const [tabActive, setTabActive] = useState(0);
    const [attendees, setAttendees] = useState([])
    const [checkins, setCheckins] = useState([])
    const [interested, setInterested] = useState([])
    const [notInterested, setNotInterested] = useState([])
    const [invited, setInvited] = useState([])

    const [eventRoster, setEventRoster] = useState<any>([]);

    const [countAttendees, setCountAttendees] = useState(0)
    const [countInterested, setCountInterested] = useState(0)
    const [countNotInterested, setCountNotInterested] = useState(0)
    const [countInvited, setCountInvited] = useState(0)

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [typeFiter, setTypeFilter] = useState('1');
    const [setmember, setSetMember] = useState<any>('pass');

    const [isChecker, setIsChecker] = useState(false);

    const [loadingCheckin, setLoadingCheckin] = useState(false);
    const [userCheckin, setUserCheckin] = useState('');

    function handleOnClick(step: number) {
        setTabActive(step);
    }

    const [showModal, setShowModal] = useState(false);

    function handleCloseModal() {
        setShowModal(false)
    };

    const isPossibleChecker = () => {
        // Check in by creator
        if (event.creator === user?.username) {
            setIsChecker(true);
            return;
        }

        // Check in by facilitators
        if (event.schedule.map(s => s.facilitators).flat().some(f => f.username ? f.username === user?.username : false)) {
            setIsChecker(true);
            return;
        }

        // Ensure isChecker won't stay active
        setIsChecker(false);
    }

    useEffect(() => {
        isPossibleChecker();
        if (user) {
            dispatch<any>(getUserFriends(user.username));
            dispatch<any>(getUserPossibleConnections(user.username));
        }
    }, [])

    useEffect(() => {
        isPossibleChecker();
        if (user) {
            dispatch<any>(getUserFriends(user.username));
            dispatch<any>(getUserPossibleConnections(user.username));
        }
    }, [id, user])

    const clubEventType = () => {
        if (event.club_type) {
            if (event.club_type === "CLUB") {
                return t('label__clubRoster')
            }

            if (event.club_type === "TEAM") {
                return t('label__teamRoster')
            }

            if (event.club_type === "COMM") {
                return t('label__communityRoster')
            }
        }
        return "Event Roster"
    }

    function checkIn(username: string, e: any, isUnregistered: boolean) {
        e.preventDefault();
        setLoadingCheckin(true);
        setUserCheckin(username);

        dispatch<any>(getCheckInEvent(id, username, isUnregistered))
            .then((results: any) => {
                setLoadingCheckin(false);
                setUserCheckin('');
                reloadAttendees();
            })
            .catch(console.log)
    }

    function checkOut(username: string, e: any) {
        e.preventDefault();
        dispatch<any>(getCheckOutEvent(id, username))
            .then((results: any) => {
                reloadAttendees();
            })
            .catch(console.log)
    }

    const sortEventRoster = (a: any, b: any) => {
        if (a.registered && !b.registered) return -1
        if (!a.registered && b.registered) return 1
        if ((a.registered && b.registered) || (!a.registered && !b.registered)) return 0
    }

    const reloadAttendees = () => {
        Backend(
            MicroServices.Events,
            `/event/${id}/related-users`)
            .then((r) => r.json())
            .then((data) => {
                //Get user lists
                //console.log('data', data)
                const checkin = data.CHECKIN?.map((item: any) => {
                    return {
                        id: item.username,
                        name: item.name ? item.name : item.username,
                        username: item.username,
                        type: 'C',
                        image: item.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
                        unregistered: false,
                    }
                }) || [];
                //console.log('checkin', checkin)
                const rsvped = data.RSVPO?.map((item: any) => {
                    return {
                        id: item.username,
                        name: item.name,
                        username: item.username,
                        type: 'R',
                        image: item.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
                        unregistered: false,
                    }
                }) || [];
                //console.log('rsvped', rsvped)
                const interestCount = data.I?.map((item: any) => {
                    return {
                        id: item.username,
                        name: item.name,
                        username: item.username,
                        type: 'I',
                        image: item.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
                        unregistered: false,
                    }
                }) || [];
                //console.log('interestCount', interestCount)
                const notinterestCount = data.NI?.map((item: any) => {
                    return {
                        id: item.username,
                        name: item.name,
                        username: item.username,
                        type: 'NI',
                        image: item.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
                        unregistered: false,
                    }
                }) || [];
                //console.log('notinterestCount', notinterestCount)

                const invitedCount = data.INVITED?.map((item: any) => {
                    return {
                        id: item.username,
                        name: item.name,
                        username: item.username,
                        type: 'INVITED',
                        image: item.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
                        unregistered: !!item.LSI1
                    }
                }) || [];
                //console.log('invitedCount', invitedCount)

                //Remove duplicates from rsvp and checkin concat
                let attendants = (rsvped || []).filter((att: any) => !checkin.find((ch: any) => ch.id === att.id))//.concat(checkin || [])

                //Set user lists
                setCheckins(checkin)
                setAttendees(attendants)
                setInterested(interestCount)
                setNotInterested(notinterestCount)
                setInvited(invitedCount)

                setCountAttendees(attendants ? attendants.length : 0)
                setCountInterested(interestCount ? interestCount.length : 0)
                setCountNotInterested(notinterestCount ? notinterestCount.length : 0)
                setCountInvited(invitedCount ? invitedCount.length : 0)

            })
            .catch(() => console.log('Error getting event participants'));
    };

    useEffect(() => {
        /*dispatch(getUsersWithLimit({
            org: organization.id
        }))*/
        reloadAttendees();

        Backend(MicroServices.Events, `/event/${event.event_id}/roster`)
            .then((r) => r.json())
            .then((data) => {
                setEventRoster(data.sort(sortEventRoster).filter((d: any) => !d.fullname.includes("undefined")))
            })
            .catch(() => {
                console.log('Error')
            })

        /*if (event.club_id && event.club_id !== '') {
            dispatch<any>(getClubMembers(event.club_id, user.username))
        }*/
    }, [id]);

    const handleDots = (member: any, username: string) => {
        setTypeFilter('1');
        setSetMember(member);

        //The connection is a friend?
        if (userFriends.find((user: any) => user.friendUsername === username)) {
            setTypeFilter('2');
        }
        //Is a possible connection?
        else if (userConnections.find((user: any) => user.usernameConnection === username)) {
            setTypeFilter('1');
        }
        //The users cannot be friends
        else {
            setTypeFilter('pass');
        }

        setOpenModal(!openModal);
    }

    const [searchString, setSearchString] = useState<string>('')

    const handleSearchString = (e: any) => {
        setSearchString(e)
    }

    return (
        <div className="container-members-club">
            <div className="tabsParticipanting" >
                <div className={`tabParticipanting ${tabActive === 0 && 'active'}`} onClick={() => handleOnClick(0)}>
                    <span>{countAttendees + countInterested + countNotInterested + countInvited + (checkins ? checkins.length : 0)}</span>
                    RSVPs
                </div>

                {(event.club_ids && event.club_ids.length > 0) ?
                    (<div className={`tabParticipanting ${tabActive === 1 && 'active'}`} onClick={() => handleOnClick(1)}>
                        <span>{eventRoster ? eventRoster.length : 0}</span>
                        {`${clubEventType()}`}
                    </div>) :
                    (
                        <div className={`tabParticipanting ${tabActive === 2 && 'active'}`} onClick={() => handleOnClick(2)}>
                            <span>{eventRoster ? eventRoster.length : 0}</span>
                            {t('label__schoolRoster')}
                        </div>
                    )}

                <div className="filterFILTERInputSearchUser">
                    <div className="filterInputSearchUser">
                        <FontAwesomeIcon
                            icon="search"
                            size='sm'
                            color={WHITE}
                            className="search  "
                        />
                        <input
                            className="inputSearchUser"
                            style={{ backgroundColor: "var(--t-blue)" }}
                            onChange={(e: any) => handleSearchString(e.target.value)}
                            type="text"
                            placeholder={t('comm__search')}
                        />
                    </div>
                </div>
            </div>


            {tabActive === 0 ?
                <div className="listMembersClub tabRSVP">
                    {checkins?.filter((member: any) => searchString === '' || member.name.toLowerCase().includes(searchString.trim().toLowerCase()))
                        .map((member: any) => (
                            <div className={`${(isChecker) && ('cardMember')} userCheckin`} key={member.id} >
                                <img src={member.image} alt="" className="round-avatar" />
                                <p>{member.name}</p>
                                {(isChecker) ? (
                                    <div className="buttonRSVP">
                                        <div className="checkin">
                                            Checked in
                                        </div>
                                        <div className="checkout" onClick={(e) => checkOut(member.username, e)}>
                                            <FontAwesomeIcon
                                                icon="door-open"
                                                size='lg'
                                                color={WHITE}
                                                className=" "
                                            />
                                            <p className="textCheckout">Check-out</p>
                                        </div>
                                    </div>
                                )
                                    : (
                                        <div>
                                            <FontAwesomeIcon
                                                icon="ellipsis-v-alt"
                                                size='lg'
                                                color={BROWN}
                                                className="ellipsis-v-alt   cursor"
                                                onClick={() => handleDots(member, member.username)}
                                            />
                                            {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                                        </div>
                                    )}
                            </div>
                        ))}

                    {attendees?.filter((member: any) => searchString === '' || member.name.toLowerCase().includes(searchString.trim().toLowerCase()))
                        .map((member: any) => (
                            <div className={`${(isChecker) && ('cardMember')} userGoing`} key={member.id} >
                                <img src={member.image} alt="" className="round-avatar" />
                                <p>{member.name}</p>
                                {(isChecker) ? (
                                    (loadingCheckin && userCheckin === member.username) ? (
                                        <div className="icon-spinner-third buttonSendSignUp" />
                                    ) : (
                                        <div className="option" onClick={(e) => checkIn(member.username, e, member.unregistered ?? !member.registered)}>
                                            <label className="content-input">
                                                <input type="radio" name="role-button" value="" />
                                                <i></i>
                                            </label>
                                            <label htmlFor="students">Check in</label>
                                        </div>
                                    )
                                )
                                    : (
                                        <div>
                                            <FontAwesomeIcon
                                                icon="ellipsis-v-alt"
                                                size='lg'
                                                color={BROWN}
                                                className="ellipsis-v-alt   cursor"
                                                onClick={() => handleDots(member, member.username)}
                                            />
                                            {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                                        </div>
                                    )}
                            </div>
                        ))}


                    {interested?.filter((member: any) => searchString === '' || member.name.toLowerCase().includes(searchString.trim().toLowerCase()))
                        .map((member: any) => (
                            <div className={`${(isChecker) && ('cardMember')} userInterested`} key={member.id} >
                                <img src={member.image} alt="" className="round-avatar" />
                                <p>{member.name}</p>
                                {(isChecker) ? (
                                    (loadingCheckin && userCheckin === member.username) ? (
                                        <div className="icon-spinner-third buttonSendSignUp" />
                                    ) : (
                                        <div className="option" onClick={(e) => checkIn(member.username, e, member.unregistered ?? !member.registered)}>
                                            <label className="content-input">
                                                <input type="radio" name="role-button" value="" />
                                                <i></i>
                                            </label>
                                            <label htmlFor="students">Check in</label>
                                        </div>
                                    )
                                )
                                    : (
                                        <div>
                                            <FontAwesomeIcon
                                                icon="ellipsis-v-alt"
                                                size='lg'
                                                color={BROWN}
                                                className="ellipsis-v-alt   cursor"
                                                onClick={() => handleDots(member, member.username)}
                                            />
                                            {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                                        </div>
                                    )}
                            </div>
                        ))}

                    {invited?.filter((member: any) => searchString === '' || member.name.toLowerCase().includes(searchString.trim().toLowerCase()))
                        .map((member: any) => (
                            <div className={`${(isChecker) && ('cardMember')} userInvited`} key={member.id} >
                                <img src={member.image} alt="" className="round-avatar" />
                                <div className="flex-column-invited">
                                    <p>{member.name}</p>
                                    {(member.unregistered) && (<div className="parragraphLavender">{t('label_userUnregistered')}</div>)}
                                </div>
                                {(isChecker) ? (
                                    (loadingCheckin && userCheckin === member.username) ? (
                                        <div className="icon-spinner-third buttonSendSignUp" />
                                    ) : (
                                        <div className="option" onClick={(e) => checkIn(member.username, e, member.unregistered ?? !member.registered)}>
                                            <label className="content-input">
                                                <input type="radio" name="role-button" value="" />
                                                <i></i>
                                            </label>
                                            <label htmlFor="students">Check in</label>
                                        </div>
                                    )
                                )
                                    : (
                                        <div>
                                            <FontAwesomeIcon
                                                icon="ellipsis-v-alt"
                                                size='lg'
                                                color={BROWN}
                                                className="ellipsis-v-alt   cursor"
                                                onClick={() => handleDots(member, member.username)}
                                            />
                                            {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                                        </div>
                                    )}
                            </div>
                        ))}

                    {notInterested?.filter((member: any) => searchString === '' || member.name.toLowerCase().includes(searchString.trim().toLowerCase()))
                        .map((member: any) => (
                            <div className={`${(isChecker) && ('cardMember')} userNoCheckin`} key={member.id} >
                                <img src={member.image} alt="" className="round-avatar" />
                                <p>{member.name} </p>
                                {(isChecker) ? (
                                    (loadingCheckin && userCheckin === member.username) ? (
                                        <div className="icon-spinner-third buttonSendSignUp" />
                                    ) : (
                                        <div className="option" onClick={(e) => checkIn(member.username, e, member.unregistered ?? !member.registered)}>
                                            <label className="content-input">
                                                <input type="radio" name="role-button" value="" />
                                                <i></i>
                                            </label>
                                            <label htmlFor="students">Check in</label>
                                        </div>
                                    )
                                )
                                    : (
                                        <div>
                                            <FontAwesomeIcon
                                                icon="ellipsis-v-alt"
                                                size='lg'
                                                color={BROWN}
                                                className="ellipsis-v-alt   cursor"
                                                onClick={() => handleDots(member, member.username)}
                                            />
                                            {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                                        </div>
                                    )
                                }
                            </div>
                        ))}
                </div>
                : tabActive === 1 ?
                    <div className="listMembersClub">
                        {eventRoster?.filter((member: any) => searchString === '' || member.fullname.toLowerCase().includes(searchString.trim().toLowerCase()))
                            .map((member: any) => (
                                <div className={`${(isChecker) && ('cardMember')}`} key={member.username} >
                                    <img src={member.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} alt="" className="round-avatar" />
                                    <p>
                                        {member.fullname}
                                        {!member.registered &&
                                            <><br /><p className="unregistered-lavender">{t('label_userUnregistered')}</p></>
                                        }
                                    </p>

                                    {(isChecker) ? (
                                        (loadingCheckin && userCheckin === member.username) ? (
                                            <div className="icon-spinner-third buttonSendSignUp" />
                                        ) : (
                                            <div className="option" onClick={(e) => checkIn(member.username, e, member.unregistered ?? !member.registered)}>
                                                <label className="content-input">
                                                    <input type="radio" name="role-button" value="" />
                                                    <i></i>
                                                </label>
                                                <label htmlFor="students">Check in</label>
                                            </div>
                                        )
                                    )
                                        : (
                                            <div>
                                                <FontAwesomeIcon
                                                    icon="ellipsis-v-alt"
                                                    size='lg'
                                                    color={BROWN}
                                                    className="ellipsis-v-alt   cursor"
                                                    onClick={() => handleDots(member, member.username)}
                                                />
                                                {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                                            </div>
                                        )
                                    }
                                </div>
                            ))}
                    </div>
                    :
                    <TabSchoolRoaster
                        searchString={searchString}
                        checkIn={checkIn}
                        id={id}
                        isChecker={isChecker}
                        loadingCheckin={loadingCheckin}
                        userCheckin={userCheckin}
                        eventRoster={eventRoster}
                        ChatServiceInstance={ChatServiceInstance}
                    />
            }
        </div>
    );
}

export default Index;