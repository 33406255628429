import { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import {
    ListComm
} from "../../components/signupsetup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faArrowsRotate } from "@fortawesome/pro-thin-svg-icons";
import { ProcessInterface } from "../../containers/SetupHealer/SetupHealer";
import { IClubExtended } from '@vibe/sdk/dist/interfaces/Club/ClubInterface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import CommunityCard, { CardInterface, isConnectionsInterface } from '../BaseComponents/Communities/CommunityCard';
import { ClubDigestExtra } from '@vibe/sdk/dist/interfaces/Roster/getUserClubs';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { ContextSuccess } from '../../Context';
import { getMyClubs } from '../../store/actions/clubActions';
import { getConnection } from '../../helpers/modalities/MList';
import { TitleContent } from '../signupsetuphealerind';
import { Scroll_Content_Signup } from '../signupsetuphealer/Introduction';

const Communities = ({
    processData,
    isSummary,
}: {
    processData: ProcessInterface
    isSummary?: boolean
}) => {

    const dispatch = useDispatch();

    const currentSuccess = useContext(ContextSuccess);

    const communities: IClubExtended[] = useSelector((state: RootState) => state.getClub.getClubsOrg);
    const listClubs: ClubDigestExtra[] = useSelector((state: RootState | any) => state.getClub.getMyClubs);
    const username = useSelector((state: RootState | any) => state.getUser.userInfo.username);

    const [cardsActive, setCardsActive] = useState<string[]>([]);
    const [clubs, setClubs] = useState<CardInterface[]>([]);

    const handleCardActive = (e: string, action: boolean) => {
        if (action) {
            setCardsActive([...cardsActive, e])
        }
        else {
            setCardsActive(cardsActive.filter(c => c !== e))
        }
    }

    const mapCommunities = () => {
        if (isSummary) {
            const myClubs: CardInterface[] = [];
            (communities || []).forEach((comm) => {
                const connection = getConnection(comm.id || '', listClubs);
                if (connection.followed || (connection.joined !== 'NO')) {
                    myClubs.push({
                        id: comm.id || '',
                        name: comm.name || '',
                        description: comm.description || '',
                        joined: connection.joined,
                        followed: connection.followed,
                        guides: [...(comm.advisorsExtended ?? []), ...(comm.advInvites ?? []).map((adv) => ({
                            name: adv.name,
                        }))],
                        members: comm.members || 0,
                        followers: comm.followers ?? 0,
                        orgId: comm.org_id,
                        modalitiesIds: comm.modalitiesIds || [],
                    });
                }
            })

            setClubs(myClubs);
        }
        else {
            setClubs((communities || []).map((comm) => {
                const connection = getConnection(comm.id || '', listClubs);
                return {
                    id: comm.id || '',
                    name: comm.name || '',
                    description: comm.description || '',
                    joined: connection.joined,
                    followed: connection.followed,
                    guides: [...(comm.advisorsExtended ?? []), ...(comm.advInvites ?? []).map((adv) => ({
                        name: adv.name,
                    }))],
                    members: comm.members || 0,
                    followers: comm.followers ?? 0,
                    orgId: comm.org_id,
                    modalitiesIds: comm.modalitiesIds || [],
                }
            }))
        }
    }

    const followCommunity = (club_id: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.userAsFollower(club_id, username, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    dispatch(getMyClubs(username));
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error trying to follow", `Please, try later to follow the community.`);
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error trying to follow", error);
            })
    };

    const joinCommunity = (club_id: string) => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Club.userAsJoinRequest(club_id, username, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    dispatch(getMyClubs(username));
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error trying to join", `Please, try later to join the community.`);
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, "Error trying to join", error);
            })
    };

    const handleOnClick = (type: 'join' | 'follow', data: string, action: boolean) => {
        //@todo function on action, on click when already requested join or follow
        switch (type) {
            case 'follow':
                followCommunity(data);
                break;
            case 'join':
                joinCommunity(data);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if ((listClubs || []).length > 0) processData.setTabsProcessed([...processData.tabsProcessed, 3]);
        mapCommunities();
    }, [listClubs, communities, isSummary])

    return (
        <Scroll_Content_Signup>
            {!isSummary && <TitleContent theme={{ align: 'center' }}>Join communities</TitleContent>}
            <ListComm isSummary={isSummary || false}>
                {clubs.map((area) => (
                    <CommunityCard
                        active={cardsActive.includes(area.id)}
                        data={area}
                        onClickButton={handleOnClick}
                        onActivation={handleCardActive}
                        isSummary={isSummary}
                    />
                ))}
            </ListComm>
        </Scroll_Content_Signup>
    )
}

export default Communities;
