import { useEffect, useState } from 'react'
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Login from '../../../containers/Login/Login';

const ModalLogin = ({
    show,
    setShowModal,
    onlyLogin = false,
    token,
    loginUseInvite,
    orgId,
}: {
    show: boolean,
    setShowModal: (type: boolean) => void,
    onlyLogin: boolean,
    token?: string,
    loginUseInvite?: boolean,
    orgId?: string;
}) => {
    console.log('callback on ModalLogin.tsx', token)
    return (
        <Modal className="modalMS" show={show} onHide={() => setShowModal(false)}>
            <IconClicker
                onClick={() => setShowModal(false)}
                icon="times"
                size='xl'
                color={'black'}
                onlyLogin={onlyLogin}
            />
            <WrapperLogin>
                <Login
                    stayHere={true}
                    token={token}
                    loginUseInvite={loginUseInvite}
                    orgId={orgId}
                />
            </WrapperLogin>
            {/*(!onlyLogin) ? (
                <Modal.Body>
                    {/*
                        <SignupHealerInd />
                        <SignupHealer />
                        }
                    <WrapperModals height={'80vh'}>
                        <SignupSeeker />
                    </WrapperModals>
                    <WrapperModals height={'50vh'}>
                        <Login stayHere={true} />
                    </WrapperModals>
                </Modal.Body>
            ) : (
                <WrapperLogin>
                    <Login stayHere={true} />
                </WrapperLogin>
            )*/}
        </Modal>
    )
}

const WrapperLogin = styled.div`
    padding: 0px 15px 15px 15px;
    margin-top: -15px;
`;
interface WrapProps {
    height: string;
}

const WrapperModals: any = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: ${(props: WrapProps) => props.height};
    overflow: scroll;

    width: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(158,158,158,1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(158,158,158,1);
    box-shadow: 0px 0px 10px 0px rgba(158,158,158,1);
    border-radius: 12px;
    padding: 0 25px;
`;

const IconClicker = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    left: 92%; //${({ onlyLogin }: { onlyLogin: boolean }) => onlyLogin ? '92%' : '97%'};
`;


export default ModalLogin