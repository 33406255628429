import React, { useState, useContext, useEffect, useMemo } from 'react'
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrash, faLink } from "@fortawesome/pro-regular-svg-icons";
import { faSpotify, faApple } from '@fortawesome/free-brands-svg-icons';
import { Row2_70, MediaOption } from "../../components/signupsetuphealerind"
import ModalMedia, { ModalMediaTabsType } from '../../components/signupsetuphealerind/ModalMedia';
import ModalUnsaved from '../BaseComponents/Modals/ModalUnsaved';
import { BROWN, ClickableIcon, RED } from '../SuperCenter/Modalities/Modals/components';
import UserMedia from '@vibe/sdk/dist/interfaces/Roster/userMedia';
import UserPodcast from '@vibe/sdk/dist/interfaces/Roster/userPodcast';
import UserSocialMedia from '@vibe/sdk/dist/interfaces/Roster/userSocialMedia';
import { useDispatch, useSelector } from 'react-redux';
import { Header, IconPlus, ListServicesEmp, SubTitle, Title } from '../../components/signupsetuphealerind/MyServices';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { ContextSuccess } from "../../Context";
import { uploadAsset, updateUser, getUser, updateUserBio } from '../../store/actions/userActions';

const Publications = () => {

  const dispatch = useDispatch();
  const currentSuccess = useContext(ContextSuccess);
  const username = useSelector((state: any) => state.getUser.userInfo.username);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const [tabActive, setTabActive] = useState(1)
  const [showModalMedia, setShowModalMedia] = useState(false)
  const [modalMediaDefaultTab, setModalMediaDefaultTab] = useState<ModalMediaTabsType>(1);
  const [userPublications, setUserPublications] = useState<UserMedia[]>([])
  const [userPublicationEdit, setUserPublicationEdit] = useState<UserMedia>()
  const [userPodcastEdit, setUserPodcastEdit] = useState<UserPodcast>()
  const [showModalMediaEdit, setShowModalMediaEdit] = useState(false)
  const [deleteType, setDeleteType] = useState<'pub' | 'pod' | 'sm'>();
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [userPodcasts, setUserPodcasts] = useState<UserPodcast[]>([])
  const [userSocialMedia, setUserSocialMedia] = useState<UserSocialMedia[]>([])

  const userSocialMediaTable = useMemo(() => userSocialMedia.filter(({platform}) => !platform.startsWith('Con') && !platform.startsWith('Acc')), [userSocialMedia])

  const contactInfo = useMemo(() => ({
      email: userSocialMedia.find(({platform}) => platform === 'ContactEmail')?.link ?? undefined,
      phone: userSocialMedia.find(({platform}) => platform === 'ContactPhone')?.link ?? undefined,
  }), [userSocialMedia])

  const showModal = (tab: ModalMediaTabsType) => {
    setModalMediaDefaultTab(tab);
    setShowModalMedia(true);
  }

  const showEditPublication = (userPublicationE: UserMedia) => {
    setUserPublicationEdit(userPublicationE);
    setUserPodcastEdit(undefined);
    showEdit(1);
  }

  const showEdit = (tab: ModalMediaTabsType) => {
    setModalMediaDefaultTab(tab);
    setShowModalMediaEdit(true);
  }

  const handleDelete = (type: 'pub' | 'pod' | 'sm', id: string) => {
    setDeleteType(type);
    setDeleteId(id);
    setDeleteModal(true);
  }

  const showEditPodcast = (userPodcastE: UserPodcast) => {
    setUserPublicationEdit(undefined);
    setUserPodcastEdit(userPodcastE);
    showEdit(2);
  }

  const showEditSocialMedia = () => {
    setUserPublicationEdit(undefined);
    setUserPodcastEdit(undefined);
    showEdit(3);
  }

  const showEditContact = () => {
    setUserPublicationEdit(undefined);
    setUserPodcastEdit(undefined);
    showEdit(4);
  }

  const hideModal = () => {
    setShowModalMedia(false);
    setShowModalMediaEdit(false);
    if (username) {
      reloadUserPodcasts();
      reloadUserPublications();
      reloadUserSocial();
    }
  }

  const reloadUserPublications = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getUserMediaByUsername(username!, dataSDK.token)
      })
      .then((response) => {
        if (response.statusCode === 200 && Array.isArray(response.body)) {
          setUserPublications(response.body);
        }
      })
  }

  const reloadUserPodcasts = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getUserPodcastByUsername(username!, dataSDK.token)
      })
      .then((response) => {
        if (response.statusCode === 200 && Array.isArray(response.body)) {
          setUserPodcasts(response.body);
        }
      })
  }

  const reloadUserSocial = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getUserSocialMedia(username!, dataSDK.token)
      })
      .then((response) => {
        if (response.statusCode === 200 && Array.isArray(response.body)) {
          setUserSocialMedia(response.body);
        }
      })
  }

  const onHideDelete = (type?: string) => {
    if (!type || type === 'discard' || !deleteType || !deleteId) {
      setDeleteType(undefined);
      setDeleteId(undefined);
      setDeleteModal(false);
      return;
    }
    if (deleteType === 'pub') {
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Roster.deleteUserMedia(deleteId, dataSDK.token);
        })
        .then((results) => {
          if (Object.keys(results?.body || {}).length === 0) {
            currentSuccess?.setSuccessData(true, true, 'Publication deleted succesfully');
            setDeleteType(undefined);
            setDeleteId(undefined);
            setDeleteModal(false);
            dispatch(getUser(userInfo.username));
            reloadUserPublications();
            return;
          }
          currentSuccess?.setSuccessData(false, true, 'Error deleting publication');
          setDeleteType(undefined);
          setDeleteId(undefined);
          setDeleteModal(false);
          return;
        })
    }
    if (deleteType === 'pod') {
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Roster.deleteUserPodcast(deleteId, dataSDK.token);
        })
        .then((results) => {
          if (Object.keys(results?.body || {}).length === 0) {
            currentSuccess?.setSuccessData(true, true, 'Podcast deleted succesfully');
            setDeleteType(undefined);
            setDeleteId(undefined);
            setDeleteModal(false);
            dispatch(getUser(userInfo.username));
            reloadUserPodcasts();
            return;
          }
          currentSuccess?.setSuccessData(false, true, 'Error deleting podcast');
          setDeleteType(undefined);
          setDeleteId(undefined);
          setDeleteModal(false);
          return;
        })
    }
    if (deleteType === 'sm') {
      getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
          return dataSDK.sdk.Roster.deleteUserSocialMedia(username, deleteId as 'Facebook' | 'YouTube' | 'Instagram' | 'Twitter' | 'LinkedIn' | 'Pinterest' | 'Snapchat' | 'TikTok' | 'Zaahah', dataSDK.token)
        })
        .then((results) => {
          if (Object.keys(results?.body || {}).length === 0) {
            currentSuccess?.setSuccessData(true, true, `${deleteId.startsWith('C')? 'Contact Information' : 'Social media'} deleted succesfully`);
            setDeleteType(undefined);
            setDeleteId(undefined);
            setDeleteModal(false);
            dispatch(getUser(userInfo.username));
            reloadUserSocial();
            return;
          }
          currentSuccess?.setSuccessData(false, true, `Error deleting ${deleteId.startsWith('C')? 'Contact Information' : 'Social media'}`);
          setDeleteType(undefined);
          setDeleteId(undefined);
          setDeleteModal(false);
          return;
        })
    }
  }

  useEffect(() => {
    if (username) {
      reloadUserPodcasts();
      reloadUserPublications();
      reloadUserSocial();
    }
  }, [username])

  return (
    <ContainerMediaIntro>
      <AddMedia>
        <MediaOption onClick={() => showModal(1)}>
          Add Publication
          <IconDrop
            icon={faPlus}
            size='lg'
            color={"black"}
            style={{
              fontSize: '20px'
            }}
          />
        </MediaOption>
        <MediaOption onClick={() => showModal(2)}>
          Add Podcast
          <IconDrop
            icon={faPlus}
            size='lg'
            color={"black"}
            style={{
              fontSize: '20px'
            }}
          />
        </MediaOption>
        <MediaOption onClick={() => showModal(3)}>
          Add Social Media
          <IconDrop
            icon={faPlus}
            size='lg'
            color={"black"}
            style={{
              fontSize: '20px'
            }}
          />
        </MediaOption>
        <MediaOption onClick={() => showModal(4)}>
          Add Contact Information
          <IconDrop
            icon={faPlus}
            size='lg'
            color={"black"}
            style={{
              fontSize: '20px'
            }}
          />
        </MediaOption>
      </AddMedia>
      <Row2_70>
        <TabsMedia>
          <Tab theme={{ active: tabActive === 1 && 'active' }} onClick={() => setTabActive(1)}>Publications</Tab>
          <Tab theme={{ active: tabActive === 2 && 'active' }} onClick={() => setTabActive(2)}>Podcasts</Tab>
          <Tab theme={{ active: tabActive === 3 && 'active' }} onClick={() => setTabActive(3)}>Social media</Tab>
          <Tab theme={{ active: tabActive === 4 && 'active' }} onClick={() => setTabActive(4)}>Contact Information</Tab>
        </TabsMedia>
        <TabsContent>
          {tabActive === 1 ?
            userPublications && userPublications.length > 0 ? (
              <TableMedia>
                <TableMediaRow>
                  <TableMediaNameCell><CellTitle>Name</CellTitle></TableMediaNameCell>
                  <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                </TableMediaRow>
                {userPublications.map((p) => (
                  <TableMediaRow>
                    <TableMediaNameCell>
                      <CellLink href={p.link} target="_blank" rel="noopener noreferrer">{p.name}</CellLink>
                    </TableMediaNameCell>
                    <TableMediaActionsCell>
                      <ClickableIcon
                        icon={faPen}
                        onClick={() => showEditPublication(p)}
                      />
                      <ClickableIcon
                        icon={faTrash}
                        color={RED}
                        onClick={() => handleDelete('pub', p.id)}
                      />
                    </TableMediaActionsCell>
                  </TableMediaRow>
                ))}
              </TableMedia>
            ) : (
              <ListServicesEmp>
                <Header>
                  <Title>No info yet.</Title>
                  <SubTitle>Here you will see the titles you upload</SubTitle>
                </Header>
              </ListServicesEmp>
            )

            : tabActive === 2 ?
              userPodcasts && userPodcasts.length > 0 ? (
                <TableMedia>
                  <TableMediaRow>
                    <TableMediaNameCell><CellTitle>Name</CellTitle></TableMediaNameCell>
                    <TableMediaNameCell><CellTitle>Links</CellTitle></TableMediaNameCell>
                    <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                  </TableMediaRow>
                  {userPodcasts.map((p) => (
                    <TableMediaRow>
                      <TableMediaLinkCell>
                        <CellName>{p.name}</CellName>
                      </TableMediaLinkCell>
                      <TableMediaLinkCellIcons>
                        {p.spotifyLink && (
                          <ClickableIcon
                            color="#000"
                            icon={faSpotify}
                            onClick={() => window.open(p.spotifyLink, '_blank', 'rel=noopener noreferrer')}
                          />
                        )}
                        {p.appleLink && (
                          <ClickableIcon
                            color="#000"
                            icon={faApple}
                            onClick={() => window.open(p.appleLink, '_blank', 'rel=noopener noreferrer')}
                          />
                        )}
                        {p.otherLink && (
                          <ClickableIcon
                            color="#000"
                            icon={faLink}
                            onClick={() => window.open(p.otherLink, '_blank', 'rel=noopener noreferrer')}
                          />
                        )}
                      </TableMediaLinkCellIcons>
                      <TableMediaActionsCell>
                        <ClickableIcon
                          icon={faPen}
                          onClick={() => showEditPodcast(p)}
                        />
                        <ClickableIcon
                          icon={faTrash}
                          color={RED}
                          onClick={() => handleDelete('pod', p.id)}
                        />
                      </TableMediaActionsCell>
                    </TableMediaRow>
                  ))}
                </TableMedia>
              ) : (
                <ListServicesEmp>
                  <Header>
                    <Title>No info yet.</Title>
                    <SubTitle>Here you will see the podcasts you upload</SubTitle>
                  </Header>
                </ListServicesEmp>
              )
              : tabActive === 3 ?
                (userSocialMediaTable.length > 0) ? (
                  <TableMedia>
                    <TableMediaRow>
                      <TableMediaNameCell><CellTitle>Social Network</CellTitle></TableMediaNameCell>
                      <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                    </TableMediaRow>
                    {userSocialMediaTable.map((p) => (
                      <TableMediaRow>
                        <TableMediaLinkCell>
                          <CellLink href={p.link} target="_blank" rel="noopener noreferrer">{p.platform !== 'Personal' ? p.platform : 'Website'}</CellLink>
                        </TableMediaLinkCell>
                        <TableMediaActionsCell>
                          <ClickableIcon
                            icon={faPen}
                            onClick={showEditSocialMedia}
                          />
                          <ClickableIcon
                            icon={faTrash}
                            color={RED}
                            onClick={() => handleDelete('sm', p.platform)}
                          />
                        </TableMediaActionsCell>
                      </TableMediaRow>
                    ))}
                  </TableMedia>
                )
                :
                (
                  <ListServicesEmp>
                    <Header>
                      <Title>No info yet.</Title>
                      <SubTitle>Here you will see your social media links</SubTitle>
                    </Header>
                  </ListServicesEmp>
                )
              : (contactInfo.email || contactInfo.phone) ? (
                <TableMedia>
                <TableMediaRow>
                    <TableMediaNameCell><CellTitle>Information</CellTitle></TableMediaNameCell>
                    <TableMediaNameCell><CellTitle>Value</CellTitle></TableMediaNameCell>
                    <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                </TableMediaRow>
                {contactInfo.email && <TableMediaRow>
                    <TableMediaLinkCell>
                        Email
                    </TableMediaLinkCell>
                    <TableMediaLinkCell>
                        {contactInfo.email}
                    </TableMediaLinkCell>
                    <TableMediaActionsCell>
                        <ClickableIcon
                            icon={faPen}
                            onClick={showEditContact}
                        />
                        <ClickableIcon
                                    icon={faTrash}
                                    color={RED}
                                    onClick={() => handleDelete('sm', 'ContactEmail')}
                                />
                    </TableMediaActionsCell>
                </TableMediaRow>}
                {contactInfo.phone && <TableMediaRow>
                    <TableMediaLinkCell>
                        Phone Number
                    </TableMediaLinkCell>
                    <TableMediaLinkCell>
                        {contactInfo.phone}
                    </TableMediaLinkCell>
                    <TableMediaActionsCell>
                        <ClickableIcon
                            icon={faPen}
                            onClick={showEditContact}
                        />
                        <ClickableIcon
                                    icon={faTrash}
                                    color={RED}
                                    onClick={() => handleDelete('sm', 'ContactPhone')}
                                />
                    </TableMediaActionsCell>
                </TableMediaRow>}
            </TableMedia>
            ) :
            (
                <ListServicesEmp>
                    <Header>
                        <Title>No info yet.</Title>
                        <SubTitle>Here you will see your Contact Information</SubTitle>
                    </Header>
                </ListServicesEmp>
            )
          }

        </TabsContent>
      </Row2_70>
      <ModalMedia
        showModal={showModalMedia}
        onHide={hideModal}
        defaultTab={modalMediaDefaultTab}
      />
      <ModalMedia
        showModal={showModalMediaEdit}
        onHide={hideModal}
        defaultTab={modalMediaDefaultTab}
        edit
        userPublication={userPublicationEdit}
        userPodcast={userPodcastEdit}
        userSocialMedia={userSocialMedia}
      />
      <ModalUnsaved show={deleteModal} onHide={onHideDelete} content="Do you want to delete?" deleteModal />
    </ContainerMediaIntro>

  )
}

const TableMedia = styled.div`
    width: 100%;
    overflow: scroll;
    text-align: left;
    margin-top: 15px;
`;

const TableMediaRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 16px 15px;

    &:nth-child(even) {
      background-color: #fffdfc;
    }
    &:nth-child(odd) {
      background-color: #f0f3f5;
    }
`;

const TableMediaNameCell = styled.div`
    width: 100%;
`;

const TableMediaLinkCell = styled.div`
    width: 100%;
`;
const TableMediaLinkCellIcons = styled(TableMediaLinkCell)`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const TableMediaActionsCell = styled.div`
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
`;

const CellName = styled.span`
    color: var(--black);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
`;

const CellLink = styled.a`
    color: blue;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
`;

const CellTitle = styled.span`
    color: var(--black);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
`;

const TabsMedia = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 30px 14px 0px 0px;
    border-radius: 7px 7px 0px 0px;
    border-bottom: 1px solid #cdcdcd;
    gap: 10px;
`

const Tab = styled.div.attrs(props => ({
  className: props.theme.active,
}))`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black);
    font-family: 'Inter';
    cursor:pointer;
    width: auto;
    padding: 0;

    &.active{
        font-family: Inter-Bold;
        color: black;
        border-bottom: 2px solid var(--electric-yellow-2);
    }
`

const TabsContent = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0px;
    border-radius: 0px 0px 7px 7px;
    height: calc(100% - 68px);
`

const AddMedia = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 15px;
    padding: 30px 10px 10px 10px;
    cursor: pointer;
`

const ContainerMediaIntro = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    height: 100%;
    width: 100%;
`

const IconDrop = styled(FontAwesomeIcon)`
    color: black;
    font-size: 18px;
`;

export default Publications