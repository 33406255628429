import { AnyAction } from "@reduxjs/toolkit";
import React from "react";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  GET_EVENT_ERROR,
  GET_EVENT_SUCCESS,
  START_GET_EVENT,

  START_EVENT_RSVP,
  EVENT_RSVP_SUCCESS,
  EVENT_RSVP_ERROR,

  START_EVENT_INTERESTED,
  EVENT_INTERESTED_SUCCESS,
  EVENT_INTERESTED_ERROR,

  GET_MY_EVENT_ERROR,
  GET_MY_EVENT_SUCCESS,
  START_GET_MY_EVENT,

  START_SAVE_EVENT,
  SAVE_EVENT_SUCCESS,
  SAVE_EVENT_ERROR,

  GET_CHECK_EVENT_ERROR,
  GET_CHECK_EVENT_SUCCESS,
  START_GET_CHECK_EVENT,

  CHECK_IN_EVENT_ERROR,
  CHECK_IN_EVENT_SUCCESS,
  START_CHECK_IN_EVENT,
  START_CHECK_OUT_EVENT,
  CHECK_OUT_EVENT_SUCCESS,
  CHECK_OUT_EVENT_ERROR,
  START_GET_COMPLETE_EVENTS,
  GET_COMPLETE_EVENTS_ERROR,
  GET_COMPLETE_EVENTS_SUCCESS,
  GET_MY_EVENTS_RSVP_ERROR,
  GET_MY_EVENTS_RSVP_SUCCESS,
  GET_MY_FRIENDS_EVENTS_ERROR,
  GET_MY_FRIENDS_EVENTS_SUCCESS,
  START_GET_MY_EVENTS_RSVP,
  START_GET_MY_FRIENDS_EVENTS,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  START_GET_EVENTS,
  START_GET_ORG_EVENTS,
  GET_ORG_EVENTS_SUCCESS,
  GET_ORG_EVENTS_ERROR,
  START_GET_PERTINENT_EVENTS,
  GET_PERTINENT_EVENTS_ERROR,
  GET_PERTINENT_EVENTS_SUCCESS,
  START_GET_MY_EVENTS,
  GET_MY_EVENTS_ERROR,
  GET_MY_EVENTS_SUCCESS,
  GET_CLUB_EVENTS_ERROR,
  GET_CLUB_EVENTS_SUCCESS,
  START_GET_CLUB_EVENTS,
  START_GET_HEAL_ORG_EVENTS,
  GET_HEAL_ORG_EVENTS_SUCCESS,
  GET_HEAL_ORG_EVENTS_ERROR,
  START_GET_HEAL_IND_EVENTS,
  GET_HEAL_IND_EVENTS_SUCCESS,
  GET_HEAL_IND_EVENTS_ERROR,
  GET_ORG_PAST_EVENTS_ERROR,
  GET_ORG_PAST_EVENTS_SUCCESS,
  START_GET_ORG_PAST_EVENTS,
} from "../constants/eventConstants";

import { toast } from "react-toastify";
import { getMyEventsIds } from "../../helpers/loginHelper";
import { reloadPostsAll } from "./postActions";
import { Backend, MicroServices, getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { IFilterEvents } from "@vibe/sdk/dist/interfaces/Events/getEventsFiltered";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";
import { EventsResponse, IGetEventSpecialFilters, TimeLimit } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { sortEventsProperly } from "../../helpers/eventHelper";

export const getClubEvents = (club_id: string, limit?: IGetEventSpecialFilters): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_CLUB_EVENTS });

    let trueLimit: IGetEventSpecialFilters = {};

    if (limit !== undefined) trueLimit = limit;
    else {
      trueLimit.limitTime = getEventLimitTime()
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEventsFiltered(true, { club_id }, trueLimit, undefined, dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200 && results.body && 'events' in results.body) {
          dispatch({
            type: GET_CLUB_EVENTS_SUCCESS,
            payload: sortEventsProperly(results.body.events),
          })
        }
        else {
          dispatch({
            type: GET_CLUB_EVENTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_CLUB_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const getEvents = (pastEvents: boolean, filters: IFilterEvents, username: string, otherFilters?: IGetEventSpecialFilters): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_EVENTS });

    let trueLimit: IGetEventSpecialFilters = {};

    if (otherFilters !== undefined) trueLimit = otherFilters;
    else {
      trueLimit.limitTime = getEventLimitTime()
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEventsFiltered(pastEvents, filters, trueLimit, username, dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200 && results.body && 'events' in results.body) {
          dispatch({
            type: GET_EVENTS_SUCCESS,
            payload: sortEventsProperly(results.body.events),
          })
        }
        else {
          dispatch({
            type: GET_EVENTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const getOrgEvents = (pastEvents: boolean, orgId: string, username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_ORG_EVENTS });

    const filters: IFilterEvents = {
      org_id: orgId,
    }
    const filtersSpecial: IGetEventSpecialFilters = {
      ignoreLimitTime: 'true'
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEventsFiltered(pastEvents, filters, filtersSpecial, username, dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200 && results.body && 'events' in results.body) {
          dispatch({
            type: GET_ORG_EVENTS_SUCCESS,
            payload: sortEventsProperly(results.body.events, 'ASC'),
          })
        }
        else {
          dispatch({
            type: GET_ORG_EVENTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ORG_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const getOrgPastEvents = (orgId: string, username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_ORG_PAST_EVENTS });

    const filters: IFilterEvents = {
      org_id: orgId,
    }
    const filtersSpecial: IGetEventSpecialFilters = {
      ignoreLimitTime: 'true'
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEventsFiltered(true, filters, filtersSpecial, username, dataSDK.token);
      })
      .then((results) => {
        if (results.statusCode === 200 && results.body && 'events' in results.body) {
          dispatch({
            type: GET_ORG_PAST_EVENTS_SUCCESS,
            payload: sortEventsProperly(results.body.events, 'ASC'),
          })
        }
        else {
          dispatch({
            type: GET_ORG_PAST_EVENTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ORG_PAST_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const getHealerOrgEvents = (pastEvents: boolean, healerOrg: string, username: string, filterEvents?: IGetEventSpecialFilters): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_HEAL_ORG_EVENTS });

    let trueLimit: IGetEventSpecialFilters = {};

    if (filterEvents !== undefined) trueLimit = filterEvents;
    else {
      trueLimit.limitTime = getEventLimitTime()
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEventsHealerOrg(pastEvents, healerOrg, username, trueLimit, dataSDK.token)
      })
      .then((results) => {
        if (results.statusCode === 200 && results.body && 'events' in results.body) {
          dispatch({
            type: GET_HEAL_ORG_EVENTS_SUCCESS,
            payload: sortEventsProperly(results.body.events),
          })
        }
        else {
          dispatch({
            type: GET_HEAL_ORG_EVENTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_HEAL_ORG_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const getHealerIndEvents = (pastEvents: boolean, healerUsername: string, username: string, filterEvents?: IGetEventSpecialFilters): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_HEAL_IND_EVENTS });

    let trueLimit: IGetEventSpecialFilters = {};

    if (filterEvents !== undefined) trueLimit = filterEvents;
    else {
      trueLimit.limitTime = getEventLimitTime()
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEventsHealerInd(pastEvents, healerUsername, username, trueLimit, dataSDK.token)
      })
      .then((results) => {
        if (results.statusCode === 200 && results.body && 'events' in results.body) {
          dispatch({
            type: GET_HEAL_IND_EVENTS_SUCCESS,
            payload: sortEventsProperly(results.body.events),
          })
        }
        else {
          dispatch({
            type: GET_HEAL_IND_EVENTS_ERROR,
            payload: (results.body as IResponseError).error || (results.body as IResponseError).message,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_HEAL_IND_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const saveEvent = (event: any, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_SAVE_EVENT });
    if (!event.club_id || event.club_id === '0') {
      delete event.club_id;
      event.category = 'SCHOOL';
    } else {
      event.category = event.club_type === 'TEAM' ? 'TEAM' : event.category;
    }
    Backend(MicroServices.Events, '/event', {
      method: 'POST',
      body: JSON.stringify(event),
    })
      .then((response) => response.json())
      .then((results) => {
        dispatch(reloadPostsAll({
          username: username || '',
          event_id: '',
          club_id: '',
          org_id: event.org_id,
          wall_id: '',
        }))
        dispatch({
          type: SAVE_EVENT_SUCCESS,
          payload: results
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: SAVE_EVENT_ERROR,
          payload: error,
        });
      });
  });

export const getEventInfo = (id: string, username: string): ThunkAction<Promise<EventsResponse>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_EVENT });

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEvent({ id: id || '', username }, dataSDK.token);
      })
      .then((response) => {
        if (response.statusCode === 200) {
          const results = response.body as EventsResponse;
          dispatch({
            type: GET_EVENT_SUCCESS,
            payload: results,
          })
          resolve(results);
        }
        else {
          const error = (response.body as IResponseError).error || (response.body as IResponseError).message;
          dispatch({
            type: GET_EVENT_ERROR,
            payload: error,
          })
          console.log(error);
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_EVENT_ERROR,
          payload: error,
        })
        console.log(error);
      })
  });

export const getEventRSVP =
  (id: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_EVENT_RSVP });
        Backend(
          MicroServices.Events,
          `/event/${id}/rsvp`, {
          method: 'POST',
          body: JSON.stringify({
            "username": username
          }),
        })
          .then((response) => response.json())
          .then((results) => {
            resolve(results);
            dispatch({
              type: EVENT_RSVP_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: EVENT_RSVP_ERROR,
              payload: error,
            });
          });
      });

export const getEventInterested =
  (id: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_EVENT_INTERESTED });
        Backend(
          MicroServices.Events,
          `/event/${id}/interested`, {
          method: 'POST',
          body: JSON.stringify({
            "username": username
          }),
        })
          .then((response) => response.json())
          .then((results) => {
            resolve(results);
            dispatch({
              type: EVENT_INTERESTED_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: EVENT_INTERESTED_ERROR,
              payload: error,
            });
          });
      });

export const getEventNotInterested =
  (id: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_EVENT_INTERESTED });
        Backend(
          MicroServices.Events,
          `/event/${id}/not-interested`, {
          method: 'POST',
          body: JSON.stringify({
            "username": username
          }),
        })
          .then((response) => response.json())
          .then((results) => {
            resolve(results);
            dispatch({
              type: EVENT_INTERESTED_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: EVENT_INTERESTED_ERROR,
              payload: error,
            });
          });
      });

export const getMyEvent = (id: string, with_limit: boolean): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_MY_EVENT });
    let limit: string | undefined = undefined;
    if (with_limit) {
      //Get limit
      const today = new Date()
      const year = today.getFullYear()
      const month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
      const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
      let offset = today.getTimezoneOffset() / 60.0
      const offset_part = offset > 0 ? 'm' : 'p'
      offset = Math.abs(offset)
      const offset_info = offset.toString().split('.')
      const off_hours = parseInt(offset_info[0]) < 10 ? `0${offset_info[0]}` : offset_info[0]
      const off_minutes = offset_info[1] ? '30' : '00'
      limit = `${year}-${month}-${day}-${offset_part}-${off_hours}-${off_minutes}`
    }

    Backend(
      MicroServices.Events,
      `/events?username=${id}&${limit ? `limit=${limit}` : 'ignoreLimit=true'}`)
      .then(async (response) => {
        if (response.status === 400) {
          const responseNotPending = await Backend(
            MicroServices.Events,
            `/events?username=${id}&${limit ? `limit=${limit}` : 'ignoreLimit=true'}`)
          return responseNotPending.json();
        }
        return response.json()
      })
      .then((results) => {
        dispatch({
          type: GET_MY_EVENT_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_MY_EVENT_ERROR,
          payload: error,
        })
      })
  });

export const getCheckEvent = (id: string, username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_CHECK_EVENT });
    Backend(
      MicroServices.Events,
      `/event/${id}/user-relation?username=${username}`)
      .then(async (response) => {
        if (response.status === 400) {
          const responseNotPending = await Backend(
            MicroServices.Events,
            `/events/${id}?user-relation=${username}`)
          return responseNotPending.json();
        }
        return response.json()
      })
      .then((results) => {
        dispatch({
          type: GET_CHECK_EVENT_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_CHECK_EVENT_ERROR,
          payload: error,
        })
      })
  });

export const getMyEventsComplete = (username: string, with_limit: boolean, limit?: string, events?: any[]): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_COMPLETE_EVENTS });
    getMyEventsIds(username, with_limit, limit, events ? events : [])
      .then((results) => {
        dispatch({
          type: GET_COMPLETE_EVENTS_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_COMPLETE_EVENTS_ERROR,
          payload: error,
        })
        console.error('Get My Events Complete:', error)
      })
  });

export const getCheckInEvent = (id: string, username: string, isUnregistered = false): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_CHECK_IN_EVENT });
    let isCorrect = true;

    let body;

    if (isUnregistered) {
      body = JSON.stringify({
        "email": username
      })
    }
    else {
      body = JSON.stringify({
        "username": username
      })
    }

    Backend(
      MicroServices.Events,
      `/event/${id}/checkin`, {
      method: 'POST',
      body: body,
    })
      .then((response) => {
        if (response.status !== 201) {
          isCorrect = false;
        }
        return response.json();
      })
      .then((results) => {
        dispatch({
          type: CHECK_IN_EVENT_SUCCESS,
          payload: results,
        })
        resolve(results)
        if (!isCorrect) {
          //toast.error(results.message);
        }
        else {
          //toast.success(results.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: CHECK_IN_EVENT_ERROR,
          payload: error,
        })
      })
  });

export const getCheckOutEvent = (id: string, username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_CHECK_OUT_EVENT });
    let isCorrect = true;

    Backend(
      MicroServices.Events,
      `/event/${id}/checkout`, {
      method: 'POST',
      body: JSON.stringify({
        "username": username
      }),
    })
      .then((response) => {
        if (response.status !== 201) {
          isCorrect = false;
        }
        return response.json();
      })
      .then((results) => {
        dispatch({
          type: CHECK_OUT_EVENT_SUCCESS,
          payload: results,
        })
        resolve(results)
        if (!isCorrect) {
          //toast.error(results.message);
        }
        else {
          //toast.error(results.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: CHECK_OUT_EVENT_ERROR,
          payload: error,
        })
      })
  });

export const getMyEventsRSVP = (username: string, with_limit: boolean, Limit?: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_MY_EVENTS_RSVP });
    let limit: string | undefined = undefined;
    if (!Limit) {
      if (with_limit) {
        //Get limit
        const today = new Date()
        const year = today.getFullYear()
        const month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
        const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
        let offset = today.getTimezoneOffset() / 60.0
        const offset_part = offset > 0 ? 'm' : 'p'
        offset = Math.abs(offset)
        const offset_info = offset.toString().split('.')
        const off_hours = parseInt(offset_info[0]) < 10 ? `0${offset_info[0]}` : offset_info[0]
        const off_minutes = offset_info[1] ? '30' : '00'
        limit = `${year}-${month}-${day}-${offset_part}-${off_hours}-${off_minutes}`
      }
    } else {
      with_limit ? limit = Limit : limit = undefined
    }

    Backend(
      MicroServices.Events,
      `/events/my-events?username=${username}&${limit ? `limit=${limit}` : 'ignoreLimit=true'}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_MY_EVENTS_RSVP_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_MY_EVENTS_RSVP_ERROR,
          payload: error,
        })
      })
  });

export const getEventLimitTime = (date?: Date) => {
  const today = date ? date : new Date();
  const year = today.getFullYear()
  const month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
  const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
  let offset = today.getTimezoneOffset() / 60.0
  const offset_part = offset > 0 ? 'm' : 'p'
  offset = Math.abs(offset)
  const offset_info = offset.toString().split('.')
  const off_hours = parseInt(offset_info[0]) < 10 ? `0${offset_info[0]}` : offset_info[0]
  const off_minutes = offset_info[1] ? '30' : '00'
  const timeLimit: TimeLimit = {
    AAAA: `${year}`,
    MM: `${month}`,
    DD: `${day}`,
    minus: offset_part === 'm',
    HH: off_hours,
    mm: off_minutes,
  }
  return timeLimit;
}

export const getMyFriendsEvents = (pastEvents: boolean, username: string, limit?: IGetEventSpecialFilters): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_MY_FRIENDS_EVENTS });

    let trueLimit: IGetEventSpecialFilters = {};

    if (limit !== undefined) trueLimit = limit;
    else {
      trueLimit.limitTime = getEventLimitTime()
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getFriendsEvents(pastEvents, username, trueLimit, dataSDK.token)
      })
      .then((results) => {
        if (results.statusCode !== 200 || !results.body || !('events' in results.body)) {
          dispatch({
            type: GET_MY_FRIENDS_EVENTS_ERROR,
            payload: results.body,
          })
          return;
        }
        dispatch({
          type: GET_MY_FRIENDS_EVENTS_SUCCESS,
          payload: sortEventsProperly(results.body.events),
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_MY_FRIENDS_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const getPertinentEvents = (pastEvents: boolean, username: string, limit?: IGetEventSpecialFilters): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_PERTINENT_EVENTS });

    let trueLimit: IGetEventSpecialFilters = {};

    if (limit !== undefined) trueLimit = limit;
    else {
      trueLimit.limitTime = getEventLimitTime()
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getPertinentEvents(pastEvents, username, trueLimit, false, false, dataSDK.token)
      })
      .then((results) => {
        if (results.statusCode !== 200 || !results.body || !('events' in results.body)) {
          dispatch({
            type: GET_PERTINENT_EVENTS_ERROR,
            payload: results.body,
          })
          return;
        }
        dispatch({
          type: GET_PERTINENT_EVENTS_SUCCESS,
          payload: sortEventsProperly(results.body.events),
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_PERTINENT_EVENTS_ERROR,
          payload: error,
        })
      })
  });

export const getMyEvents = (pastEvents: boolean, username: string, limit?: IGetEventSpecialFilters): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_MY_EVENTS });

    let trueLimit: IGetEventSpecialFilters = {};

    if (limit !== undefined) trueLimit = limit;
    else {
      trueLimit.limitTime = getEventLimitTime()
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getMyEvents(pastEvents, username, trueLimit, dataSDK.token)
      })
      .then((results) => {
        if (results.statusCode !== 200 || !results.body || !('events' in results.body)) {
          dispatch({
            type: GET_MY_EVENTS_ERROR,
            payload: results.body,
          })
          return;
        }
        dispatch({
          type: GET_MY_EVENTS_SUCCESS,
          payload: sortEventsProperly(results.body.events),
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_MY_EVENTS_ERROR,
          payload: error,
        })
      })
  });
