import React, { useState, useEffect, useContext } from 'react'
import { BaseFieldState, getFieldState } from '../../BaseComponents/utils';
import useActiveCountry, { CountryOption } from '../../../hooks/Locations/useActiveCountry';
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../../ClubIconsNavBar";
import Modal from 'react-bootstrap/Modal';
import ModalBaseProps from "../../../interfaces/modals/ModalBaseProps";
import SelectorCountry from '../../BaseComponents/Location/SelectorCountry';
import { BaseInput, CancelButton, ClickableIcon, Row, SaveButton }
    from "../../SuperCenter/SuperAdminComponents/basicForSACenter";
import { Row1, Imp } from "../../../containers/Website/Form"
import styled from 'styled-components';
import { DropdownIndicator, customStylesRSMultiWhite } from "../../../helpers/selectHelper";
import useModalitiesSA from "../../../hooks/Superadmin/Modalities/useModalitiesSA";
import { getDataSDK, getDataSDKResponse } from '../../../helpers/backendHelper';
import { ContextSuccess } from '../../../Context';
import ErrorField from '../../BaseComponents/ErrorField';
import { SelectMultiWrapper } from '../../BaseComponents/SelectMultiWrapper';
import SelectorCity from '../../BaseComponents/Location/SelectorCity';
import SelectorState from '../../BaseComponents/Location/SelectorState';
import { CityOption } from '../../../hooks/Locations/useStateCities';
import { StateOption } from '../../../hooks/Locations/useActiveState';
import TextEditor from '../../BaseComponents/TextEditor';
import { Loader } from '../../BaseComponents/Loader';
import ReferHealerRequest from '@vibe/sdk/dist/interfaces/AccessManager/referHealer';

export interface ModalityOptions<T> {
    value: T;
    label: string;
    __isNew__: boolean;
}

interface ModalReferProps extends ModalBaseProps {
}

const ModalRefer = ({
    show,
    onHide,
}: ModalReferProps) => {

    const [ModalityOptions, setModalityOptions] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelected, setModalitySelected] = useState<ModalityOptions<string>[]>();
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const currentSuccess = useContext(ContextSuccess);

    const [firstName, setFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [lastName, setLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityID, setModalityID] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [city, setCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));
    const [state, setState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [zipCode, setZipCode] = useState<BaseFieldState<string>>(getFieldState(''));
    const [email, setEmail] = useState<BaseFieldState<string>>(getFieldState(''));
    const [message, setMessage] = useState<BaseFieldState<string>>(getFieldState(''));

    const [fromFirstName, setFromFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [fromLastName, setFromLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [fromEmail, setFromEmail] = useState<BaseFieldState<string>>(getFieldState(''));

    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setModalityOptions(modalitiesPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [modalitiesPages])

    const onChangeModality = (updatedList: ModalityOptions<string>[]) => {
        setModalityID({
            ...ModalityID,
            value: updatedList.map(item => item.value),
        })
        setModalitySelected(updatedList);
    }

    const checkForm = () => {
        let hasErrors = false;

        if (!firstName.value || firstName.value.length === 0) {
            hasErrors = true;
            setFirstName({
                ...firstName,
                error: 'First Name is required',
                showError: true,
            })
        } else {
            setFirstName({
                ...firstName,
                error: '',
                showError: false,
            })
        }

        if (!lastName.value || lastName.value.length === 0) {
            hasErrors = true;
            setLastName({
                ...lastName,
                error: 'Last Name is required',
                showError: true,
            })
        } else {
            setLastName({
                ...lastName,
                error: '',
                showError: false,
            })
        }

        if (!ModalityID.value || ModalityID.value.length === 0) {
            hasErrors = true;
            setModalityID({
                ...ModalityID,
                error: 'At least one modality is required',
                showError: true,
            })
        } else {
            setModalityID({
                ...ModalityID,
                error: '',
                showError: false,
            })
        }

        if (!country.value || country.value.value.length === 0) {
            hasErrors = true;
            setCountry({
                ...country,
                error: 'Country is required',
                showError: true,
            })
        } else {
            setCountry({
                ...country,
                error: '',
                showError: false,
            })
        }

        if (!city.value || city.value?.value.length === 0) {
            hasErrors = true;
            setCity({
                ...city,
                error: 'City is required',
                showError: true,
            })
        } else {
            setCity({
                ...city,
                error: '',
                showError: false,
            })
        }

        if (!state.value || state.value?.value.length === 0) {
            hasErrors = true;
            setState({
                ...state,
                error: 'City is required',
                showError: true,
            })
        } else {
            setState({
                ...state,
                error: '',
                showError: false,
            })
        }

        if (!zipCode.value || zipCode.value.length === 0) {
            hasErrors = true;
            setZipCode({
                ...zipCode,
                error: 'Zipcode is required',
                showError: true,
            })
        } else if (zipCode.value.length < 5 || isNaN(+zipCode.value)) {
            hasErrors = true;
            setZipCode({
                ...zipCode,
                error: 'Zipcode is not in the right format',
                showError: true,
            })
        } else {
            setZipCode({
                ...zipCode,
                error: '',
                showError: false,
            })
        }

        if (!email.value || email.value.length === 0) {
            hasErrors = true;
            setEmail({
                ...email,
                error: 'Email is required',
                showError: true,
            })
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.value)) {
            hasErrors = true;
            setEmail({
                ...email,
                error: 'Email is not in the right format',
                showError: true,
            })
        } else {
            setEmail({
                ...email,
                error: '',
                showError: false,
            })
        }

        if (!fromFirstName.value || fromFirstName.value.length === 0) {
            hasErrors = true;
            setFromFirstName({
                ...fromFirstName,
                error: 'Your first name is required',
                showError: true,
            })
        } else {
            setFromFirstName({
                ...fromFirstName,
                error: '',
                showError: false,
            })
        }

        if (!fromLastName.value || fromLastName.value.length === 0) {
            hasErrors = true;
            setFromLastName({
                ...fromLastName,
                error: 'Your last name is required',
                showError: true,
            })
        } else {
            setFromLastName({
                ...fromLastName,
                error: '',
                showError: false,
            })
        }

        if (!fromEmail.value || fromEmail.value.length === 0) {
            hasErrors = true;
            setFromEmail({
                ...fromEmail,
                error: 'Your email is required',
                showError: true,
            })
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(fromEmail.value)) {
            hasErrors = true;
            setFromEmail({
                ...fromEmail,
                error: 'Your email is not in the right format',
                showError: true,
            })
        } else {
            setFromEmail({
                ...fromEmail,
                error: '',
                showError: false,
            })
        }

        return hasErrors;
    }

    const cleanForm = () => {
        setFirstName(getFieldState(''));
        setLastName(getFieldState(''));
        setModalityID(getFieldState([]));
        setCountry(getFieldState({ label: '', value: '' }));
        setCity(getFieldState({ label: '', value: '' }));
        setState(getFieldState({ label: '', value: '' }));
        setZipCode(getFieldState(''));
        setEmail(getFieldState(''));
        setMessage(getFieldState(''));

        setFromFirstName(getFieldState(''));
        setFromLastName(getFieldState(''));
        setFromEmail(getFieldState(''));
    }

    const onHideClean = () => {
        cleanForm();
        onHide();
    }

    const onSubmit = () => {
        if (checkForm() || loading) return;
        setLoading(true);

        const toSend: ReferHealerRequest = {
            fromFirstname: fromFirstName.value!,
            fromLastname: fromLastName.value!,
            fromEmail: fromEmail.value!,

            healerFirstName: firstName.value!,
            healerLastName: lastName.value!,
            modality: ModalityID.value!
                .map((id) => ({
                    id,
                    name: ModalityOptions.find((m) => m.value === id)?.label ?? '',
                })),
            countryCode: country.value?.value || '',
            stateCode: state.value?.value || state.value?.label || '',
            cityCode: city.value?.value || city.value?.label || '',
            zipCode: zipCode.value!,
            healerEmail: email.value!,
        }

        if (message.value) toSend.message = message.value || '';

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.referHealerForm(toSend);
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    currentSuccess?.setSuccessData(true, true, "Info Saved!", "");
                    cleanForm();
                    onHide();
                } else {
                    currentSuccess?.setSuccessData(false, true, "Error saving", "");
                }
            })
            .catch(() => {
                currentSuccess?.setSuccessData(false, true, "Error saving", "");
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Modal className="modalRefer" show={show} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>Refer a Healer</Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={'var(--black)'}
                        className="icon-times"
                        onClick={onHideClean}
                    />
                </Modal.Header>
                <Modal.Body>
                    <FlexRefer>
                        <Inputs>
                            <Row2>
                                <Label>Your First name<Imp>*</Imp></Label>
                                <Input value={fromFirstName.value} onChange={(e) => setFromFirstName({ ...fromFirstName, value: e.currentTarget.value })} />
                                {(fromFirstName.showError) && (<ErrorField error={fromFirstName.error} />)}
                            </Row2>
                            <Row2>
                                <Label>Your Last name<Imp>*</Imp></Label>
                                <Input value={fromLastName.value} onChange={(e) => setFromLastName({ ...fromLastName, value: e.currentTarget.value })} />
                                {(fromLastName.showError) && (<ErrorField error={fromLastName.error} />)}
                            </Row2>
                        </Inputs>
                        <Inputs>
                            <Row1>
                                <Label>Your Email<Imp>*</Imp></Label>
                                <Input value={fromEmail.value} onChange={(e) => setFromEmail({ ...fromEmail, value: e.currentTarget.value })} />
                                {(fromEmail.showError) && (<ErrorField error={fromEmail.error} />)}
                            </Row1>
                        </Inputs>

                        <DivideLine />

                        <SubtitleRefer>I'm Referring</SubtitleRefer>
                        <Inputs>
                            <Row2>
                                <Label>First name<Imp>*</Imp></Label>
                                <Input value={firstName.value} onChange={(e) => setFirstName({ ...firstName, value: e.currentTarget.value })} />
                                {(firstName.showError) && (<ErrorField error={firstName.error} />)}
                            </Row2>
                            <Row2>
                                <Label>Last name<Imp>*</Imp></Label>
                                <Input value={lastName.value} onChange={(e) => setLastName({ ...lastName, value: e.currentTarget.value })} />
                                {(lastName.showError) && (<ErrorField error={lastName.error} />)}
                            </Row2>
                        </Inputs>
                        <Inputs>
                            <Row1>
                                <Label>Modalities and Services<Imp>*</Imp></Label>
                                <SelectMultiWrapper
                                    options={ModalityOptions}
                                    styles={customStylesRSMultiWhite()}
                                    components={{
                                        DropdownIndicator
                                    }}
                                    value={ModalitySelected || []}
                                    onChangeValue={onChangeModality}
                                    placeholder="Select Multiple Modalities"
                                    showError={false}
                                    error=''
                                />
                                {(ModalityID.showError) && (<ErrorField error={ModalityID.error} />)}
                            </Row1>
                        </Inputs>
                        <Inputs>
                            <Row2>
                                <Label>Country<Imp>*</Imp></Label>
                                <SelectorCountry
                                    country={country}
                                    setCountry={setCountry}
                                    searchCountry={searchCountry}
                                    setSearchCountry={setSearchCountry}
                                />
                                {(country.showError) && (<ErrorField error={country.error} />)}
                            </Row2>
                            <Row2>
                                <Label>State / Region<Imp>*</Imp></Label>
                                <SelectorState
                                    country={country.value?.value ?? ''}
                                    state={state}
                                    setState={setState}
                                    searchState={searchState}
                                    setSearchState={setSearchState}
                                />
                                {(state.showError) && (<ErrorField error={state.error} />)}
                            </Row2>
                        </Inputs>
                        <Inputs>
                            <Row2>
                                <Label>City<Imp>*</Imp></Label>
                                <SelectorCity
                                    state={state.value?.value}
                                    city={city}
                                    setCity={setCity}
                                    searchCity={searchCity}
                                    setSearchCity={setSearchCity}
                                />
                                {(city.showError) && (<ErrorField error={city.error} />)}
                            </Row2>
                            <Row2>
                                <Label>Zip / Postal Code<Imp>*</Imp></Label>
                                <Input value={zipCode.value} onChange={(e) => setZipCode({ ...zipCode, value: e.currentTarget.value })} />
                                {(zipCode.showError) && (<ErrorField error={zipCode.error} />)}
                            </Row2>
                        </Inputs>
                        <Inputs>
                            <Row1>
                                <Label>Email<Imp>*</Imp></Label>
                                <Input value={email.value} onChange={(e) => setEmail({ ...email, value: e.currentTarget.value })} />
                                {(email.showError) && (<ErrorField error={email.error} />)}
                            </Row1>
                        </Inputs>
                        {/* <Inputs>
                            <Row1>
                                <Label>Message to Healer (optional)</Label>
                                <TextEditor
                                    value={message.value || ''}
                                    setValue={(e: string) => setMessage({ ...message, value: e })}
                                    charLimit={1000}
                                    placeholder='Encourage your healers to join Vibe with you!'
                                />
                            </Row1>
                        </Inputs> */}
                    </FlexRefer>
                </Modal.Body>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <SendButton onClick={onSubmit}>
                        {loading ? <Loader /> : 'Send'}
                    </SendButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const SubtitleRefer = styled.div`
    color: var(--default-dark);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.1px;
    width: 100%;
    text-align: left;
`;

const FlexRefer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
  overflow: scroll;
`;

const DivideLine = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--border-default);
    border-radius: 1px;
`;

const SendButton = styled.button`
    background-color: var(--t-blue);
    border-radius: 12px;
    padding: 14px 20px;
    width: 100%;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    font-family: Inter-Bold;
    text-align: center;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Row2 = styled.div`
    width: 48%;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    flex-direction: column;
    position: relative;

    @media only screen and (max-width: 700px) {
        width: 100%;
    }
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 700px) {
        flex-direction: column;
    }
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);

`

const Textarea = styled.textarea`
    outline: none;
    width: 100%;
    height: 100px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    margin: 7px 0;

`

export default ModalRefer;