import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalUnsaved from '../../components/BaseComponents/Modals/ModalUnsaved';
import Certifications from '../../components/signupsetuphealerind/Certifications';
import Column2 from "../../components/signupsetuphealerind/Column2";
import Communities from '../../components/signupsetuphealerind/Communities';
import Education from '../../components/signupsetuphealerind/Education';
import Footer from "../../components/signupsetuphealerind/Footer";
import Introduction from '../../components/signupsetuphealerind/Introduction';
import MyServices from '../../components/signupsetuphealerind/MyServices';
import PaymentTab from '../../components/signupsetuphealerind/Payment';
import Summary from '../../components/signupsetuphealerind/Summary';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import IPaymentInfo from '../../interfaces/user/IPaymentINfo';
import { getTCommsByCountry } from '../../store/actions/TClubsActions';
import { getClubs, getCommunities } from '../../store/actions/clubActions';
import { getEmailsSetup } from '../../store/actions/inviteActions';
import { getCertifications, getHealerServices } from '../../store/actions/modalityActions';
import { getUserEducation } from '../../store/actions/userActions';
import { RootState } from '../../store/store';
import { Column1_Setup, Content_Signup, ProcessInterface, ProcessType, TabsInterface } from '../SetupHealer/SetupHealer';
import HeaderSetup from '../SetupSeeker/HeaderSetup';
import { ContainerSetup_v2, Tabs_Setup } from '../SetupSeeker/SetupSeeker';
import Tab_Setup from '../SetupSeeker/Tab_Setup';
import { Container_Signup } from '../Signup/WrapBusiness';

const tabsHealer: TabsInterface[] = [
    {
        id: 1,
        label: 'About me',
    },
    {
        id: 2,
        label: 'Certifications',
    },
    {
        id: 3,
        label: 'Education',
    },
    {
        id: 4,
        label: 'Services',
    },
    {
        id: 5,
        label: 'Communities',
    },
    {
        id: 6,
        label: 'Summary',
    },
]

const SetupHealerInd = ({
    setShowSetupUser,
    setShowSetupOrg,
    ChatServiceInstance
}: {
    setShowSetupUser: (value: boolean) => void
    setShowSetupOrg: (value: boolean) => void
    ChatServiceInstance?: ChatServiceInterface
}) => {

    const dispatch = useDispatch();

    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const user = useSelector((state: RootState) => state.getUser.user);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    const [tabActive, setTabActive] = useState<number>(1);
    const [process, setProcess] = useState<ProcessType>('off');
    const [tabsProcessed, setTabsProcessed] = useState<number[]>([]);
    const [newData, setNewData] = useState<boolean>(false);
    const [showUnsavedData, setShowUnsavedData] = useState<boolean>(false);
    const [tabClicked, setTabClicked] = useState<number>(1);

    useEffect(() => {
        if (
            user && userPayment && tabsHealer.length === 6 &&
            user.organizations.length === 1 && !userPayment.hasActiveSubscription
        ) {
            tabsHealer.push({
                id: 7,
                label: 'Payment'
            })
        }
    }, [user, userPayment])

    const processData = useMemo((): ProcessInterface => ({
        process,
        setProcess,
        step: tabActive,
        tabsProcessed,
        setTabsProcessed,
        setNewData,
    }), [
        process,
        setProcess,
        tabActive,
        tabsProcessed,
        setTabsProcessed,
        setNewData,
    ])

    const handleTab = (tab: number) => {
        if (newData) {
            setShowUnsavedData(true);
            setTabClicked(tab)
            return;
        }
        setTabActive(tab);
    }

    const onHideUnsaved = (type = 'close') => {
        switch (type) {
            case 'close':
                setShowUnsavedData(false);
                break;

            case 'discard':
                setShowUnsavedData(false);
                setNewData(false);
                setTabActive(tabClicked);
                break;

            case 'save':
                setShowUnsavedData(false);
                setProcess('loading');
                break;

            default:
                setShowUnsavedData(false);
                break;
        }
    }

    const getClassNameTab = (tab: number) => {
        if (tabActive === tab) return 'tabActive';
        if (tabsProcessed.includes(tab)) return 'processed';
        return '';
    }

    useEffect(() => {
        setProcess('off');
    }, [tabActive])

    useEffect(() => {
        if (organization) {
            dispatch(getTCommsByCountry(organization?.countryCode));
            dispatch(getCommunities(organization.id, organization.status.startsWith('PE')));
            dispatch(getHealerServices(userInfo?.username || ''));
            dispatch(getClubs({
                pending: organization.status.startsWith('PE'),
                org_id: organization.id,
                club_type: 'CLUB',
            }));
            dispatch(getCertifications(userInfo?.username || ''));
            dispatch(getUserEducation(userInfo?.username || ''));
            dispatch(getEmailsSetup(organization.id, organization.status.startsWith('PE')));
        }
    }, [organization])

    const tabComponent = useMemo(() => {
        switch (tabActive) {
            case 1:
                return <Introduction processData={processData} />
            case 2:
                return <Certifications processData={processData} />
            case 3:
                return <Education processData={processData} />
            case 4:
                return <MyServices processData={processData} />
            case 5:
                return <Communities processData={processData} />
            case 6:
                return <Summary
                    processData={processData}
                    handleTabActive={setTabActive}
                />
            default:
                return <PaymentTab
                    processData={processData}
                    handleTabActive={setTabActive}
                />
        }
    }, [processData, setTabActive, tabActive])

    return (
        <ContainerSetup_v2>
            <HeaderSetup ChatServiceInstance={ChatServiceInstance} />
            <Container_Signup>
                <Column1_Setup>
                    <Content_Signup>
                        <Tabs_Setup>
                            {tabsHealer.map((tab) => (
                                <Tab_Setup
                                    onClick={() => handleTab(tab.id)}
                                    text={tab.label}
                                    active={getClassNameTab(tab.id) === 'tabActive'}
                                    processed={getClassNameTab(tab.id) === 'processed'}
                                />
                            ))}
                        </Tabs_Setup>
                        {tabComponent}
                    </Content_Signup>
                    <Footer
                        tabActive={tabActive}
                        handleTabActive={setTabActive}
                        setShowSetupUser={setShowSetupUser}
                        setShowSetupOrg={setShowSetupOrg}
                        setProcess={setProcess}
                        process={process}
                    />
                    <ModalUnsaved
                        show={showUnsavedData}
                        onHide={onHideUnsaved}
                        content={"Do you want to save your changes?"}
                    />
                </Column1_Setup>

                <Column2 tabActive={tabActive} />
            </Container_Signup>
        </ContainerSetup_v2>
    )
}

export default SetupHealerInd