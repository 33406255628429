import { useEffect, useState } from "react";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import SelectItem from "../../interfaces/SelectItem";
import { ICity } from "@vibe/sdk/dist/interfaces/Roster/Location";

export interface CityOption extends SelectItem { };

const useStateCities = () => {
  const [cities, setCities] = useState<ICity[]>([]);
  const [citiesReactSelect, setCitiesReactSelect] = useState<CityOption[]>([])
  const [isNewState, setIsNewState] = useState<boolean>(false);

  useEffect(() => {
    setCities([]);
  }, []);

  const reloadState = (state: string) => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Roster.getStateCities(state);
      })
      .then((results) => {
        if (!Array.isArray(results.body)) {
          if(state) setIsNewState(true);
          return;
        }

        if(results.body.length === 0 && state){
          setIsNewState(true);
          return;
        }
        setCities(results.body);
        setCitiesReactSelect(results.body.map((c) => ({
          label: c?.name ?? c.location.split('#')[c.location.split('#').length - 1],
          value: c.location.replaceAll('#','_'),
        })))
      })
  }

  return { 
    cities,
    citiesReactSelect,
    reloadState,
    isNewState,
  };
}

export default useStateCities;