import { SearchEngineCommunity, SearchEngineEvent, SearchEngineHealer, SearchEngineModality, SearchEngineQuote } from "@vibe/sdk/dist/interfaces/Connections/SearchEngine";
import { ICommunityHomeCard } from "../components/BaseComponents/HomeCards/CommunityHomeCard";
import { EventCardInterface } from "../components/BaseComponents/Events/EventCard";
import { IHealerCard, ILocationHealerCard } from "../components/BaseComponents/HomeCards/HealerCard";
import { IModalityCard } from "../components/BaseComponents/HomeCards/ModalityCard";
import { IInspiration } from "../components/Home/ResultsHome/CarrouselInspiration/Content";
import { GuideInfoExtended } from "../components/BaseComponents/Communities/CommunityCard";
import { formatDateTime, mapResponseRSVP } from "../hooks/Events/useEventsPanel";
import { ActivePanelDataType, ActivePanelType, IPanelRightHome, mockPanelRightData } from "../interfaces/HomeSearch/ItemFilterHome";
import { getEventLocation } from "./locationHelper";
import IHealer from "@vibe/sdk/interfaces/Admin/IHealer";

const mockGuide: GuideInfoExtended = {
    username: '',
    name: '',
}

const mockLocation: ILocationHealerCard = {
    city: '',
    city_code: '',
    state: '',
    state_code: '',
}

export const mapCommunityHomeCard = (items: SearchEngineCommunity[]): ICommunityHomeCard[] =>
    items.map((item) => ({
        id: item.clubId,
        name: item.name,
        guides: item.guides ?? [],
        creator: item.creator ?? { ...mockGuide },
        followers: item.followers ?? 0,
        members: item.members ?? 0,
        aliases: item.aliases ?? [],
        description: item.description,
        org_id: item.org_id || '',
        modalitiesIDs: item.modalitiesIDs || [],
        posts: item.postsCount || 0,
        lastPostDate: item.lastPostDate ? new Date(parseInt(item.lastPostDate)) : undefined,
        orgName: item.orgName,
    }));

export const mapModalityCard = (items: SearchEngineModality[]): IModalityCard[] =>
    items.map((item) => ({
        id: item.modalityId,
        name: item.name,
        comms: item.communities ?? 0,
        seekers: item.seekers ?? 0,
        healers: item.healers ?? 0,
        aliases: item.aliases,
        description: item.description,
    }));

export const mapHealerCard = (items: SearchEngineHealer[]): IHealer[] =>
    items.map((item) => ({
        username: item.username,
        name: item.name,
        services: [], //for TPs
        avatar: item.avatar || '',
        city: item.location.city,
        country: item.location.state,
        education: [],
        certifications: [],
        roles: [],

        seekerCount: item.seekers ?? 0,
        description: item.description,
        organizations: []

        // location: item.location,
        // socialMedia: item.socialMedia ?? [],
        // seekers: item.seekers ?? 0,
        // healers: item.healers ?? 0,

        // degree_levels: item.degree_levels || [], //for example MD or PHd, for TPs
    }));

export const mapEventHomeCard = (items: SearchEngineEvent[]): EventCardInterface[] =>
    items.map((item) => ({
        id: item.eventId,
        cover: item.event_cover || '',
        name: item.name,
        description: (item.description ?? '').replace(/style="(.*?)"/gm, ""),
        startDate: item.start ? formatDateTime(item.start)[0] : '',
        startTime: item.start ? formatDateTime(item.start)[1] : '',
        endDate: item.end ? formatDateTime(item.end)[0] : '',
        endTime: item.end ? formatDateTime(item.end)[1] : '',
        delivery: item.delivery,
        interested: item.interested ?? 0,
        attending: item.goingOnline + item.goingInPerson,
        isFullOnline: item.max_event_capacity_online === item.goingOnline,
        isFullInPerson: item.max_event_capacity_inperson === item.goingInPerson,
        rsvp: mapResponseRSVP(item.userAttendance),
        link: item.link || '',
        eventBriteUrl: item.eventBriteUrl || '',
        address1: item.addressLine1 || '',
        address2: item.addressLine2 || '',
        fee_online: item.eventFeeOnline,
        fee_person: item.eventFeeInPerson,
        guide: {
            name: item.creatorName,
            avatar: item.creatorAvatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
            username: item.creatorExtended.username,
        },
        createdAt: item.createdAt,
        creator: item.creatorExtended.username,
        locationComplete: getEventLocation(
            item.addressLine1,
            item.addressLine2,
            item.location,
            item.zipcode,
            item.countryCode,
            item.country,
            item.city,
            item.state,
            item.stateCode
        ),

        typeID: item.eventType,
        start: item.start ? formatDateTime(item.start)[2] : new Date(),
        instructionsOnline: item.instructionsOnline,
        instructionsPerson: item.instructionsInPerson,
    }));

export const mapQuoteCard = (items: SearchEngineQuote[]): IInspiration[] =>
    items.map((item) => ({
        quote: item.quote,
        from: item.quoteAuthor,
    }));

export const getPages = <T,>(results: T[], pageSize: number): T[][] => {
    const pages: T[][] = [];

    for (let i = 0; i < results.length; i += pageSize) {
        pages.push(results.slice(i, i + pageSize) as T[]);
    }

    if (pages.length === 0) {
        pages.push([]);
    }

    return pages;
}

export const mapPanelRightData = (data: ActivePanelDataType, type: ActivePanelType): IPanelRightHome => {
    let info: any;
    switch (type) {
        case 'MOD':
            info = data as IModalityCard;
            return ({
                title: info.name,
                healers: info.healers,
                seekers: info.seekers,
                aliases: info.aliases,
                description: info.description,
                route: `../../../../../../modality/${info.id}`,
                type,
            });
        case 'COMM':
            info = data as ICommunityHomeCard;
            return ({
                title: info.name,
                healers: info.followers,
                seekers: info.members,
                aliases: info.aliases,
                description: info.description,
                route: `../../../../../../`, // Design not available yet
                guide: {
                    username: info.creator.username,
                    name: info.creator.name,
                    avatar: info.creator.avatar,
                },
                type,
                posts: info.posts,
                lastPost: info.lastPostDate,
            });
        case 'TP':
            info = data as IHealer;
            console.log('healerdata', data)
            return ({
                title: info.name,
                type,
                avatar: info.avatar,
                healers: info.healers,
                seekers: info.seekerCount,
                aliases: info.services || [],
                description: info.description,
                route: `../../../../../../h/${info.username}`,
            });
        case 'PO':
            info = data as IHealer;
            return ({
                title: info.name,
                type,
                avatar: info.avatar,
                healers: info.healers,
                seekers: info.seekerCount,
                aliases: info.services || [],
                description: info.description,
                route: `../../../../../../h/${info.username}`,
            });
        case 'EVENT':
            info = data as EventCardInterface;
            return ({
                title: info.name,
                type,
                healers: info.interested,
                seekers: info.attending,
                description: info.description,
                aliases: [],
                route: `../../../../../../`, // Design not available yet
            });

        default:
            return { ...mockPanelRightData }
    }
};