export const handleDownloadPdf = (name: string, link?: string) => {
  if (link) {
      fetch(link)
          .then(response => response.blob())
          .then(blob => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${name}_Certification.pdf`);
              document.body.appendChild(link);
              link.click();
              link.parentNode?.removeChild(link);
          })
          .catch(error => {
              console.log(error)
          });
  }
}