import React, { createContext, useState } from "react";
import { WarningType } from "./App";
import { onHideType } from "./components/BaseComponents/Modals/ModalUnsaved";
import { deliveryEvent, deliverySimple } from "./components/BaseComponents/Events/EventCard";

export type ContextType = {
    setSuccessData: (
        successBool: boolean,
        showModalSuccess: boolean,
        titleSuccess: string,
        subTitleSuccess?: string) => void,

    setLoginModal: (
        showLoginModal: boolean,
        onlyLogin: boolean,
        token?: string,
        useInvite?: boolean,
        orgId?: string,
    ) => void,

    showLoginModal: boolean,

    setModalWarning: (
        type: WarningType,
        onHide: (type: onHideType) => void,
        message: string,
        btnContent?: string
    ) => void,

    setLoadingModal: (
        type: boolean
    ) => void,

    setModalPayInfo: (
        content?: string
    ) => void,

    setPaymentForm: (
        amount: number,
    ) => void,

    setPaymentSuccess: (
        success: boolean,
        amount: number,
        error?: string
    ) => void,

    setModalsOff: () => void,

    openModalPurchaseEvent: (
        id: string,
        name: string,
        delivery: deliverySimple,
        amount: number,
        reload: () => void
    ) => void,
};

export const ContextSuccess = createContext<ContextType | null>(null);