import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getMyEvents, getMyFriendsEvents, getPertinentEvents } from "../../store/actions/eventActions";
import { IGetEventSpecialFilters, TimeLimit } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { EventsResponse } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { EventCardInterface, rsvp } from "../../components/BaseComponents/Events/EventCard";
import { getEventLocation } from "../../helpers/locationHelper";
import { IEventFilter } from "../../components/BaseComponents/Filter";

type EventTabs = 'ALL' | 'MY' | 'FRIENDS' | 'ADMIN'

export const formatNoTime = (date: Date) => {
  const dateFinal = new Date(date);
  dateFinal.setHours(0,0,0,0)
  return dateFinal;
}

export const mapResponseRSVP = (res?: 'RSVPO' | 'RSVPP' | 'I' | 'NI' | 'CHECKIN'): rsvp => {
  if (res === 'RSVPP' || res === 'CHECKIN') return 'Going In Person';
  if (res === 'RSVPO') return 'Going Online';
  if (res === 'I') return 'Interested';
  if (res === 'NI') return 'Not Interested';
  return 'RSVP';
}

export const formatDateTime = (date: string): [string, string, Date] => {
  const parts = date.split('-');
  const dateTime = new Date(`${parts[0]}/${parts[1]}/${parts[2]}`);

  return [
    dateTime.toDateString(),
    `${parts[3]}:${parts[4]}`,
    dateTime
  ]
}

export const mapEventToCard = (event: EventsResponse): EventCardInterface => ({
  id: event.event_id,
  cover: event.event_cover,
  name: event.name,
  description: (event.description ?? '').replace(/style="(.*?)"/gm, ""),
  startDate: event.start ? formatDateTime(event.start)[0] : '',
  startTime: event.start ? formatDateTime(event.start)[1] : '',
  endDate: event.end ? formatDateTime(event.end)[0] : '',
  endTime: event.end ? formatDateTime(event.end)[1] : '',
  delivery: event.delivery,
  interested: event.interested,
  attending: event.goingInPerson + event.goingOnline,
  isFullOnline: event.max_event_capacity_online === event.goingOnline,
  isFullInPerson: event.max_event_capacity_inperson === event.goingInPerson,
  rsvp: mapResponseRSVP(event.userAttendance),
  link: event.link || '',
  eventBriteUrl: event.eventBriteUrl || '',
  address1: event.addressLine1 || '',
  address2: event.addressLine2 || '',
  fee_online: event.eventFeeOnline,
  fee_person: event.eventFeeInPerson,
  guide: {
    name: event.creatorName,
    avatar: event.creatorAvatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
    username: event.creator,
  },
  creator: event.creator,
  createdAt: event.createdAt,
  locationComplete: getEventLocation(
    event.addressLine1,
    event.addressLine2,
    event.location,
    event.zipcode,
    event.countryCode,
    event.country,
    event.city,
    event.state,
    event.stateCode
  ),
  modalities: event.modalities,

  typeID: event.eventType,
  modalitiesIDs: event.modalitiesIds || [],
  city_id: event.cityCode || '',
  country_id: event.countryCode || '',
  state_id: event.stateCode || '',
  start: event.start ? formatDateTime(event.start)[2] : new Date(),

  instructionsOnline: event.instructionsOnline,
  instructionsPerson: event.instructionsInPerson,
})

const filterEvents = (event: EventsResponse, itemSearch: string, eventFilter: IEventFilter) => {
  const filtersResponses = [
    false, //itemSearch
    false, //cityID
    false, //countryID
    false, //stateID
    false, //typeID
    false, //start_date
    false, //Delivery
    false, // modality
  ];

  if (!itemSearch) filtersResponses[0] = true;
  else if (event.name && (
    event.name.toUpperCase().includes(itemSearch) ||
    event.name.toLowerCase().includes(itemSearch) ||
    event.name.includes(itemSearch)
  )) {
    filtersResponses[0] = true;
  }

  if (!eventFilter.cityID) filtersResponses[1] = true;
  else if (event.cityCode === eventFilter.cityID) {
    filtersResponses[1] = true;
  }

  if (!eventFilter.countryID) filtersResponses[2] = true;
  else if (event.countryCode === eventFilter.countryID) {
    filtersResponses[2] = true;
  }

  if (!eventFilter.stateID) filtersResponses[3] = true;
  else if (event.stateCode === eventFilter.stateID) {
    filtersResponses[3] = true;
  }

  if (!eventFilter.typeID) filtersResponses[4] = true;
  else if (event.eventType === eventFilter.typeID) {
    filtersResponses[4] = true;
  }

  const start = event.start ? formatDateTime(event.start)[2] : new Date();
  if (!eventFilter.start_date) filtersResponses[5] = true;
  else if (formatNoTime(start).getTime() >= formatNoTime(eventFilter.start_date).getTime()) {
    filtersResponses[5] = true;
  }

  if (!eventFilter.delivery) filtersResponses[6] = true;
  else if (event.delivery === eventFilter.delivery) {
    filtersResponses[6] = true;
  }

  if (!eventFilter.modID) filtersResponses[7] = true;
  else if ((event.modalitiesIds || []).includes(eventFilter.modID)) {
    filtersResponses[7] = true;
  }

  return filtersResponses.every((p) => p);
}

export const getEventLimitTime = (date?: Date) => {
  const today = date ? date : new Date();
  const year = today.getFullYear()
  const month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
  const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
  let offset = today.getTimezoneOffset() / 60.0
  const offset_part = offset > 0 ? 'm' : 'p'
  offset = Math.abs(offset)
  const offset_info = offset.toString().split('.')
  const off_hours = parseInt(offset_info[0]) < 10 ? `0${offset_info[0]}` : offset_info[0]
  const off_minutes = offset_info[1] ? '30' : '00'
  const timeLimit: TimeLimit = {
    AAAA: `${year}`,
    MM: `${month}`,
    DD: `${day}`,
    minus: offset_part === 'm',
    HH: off_hours,
    mm: off_minutes,
  }
  return timeLimit;
}

const useEventsPanel = ({
  searchItem,
  eventFilter,
}: {
  searchItem: string,
  eventFilter: IEventFilter
}) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState<EventTabs>('ALL');

  const username = useSelector((state: RootState) => state.getUser.userInfo?.username);

  const pertinentEvents = useSelector((state: RootState) => state.getEvent.getPertinentEvents ?? []);
  const myEvents = useSelector((state: RootState) => state.getEvent.getMyEvents ?? []);
  const friendsEvents = useSelector((state: RootState) => state.getEvent.getMyFriendsEvents ?? []);

  useEffect(() => console.log('pertinentEvents', pertinentEvents), [pertinentEvents])

  const cardPertinentEvents = useMemo(() => pertinentEvents.filter((event) => filterEvents(event, searchItem, eventFilter)).map(mapEventToCard), [pertinentEvents, searchItem, eventFilter]);
  const cardMyEvents = useMemo(() => myEvents.filter((event) => filterEvents(event, searchItem, eventFilter)).map(mapEventToCard), [myEvents, searchItem, eventFilter]);
  const cardFriendsEvents = useMemo(() => friendsEvents.filter((event) => filterEvents(event, searchItem, eventFilter)).map(mapEventToCard), [friendsEvents, searchItem, eventFilter]);

  const currentEvents = useMemo(() => {
    if (tab === 'ALL') return pertinentEvents;
    else if (tab === 'MY') return myEvents;
    else return friendsEvents;
  }, [pertinentEvents, myEvents, friendsEvents, tab])

  const modalities = useMemo(() => {
    return currentEvents.map(({ modalities }) => modalities).flat().filter(({ id }, idx, arr) => arr.findIndex(({ id: otherId }) => otherId === id) === idx);
  }, [currentEvents])

  const currentCards = useMemo(() => {
    if (tab === 'ALL') return cardPertinentEvents;
    else if (tab === 'MY') return cardMyEvents;
    else return cardFriendsEvents;
  }, [cardPertinentEvents, cardMyEvents, cardFriendsEvents, tab])

  useEffect(() => {
    const limit = {
      limitTime: getEventLimitTime(),
    };
    if (eventFilter.start_date) {
      limit.limitTime = getEventLimitTime(eventFilter.start_date);
    }

    let pastEvents = false;

    if (eventFilter.start_date && formatNoTime(eventFilter.start_date).getTime() < formatNoTime(new Date()).getTime()) {
      pastEvents = true;
    }

    if (tab === 'ALL') reloadAllEvents(pastEvents, limit);
    else if (tab === 'MY') reloadMyEvents(pastEvents, limit);
    else reloadFriendEvents(pastEvents, limit);
  }, [tab, username, eventFilter]);

  const reloadAllEvents = (pastEvents: boolean, limit: IGetEventSpecialFilters) => {
    if (!username) return;

    dispatch(getPertinentEvents(pastEvents, username, limit));
  }

  const reloadMyEvents = (pastEvents: boolean, limit: IGetEventSpecialFilters) => {
    if (!username) return;

    dispatch(getMyEvents(pastEvents, username, limit));
  }

  const reloadFriendEvents = (pastEvents: boolean, limit: IGetEventSpecialFilters) => {
    if (!username) return;

    dispatch(getMyFriendsEvents(pastEvents, username, limit))
  }

  const reload = () => {
    reloadAllEvents(false, {});
    reloadMyEvents(false, {});
    reloadFriendEvents(false, {});
  }

  const handleTabChange = (tab: EventTabs) => setTab(tab);

  return {
    currentTab: tab,
    currentEvents,
    pertinentEvents,
    myEvents,
    friendsEvents,
    currentCards,
    cardPertinentEvents,
    cardMyEvents,
    cardFriendsEvents,
    modalities,
    handleTabChange,
    reload,
  }
};

export default useEventsPanel;