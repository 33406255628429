import styled from "styled-components"
import { BaseFieldState } from "../utils"
import ErrorField from "../ErrorField"
import { useEffect, useState } from "react"
import { isValidZipCode } from "../../../helpers/userHelper"
import { CountryOption } from "../../../hooks/useCountry"
import { PostalCodeClean } from "@vibe/sdk/dist/interfaces/Roster/postalCodes"
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper"

const InputZipcode = ({
    country,
    zipcode,
    setZipcode,
}: {
    country: BaseFieldState<CountryOption>
    zipcode: BaseFieldState<string>
    setZipcode: (data: BaseFieldState<string>) => void
}) => {

    const [zipcodeLengths, setZipcodeLengths] = useState<PostalCodeClean>({ maxLength: 5, minLength: 5 })

    const validateZipcode = () => {

        if(!country.value?.value) return;

        const errorZipCode = isValidZipCode(zipcode.value || '', zipcodeLengths, country.value?.label);
        if (errorZipCode !== '') {
            setZipcode({
                ...zipcode,
                showError: true,
                error: errorZipCode
            })
        } else {
            setZipcode({
                ...zipcode,
                showError: false,
                error: ''
            })
        }
    }

    const getZipcodeLengths = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.postalCodes(country.value?.value, dataSDK.token)
            })
            .then((results) => {
                if (results.body && 'maxLength' in results.body) {
                    setZipcodeLengths(results.body)
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (country.value?.value) {
            getZipcodeLengths()
        }
    }, [country])

    return (
        <Row1>
            <Input
                value={zipcode.value}
                onChange={(e) => setZipcode({ ...zipcode, value: e.currentTarget.value })}
                onBlur={() => validateZipcode()}
            />
            {(zipcode.showError) && (<ErrorField error={zipcode.error} />)}
        </Row1>
    )
}

export default InputZipcode;

const Row1 = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`;