import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonHealerHome, ButtonsHealerHome, IconHealerHome, InputHome, RoundedSearchIcon, RowHealerHome, SearchContainerHome, SearchHomeInput, TitleHome } from "./styles";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ContextSuccess } from "../../../Context";
import HomeSearchProps from "../../../interfaces/HomeSearch/HomeSearchProps";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { contactUs } from "../../../AppConfig";
import ModalSearchWarning from "../../BaseComponents/ModalSearchWarning"
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper"
import { IHasAcceptedDisclaimerResponse } from "@vibe/sdk/dist/interfaces/Connections/connections"

const SearchHome = ({
    searchPreview,
    setSearch,
    onKeyDown,
    goResults
}: HomeSearchProps) => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const [showModalSearchWarning, setShowModalSearchWarning] = useState<boolean>(false);

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

    const [ip, setIp] = useState<string>('');
    const [hasAccepted, setHasAccepted] = useState<boolean>(false);

    useEffect(() => {
        fetch('https://api.ipify.org/?format=json')
            .then(res => res.json())
            .then(({ip}) => {
                setIp(ip);
            })
            .catch(err => {
                console.log(err);
        })
    }, [])

    useEffect(() => {
        getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Connections.hasAcceptedDisclaimer(ip, userInfo?.username);
        })
        .then(({body}) => {
            const {response} = body as IHasAcceptedDisclaimerResponse;
            setHasAccepted(response);
        })
        .catch((error) => {
           console.log("Error checking if disclaimer was accepted", error);
        })
    }, [ip, userInfo])

    const onSearchClick = () => {
        getDataSDK()
        .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Connections.hasAcceptedDisclaimer(ip, userInfo?.username);
        })
        .then(({body}) => {
            const {response} = body as IHasAcceptedDisclaimerResponse;
            setShowModalSearchWarning(!response)
        })
        .catch((error) => {
           console.log("Error checking if disclaimer was accepted", error);
        })
    }

    return (
        <SearchContainerHome>
            {/* <TitleHome>Discover alternative wellness - the modalities, healers,<br />communities, and events that can transform your life.
            </TitleHome> */}
            <TitleHome>Raise your vibration through alternative wellness -<br />discover healers, communities, and events to live a life<br />that is optimal, youthful, and enlightened.
            </TitleHome>

            <SearchHomeInput>
                <InputHome
                    placeholder="Search & Raise Your Vibration"
                    value={searchPreview}
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    onClick={onSearchClick}
                    onKeyDown={(e) => onKeyDown(e)}
                />
                <RoundedSearchIcon
                    icon="search"
                    size="lg"
                    color="white"
                    onClick={() => contactUs ? navigate('/contact') : goResults()}
                />
            </SearchHomeInput>

            {(!isLoggedIn) && (
                <ButtonsHealerHome>
                    <RowHealerHome>
                        <IconHealerHome />
                        I'm a Healer
                    </RowHealerHome>
                    <ButtonHealerHome
                        onClick={() => navigate(contactUs ? '/contact' : '../../../../../signup-healer')}
                    >
                        Let's Vibe
                        <FontAwesomeIcon
                            icon="chevron-right"
                            size="2xs"
                            color="white"
                        />
                    </ButtonHealerHome>
                </ButtonsHealerHome>
            )}
            <ModalSearchWarning
                showModal={showModalSearchWarning}
                setShowModal={setShowModalSearchWarning}
                // home={true}
            />
        </SearchContainerHome>
    )
}

export default SearchHome;