import { useEffect, useState } from "react";
import { FilterColorRounded, LabelShowingResults, ResultsContainer, ResultsTitleWrapper, RowResultsHome, TitleResultsHome, ContainerFilter, ContFilter } from "./styles";
import ContentAllHome from "./ContentAllHome";
import ContentResults from "./ContentResults";
import { filtersHomeComms, filtersHomeEvents, filtersHomeMods, filtersHomeTPs, headerHomeTabs } from "../../../../interfaces/HomeSearch/TabsResultsHome";
import { ActivePanelDataType, ActivePanelType, IPanelRightHome, SelectedItemHome, mockPanelRightData } from "../../../../interfaces/HomeSearch/ItemFilterHome";
import ModalityCard, { IModalityCard } from "../../../BaseComponents/HomeCards/ModalityCard";
import CommunityHomeCard, { ICommunityHomeCard } from "../../../BaseComponents/HomeCards/CommunityHomeCard";
import EventCard, { EventCardInterface, deliveryEvent } from "../../../BaseComponents/Events/EventCard";
import useSearchEngine from "../../../../hooks/SearchBar/useSearchEngine";
import { searchBase } from "@vibe/sdk/dist/interfaces/Connections/SearchEngine";
import useFilterItem from "../../../../hooks/FilterTip/useFilterItem";
import PanelRightHome from "../PanelsMoreInfo/PanelRightHome";
import { mapPanelRightData } from "../../../../helpers/searchBarHelper";
import { Loader } from "../../../BaseComponents/Loader";
import FiltersPanel from "./FiltersPanel";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons';
import IHealer from "@vibe/sdk/interfaces/Admin/IHealer";
import HealerCard from "../../../HealerCard/HealerCard";
interface ContentResultsHomeProps {
  search: string
}

const ContentResultsHome = ({ search }: ContentResultsHomeProps) => {
  const [headerTabSelected, setHeaderTabSelected] = useState<number>(1);
  const [category, setCategory] = useState<searchBase>('Everything');

  const [selectedOption, setSelectedOption] = useState<SelectedItemHome>({ label: '', value: '' });

  const [filtersActiveMods, setFiltersActiveMods] = useState<number[]>([]);
  const [filtersActiveComms, setFiltersActiveComms] = useState<number[]>([]);
  const [filtersActiveHealers, setFiltersActiveHealers] = useState<number[]>([]);
  const [filtersActiveTPs, setFiltersActiveTPs] = useState<number[]>([]);
  const [filtersActivePOs, setFiltersActivePOs] = useState<number[]>([]);
  const [filtersActiveEvents, setFiltersActiveEvents] = useState<number[]>([]);

  const [rowsActiveMods, setRowsActiveMods] = useState<number>(2);
  const [rowsActiveComms, setRowsActiveComms] = useState<number>(2);
  const [rowsActiveHealers, setRowsActiveHealers] = useState<number>(2);
  const [rowsActiveEvents, setRowsActiveEvents] = useState<number>(2);

  const [panelRightInfo, setPanelRightInfo] = useState<IPanelRightHome>({ ...mockPanelRightData });
  const [showPanelRightInfo, setShowPanelRightInfo] = useState<boolean>(false);
  const [showPanelFilters, setShowPanelFilters] = useState<boolean>(false);

  const activeLeftPanel = (data: ActivePanelDataType, type: ActivePanelType) => {
    if (type !== 'EVENT') {
      setPanelRightInfo(mapPanelRightData(data, type));
      setShowPanelRightInfo(true);
    }
  }

  const options = useFilterItem([{
    id: 1,
    type: "BEN",
    dropdown: true,
  },
  {
    id: 2,
    type: "DEL",
    dropdown: true,
  },
  {
    id: 3,
    type: "LOC",
    dropdown: true,
  }])

  const {
    modalitiesPages,
    communitiesPages,
    healersPages,
    topPractitionersPages,
    profOrganizationsPages,
    eventsPages,
    loadingSearchEngine,
    totalResults,
    showingResults,
    doInitialSearch,
  } = useSearchEngine({
    search,
    filters: [],
    category,
  });

  useEffect(() => doInitialSearch(), [])

  useEffect(() => {
    switch (headerTabSelected) {
      case 1:
        setCategory('Everything')
        break;
      case 2:
        setCategory('Modalities')
        break;
      case 3:
        setCategory('Healers')
        break;
      case 4:
        setCategory('Communities')
        break;
      case 5:
        setCategory('Events')
        break;

      default:
        setCategory('Everything')
        break;
    }
  }, [headerTabSelected])

  const getContent = () => {
    switch (headerTabSelected) {
      case 1:
        return <ContentAllHome
          mods={modalitiesPages}
          comms={communitiesPages}
          tps={topPractitionersPages}
          pos={profOrganizationsPages}
          events={eventsPages}
          filtersActiveMods={filtersActiveMods}
          filtersActiveComms={filtersActiveComms}
          filtersActiveTPs={filtersActiveTPs}
          filtersActivePOs={filtersActivePOs}
          filtersActiveEvents={filtersActiveEvents}

          setFiltersActiveMods={setFiltersActiveMods}
          setFiltersActiveComms={setFiltersActiveComms}
          setFiltersActiveTPs={setFiltersActiveTPs}
          setFiltersActivePOs={setFiltersActivePOs}
          setFiltersActiveEvents={setFiltersActiveEvents}

          activeLeftPanel={activeLeftPanel}
          setTabActive={setHeaderTabSelected}
        />;

      case 2:
        return <ContentResults
          name="Modalities and Services"
          cards={(c) => (
            <ModalityCard
              data={c}
              onClick={(data: IModalityCard) => activeLeftPanel(data, 'MOD')}
            />
          )}
          elements={modalitiesPages}
          filters={options.items}
          /*filters={filtersHomeMods}*/
          filtersActive={filtersActiveMods}
          setFiltersActive={setFiltersActiveMods}
          activeRows={rowsActiveMods}
          setActiveRows={setRowsActiveMods}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />;

      case 3:
        return <ContentResults
          name="Healers"
          cards={(c) => (
            <HealerCard
              healer={c}
              onClick={(data: IHealer) => activeLeftPanel(data, 'TP')}
            />
          )}
          elements={healersPages}
          filters={options.items}
          // filters={filtersHomeTPs}
          filtersActive={filtersActiveHealers}
          setFiltersActive={setFiltersActiveHealers}
          activeRows={rowsActiveHealers}
          setActiveRows={setRowsActiveHealers}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />;

      case 4:
        return <ContentResults
          name="Communities"
          cards={(c) => (
            <CommunityHomeCard
              data={c}
              onClick={(data: ICommunityHomeCard) => activeLeftPanel(data, 'COMM')}
            />
          )}
          elements={communitiesPages}
          filters={options.items}
          // filters={filtersHomeComms}
          filtersActive={filtersActiveComms}
          setFiltersActive={setFiltersActiveComms}
          activeRows={rowsActiveComms}
          setActiveRows={setRowsActiveComms}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />;

      case 5:
        return <ContentResults
          name="Events"
          cards={(c) => (
            <EventCard
              data={c}
              reload={() => { }}
              onClickHome={(data: EventCardInterface) => activeLeftPanel(data, 'EVENT')}
              isHome
              width={"315px"}
            />
          )}
          elements={eventsPages}
          filters={options.items}
          // filters={filtersHomeEvents}
          filtersActive={filtersActiveEvents}
          setFiltersActive={setFiltersActiveEvents}
          activeRows={rowsActiveEvents}
          setActiveRows={setRowsActiveEvents}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />;

      default:
        return <></>;
    }
  }

  return (
    <ResultsContainer>
      <ResultsTitleWrapper>
        <TitleResultsHome>
          Explore Alternative Modalities & Services
        </TitleResultsHome>
        Discover Healers, Communities & Events to Support Your Journey
      </ResultsTitleWrapper>
      <RowResultsHome>
        {headerHomeTabs.map((t) => (
          <FilterColorRounded
            active={t.id === headerTabSelected}
            onClick={() => setHeaderTabSelected(t.id)}
          >
            {t.label}
          </FilterColorRounded>
        ))}
      </RowResultsHome>
      <ContainerFilter>
        {!loadingSearchEngine && headerTabSelected === 1 && (<LabelShowingResults>Showing {`${showingResults}`} from {`${totalResults}`} results</LabelShowingResults>)}
        <ContFilter onClick={() => setShowPanelFilters(true)}>
          Filter by
          <FontAwesomeIcon
            icon={faBarsFilter}
            size="lg"
          />
        </ContFilter>
      </ContainerFilter>
      <PanelRightHome
        data={panelRightInfo}
        show={showPanelRightInfo}
        onHide={() => setShowPanelRightInfo(false)}
      />
      {loadingSearchEngine ? <LoaderWrapper><Loader color="white" /></LoaderWrapper> : getContent()}
      {showPanelFilters &&
        <FiltersPanel showFilters={setShowPanelFilters} />
      }
    </ResultsContainer>
  )
}

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default ContentResultsHome;
