import { faMessage } from "@fortawesome/pro-regular-svg-icons";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import UserSocialMedia from "@vibe/sdk/interfaces/Roster/userSocialMedia";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContextSuccess } from "../../Context";
import { cleanStyleHTML } from "../../helpers/letterHelper";
import { RootState } from "../../store/store";
import ButtonSpinner from "../BaseComponents/ButtonSpinner";
import { CardBack, CardItem, Card as FlipCard } from "../BaseComponents/Communities/CommunityCard";
import BtnFollow from "../BaseComponents/Follow/BtnFollow";
import AvatarInitials from "../BaseComponents/Healers/AvatarInitials";
import { ButtonMessage, ButtonsActions, ButtonsLeft, CardDetail, Description, IconMedia, IconSeeker, InfoCard, InfoMedia, InfoRow, InfoSeekers, LinkVibe, LinkWeb, LocationCard, NameUserCard, SocialMedia, Tooltip, WrapperFollow } from "../FeedLeftSectionInd";
import {
  InfoProfile,
  ReadMore,
  StyledFontAwesomeIcon
} from '../HealerProfile/components';
import IHealer from "@vibe/sdk/interfaces/Admin/IHealer";

const HealerCard = ({ healer, onClick }: { healer: IHealer, onClick?: (h: IHealer) => void }) => {
  const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);

  const currentSuccess = useContext(ContextSuccess);

  const [flipCard, setflipCard] = useState(false)
  const [activeDescription, setActiveDescription] = useState<boolean>(false);

  const {
    avatar,
    name,
    city,
    country,
    zipcode,
    seekerCount,
    description,
    organizations,
    org_id,
    username,
    media,
  } = healer;

  const showFollowBtn = (): boolean => {
    if (!isLoggedIn) return true;

    const arrayA: string[] = organizations || [];
    const arrayB = user.organizations || []
    const hasCommonElementInA = arrayA.some(elementA => arrayB.includes(elementA));
    const hasCommonElementInB = arrayB.some(elementB => arrayA.includes(elementB));

    return !(hasCommonElementInA || hasCommonElementInB);
  }

  const handleClick = () => {
    if (!isLoggedIn) {
      currentSuccess?.setLoginModal(true, false);
      return;
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href)
    currentSuccess?.setSuccessData(true, true, `Link copied to clipboard`);
  }

  const getImageMedia = (social: UserSocialMedia): string => {
    switch (social.platform) {
      case 'Facebook':
        return '../facebook-f.svg';
      case 'YouTube':
        return '../youtube.svg';
      case 'Instagram':
        return '../instagram.svg';
      case 'Twitter':
        return '../twitter.svg';
      case 'LinkedIn':
        return '../linkedin.svg';
      case 'Pinterest':
        return '../pinterest.svg';
      case 'Snapchat':
        return '../snapchat.svg';
      case 'TikTok':
        return '../tiktokicon.svg';
      case 'Zaahah':
        return '../Zaahah.svg';
      case 'Personal':
        return '../globe-solid.svg'
      case 'Substack':
        return '../substack.svg'
      case 'ContactEmail':
        return '../email.svg'
      case 'ContactPhone':
        return '../phone.svg'
      default:
        return '';
    }
  }

  return (
    <FlipCard theme={{ main: flipCard ? 'active' : '', mains: activeDescription ? '400px' : '' }}>
      <CardItem>
        <CardDetail theme={{ main: activeDescription ? 'showDesc' : 'HideDesc' }}>
          <InfoProfile>
            <AvatarInitials
              avatar={avatar || ""}
              name={name}
              size={56}
            />
            <InfoCard>
              <InfoRow>
                <NameUserCard theme={{ name: name }}>{name}</NameUserCard>
              </InfoRow>
              <InfoRow>
                <LocationCard>
                  {city ? city : zipcode}, {country}
                </LocationCard>
              </InfoRow>
            </InfoCard>
          </InfoProfile>
          <InfoMedia>
            {(seekerCount && seekerCount > 0) ? <InfoSeekers><IconSeeker /> {`${seekerCount} ${seekerCount > 1 || seekerCount === 0 ? 'Seekers' : 'Seeker'}`}</InfoSeekers> : null}
          </InfoMedia>
          <Description
            theme={{ main: activeDescription ? 'active' : '' }}
            dangerouslySetInnerHTML={{ __html: cleanStyleHTML(description as string ?? '') }}>
          </Description>
          {(!onClick && (description ?? '').length > 10) && <ReadMore onClick={() => setActiveDescription(!activeDescription)}>Read {activeDescription ? 'less' : 'more'}</ReadMore>}
        </CardDetail>
        <ButtonsActions>
          <ButtonsLeft>
            {(org_id && showFollowBtn()) && <WrapperFollow><BtnFollow username={userInfo?.username || ''} orgID={org_id} /></WrapperFollow>}

            {process.env.REACT_APP_SHOW_CHAT === 'true' && ( // Improve this logic afterwards
              username !== userInfo?.username &&
              <ButtonMessage
                onClick={handleClick}
              >
                <StyledFontAwesomeIcon
                  icon={faMessage}
                  size='lg'
                  color={"black"}
                  style={{
                    fontSize: '18px',
                    marginRight: '0px'
                  }}
                />

              </ButtonMessage>
            )}
          </ButtonsLeft>
          <ButtonSpinner
            loading={false}
            handleClick={() => onClick ? onClick(healer) : setflipCard(!flipCard)}
            text={onClick ? "More" : "Contact"}
            hasIcon={false}
            color={"#e59b50"}
          />
        </ButtonsActions>
      </CardItem>
      <CardBack>
        <InfoProfile>
          <AvatarInitials
            avatar={avatar || ""}
            name={name}
            size={56}
          />
          <InfoCard>
            <InfoRow>
              <NameUserCard theme={{ name: name }}>{name}</NameUserCard>
            </InfoRow>
            <InfoRow>
              <LocationCard>
                {city ? city : zipcode}, {country}
              </LocationCard>
            </InfoRow>
          </InfoCard>
        </InfoProfile>
        <InfoMedia>
          {((media ?? []).find(({ platform }: any) => platform === 'ContactVisible') && (media ?? []).find(({ platform }: any) => platform === 'ContactVisible')?.link === 'true') && (
            <>
              {(media ?? []).find(({ platform }: any) => platform === 'ContactEmail') && <InfoSeekers><div style={{ padding: 5, paddingTop: 0, paddingBottom: 0 }}><IconMedia src='../email.svg' /></div> {(media ?? []).find(({ platform }: any) => platform === 'ContactEmail')?.link}</InfoSeekers>}
              {(media ?? []).find(({ platform }: any) => platform === 'ContactPhone') && <InfoSeekers><div style={{ padding: 5, paddingTop: 0, paddingBottom: 0 }}><IconMedia src='../phone.svg' /></div> {(media ?? []).find(({ platform }: any) => platform === 'ContactPhone')?.link}</InfoSeekers>}
            </>
          )}
          <SocialMedia style={{ paddingLeft: 5 }}>
            {username === userInfo?.username &&
              <LinkVibe onClick={copyLink}>
                <Tooltip className="tooltip">Copy your vibe link</Tooltip>
                <IconMedia src={'../../../vibeLogo.svg'} />
              </LinkVibe>
            }
            {(media ?? []).filter((social: any) => social.platform != 'Personal' && !social.platform.startsWith('C') && !social.platform.startsWith('Acc')).map((filteredPerson: any) => (
              <a href={filteredPerson.link} target="_blank" rel="noopener noreferrer">
                <IconMedia src={getImageMedia(filteredPerson)} />
              </a>
            ))}

            {(media ?? []).filter((social: any) => social.platform == 'Personal').map((filteredPerson: any) => (
              <LinkWeb href={filteredPerson.link} target="_blank" rel="noopener noreferrer">
                Website
              </LinkWeb>
            ))}
          </SocialMedia>
        </InfoMedia>
        <ButtonsActions>
          {/* <ButtonsLeft></ButtonsLeft> */}
          {((media ?? []).find(({ platform }: any) => platform === 'AcceptingClients') && (media ?? []).find(({ platform }: any) => platform === 'AcceptingClients')?.link === 'true') ? (
            <ButtonSpinner
              loading={false}
              handleClick={() => { }}
              text="Schedule Appointment"
              hasIcon={false}
              color={"#166E42"}
            />
          ) : (
            <ButtonSpinner
              loading={false}
              handleClick={() => { }}
              text="Not Accepting New Clients"
              hasIcon={false}
              color={"#d92b2b"}
            />

          )}
        </ButtonsActions>
        <ButtonsActions>
          <ButtonsLeft></ButtonsLeft>
          <ButtonSpinner
            loading={false}
            handleClick={() => setflipCard(!flipCard)}
            text="Back"
            hasIcon={false}
            color={"#e59b50"}
          />
        </ButtonsActions>
      </CardBack>
    </FlipCard>
  );
}

export default HealerCard;