import { UserResponse } from "../interfaces/user/UserResponse";
import User from "../interfaces/user/User";
import { Backend, MicroServices } from "./backendHelper";
import { passwordTips } from "../components/Website/PasswordToolTip";
import { IUserGradeResponse } from "@vibe/sdk/dist/interfaces/Admin/User";
import { PostalCodeClean } from "@vibe/sdk/dist/interfaces/Roster/postalCodes";

interface IValidatePass {
    completeIDs: number[];
    lastError: string;
}

export const isValidUsername = (username: string): string => {

    if (typeof username !== "string") {
        return "Must be a string";
    }

    if (username.length === 0) {
        return 'Username is required';
    }

    if (username.length > 15) {
        return 'Username must have a maximum of 15 characters';
    }

    if (username.length < 4) {
        return 'Username must have a minimum of 4 characters';
    }

    if (username.indexOf(" ") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("&") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf(";") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("/") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("[") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("]") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf(":") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("|") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("+") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("=") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf(",") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("?") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("*") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("%") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("@") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("#") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf('"') !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("'") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("{") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("}") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("(") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf(")") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("¨") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("´") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("`") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf("-") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    if (username.indexOf(".") !== -1) {
        return 'Username can have only letters, numbers and underscores';
    }

    return '';
}

export const validatePassword = (password: string, toolTip = false): IValidatePass => {
    if (password.length === 0 && toolTip) {
        return {
            completeIDs: [],
            lastError: 'Password is required'
        }
    }

    if (password === '') {
        return {
            completeIDs: [],
            lastError: 'Password is required'
        }
    }

    const completeIDs: number[] = [];
    let lastError: string = '';

    if (password.length >= 8) {
        completeIDs.push(1);
    }
    else {
        completeIDs.filter(tip => tip !== 1);
    }

    for (let i = 1; i < passwordTips.length; i++) {
        if (new RegExp(passwordTips[i].regex).test(password)) {
            completeIDs.push(passwordTips[i].id);
            continue;
        }

        completeIDs.filter(tip => tip !== passwordTips[i].id);
        lastError = passwordTips[i].label;
    }

    return {
        completeIDs,
        lastError,
    }
}

export const isValidBirthdate = (allegedBirthdate: any): boolean => {
    if (!allegedBirthdate) {
        return false;
    }
    if (typeof allegedBirthdate === "string") {
        try {
            let separator = "";
            allegedBirthdate.includes("-") ? separator = "-" : separator = "/";
            const allegedBirthdateSplit = allegedBirthdate.split(separator)
            const month = parseInt(allegedBirthdateSplit[0]);
            const day = parseInt(allegedBirthdateSplit[1]);
            const year = parseInt(allegedBirthdateSplit[2]);


            if (isNaN(year) || isNaN(month) || isNaN(day)) {
                return false
            }

            if (year < 1900 || year > (new Date()).getFullYear()) {
                return false
            }

            if (month < 1 || month > 12) {
                return false
            }

            if (day < 1 || day > 31) {
                return false
            }
        } catch (error) {
            return false
        }

    } else {
        return false
    }

    const birthday = (new Date(allegedBirthdate)).getTime();
    const now = Date.now();
    const gap = new Date(now - birthday).getFullYear() - 1970;

    return gap >= 18;

}

export const getNameUser = (user: IUserGradeResponse, userInfo?: any, userRoles?: any[], organization?: any) => {

    if (userRoles && userRoles.includes('SUPER')) {
        return 'Vibe Superadmin'
    }

    if (userRoles && organization && userRoles.includes('ORGADMIN')) {
        return organization.name;
    }

    if (user?.firstname && user?.lastname) {
        return `${user?.firstname} ${user?.lastname}` || ''
    }

    if (userInfo) {
        return userInfo.username || ''
    }

    return ''
};

export const formatGender = (gender: string) => {
    if (gender === 'M') {
        return 'Male'
    }

    if (gender === 'F') {
        return 'Female'
    }

    if (gender === 'O') {
        return 'Non binary'
    }

    if (gender === 'P') {
        return 'Prefer not to disclose'
    } //@audit-issue change to avoid O and P gender @comment Dismissed since this is just for formatting

    return '';
}

export const isValidString = (name: any) => typeof name === "string" && name.length > 0;

export const isUserRegistered = (email: string, org_id: string) => new Promise((resolve, reject) => {
    Backend(
        MicroServices.Admin,
        `/users?email=${email}&org=${org_id}`)
        .then((response) => response.json())
        .then((results) => {
            if (results.users && results.users.length > 0) {
                resolve(true);
            }
            else {
                resolve(false)
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const isValidZipCode = (zipcode: string, lengths: PostalCodeClean, country?: string): string => {
    if (zipcode.length === 0 && lengths.maxLength > 0 && lengths.minLength > 0) {
        return 'Zip Code is required';
    }

    if (zipcode.length < lengths.minLength || zipcode.length > lengths.maxLength) {
        if (lengths.maxLength === lengths.minLength) {
            return `Zip Code for ${country ?? 'USA'} must have ${lengths.minLength} characters`;
        } else {
            return `Zip Code for ${country ?? 'USA'} must have between ${lengths.minLength} and ${lengths.maxLength} characters`;
        }
    }

    return '';
}

export const getIdOrgUser = (userRolesByOrg: string[][], userOrgs: string[]): string => {
    const orgIndex = (userRolesByOrg || [[]]).findIndex((roles) => (roles || []).includes('ORGADMIN'));
    return orgIndex !== -1 ? userOrgs[orgIndex] : userOrgs[0];
}

export const isPaidHealer = (userRoles: string[], hasActiveSubscription: boolean): boolean => userRoles.includes('ORGADMIN') && hasActiveSubscription;