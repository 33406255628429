import { useEffect, useState, useContext, useRef, KeyboardEventHandler } from 'react'
import styled, { css } from "styled-components";
import { Imp, listAreasModal } from "../signupsetuphealerind"
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-thin-svg-icons";
import { faCirclePlus, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import DatePicker from "react-datepicker";
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import ButtonSpinner from '../BaseComponents/ButtonSpinner';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { useDispatch, useSelector } from 'react-redux';
import { getCertifications } from '../../store/actions/modalityActions';
import { RootState } from '../../store/store';
import { ICertification, IDegreeCertification, IExperienceCertification } from '@vibe/sdk/dist/interfaces/Modalities/certifications/Certification';
import { FileRejection, useDropzone } from 'react-dropzone';
import { LogoInterface } from '../signupsetuphealer/Introduction';
import { uploadAsset } from '../../store/actions/userActions';
import { uploadImage } from '../../store/actions/orgsActions';
import ErrorField from '../BaseComponents/ErrorField';
import { ContextSuccess } from "../../Context";
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { onHideType } from '../BaseComponents/Modals/ModalUnsaved';
import SelectItem from '../../interfaces/SelectItem';
import Selector from '../BaseComponents/Selector';
import CreatableSelect from 'react-select/creatable';
import { OnChangeValue, SingleValue } from 'react-select';
import SelectorYearMonth from '../SelectorYearMonth';
import { isIDegreeCertification } from '../../guards/modalities/certification.guard';

const ModalCertifications = ({
    showModal,
    onHide,
    data,
    edit,
    certificationInfo,
}: {
    showModal: boolean,
    onHide: (show: boolean, value?: Modality) => void,
    data: Modality,
    edit?: boolean,
    certificationInfo?: ICertification,
}) => {
    const dispatch = useDispatch();
    const username = useSelector((state: RootState | any) => state.getUser.userInfo.username);
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const currentSuccess = useContext(ContextSuccess);
    const onDrop = (acceptedFile: File[], rejectedFiles: FileRejection[]) => {
        if(rejectedFiles.length > 0) return;

        console.log(acceptedFile[0])
        setPDF({
            ...cerPDF,
            value: {
                fileLink: '',
                file: acceptedFile[0],
            }
        })
    }

    const handleCertifications = () => new Promise((resolve, reject) => {
        const dataFile = URL.createObjectURL(cerPDF.value?.file as any);
        let contentType: any;
        let file: any;

        fetch(dataFile)
            .then(res => res.blob())
            .then(blob => {
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'certification' + cerName + userInfo?.username, { type: contentType });

                if (file) {
                    return dispatch<any>(uploadAsset(
                        file.type,
                        file.name + '.' + extension,
                        "post_asset"
                    ))
                }
                else {
                    return Promise.reject(new Error("File not found"));
                }
            })
            .then((results: any) => {
                if (results.body) {

                    dispatch<any>(uploadImage(
                        results.body,
                        contentType,
                        file,
                    ))
                        .then((result: any) => {
                            if (result.status === 200) {
                                const urlImage = results.body.split('?')[0];
                                setPDF({
                                    ...cerPDF,
                                    value: {
                                        ...cerPDF.value as any,
                                        fileLink: urlImage,
                                    }
                                })
                                resolve(urlImage);
                            }
                            else {
                                console.log(result)
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                        })

                }
            })
    });

    const [loading, setLoading] = useState<boolean>(false)
    const [certifications, setCertifications] = useState<ICertification[]>([]); //@audit que tus ids sean numeritos 1 -2 -3 y asi mientras, pero no se los vayas a mandar al backend

    const [cerName, setCerName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [cerYear, setCerYear] = useState<BaseFieldState<string>>(getFieldState(''));
    const [cerOrgName, setOrgName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [cerOrgWeb, setOrgWeb] = useState<BaseFieldState<string>>(getFieldState(''));
    const [cerPDF, setPDF] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [cerExp, setCerExp] = useState<BaseFieldState<string>>(getFieldState(''));
    const [noExp, setNoExp] = useState<boolean>(false)

    const [lineages, setLineages] = useState<SelectItem[]>([]);
    const [lineage_search, setLineage_search] = useState<string>('');
    const [lineage_input, setLineage_input] = useState<string>('');
    const [lineage, setLineage] = useState<SingleValue<SelectItem>>({ label: '', value: '' });

    const [yearExp, setYearExp] = useState<BaseFieldState<number>>(getFieldState(0))
    const [personalStatement, setPersonalStatement] = useState<BaseFieldState<string>>(getFieldState(''))

    const [tab, setTab] = useState<'CER' | 'EXP'>('CER');

    const [year, setYear] = useState<string>('');
    const [month, setMonth] = useState<string>('');

    const [yearDExp, setYearDExp] = useState<string>('');
    const [monthDExp, setMonthDExp] = useState<string>('');

    const maxSize = 2 * 1024 * 1024; 

    const { getRootProps, getInputProps, open, isDragReject, fileRejections } = useDropzone({
        onDrop,
        noClick: true,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': [],
        },
        maxSize
    })

    const isFileTooLarge = fileRejections.some(file => file.file.size > maxSize);

    const cleanFields = () => {
        setCerName(getFieldState(''));
        setCerYear(getFieldState(''));
        setOrgName(getFieldState(''));
        setOrgWeb(getFieldState(''));
        setPDF(getFieldState({ file: '', fileLink: '' }));
        setCerExp(getFieldState(''));
        setNoExp(false)
        setYearExp(getFieldState(0));
        setLineage_search('')
        setLineage({ label: '', value: '' });
        setPersonalStatement(getFieldState(''));
    }

    const updateFields = () => {
        cleanFields();
        if (data.name === 'custom') return;

        setCerName({
            ...cerName,
            showError: false,
            value: data.name
        });
    }

    const loadData = () => {
        if (edit && certificationInfo) {
            if (isIDegreeCertification(certificationInfo)) {
                setTab('CER')
                setCerName({
                    ...cerName,
                    value: certificationInfo.name,
                })
                setCerYear({
                    ...cerYear,
                    value: certificationInfo.dateEarned,
                })
                setOrgName({
                    ...cerOrgName,
                    value: certificationInfo.grantedOrgName,
                })
                setOrgWeb({
                    ...cerOrgWeb,
                    value: certificationInfo.grantedOrgWebsite,
                })
                setPDF({
                    ...cerPDF,
                    value: {
                        fileLink: certificationInfo.pdfLink ?? '',
                        file: '',
                    },
                })
                setCerExp({
                    ...cerExp,
                    value: certificationInfo.expDate !== '0000-00' ? certificationInfo.expDate : '',
                })
                setNoExp(certificationInfo.expDate === '0000-00')
            } else {
                setTab('EXP')
                setCerName({
                    ...cerName,
                    value: certificationInfo.name,
                })
                setYearExp({
                    ...yearExp,
                    value: certificationInfo.yearsOfExperience,
                });
                setLineage({
                    label: certificationInfo.lineage ?? '',
                    value: certificationInfo.lineage ?? ''
                });
                setPersonalStatement({
                    ...personalStatement,
                    value: certificationInfo.personalStatement ?? '',
                });
            }

        }
    }

    const handleCreateCert = (certification: Omit<IDegreeCertification, 'id'>) => {
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.createCertification(certification, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, 'Certification created successfully.');
                    dispatch(getCertifications(username))
                    onHide(false);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error creating certification.');
                }
            })
            .catch((error) => {
                console.error('Certification error:', error);
                currentSuccess?.setSuccessData(false, true, 'Error creating certification.');
            })
            .finally(() => setLoading(false))
    }

    const handleCreateExp = (certification: Omit<IExperienceCertification, 'id'>) => {
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.createCertification(certification, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, 'Experience created successfully.');
                    dispatch(getCertifications(username))
                    onHide(false);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error creating experience.');
                }
            })
            .catch((error) => {
                console.error('experience error:', error);
                currentSuccess?.setSuccessData(false, true, 'Error creating experience.');
            })
            .finally(() => setLoading(false))
    }

    const handleEditCert = (certificationId: string, certification: Partial<IDegreeCertification>) => {
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.editCertification(certificationId, certification, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, 'Certification updated successfully.');
                    dispatch(getCertifications(username))
                    onHide(false);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error updating certification.');
                }
            })
            .catch((error) => {
                console.error('Certification error:', error);
                currentSuccess?.setSuccessData(false, true, 'Error updating certification.');
            })
            .finally(() => setLoading(false))
    }

    const handleEditExp = (certificationId: string, certification: Partial<IExperienceCertification>) => {
        setLoading(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.editCertification(certificationId, certification, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    currentSuccess?.setSuccessData(true, true, 'Experience updated successfully.');
                    dispatch(getCertifications(username))
                    onHide(false);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, 'Error updating experience.');
                }
            })
            .catch((error) => {
                console.error('experience error:', error);
                currentSuccess?.setSuccessData(false, true, 'Error updating experience.');
            })
            .finally(() => setLoading(false))
    }

    const validExpirationDate = (expDate: string): boolean => {
        if (expDate === '0000-00') return true;
        if (!(/((?:19|20|21)\d\d)-(0?[1-9]|1[012])/.test(expDate))) {
            return false
        }
        const parts = expDate.split('-')
        if (parts[0].length !== 4 || parts[1].length !== 2) return false;
        const year = Number(parts[0])
        const month = Number(parts[1])
        const today = new Date()
        if (year < today.getFullYear()) {
            return false
        } else if (year === today.getFullYear()) {
            if (month < today.getMonth() + 1) return false
        }
        return true
    }

    const CheckDataStep = (): boolean => {
        let hasErrors = false;

        if (!cerName.value || cerName.value?.length === 0) {
            hasErrors = true;
            setCerName({
                ...cerName,
                showError: true,
                error: 'Certification name is required'
            })
        } else {
            setCerName({
                ...cerName,
                showError: false,
                error: ''
            })
        }

        if (!cerYear.value || cerYear.value?.length === 0) {
            hasErrors = true;
            setCerYear({
                ...cerYear,
                showError: true,
                error: 'Certification year is required'
            })
        } else {
            setCerYear({
                ...cerYear,
                showError: false,
                error: ''
            })
        }

        if (!cerOrgName.value || cerOrgName.value?.length === 0) {
            hasErrors = true;
            setOrgName({
                ...cerOrgName,
                showError: true,
                error: 'Granted organization name is required'
            })
        } else {
            setOrgName({
                ...cerOrgName,
                showError: false,
                error: ''
            })
        }

        // if (!cerOrgWeb.value || cerOrgWeb.value?.length === 0) {
        //     hasErrors = true;
        //     setOrgWeb({
        //         ...cerOrgWeb,
        //         showError: true,
        //         error: 'Granted organization website is required'
        //     })
        // } else {
        setOrgWeb({
            ...cerOrgWeb,
            showError: false,
            error: ''
        })
        // }

        if ((cerExp.value && cerExp.value?.length === 0) && !validExpirationDate(cerExp.value ?? '0000-00')) {
            hasErrors = true;
            setCerExp({
                ...cerExp,
                showError: true,
                error: 'Expiration date is not time logic.'
            })
        } else {
            setCerExp({
                ...cerExp,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }
    const CheckDataStepExp = (): boolean => {
        let hasErrors = false;

        if (!cerName.value || cerName.value?.length === 0) {
            hasErrors = true;
            setCerName({
                ...cerName,
                showError: true,
                error: 'Certification name is required'
            })
        } else {
            setCerName({
                ...cerName,
                showError: false,
                error: ''
            })
        }

        if (!yearExp.value || yearExp.value === 0) {
            hasErrors = true;
            setYearExp({
                ...yearExp,
                showError: true,
                error: 'Years of experience is required'
            })
        } else {
            setYearExp({
                ...yearExp,
                showError: false,
                error: ''
            })
        }

        // if (!personalStatement.value || personalStatement.value?.length === 0) {
        //     hasErrors = true;
        //     setPersonalStatement({
        //         ...personalStatement,
        //         showError: true,
        //         error: 'Personal Statement is required'
        //     })
        // } else {
        //     setPersonalStatement({
        //         ...personalStatement,
        //         showError: false,
        //         error: ''
        //     })
        // }

        return hasErrors;
    }

    const handleOnSaveCertifications = async () => {
        if (tab === 'CER') {
            if (CheckDataStep()) return;

            const toSend: Omit<IDegreeCertification, 'id'> = {
                username,
                grantedOrgName: cerOrgName.value ?? '',
                grantedOrgWebsite: cerOrgWeb.value && cerOrgWeb.value.length ? cerOrgWeb.value : undefined,
                name: cerName.value ?? '',
                dateEarned: cerYear.value ?? '',
                expDate: cerExp.value && cerExp.value.length ? cerExp.value : '0000-00',
                createdAt: `${new Date().valueOf()}`,
            }

            if (cerPDF.value?.file) {
                toSend.pdfLink = await handleCertifications() as string;
            }

            if (edit) handleEditCert(certificationInfo!.id, toSend);
            else handleCreateCert(toSend);
        }
        else {
            if (CheckDataStepExp()) return;

            const toSend: Omit<IExperienceCertification, 'id'> = {
                username,
                name: cerName.value ?? '',
                createdAt: `${new Date().valueOf()}`,
                yearsOfExperience: yearExp.value ?? 0,
                personalStatement: personalStatement.value ?? '',
            }

            if (lineage && lineage.value && lineage.value !== '') toSend.lineage = lineage.value;

            if (edit) handleEditExp(certificationInfo!.id, toSend);
            else handleCreateExp(toSend);
        }
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                onHide(false);
                cleanFields();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                handleOnSaveCertifications();
                break;

            default:
                currentSuccess?.setModalsOff();
                break;
        }
    }

    const handleUnsaved = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to save your changes?'
        );
    }

    useEffect(() => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getLineages(dataSDK.token)
            })
            .then(lineages => {
                if (Array.isArray(lineages.body)) {
                    setLineages(lineages.body.map(lin => { return { label: lin, value: lin } }))
                } else {
                    console.log(lineages.body)
                    alert('Error getting lineages')
                }
            })
            .catch((error) => {
                console.error(error)
                alert('error')
            })
        if (edit) loadData();
        else updateFields();
    }, [data, certificationInfo, edit, showModal])

    useEffect(() => {
        if (year !== '' && month !== '') {
            setCerYear({
                ...cerYear,
                value: `${year}-${month}`
            })
        }
    }, [year, month])

    useEffect(() => {
        if (yearDExp !== '' && monthDExp !== '') {
            setCerExp({
                ...cerExp,
                value: `${yearDExp}-${monthDExp}`
            })
        }
    }, [yearDExp, monthDExp])

    useEffect(() => {
        bodyRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, [showModal])

    const bodyRef = useRef<HTMLDivElement>(null);

    const colourStyles = {
        menuList: (base: any) => ({
            height: 150,
            overflow: 'auto',
            fontFamily: 'Inter'
        }),
        singleValue: (base: any) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            color: 'black'
        }),
        control: (styles: any) => ({
            ...styles,
            border: '1px solid var(--simple-gray)',
            borderRadius: "9px",
            boxShadow: "none",
            minWidth: "50%",
            height: 40
        }),
        option: (styles: any, { isFocused, isSelected }: any) => ({
            ...styles,
            color: 'black',
            display: 'flex',
            flexDirection: 'row',
            "&:hover": {
                backgroundColor: "#e8e8e8"
            },
            backgroundColor: isSelected ? '#e8e8e8' : ''
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: 'white',
            textAlign: "left"
        }),
        container: (styles: any) => ({
            ...styles,
            padding: 0,
            width: "100%"
        }),
        menu: (styles: any) => ({
            ...styles,
            borderRadius: "8px",
        }),
        input: (styles: any, state: any) => ({
            ...styles,
            border: 'none',
            color: 'black',
        }),
        dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
        indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
        multiValue: (styles: any) => ({
            ...styles,
            color: 'white',
            backgroundColor: 'var(--electric-yellow-2)',
        }),
        multiValueLabel: (styles: any) => ({
            ...styles,
            color: 'white',
            fontFamily: 'RobotoCondensed-Regular',
            fontSize: '16px'
        }),
        multiValueRemove: (styles: any) => ({
            ...styles,
            color: 'white',
            backgroundColor: "transparent"
        }),
    };

    return (
        <>
            <Modal className="modalCertification" show={showModal}>
                <Modal.Header>
                    <Modal.Title>
                        {!edit && (
                            <Tabs>
                                <Tab theme={{ main: tab === 'CER' && 'tabActive' }} onClick={() => setTab('CER')} left>Certification</Tab>
                                <Tab theme={{ main: tab === 'EXP' && 'tabActive' }} onClick={() => setTab('EXP')} right>Experience / Lineage</Tab>
                            </Tabs>
                        )}
                        <FontAwesomeIcon
                            onClick={handleUnsaved}
                            icon="times"
                            size='xl'
                            color={'black'}
                            className=""
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WrapperBody ref={bodyRef}>
                        {tab === 'CER' ? (
                            <>
                                <Row1 {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {cerPDF.value && (cerPDF.value.file || cerPDF.value?.fileLink.length > 0) ? (
                                        <DropPhoto onClick={open}>
                                            <ContIconDrop>
                                                <IconDrop src='../fileUpload.svg' />
                                            </ContIconDrop>
                                            <TextDrop>
                                                File uploaded
                                            </TextDrop>
                                        </DropPhoto>
                                    ) : (
                                        <DropPhoto onClick={open}>
                                            <ContIconDrop>
                                                <IconDrop src='../fileUpload.svg' />
                                            </ContIconDrop>
                                            <TextDrop>
                                                <TextBlue>Choose</TextBlue> or Drag & Drop file to upload (Optional)
                                                <br />
                                                <SubtextDrop>
                                                    PDF, JPG or PNG (2mb max)
                                                </SubtextDrop>
                                            </TextDrop>
                                        </DropPhoto>
                                    )}
                                    {isDragReject && <ErrorField error={'Sorry, only PNG, JPEG, and PDF files are allowed'} />}
                                    {isFileTooLarge && <ErrorField error={'File is too large (max 2MB)'} />}
                                </Row1>
                                <Row1>
                                    <Label>Certification Name<Imp>*</Imp></Label>
                                    <Input value={cerName.value} onChange={(e) => setCerName({ ...cerName, value: e.currentTarget.value })} />
                                    {(cerName.showError) && (<ErrorField error={cerName.error} />)}
                                </Row1>
                                <Row1>
                                    <Label>Granted Organization Name<Imp>*</Imp></Label>
                                    <Input value={cerOrgName.value} onChange={(e) => setOrgName({ ...cerOrgName, value: e.currentTarget.value })} />
                                    {(cerOrgName.showError) && (<ErrorField error={cerOrgName.error} />)}
                                </Row1>
                                <Row1>
                                    <Label>Granted Organization Website</Label>
                                    <Input
                                        placeholder='https://'
                                        value={cerOrgWeb.value}
                                        onChange={(e) => setOrgWeb({ ...cerOrgWeb, value: e.currentTarget.value })}
                                    />
                                    {(cerOrgWeb.showError) && (<ErrorField error={cerOrgWeb.error} />)}
                                </Row1>

                                <Inputs>
                                    <Row2>
                                        <Label>Date Earned<Imp>*</Imp></Label>
                                        <InputIcon theme={{ main: 'datePickerYearMonthList' }}>
                                            <SelectorYearMonth
                                                setYear={(date: string) => setYear(date)}
                                                setMonth={(date: string) => setMonth(date)}
                                                year={cerYear.value?.split("-")[0]}
                                                month={cerYear.value?.split("-")[1]}
                                            />
                                            {/* <DatePicker
                                                // onChange={(date: Date) => setCerYear({
                                                //     ...cerYear,
                                                //     value: `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}`
                                                // })}
                                                onChange={(date: Date) => alert(date)}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                maxDate={new Date()}
                                                className={"inputDatePicker"}
                                            />
                                            <DatePicker
                                                onChange={(date: Date) => setCerYear({
                                                    ...cerYear,
                                                    value: `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}`
                                                })}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                maxDate={new Date()}
                                                className={"inputDatePicker"}
                                            /> */}
                                        </InputIcon>
                                        {/* <InputIcon theme={{ main: 'datePickerYearMonth' }}>
                                            <DatePicker
                                                placeholderText={'YYYY / MM'}
                                                value={cerYear.value}
                                                onChange={(date: Date) => setCerYear({
                                                    ...cerYear,
                                                    value: `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}`
                                                })}
                                                className={"inputDatePicker"}
                                                showYearDropdown
                                                showMonthDropdown
                                                maxDate={new Date()}
                                            />
                                            <StyledFontAwesomeIcon
                                                icon={faCalendar}
                                                size='lg'
                                            />
                                        </InputIcon> */}
                                        {(cerYear.showError) && (<ErrorField error={cerYear.error} />)}
                                    </Row2>
                                    <Row2>
                                        <Label>Expiration Date</Label>
                                        <InputIcon theme={{ main: 'datePickerYearMonthList' }}>
                                            <SelectorYearMonth
                                                setYear={(date: string) => setYearDExp(date)}
                                                setMonth={(date: string) => setMonthDExp(date)}
                                                year={cerExp.value?.split("-")[0]}
                                                month={cerExp.value?.split("-")[1]}
                                            />
                                            {/* <SelectorYearMonth
                                                onChange={(date: string) => setCerExp({
                                                    ...cerExp,
                                                    value: date
                                                })}
                                                value={cerExp.value}
                                            /> */}
                                            {/* <DatePicker
                                                placeholderText={noExp ? 'No expiration' : 'YYYY / MM'}
                                                value={noExp ? '' : cerExp.value}
                                                onChange={(date: Date) => setCerExp({
                                                    ...cerExp,
                                                    value: `${date.getFullYear()}-${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}`
                                                })}
                                                disabled={noExp}
                                                className={"inputDatePicker"}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                            <StyledFontAwesomeIcon
                                                icon={faCalendar}
                                                size='lg'
                                            /> */}
                                        </InputIcon>
                                        {/* <Row3>
                                            <Label2>Certification without expiration date</Label2>
                                            <CheckBox
                                                type="checkbox"
                                                checked={noExp}
                                                onChange={() => setNoExp(!noExp)}
                                                disabled={false}
                                            />
                                        </Row3> */}
                                        {(cerExp.showError) && (<ErrorField error={cerExp.error} />)}
                                    </Row2>
                                </Inputs>
                            </>
                        ) : (
                            <>
                                <Row1>
                                    <Label>Name<Imp>*</Imp></Label>
                                    <Input value={cerName.value} onChange={(e) => setCerName({ ...cerName, value: e.currentTarget.value })} />
                                    {(cerName.showError) && (<ErrorField error={cerName.error} />)}
                                </Row1>
                                <Inputs>
                                    <Row2>
                                        <Label>Years of experience<Imp>*</Imp></Label>
                                        <Input
                                            type='number'
                                            value={yearExp.value}
                                            onChange={(e) => setYearExp({ ...yearExp, value: parseInt(e.currentTarget.value) })}
                                        />
                                        {(yearExp.showError) && (<ErrorField error={yearExp.error} />)}
                                    </Row2>
                                    <Row2>
                                        <Label>Lineage</Label>
                                        <LineageContainer>
                                            <CreatableSelect
                                                className={'sectionTest'}
                                                options={lineages}
                                                components={{ DropdownIndicator: null }}
                                                inputValue={lineage_input}
                                                isClearable
                                                onChange={(e) => setLineage(e)}
                                                onInputChange={(e) => setLineage_input(e)}
                                                onKeyDown={(e) => {
                                                    if (!lineage_input) return;
                                                    switch (e.key) {
                                                        case 'Enter':
                                                            setLineage({ label: lineage_input, value: lineage_input })
                                                            setLineage_input('');
                                                            e.preventDefault();
                                                    }
                                                }}
                                                styles={colourStyles}
                                                placeholder=""
                                                value={lineage}
                                            />
                                        </LineageContainer>

                                    </Row2>
                                </Inputs>
                                <Row1>
                                    <Label>Personal statement</Label>
                                    <TextArea
                                        value={personalStatement.value ?? ''}
                                        onChange={(e) => setPersonalStatement({ ...personalStatement, value: e.currentTarget.value })}
                                        rows={3}
                                    />
                                    {(personalStatement.showError) && (<ErrorField error={personalStatement.error} />)}

                                </Row1>
                            </>
                        )}
                    </WrapperBody>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonCancel onClick={handleUnsaved}>Cancel</ButtonCancel>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loading}
                            handleClick={handleOnSaveCertifications}
                            text={'Save'}
                            hasIcon={false}
                        />
                    </WrapperButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const LineageContainer = styled.div`
    height: 40px;
`

const WrapperBody = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    padding-bottom: 120px;
    overflow: scroll;
`;

const WrapperButton = styled.div`
    width: 50%;
`;

const List = styled.div`
    padding: 0 24px;
`

const IconDrop = styled.img`
    width: 28px;
`

const FileD = styled.div`
    display: flex;
    align-items: center;

    & ${IconDrop}{
        width: 18px;
        margin-left: 10px;
    }
`

const TableIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
`;

const TableList = styled.table`
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin-left: 10px;
`

const Tr = styled.tr`
    & td:last-child,
    & th:last-child {
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const Th = styled.th`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter-Bold';
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
`

const Td = styled.td`
    vertical-align: middle;
    height: 40px;
    border-bottom: 1px solid var(--simple-gray-2);
    font-family: 'Inter';
    font-size: 14px; 
    padding: 0 15px; 
    text-align: left;
`

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const SeeAllModalities = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    margin: 0 24px;
    border-bottom: ${props => props.theme.border || '1'}px solid gray;
    padding-bottom: 13px;
    width: calc(100% - 33px);
    cursor: pointer;
    margin-top: 20px;
`

const PlusIcon = styled(FontAwesomeIcon)`
    font-size: 24px;
    margin-right: 10px;
`;


const Inputs = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 7px;
    gap: 20px;
    padding: 0 24px;
`

const ButtonCancel = styled.div`
    width: 49%;
    text-align: center;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 1px solid var(--dark-blue);
`
const ButtonSave = styled.div`
    width: 49%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    padding: 11px 0;
    border-radius: 7px;
    font-size: 16px;
    font-family: 'Inter-Bold';
    border: 0px;
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export const InputOnlyPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--black);
    padding-left: 20px;

    &::placeholder{
        color: var(--black);
        font-family: 'Inter-Bold'
        
    }
`;

const Title = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    text-align: left;
`

const Row1 = styled.div`
    width: 100%;
    padding: 0 24px;
`

const Row2 = styled.div`
    width: 50%;
`

const Row3 = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 18px 0  8px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Label2 = styled.div`    
    width: 100%;
    text-align: left;
    margin: 18px 0  8px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 12px;
`
const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`
const TextArea = styled.textarea`
    outline: none;
    width: 100%;
    border-radius: 9px;
    border: 0;
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    resize: none;
`


const InputIcon = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    position: relative;

    & .react-datepicker{
        border: 1px solid var(--simple-gray-2);
        border-radius: 11px;
        overflow: hidden;
        color: black;
    }

    & .react-datepicker-wrapper{
        text-align: left;
    }
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const CheckBox = styled.input`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid var(--t-blue);
    width: 20px;
    height: 20px;
    opacity: "1";

    &:hover {
        background: var(--simple-gray);
    }

    &:checked {
        background-clip: content-box;
        background: var(--simple-gray);
        box-shadow: inset 0 0 0 4px white;
    }
`;

const DropPhoto = styled.div`
    width: 100%;
    margin: 7px 0 4px 0;
    text-align: initial;
    border: 1px dashed var(--simple-gray);
    height: 106px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
`

const ContIconDrop = styled.div`
    width: 74px;
    height: 74px;
    border-radius: 12px;
    background-color: #F2F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextDrop = styled.div`
    font-family: 'Inter';
    width: calc(100% - 74px);
    font-size: 14px;
    line-height: 17px;
    padding-left: 16px;
    color: var(--simple-brown-dark);
    text-align: center;
`

const SubtextDrop = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
    margin-top: 4px;
`

const TextBlue = styled.span`
    color: blue
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
    margin-left: 10px;
`;


const Tabs = styled.div`
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
`

const Tab = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    background-color: var(--simple-gray-2);
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Inter';
    cursor: pointer;

    ${({ left }: { left?: boolean, right?: boolean }) => left ? css`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    ` : ''}

    ${({ right }: { right?: boolean }) => right ? css`
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    ` : ''}


    &.tabActive{
        background-color: #E89F4D;
        color: white;
        font-family: 'Inter-Bold';
    }
`

export default ModalCertifications