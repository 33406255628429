import { ContainerContentResults } from "../styles";
import RowResults from "./RowResults";
import { filtersHomeComms, filtersHomeEvents, filtersHomeMods, filtersHomePOs, filtersHomeTPs } from "../../../../../interfaces/HomeSearch/TabsResultsHome";
import { ActivePanelDataType, ActivePanelType, SelectedItemHome } from "../../../../../interfaces/HomeSearch/ItemFilterHome";
import ModalityCard, { IModalityCard } from "../../../../BaseComponents/HomeCards/ModalityCard";
import CommunityHomeCard, { ICommunityHomeCard } from "../../../../BaseComponents/HomeCards/CommunityHomeCard";
import EventCard, { EventCardInterface } from "../../../../BaseComponents/Events/EventCard";
import useFilterItem from "../../../../../hooks/FilterTip/useFilterItem";
import { useEffect, useMemo, useState } from "react";
import HealerCard from "../../../../HealerCard/HealerCard";
import IHealer from "@vibe/sdk/interfaces/Admin/IHealer";
interface ContentAllHomeProps {
    mods: IModalityCard[][]
    comms: ICommunityHomeCard[][]
    tps: IHealer[][]
    pos: IHealer[][]
    events: EventCardInterface[][]

    filtersActiveMods: number[]
    filtersActiveComms: number[]
    filtersActiveTPs: number[]
    filtersActivePOs: number[]
    filtersActiveEvents: number[]

    setFiltersActiveMods: (data: number[]) => void
    setFiltersActiveComms: (data: number[]) => void
    setFiltersActiveTPs: (data: number[]) => void
    setFiltersActivePOs: (data: number[]) => void
    setFiltersActiveEvents: (data: number[]) => void

    activeLeftPanel: (data: ActivePanelDataType, type: ActivePanelType) => void

    setTabActive: (tabID: number) => void
}

const ContentAllHome = (props: ContentAllHomeProps) => {

    const [selectedOption, setSelectedOption] = useState<SelectedItemHome>({ label: '', value: '' });

    const benefits: string[] = useMemo(() => {
        return [
            ...props.mods.flat().map((item) => item.aliases).flat(),
            ...props.comms.flat().map((item) => item.aliases).flat()
        ].filter((s, idx, arr) => arr.indexOf(s) === idx)
    }, [props.mods, props.comms])

    const locations: string[] = useMemo(() => {
        return [
            ...props.tps.flat().map((item) => `${item.city}, ${item.country}`).flat(),
            ...props.pos.flat().map((item) => `${item.city}, ${item.country}`).flat(),
        ].filter((s, idx, arr) => arr.indexOf(s) === idx)
    }, [props.tps, props.pos])

    const options = useFilterItem([{
        id: 1,
        type: "BEN",
        dropdown: true,
    },
    {
        id: 2,
        type: "DEL",
        dropdown: true,
    },
    {
        id: 3,
        type: "LOC",
        dropdown: true,
    }])

    useEffect(() => {
        options.handleChangeBenefits(benefits);
    }, [benefits])

    useEffect(() => {
        options.handleChangeLocations(locations);
    }, [locations])

    const modFiltered = useMemo(() => {
        if (!selectedOption?.value) return props.mods[0];
        return props.mods[0].filter((m) => m?.aliases?.includes(selectedOption.value));
    }, [selectedOption, props.mods[0]])

    const commFiltered = useMemo(() => {
        if (!selectedOption?.value) return props.comms[0];
        return props.comms[0].filter((m) => m?.aliases?.includes(selectedOption.value));
    }, [selectedOption, props.comms[0]])

    const tpFiltered = useMemo(() => {
        if (!selectedOption?.value) return props.tps[0];
        return props.tps[0].filter((m) => m?.services?.map((s) => s.id).includes(selectedOption.value));
    }, [selectedOption, props.tps[0]])

    const poFiltered = useMemo(() => {
        if (!selectedOption?.value) return props.pos[0];
        return props.pos[0].filter((m) => m?.services?.map((s) => s.id).includes(selectedOption.value));
    }, [selectedOption, props.pos[0]])

    const eventsFiltered = useMemo(() => {
        if (!selectedOption?.value) return props.events[0];
        return props.events[0].filter((m) => m?.description?.includes(selectedOption.value));
    }, [selectedOption, props.events[0]])

    return (
        <ContainerContentResults>
            <RowResults
                name="Modalities and Services"
                cards={(c) => (
                    <ModalityCard
                        data={c}
                        onClick={(data: IModalityCard) => props.activeLeftPanel(data, 'MOD')}
                    />
                )}
                elements={modFiltered}
                // filters={filtersHomeMods}
                filters={options.items}
                filtersActive={props.filtersActiveMods}
                setFiltersActive={props.setFiltersActiveMods}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setTabActive={() => props.setTabActive(2)}
            />
            <RowResults
                name="Healers"
                cards={(c) => (
                    <HealerCard
                        healer={c}
                        onClick={(data: IHealer) => props.activeLeftPanel(data, 'TP')}
                    />
                )}
                elements={tpFiltered}
                // filters={filtersHomeTPs}
                filters={options.items}
                filtersActive={props.filtersActiveTPs}
                setFiltersActive={props.setFiltersActiveTPs}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setTabActive={() => props.setTabActive(3)}
            />
            <RowResults
                name="Communities"
                cards={(c) => (
                    <CommunityHomeCard
                        data={c}
                        onClick={(data: ICommunityHomeCard) => props.activeLeftPanel(data, 'COMM')}
                    />
                )}
                elements={commFiltered}
                // filters={filtersHomeComms}
                filters={options.items}
                filtersActive={props.filtersActiveComms}
                setFiltersActive={props.setFiltersActiveComms}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setTabActive={() => props.setTabActive(4)}
            />
            {/* <RowResults
                name="Professional Organizations"
                cards={(c) => (
                    <HealerCard
                        data={c}
                        type={'PO'}
                        onClick={(data: IHealer) => props.activeLeftPanel(data, 'PO')}
                    />
                )}
                elements={poFiltered}
                // filters={filtersHomePOs}
                filters={options.items}
                filtersActive={props.filtersActivePOs}
                setFiltersActive={props.setFiltersActivePOs}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setTabActive={() => props.setTabActive(3)}
            /> */}
            <RowResults
                name="Events"
                cards={(c) => (
                    <EventCard
                        data={c}
                        reload={() => { }}
                        onClickHome={(data: EventCardInterface) => props.activeLeftPanel(data, 'EVENT')}
                        isHome
                        width={"315px"}
                    />
                )}
                elements={eventsFiltered}
                filters={options.items}
                // filters={filtersHomeEvents}
                filtersActive={props.filtersActiveEvents}
                setFiltersActive={props.setFiltersActiveEvents}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setTabActive={() => props.setTabActive(5)}
            />
        </ContainerContentResults>
    )
}

export default ContentAllHome;