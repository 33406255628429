import { useContext, useEffect, useState, useRef } from 'react'
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import TextEditor from "../../components/BaseComponents/TextEditor";
import { useDispatch, useSelector } from 'react-redux';
import { ContextSuccess } from '../../Context';
import useGenders, { GenderOption } from '../../hooks/users/useGenders';
import { RootState } from '../../store/store';
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getUser, getUserInfoProfile, uploadAsset, uploadImage } from '../../store/actions/userActions';
import SelectorGender from '../BaseComponents/Genders/SelectorGenders';
import { CountryOption } from '../../hooks/useCountry';
import { Loader, Spinner } from '../BaseComponents/Loader';
import SelectorCountry from '../BaseComponents/Location/SelectorCountry';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import AddPhotoRounded from '../BaseComponents/ProfilePhoto/AddPhotoRounded';
import { LogoInterface } from '../signupsetuphealer/Introduction';
import { IUpdateUserBody } from '@vibe/sdk/dist/interfaces/Admin/updateUser';
import ModalImageEditorCrop from '../ModalImageEditorCrop';
import { StateOption } from '../../hooks/Locations/useActiveState';
import { CityOption } from '../../hooks/Locations/useStateCities';
import SelectorCity from '../BaseComponents/Location/SelectorCity';
import SelectorState from '../BaseComponents/Location/SelectorState';
import InputZipcode from '../BaseComponents/Location/InputZipcode';

const TabGeneral = ({
  isSeeker = false
}: {
  isSeeker?: boolean
}) => {

  const dispatch = useDispatch();
  const currentSuccess = useContext(ContextSuccess);
  const datepickerRef = useRef<any>(null);

  const { GendersReactSelect } = useGenders();

  const [loading, setLoading] = useState<boolean>(false);

  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);

  const [firstname, setFirstname] = useState<string>(user.firstname || '');
  const [lastname, setLastname] = useState<string>(user.lastname || '');
  const [birthday, setBirthday] = useState<Date>(user.birthdate ? new Date(user.birthdate) : new Date());
  const [gender, setGender] = useState<BaseFieldState<GenderOption>>(getFieldState({ label: 'Select a gender', value: 'N' }));
  const [address, setAddress] = useState<string>(user.address || '')
  const [phoneNumber, setPhoneNumber] = useState<string>(`${user.phoneNumber}` || '')
  const [biography, setBiography] = useState<string>(user.biography || '')
  const [updated, setUpdated] = useState<boolean>(false);

  const [searchCountry, setSearchCountry] = useState('');
  const [searchState, setSearchState] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [country, setCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: user.country, value: user.countryCode || '' }));
  const [orgState, setOrgState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: user.state || '', value: user.state || '' }));
  const [orgCity, setOrgCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: user.city || '', value: user.city || ''}));
  const [zipcode, setZipcode] = useState<BaseFieldState<string>>(getFieldState(user.zipcode || ''));

  const [logoOrg, setLogoOrg] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: user.avatar || '', fileLink: user.avatar || '' }));
  const [newLogo, setNewLogo] = useState(false);

  const castDateToString = (date: Date): string => `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

  const clickCalendar = () => {
    if (datepickerRef) {
      datepickerRef.current.setFocus(true)
    }
  }

  const handleSaveImage = () => new Promise((resolve, reject) => {
    const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
    let contentType: any;
    let file: any;
    fetch(dataFile)
      .then(res => res.blob())
      .then(blob => {
        contentType = dataFile.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        file = new File([blob], 'Avatar' + userInfo?.username || '', { type: contentType });

        if (file) {
          return dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            'profile_photo'
          ))
        }
        else {
          return Promise.reject(new Error("File not found"));
        }
      })
      .then((results: any) => {
        if (results.body) {

          dispatch<any>(uploadImage(
            results.body,
            contentType,
            file,
          ))
            .then((result: any) => {
              if (result.status === 200) {
                const urlImage = results.body.split('?')[0];
                setLogoOrg({
                  ...logoOrg,
                  value: {
                    ...logoOrg.value as LogoInterface,
                    fileLink: urlImage,
                    file: urlImage
                  }
                })
                resolve(urlImage);
              }
              else {
                console.log(result);
                reject();
              }
            })
            .catch((error: any) => {
              console.log(error)
              reject();
            })

        }
      })
  });

  const saveEditProfile = async () => {
    setLoading(true);

    const toSend: IUpdateUserBody = {
      firstname: firstname !== '' ? firstname : undefined,
      lastname: lastname !== '' ? lastname : undefined,
      birthday: birthday ? castDateToString(birthday) : undefined,
      gender: gender.value?.value !== 'N' ? gender.value?.value : undefined,
      countryCode: country.value?.value !== 'N' ? country.value?.value : undefined,
      city: orgCity.value?.label !== '' ? orgCity.value?.label : undefined,
      stateCode: orgState.value?.value !== 'N' ? orgState.value?.value : (orgState.value?.label !== 'N' ? orgState.value?.value : undefined),
      zipcode: zipcode.value !== '' ? zipcode.value : undefined,
      address: address !== '' ? address : undefined,
      phoneNumber: phoneNumber !== '3000000000' ? parseInt(phoneNumber.trim()) : 3000000000,
    }

    if (newLogo && logoOrg.value?.file !== '') {
      toSend.avatar = await handleSaveImage() as string;
    }

    getDataSDK()
      .then((dataSDK: getDataSDKResponse) =>
        Promise.all([
          dataSDK.sdk.Roster.updateUserBio(userInfo?.username || '', biography, dataSDK.token),
          dataSDK.sdk.Admin.editUser(
            userInfo?.username || '',
            toSend,
            'OA',
            dataSDK.token)
        ])
      )
      .then(([bioResults, results]) => {
        dispatch(getUser(userInfo?.username || '', 'OA'));
        dispatch(getUserInfoProfile(userInfo?.username || ''));
        if (bioResults && results && bioResults.statusCode === 201 && results.statusCode === 200) {
          currentSuccess?.setSuccessData(true, true, 'User information updated.');
          setUpdated(false);
        } else if (bioResults && bioResults.statusCode === 201) {
          currentSuccess?.setSuccessData(true, true, 'User statement updated.');
          setUpdated(false);
        } else if (results && results.statusCode === 200) {
          currentSuccess?.setSuccessData(true, true, 'User information updated.');
          setUpdated(false);
        } else {
          console.log(bioResults?.body, results?.body)
          currentSuccess?.setSuccessData(false, true, 'Error updating user information.');
        }
      })
      .catch((error) => {
        console.error(error);
        currentSuccess?.setSuccessData(false, true, 'Error updating user information.', error);
      })
      .finally(() => setLoading(false));
  }

  const onChangeField = (data: any, handle: (data: any) => void) => {
    setUpdated(true);
    handle(data);
  }

  const [showModal, setShowModal] = useState(false);
  const [activeCrop, setActiveCrop] = useState(false);
  const [logoNull, setLogoNull] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));

  function handleCloseModal(e: any, type: any) {
    setShowModal(() => false);
  };

  function handleCropImage(e: any) {

    const dataFile = URL.createObjectURL(logoOrg.value?.file as any);
    let contentType: any;
    let file: any;

    fetch(e)
      .then(res => res.blob())
      .then(blob => {

        // contentType = logo?.value?.file.type;
        contentType = dataFile.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        file = new File([blob], 'Avatar' + userInfo?.username || '', { type: contentType });

        setActiveCrop(true)
        setLogoNull({
          ...logoOrg,
          value: {
            fileLink: '',
            file: file,
          }
        })
        setLogoOrg({
          ...logoOrg,
          value: {
            fileLink: '',
            file: file,
          }
        })
      })
  };

  function showModalCrop(e: any) {
    setLogoOrg(e);
    setShowModal(true);
    setActiveCrop(false);
  }

  // useEffect(() => {
  //   if (logoOrg.value?.file === '') {
  //     setLogoNull({
  //       ...logoOrg,
  //       value: {
  //         fileLink: '',
  //         file: '',
  //       }
  //     })
  //     setShowModal(false);
  //   } else {
  //   }
  // }, [logoOrg])

  useEffect(() => {
    if (user.gender) {
      const g = GendersReactSelect.find(gen => gen.value === user.gender);
      if (g && gender.value?.value === 'N') setGender(getFieldState({ label: g.label, value: g.value }))
    }
  }, [user, GendersReactSelect])

  return (
    <Container>
      <ModalImageEditorCrop preview={logoOrg} handleSaveImage={handleCropImage} show={showModal} onHide={handleCloseModal} isProfilePhoto={true} />
      <AddPhotoRounded
        logo={logoOrg}
        setLogo={(e) => { console.log(e); showModalCrop(e) }}
        setNewLogo={(data: boolean) => onChangeField(data, setNewLogo)}
      />
      <TwoColumns>
        <Column>
          <Label>First Name</Label>
          <Input
            value={firstname}
            type={'text'}
            onChange={(e) => onChangeField(e.target.value, setFirstname)}
          />
        </Column>
        <Column>
          <Label>Last Name</Label>
          <Input
            value={lastname}
            type={'text'}
            onChange={(e) => onChangeField(e.target.value, setLastname)}
          />
        </Column>
      </TwoColumns>
      <TwoColumns>
        <Column>
          <TwoColumns>
            <Column>
              <Label>Email</Label>
              <InputDisabled>{user.email || ''}</InputDisabled>
            </Column>
            <Column>
              <Label>Phone number</Label>
              <Input
                value={phoneNumber}
                type={'text'}
                onChange={(e) => onChangeField(e.target.value, setPhoneNumber)}
              />
            </Column>
          </TwoColumns>
        </Column>
        <Column>
          <TwoColumns>
            <Column>
              <Label>Date of Birth</Label>
              <InputIcon theme={{ main: 'datePickerYearMonth' }}>
                <DatePicker
                  ref={datepickerRef}
                  placeholderText={'MM  /  DD  /  YYYY'}
                  selected={birthday}
                  onChange={(date: Date) => onChangeField(date, setBirthday)}
                  className="inputDatePicker"
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={100}
                />
                <StyledFontAwesomeIcon
                  icon={faCalendar}
                  size='lg'
                  onClick={clickCalendar}
                />
              </InputIcon>
            </Column>
            <Column>
              <Label>Gender</Label>
              <SelectorGender
                gender={gender}
                setGender={(data: BaseFieldState<GenderOption>) => onChangeField(data, setGender)}
              />
            </Column>
          </TwoColumns>
        </Column>
      </TwoColumns>
      <TwoColumns>
        <Column>
          <TwoColumns>
            <Column>
              <Label>Address</Label>
              <Input
                value={address}
                type={'text'}
                onChange={(e) => onChangeField(e.target.value, setAddress)}
              />
            </Column>
            <Column>
              <Label>Country</Label>
              <SelectorCountry
                country={country}
                setCountry={(data: BaseFieldState<CountryOption>) => onChangeField(data, setCountry)}
                searchCountry={searchCountry}
                setSearchCountry={setSearchCountry}
              />
            </Column>

          </TwoColumns>
        </Column>
        <Column>
          <TwoColumns>
            <Column>
              <Label>State</Label>
              <SelectorState
                country={country.value?.value ?? ''}
                state={orgState}
                setState={(data: BaseFieldState<CountryOption>) => onChangeField(data, setOrgState)}
                searchState={searchState}
                setSearchState={setSearchState}
              />
            </Column>
            <Column>
              <Label>City</Label>
              <SelectorCity
                state={orgState.value?.value || ''}
                city={orgCity}
                setCity={(data: BaseFieldState<CountryOption>) => onChangeField(data, setOrgCity)}
                searchCity={searchCity}
                setSearchCity={setSearchCity}
              />
            </Column>
            <Column>
              <Label>Zip / Postal Code</Label>
              <InputZipcode
                country={country}
                zipcode={zipcode}
                setZipcode={(data: BaseFieldState<string>) => onChangeField(data, setZipcode)}
              />
            </Column>
          </TwoColumns>
        </Column>
      </TwoColumns>
      <OneColumn>
        <Column>
          <Label>Personal Statement</Label>
          <TextEditor
            value={biography}
            setValue={(e: string) => onChangeField(e, setBiography)}
            charLimit={1000}
            maxHeight={'300px'}
            placeholder='Please write about yourself...'
          />
        </Column>
      </OneColumn>
      <ContButtn>
        {loading ?
          <WrapperSpinner>
            <Loader />
          </WrapperSpinner> :
          <Button onClick={saveEditProfile} color={updated ? '#2e3031' : '#bbbabc'}>Save</Button>
        }
      </ContButtn>
    </Container>
  )
}

const WrapperSpinner = styled.div`
  background-color: #2e3031;
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 8px;
  color: white;
  font-family: 'Inter-Bold';
`;

const ContButtn = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface ButtonProps {
  color: string;
}

const Button = styled.div`
background-color: ${(props: ButtonProps) => props.color};
width: 165px;
height: 40px;
display: flex;
justify-content: center;
padding: 12px 0;
border-radius: 8px;
color: white;
font-family: 'Inter-Bold';
cursor: pointer;
`

const Image = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 56px;
  border: 1px solid gray;
`
const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 8px;
  color: #2A4CD3;
  margin-bottom: 15px;

  text-align: center;
  font-family: Inter-Bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.1px;
`
const OneColumn = styled.div``

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;

const Input = styled.input`
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 0;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Inter';
  border: 1px solid var(--simple-gray);
`

export const InputDisabled = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 0;
  padding: 0 20px;
  font-size: 16px;
  font-family: 'Inter';
  border: 1px solid var(--simple-gray-2);
  display: flex;
  align-items: center;
  background-color: var(--simple-gray-2);
`

const InputIcon = styled.div.attrs(props => ({
  className: props.theme.main,
}))`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 9px;
  align-items: center;
  padding-right: 15px;
  border: 1px solid var(--simple-gray);
  height: 40px;
  
  & ${Input}{
      border: 0;
      height: 38px;
  } 
`

const Imp = styled.p`
    color: red;
    margin-left: 5px;
`
const Column = styled.div`
flex: 1;
text-align: left;
display: flex;
flex-direction: column;
gap: 8px;
`
const Label = styled.div`
  display: flex;
  gap: 10px;
`
const TwoColumns = styled.div`
  display: flex;
  gap: 32px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0;
  flex: 1;
  height: 100vh;
`


export default TabGeneral