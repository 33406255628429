import { faCalendar, faSlidersSimple } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { IconClicker } from '../Home/HeaderHome/styles';
import Selector from './Selector';
import useCountriesSA from '../../hooks/useCountriesSA';
import SelectorCountry from './Location/SelectorCountry';
import SelectorState from './Location/SelectorState';
import SelectorCity from './Location/SelectorCity';
import SelectItem from '../../interfaces/SelectItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { useSelector } from 'react-redux';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { capitalize } from '../../helpers/letterHelper';
import { StateOption } from '../../hooks/Locations/useActiveState';
import { CityOption } from '../../hooks/Locations/useStateCities';
import useBenefitsSA from '../../hooks/Superadmin/Modalities/useBESA';
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import useTherapeuticAreasSA from '../../hooks/Superadmin/Modalities/useTASA';
import { CountryOption } from '../../hooks/useCountry';
import { RootState } from '../../store/store';
import { BaseFieldState, getFieldState } from './utils';
import moment, { Moment } from 'moment';
import DatePicker from "react-datepicker";
import OutsideClickHandler from 'react-outside-click-handler';
import useOrgList from '../../hooks/Superadmin/Healers/useOrgList';
import Modal from "react-bootstrap/Modal";
import IPaymentInfo from '../../interfaces/user/IPaymentINfo';
import { isPaidHealer } from '../../helpers/userHelper';

export interface IEventFilter {
    cityID: string
    countryID: string
    stateID: string
    typeID: string
    modID: string
    delivery: 'online' | 'inPerson' | 'mixed' | ''
    start_date: Date | ''
}
export const MockEventFilter: IEventFilter = {
    cityID: '',
    countryID: '',
    stateID: '',
    typeID: '',
    modID: '',
    delivery: '',
    start_date: '',
}

export interface IModFilter {
    delivery: 'online' | 'inPerson' | 'mixed' | ''
    benID: string
    taID: string
}
export const MockModFilter: IModFilter = {
    delivery: '',
    benID: '',
    taID: '',
}

export interface IComFilter {
    orgID: string
    modID: string
}
export const MockComFilter: IComFilter = {
    orgID: '',
    modID: '',
}

const Filter = ({
    type,
    from,
    eventFilter = { ...MockEventFilter },
    setEventFilter = () => { },
    modFilter = { ...MockModFilter },
    setModFilter = () => { },
    comFilter = { ...MockComFilter },
    setComFilter = () => { },
    areServices = false,
}: {
    type: 'EV' | 'MOD' | 'COM'
    from: 'BULLETIN' | 'PROFILE' | 'MODALITY'
    eventFilter?: IEventFilter
    setEventFilter?: (filter: IEventFilter) => void
    modFilter?: IModFilter
    setModFilter?: (filter: IModFilter) => void
    comFilter?: IComFilter
    setComFilter?: (filter: IComFilter) => void
    areServices?: boolean
}) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const { modalitiesPages } = useModalitiesSA({ pageSizeProp: 50, searchName: '' });
    const { benefitsPages } = useBenefitsSA({ pageSizeProp: 50, searchName: '' });
    const { taPages } = useTherapeuticAreasSA({ pageSizeProp: 50, searchName: '' });

    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [orgCountry, setOrgCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [orgState, setOrgState] = useState<BaseFieldState<StateOption>>(getFieldState({ label: '', value: '' }));
    const [orgCity, setOrgCity] = useState<BaseFieldState<CityOption>>(getFieldState({ label: '', value: '' }));

    const [eventTypeOptions, setEventTypeOptions] = useState<SelectItem[]>([]);
    const [eventTypeSelected, setEventTypeSelected] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchType, setSearchType] = useState('');

    const [deliverySelected, setDeliverySelected] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchDelivery, setSearchDelivery] = useState('');

    const [modalitySelected, setModalitySelected] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchModality, setSearchModality] = useState('');

    const [benefitSelected, setBenefitSelected] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchBenefit, setSearchBenefit] = useState('');
    const [TASelected, setTASelected] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchTA, setSearchTA] = useState('');

    const [orgSelected, setOrgSelected] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [searchOrg, setSearchOrg] = useState('');

    const [start, setStart] = useState<Moment>(moment());

    const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    const getTypeEvent = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Events.getAllEventTypes();
            })
            .then((results) => {
                if (results) {
                    if (results.statusCode === 200 && 'body' in results && Array.isArray(results.body)) {
                        setEventTypeOptions(
                            results.body.map(c => ({
                                label: c.name,
                                value: c.id
                            })))
                    }
                }
            })
            .catch((error) => console.error('Error', error))
    }

    const setCountry = (item: BaseFieldState<CountryOption>) => {
        setOrgCountry(item);
        setEventFilter({
            ...eventFilter,
            countryID: item.value?.value || '',
        });
    }

    const setCity = (item: BaseFieldState<CityOption>) => {
        setOrgCity(item);
        setEventFilter({
            ...eventFilter,
            cityID: item.value?.value || '',
        });
    }

    const setState = (item: BaseFieldState<StateOption>) => {
        setOrgState(item);
        setEventFilter({
            ...eventFilter,
            stateID: item.value?.value || '',
        });
    }

    const editMoment = (mom: Moment, date: Date): Moment => {
        let year: number | string = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let day: number | string = date.getDate();
        let hours: number | string = mom?.hours() ?? 0;
        let minutes: number | string = mom?.minutes() ?? 0;
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
        minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
        return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
    };

    const handleOnStartDateChange = (e: any) => {
        const new_moment_s = editMoment(start, e);
        setStart(new_moment_s);
        setEventFilter({
            ...eventFilter,
            start_date: new_moment_s.toDate(),
        });
        setShowModal(false);
    }

    const getMomentDate = (mom: Moment | undefined): Date | undefined => {
        return mom ? mom.toDate() : undefined;
    };

    const clearFilters = () => {
        setSearchCountry('');
        setSearchState('');
        setSearchCity('');
        setOrgCountry(getFieldState({ label: '', value: '' }));
        setOrgState(getFieldState({ label: '', value: '' }));
        setOrgCity(getFieldState({ label: '', value: '' }));
        setEventTypeSelected(getFieldState({ label: '', value: '' }));
        setSearchType('');
        setDeliverySelected(getFieldState({ label: '', value: '' }));
        setSearchDelivery('');
        setStart(moment());
        setModalitySelected(getFieldState({ label: '', value: '' }));
        setSearchModality('');
        setBenefitSelected(getFieldState({ label: '', value: '' }));
        setSearchBenefit('');
        setTASelected(getFieldState({ label: '', value: '' }));
        setSearchTA('');
        setOrgSelected(getFieldState({ label: '', value: '' }));
        setSearchOrg('');

        setEventFilter({ ...MockEventFilter });
        setModFilter({ ...MockModFilter });
        setComFilter({ ...MockComFilter });
        setShowModal(false);
    }

    const getOrgs = () => {
        if (isLoggedIn) {
            return user.organizations.map((org, idx) => ({ value: org, label: user.organizationsNames[idx] }))
        }

        return []; //@todo return all orgs related with the modality
    }

    const delivery: SelectItem[] = [{
        value: 'online',
        label: 'Online',
    },
    {
        value: 'inPerson',
        label: 'In Person',
    },
    {
        value: 'mixed',
        label: 'Mixed',
    }]

    useEffect(() => {
        getTypeEvent();
    }, [])

    useEffect(() => {
        if(isLoggedIn){
            setIsAdmin(isPaidHealer(userRoles, userPayment.hasActiveSubscription));
        }
    }, [userRoles, userPayment])

    const getModalDialogStyle = (): string => {
        if(from === 'PROFILE' && type === 'EV') return "modalFiltersProfileEvents";
        if(from === 'PROFILE' && type === 'COM') return "modalFiltersProfileCommunities";
        if(from === 'PROFILE' && type === 'MOD') return "modalFiltersProfileModalities";

        if(from === 'MODALITY' && type === 'EV') return "modalFiltersModalityEvents";
        if(from === 'MODALITY' && type === 'COM') return "modalFiltersModalityCommunities";

        if(from === 'BULLETIN' && type === 'EV')return "modalFiltersLeftPanelEvents";
        if(from === 'BULLETIN' && type === 'COM') return "modalFiltersLeftPanelCommunities";

        return "modalFilters";
    }

    return (
        <Clicker>
            <IconClicker
                size='lg'
                icon={faSlidersSimple}
                onClick={() => setShowModal(true)}
            />
            <Modal className={getModalDialogStyle()} show={showModal}>
                <Modal.Body>
                    {showModal &&
                        <OutsideClickHandler onOutsideClick={() => {
                            setShowModal(false);
                        }}>
                            {(type === 'EV' ? (
                                <Container>
                                    <ClearSelection onClick={clearFilters}>Clear All</ClearSelection>
                                    <SelectorCountry
                                        country={orgCountry}
                                        setCountry={setCountry}
                                        searchCountry={searchCountry}
                                        setSearchCountry={setSearchCountry}
                                        onlyActive={false}
                                    />
                                    <SelectorState
                                        country={orgCountry.value?.value || ''}
                                        state={orgState}
                                        setState={setState}
                                        searchState={searchState}
                                        setSearchState={setSearchState}
                                        deactivateCreate
                                    />
                                    <SelectorCity
                                        state={orgState.value?.value || ''}
                                        city={orgCity}
                                        setCity={setCity}
                                        searchCity={searchCity}
                                        setSearchCity={setSearchCity}
                                        deactivateCreate
                                    />

                                    <Selector
                                        options={eventTypeOptions}
                                        selected={eventTypeSelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setEventTypeSelected(data);
                                            setEventFilter({
                                                ...eventFilter,
                                                typeID: data.value?.value || '',
                                            })
                                        }}
                                        searchItem={searchType}
                                        setSearchItem={setSearchType}
                                        placeholder="Select type"
                                    />
                                    <Selector
                                        options={delivery}
                                        selected={deliverySelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setDeliverySelected(data);
                                            setEventFilter({
                                                ...eventFilter,
                                                delivery: data.value?.value as 'online' | 'inPerson' | 'mixed' | '' || '',
                                            })
                                        }}
                                        searchItem={searchDelivery}
                                        setSearchItem={setSearchDelivery}
                                        placeholder="Select delivery"
                                    />
                                    <Selector
                                        options={modalitiesPages[0].map(mod => ({ value: mod.id, label: mod.name }))}
                                        selected={modalitySelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setModalitySelected(data);
                                            setEventFilter({
                                                ...eventFilter,
                                                modID: data.value?.value || '',
                                            })
                                        }}
                                        searchItem={searchModality}
                                        setSearchItem={setSearchModality}
                                        placeholder="Select modality"
                                    />
                                    <InputIcon className='inputDatePickerBlack'>
                                        <DatePicker
                                            selected={getMomentDate(start)}
                                            onChange={handleOnStartDateChange}
                                            placeholderText={'MM / DD / YYYY'}
                                            className="inputDatePicker"
                                            showYearDropdown
                                            showMonthDropdown
                                        />
                                        <IconClicker
                                            icon='calendar'
                                            size='lg'
                                        />
                                    </InputIcon>
                                </Container>
                            ) : (type === 'MOD' ? (
                                <Container>
                                    <ClearSelection onClick={clearFilters}>Clear All</ClearSelection>
                                    {areServices && <Selector
                                        options={delivery}
                                        selected={deliverySelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setDeliverySelected(data);
                                            setModFilter({
                                                ...modFilter,
                                                delivery: data.value?.value as 'online' | 'inPerson' | 'mixed' | '' || '',
                                            })
                                        }}
                                        searchItem={searchDelivery}
                                        setSearchItem={setSearchDelivery}
                                        placeholder="Select delivery"
                                        className={'heightAuto'}
                                    />}
                                    <Selector
                                        options={benefitsPages[0].map(mod => ({ value: mod.id, label: mod.name }))}
                                        selected={benefitSelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setBenefitSelected(data);
                                            setModFilter({
                                                ...modFilter,
                                                benID: data.value?.value || '',
                                            })
                                        }}
                                        searchItem={searchBenefit}
                                        setSearchItem={setSearchBenefit}
                                        placeholder="Select benefit"
                                        className={'heightAuto'}
                                    />
                                    <Selector
                                        options={taPages[0].map(mod => ({ value: mod.id, label: mod.name }))}
                                        selected={TASelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setTASelected(data);
                                            setModFilter({
                                                ...modFilter,
                                                taID: data.value?.value || '',
                                            })
                                        }}
                                        searchItem={searchTA}
                                        setSearchItem={setSearchTA}
                                        placeholder="Select condition"
                                        className={'heightAuto'}
                                    />
                                </Container>
                            ) : (
                                <Container>
                                    <ClearSelection onClick={clearFilters}>Clear All</ClearSelection>
                                    <Selector
                                        options={modalitiesPages[0].map(mod => ({ value: mod.id, label: mod.name }))}
                                        selected={modalitySelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setModalitySelected(data);
                                            setComFilter({
                                                ...comFilter,
                                                modID: data.value?.value || '',
                                            })
                                        }}
                                        searchItem={searchModality}
                                        setSearchItem={setSearchModality}
                                        placeholder="Select modality"
                                        className={'heightAuto'}
                                    />
                                    <Selector
                                        options={getOrgs()}
                                        selected={orgSelected}
                                        setSelected={(data: BaseFieldState<SelectItem>) => {
                                            setOrgSelected(data);
                                            setComFilter({
                                                ...comFilter,
                                                orgID: data.value?.value || '',
                                            })
                                        }}
                                        searchItem={searchOrg}
                                        setSearchItem={setSearchOrg}
                                        placeholder="Select organization"
                                        className={'heightAuto'}
                                    />
                                </Container>
                            )))}
                        </OutsideClickHandler>}
                </Modal.Body>

            </Modal>

        </Clicker>)
}

export default Filter;

const ClearSelection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    color: blue;
    text-decoration-line: underline;
    font-family: 'Inter';
    font-size: 14;
    font-weight: 400;
    cursor: pointer;
`;

const Clicker = styled.div`
    position: relative;
    z-index: 1;
`;

const Container = styled.div`
    background-color: white;
    // border-radius: 8px;
    // position: absolute;
    width: 250px;
    max-height: 300px;
    // padding: 12px;
    // border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    // top: 100%;
    margin-top: 10px;
    // right: 0;
    // z-index: 1000001;
`;

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const InputIcon = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;

    &.inputDatePickerBlack .react-datepicker__header__dropdown{
        color: black;
        display: flex;
        justify-content: center;
        gap: 35px;
    }

    &.inputDatePickerBlack .react-datepicker__month-container{
        border: 1px solid #b8b8b8;
        border-radius: 12px;
        overflow: hidden;
    }

    & ${Input}{
        border: 0;
        height: 38px;
    } 
`