import React, { useEffect, useState, useContext } from 'react';
import Header from "../../components/Header";
import ClubLeftSection from "../../components/ClubLeftSection";
import ClubRightSection from "../../components/ClubRightSection";
import { Outlet, useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { CentralBase, ContainerAppLayout, PrincipalContainer } from './components';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import { ContextSuccess } from "../../Context";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DashboardsHeader, { tabsDashboardHealer } from '../../components/Dashboards/DashboardsHeader';
import TableCommunities from '../../components/Dashboards/TableCommunities';
import TableEvents from '../../components/Dashboards/TableEvents';
import TableModalities from '../../components/Dashboards/TableModalities';
import TableRevenue from '../../components/Dashboards/TableRevenue';
import IPaymentInfo from '../../interfaces/user/IPaymentINfo';
import { isPaidHealer } from '../../helpers/userHelper';

toast.configure();

interface AppLayoutProps {
    existEvents: any;
    isAdmin: any;
    ChatServiceInstance: ChatServiceInterface
}

const AppLayout = (props: AppLayoutProps) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const currentSuccess = useContext(ContextSuccess);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeNotification, setActiveNotification] = useState(false);
    const [tabActive, setTabActive] = useState<tabsDashboardHealer>('BULLETIN'); //for Healer new dashboard on bulletin

    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const userPayment: IPaymentInfo = useSelector((state: RootState) => state.getUser.paymentInfo);

    useEffect(() => {
        const logged = searchParams.get('signupLogged');
        const notification = searchParams.get('notifications');
        if (logged) {
            currentSuccess?.setSuccessData(false, true, t('toast_pleaseLogout'));
            navigate('/app');
        }
        if (notification) {
            setActiveNotification(true);
        }
    }, [])

    function handleActiveNotification(e: any) {
        setActiveNotification(e)
    }

    return (
        <ContainerAppLayout>
            <Header
                handleActiveNotification={handleActiveNotification}
                ChatServiceInstance={props.ChatServiceInstance}
                isHealerBulletin={true /*isPaidHealer(userRoles, userPayment.hasActiveSubscription)*/}
                tabActive={setTabActive}
            />

            {/* {(isPaidHealer(userRoles, userPayment.hasActiveSubscription)) && (
                <DashboardsHeader
                    tabActive={tabActive}
                    setTabActive={setTabActive}
                />
            )} */}

            {(!userRoles.includes('ORGADMIN') || tabActive === 'BULLETIN') ? (
                <PrincipalContainer
                    isHealerBulletin={true /*isPaidHealer(userRoles, userPayment.hasActiveSubscription)*/}
                >
                    <ClubLeftSection
                        isHealerBulletin={true /*isPaidHealer(userRoles, userPayment.hasActiveSubscription)*/}
                        isAdmin={props.isAdmin}
                        existEvents={props.existEvents}
                        ChatServiceInstance={props.ChatServiceInstance} />

                    <CentralBase isHealerBulletin={true /*isPaidHealer(userRoles, userPayment.hasActiveSubscription)*/}>
                        <Outlet />
                    </CentralBase>

                    <ClubRightSection
                        activeNotification={activeNotification}
                        handleActiveNotification={handleActiveNotification}
                    />
                </PrincipalContainer>
            ) : (
                tabActive === 'PROFILE' ?
                    'Profile'
                    :
                    tabActive === 'REVENUE' ?
                        <TableRevenue />
                        :
                        tabActive === 'MODALITIES' ?
                            <TableModalities />
                            :
                            tabActive === 'COMMUNITIES' ?
                                <TableCommunities handleTab={setTabActive} />
                                :
                                tabActive === 'EVENTS' ?
                                    <TableEvents handleTab={setTabActive} />
                                    :
                                    <></>
            )}


        </ContainerAppLayout>
    );
}

export default AppLayout;