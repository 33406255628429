import { useEffect, useState } from "react";
import "./styles.css";
import ClubIconsBar from "../../components/ClubIconsNavBar";
import ClubSelectBar from "../../components/ClubSelectBar";
import ConnectionSelectBar from "../../components/ConnectionSelectBar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LeftPanel } from "../../containers/AppLayout/components";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import EventSelectBar from "../EventSelectBar";
import useEventsPanel from "../../hooks/Events/useEventsPanel";
import { useSelector } from "react-redux";
import { IEventFilter, MockEventFilter } from "../BaseComponents/Filter";

interface ClubLeftSectionProps{
  //todo -> put types
  existEvents:any;
  isAdmin:any;
  ChatServiceInstance:ChatServiceInterface
  isHealerBulletin: boolean
}

function Index(props: ClubLeftSectionProps) {
  const { id = "" } = useParams();
  const location = useLocation();
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const [eventFilter, setEventFilter] = useState<IEventFilter>({ ...MockEventFilter });

  const [tabSelected, setTabSelected] = useState(1);
  const [showBorder, setShowBorder] = useState(false);
  const [selected, setSelected] = useState('Clubs');
  const [valueFilter, setValueFilter] = useState("");
  
  const {
    currentCards,
    pertinentEvents
  } = useEventsPanel({
    searchItem: valueFilter,
    eventFilter,
  });

  useEffect(() => {
    setShowBorder(
      (tabSelected === 0 && window.location.href.includes('event')) ||
      (tabSelected === 1 && window.location.href.includes('club')) ||
      (tabSelected === 2 && window.location.href.includes('conn'))
    )
  }, [window.location, tabSelected])

  useEffect(() => {
    console.log('userRoles --------------------- ', userRoles)
    if((userRoles.includes(`SEEKER`) && !userRoles.includes(`ORGADMIN`) ) && pertinentEvents.length > 0){
      setTabSelected(0)
      setSelected('Events')
    }
  }, [pertinentEvents])

  useEffect(() => {
    if(location.pathname.includes('clubs')){
      setTabSelected(1)
      setSelected('Clubs')
    }
    else if(location.pathname.includes('events') && props.existEvents){
      setTabSelected(0)
      setSelected('Events')
    }
    else if(location.pathname.includes('connections')){
      setTabSelected(2)
      setSelected('Connections')
    }
    else if(props.existEvents){
      setTabSelected(0)
      setSelected('Events')
    }
    else{
      setTabSelected(1)
      setSelected('Clubs')
    }
  }, [props.existEvents])
  

  function handleOnClick(step: number) {
    setTabSelected(step);
  }

  useEffect(() => {
    if(id){
      setShowBorder(
        (tabSelected === 0 && window.location.href.includes('event')) ||
        (tabSelected === 1 && window.location.href.includes('club')) ||
        (tabSelected === 2 && window.location.href.includes('conn'))
      )
    }
  }, [id])

  return (
    <LeftPanel isHealerBulletin={props.isHealerBulletin}>
      <ClubIconsBar handleOnClick={handleOnClick} selected={selected}/>

      {tabSelected == 0 ?
          <EventSelectBar />
        :
        tabSelected == 1 ?
          <ClubSelectBar />
        :
          tabSelected == 2 ?
          <ConnectionSelectBar ChatServiceInstance={props.ChatServiceInstance} />
        :  
          ''
      }
      
    </LeftPanel>
  );
}

export default Index;
