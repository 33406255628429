import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import "./styles.css";
import "./styles.scoped.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserInfoProfile, updateProfilePhoto, uploadAsset, uploadImage } from '../../store/actions/userActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalImageEditor from '../ModalImageEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../ClubIconsNavBar';
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { useTranslation } from "react-i18next";
import { ContextSuccess } from "../../Context";
import { IUserGradeResponse } from '@vibe/sdk/interfaces/Admin/User';
import { RootState } from '../../store/store';
import { faUserPen } from '@fortawesome/pro-regular-svg-icons';
import styled from "styled-components";

toast.configure();

function Index({
  editVisible,
  settingsVisible,
  isUserProfile,
  setEditVisible,
  setSettingsVisible
}: {
  editVisible: boolean;
  settingsVisible: boolean;
  isUserProfile: boolean;
  setEditVisible: (data: boolean) => void;
  setSettingsVisible: (data: boolean) => void;
}) {
  const { t, i18n } = useTranslation();
  const { username } = useParams();
  const currentSuccess = useContext(ContextSuccess);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userAll = useSelector((state: any) => state.getUser.user);
  const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

  const [user, setUser] = useState(userAll);
  const [showModal, setShowModal] = useState(false);
  const [userProfilePhoto, setUserProfilePhoto] = useState('');

  useEffect(() => {
    if (isUserProfile) {
      setUser(userAll);
      setUserProfilePhoto(userAll.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
    }
    else {
      dispatch(getUserInfoProfile(username || ''));
    }
  }, [username, isUserProfile]);

  useEffect(() => {
    setUser(userProfile);
    setUserProfilePhoto(userProfile.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
  }, [userProfile]);

  function handleSaveImage(e: any) {

    fetch(e)
      .then(res => res.blob())
      .then(blob => {
        const contentType = e.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        const file = new File([blob], 'profile' + userInfo.username, { type: contentType });

        if (file) {
          dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            "profile_photo"
          ))
            .then((results: any) => {
              if (results.body) {
                dispatch<any>(uploadImage(
                  results.body,
                  contentType,
                  file,
                ))
                  .then((result: any) => {
                    if (result.status === 200) {
                      const urlImage = results.body.split('?')[0];
                      setUserProfilePhoto(urlImage);
                      dispatch<any>(updateProfilePhoto(
                        urlImage,
                        userInfo.username,
                      ))
                        .then((result: any) => {
                          if (result.status === 200) {
                            setUserProfilePhoto(urlImage);
                            setShowModal(false);
                          }
                          else {
                            setShowModal(false);
                          }
                        })
                        .catch((error: any) => {
                          console.log(error)
                        })
                    }
                  })
                  .catch((error: any) => {
                    console.log(error)
                  })

              }
              else {
                currentSuccess?.setSuccessData(false, true, t('toast_errorImageUrl'));
              }
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
        else {
          currentSuccess?.setSuccessData(false, true, t('toast_addFile'));
        }

      })
  };

  const closeModal = (e?: any, type?: any) => {
    setShowModal(false);
  }

  return (
    <div className="ProfileAccessPhoto">
      <div className="infoProfileAccess">

        <div className='imageProfileAccess'>
          {(showModal) && (<ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={closeModal} isProfilePhoto={true} />)}
          <img src={userProfilePhoto !== '' ? userProfilePhoto : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'}
            alt=""
            onClick={() => isUserProfile && setShowModal(true)} />
        </div>

        <div>
          {(isUserProfile) ? (
            <p className="nameProfile pointer"
              onClick={() => {
                setSettingsVisible(false)
                setEditVisible(false)
              }}>
              {user.firstname} {user.lastname}
            </p>
          )
            :
            (
              <p className="nameProfile">
                {user.firstname} {user.lastname}
              </p>
            )}
          <InfoRow>
            {console.log(user)}
            <LocationCard>
              {user.city ? user.city : user.zipcode}, {user.country}
            </LocationCard>
            <Tooltip theme={{ top: -30 }}>{user.city ? user.city : user.zipcode}, {user.country}</Tooltip>
          </InfoRow>
          {/* <ul>
            {(user.organizationsNames || []).map((request: any) => (
              <li className='listOrgs'>{request}</li>
            ))}
          </ul> */}
        </div>
      </div>

      {(isUserProfile) &&
        (
          <div className="ProfileEditIcons">
            <FontAwesomeIcon
              icon={faUserPen}
              size="sm"
              color={'var(--t-blue)'}
              className="iconEditInfo pointer"
              onClick={() => navigate(`../../../edit-userProfile/${userInfo.username}`) /*setEditVisible(!editVisible)*/}
            />

            {/* <FontAwesomeIcon
              icon="gear"
              size="sm"
              color={'var(--t-blue)'}
              className="iconEditInfo pointer"
              onClick={() => setSettingsVisible(!settingsVisible)}
            /> */}
          </div>
        )}

    </div>

  );
}

const Tooltip = styled.div`
    background-color: black;
    color: white;
    content: "";
    position: absolute;
    padding: 0px 9px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 7px;
    top: ${props => props.theme.top}px;
    font-size: 12px;
    left: -70px;
    right: 0;
    margin: 0 auto;
    height: 24px;
    display: flex;
    width: max-content;
    display: none;

    &::after{
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        top: 23px;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
        border-right: 5px solid transparent;
        border-top: 5px solid #000000;
        border-left: 5px solid transparent;
    }
`

const Location = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    text-align: left;
    margin-top: ${props => props.theme.top || '0px'};
`

const LocationCard = styled(Location)`
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    cursor: pointer;

    &:hover ~ ${Tooltip}{ display: flex; }
`

const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100%);
    gap: 10px;
    position: relative;
`;

export default Index;
