import { useEffect, useState, useContext } from 'react'
import { ContextSuccess } from "../../Context";
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import styled, { css } from "styled-components";
import { Input } from "../signupsetuphealerind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { sortAlphabeticallyOnProperty } from '../../containers/Signup/SignupHealerInd';
import { useDispatch, useSelector } from 'react-redux';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { customModality } from '../signupsetuphealer/Services';
import { RootState } from '../../store/store';
import BlankTable from '../BaseComponents/BlankTable';
import Delivery from '@vibe/sdk/interfaces/Modalities/hservices/Delivery';
import IHS from '@vibe/sdk/dist/interfaces/Modalities/hservices/HS';
import { onHideType } from "../../components/BaseComponents/Modals/ModalUnsaved";
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { getHealerServices } from '../../store/actions/modalityActions';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import ModalServices from '../signupsetuphealer/ModalServices';

const Services = () => {

  const services = useSelector((state: RootState) => state.getModality.getHealerServices);

  const dispatch = useDispatch();
  const currentSuccess = useContext(ContextSuccess);
  const { modalitiesPages, reloadModalities } = useModalitiesSA({
    pageSizeProp: 1000,
    searchName: '',
  });

  const [filteredModality, setFilteredModality] = useState<string>('');
  const [modality, setModality] = useState<Modality>({ ...customModality })
  const [showModal, setShowModal] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [serviceEdit, setServiceEdit] = useState<IHS>()
  const username = useSelector((state: RootState) => state.getUser.userInfo?.username || '');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();

  const getFiltered = (listName: any[]) => {
    return listName.filter((item: any) => {
      if (!filteredModality) return true;

      if (item.name && (
        item.name.toUpperCase().includes(filteredModality) ||
        item.name.toLowerCase().includes(filteredModality) ||
        item.name.includes(filteredModality)
      )) {
        return true;
      }

      return false;
    })
  }

  const handleModality = (open: boolean, mod?: Modality) => {
    if (mod) setModality(mod);
    else setModality({ ...customModality })
    setShowModal(open);
  }

  const getDeliveryOneName = (delivery: Delivery[]): string => {
    const inPerson = delivery.some(delivery => delivery.inPerson)
    const online = delivery.some(delivery => delivery.online)
    const mixed = inPerson && online
    if (mixed) return 'Online and In Person';
    if (inPerson) return 'In Person';
    if (online) return 'Online';
    return 'Not specified';
  }

  const handleDelete = (data: string) => {
    currentSuccess?.setModalWarning(
      'DELETE',
      (type) => onHideDelete(type, data),
      'Are you sure you want to delete this service?'
    );
  }

  const handleModalityEdit = (open: boolean, mod?: Modality, service?: IHS) => {
    setShowModalEdit(open);
    setServiceEdit(service);
  }

  const handleUpdate = (service_is: string) => {
    const s = services.find(s => s.id === service_is);
    handleModalityEdit(true, undefined, s);
  }

  const onHideDelete = (type: onHideType, id: string) => {
    switch (type) {
      case 'save':
        currentSuccess?.setLoadingModal(true);
        getDataSDK()
          .then((dataSDK: getDataSDKResponse) => {
            return dataSDK.sdk.Modalities.deleteHS(username, id, dataSDK.token);
          })
          .then((results) => {
            if (results.statusCode === 201) {
              dispatch(getHealerServices(username));
              currentSuccess?.setLoadingModal(false);
              currentSuccess?.setModalsOff();
            }
            else {
              console.log((results.body as IResponseError).error || (results.body as IResponseError).message)
            }
          })
          .finally(() => {
            setDeleteModal(false);
            setDeleteLoading(false);
            setDeleteId(undefined);
          })
        break;

      case 'close':
        currentSuccess?.setModalsOff();
        break;

      case 'discard':
        currentSuccess?.setModalsOff();
        break;

      default:
        break;
    }
  }

  return (
    <>
      <Content_Signup_Columns>
        <LeftColumn>
          <InputIcon>
            <SearchIcon
              icon={faSearch}
              size='lg'
            />
            <InputPlaceholder
              placeholder="Search"
              value={filteredModality}
              onChange={(e) => setFilteredModality(e.currentTarget.value)}
            />
          </InputIcon>
          <BaseAreas>
            <TitleAreas>Modalities & Services</TitleAreas>
            <ListAreas>
              {sortAlphabeticallyOnProperty(getFiltered(modalitiesPages[0]), 'name').map((item: Modality) => (
                <Area onClick={() => handleModality(true, item)}>
                  {item.name}
                  <IconPlus
                    icon={faPlus}
                    size='lg'
                    color={"black"}
                  />
                </Area>
              ))}
            </ListAreas>
          </BaseAreas>
          <Button onClick={() => handleModality(true)}>
            Add custom service
            <IconPlus
              icon={faPlus}
              size='lg'
              color={"var(--white)"}
            />
          </Button>
        </LeftColumn>
        {(services || []).length === 0 ?
          <ListServicesEmp>
            <Header>
              <Title>No services yet.</Title>
              <SubTitle>Here you will see the modalities/services you upload</SubTitle>
              <Button onClick={() => handleModality(true)}>
                Add service
                <IconPlus
                  icon={faPlus}
                  size='lg'
                  color={"var(--white)"}
                />
              </Button>
            </Header>
          </ListServicesEmp>
          :
          <ListServices>
            <BlankTable
              headers={['Name', 'Delivery']}
              items={sortAlphabeticallyOnProperty(getFiltered(services || []), 'name').map((service: IHS) => ({
                id: service.id,
                labels: [
                  service.name,
                  getDeliveryOneName(service.delivery || [])
                ],
              }))}
              actions={['UPDATE', 'DELETE']}
              actionsHandlers={{
                handleUpdate,
                handleDelete,
              }}
              maxWidth={'200px'}
            />
          </ListServices>
        }
      </Content_Signup_Columns>
      <ModalServices
        showModal={showModal}
        onHide={handleModality}
        data={modality}
      />
      <ModalServices
        edit
        showModal={showModalEdit}
        onHide={handleModalityEdit}
        data={modality}
        serviceInfo={serviceEdit}
      />
    </>
  )
}

const HeaderTable = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: calc(100% - 260px);
`;

const Area = styled.div`
    border-bottom: 1px solid darkgrey;
    background-color: white;
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    padding: 13px 9px;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

const ListAreas = styled.div`
    width: 100%;
    padding: 0px 9px;
    background-color: white;
    height: fit-content;
    max-height: calc(100vh - 80px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 5px;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--t-blue);
    }
`

const BaseAreas = styled.div`
    width: 100%;
    border-radius: 9px;
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
`

const TitleAreas = styled.span`
    font-family: 'Inter-Bold';
    font-size: 16px;
    color: black;
    margin: 15px 0px 0px 10px;
`;

const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 20px;
    color: var(--black);
    margin-left: 20px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--simple-gray);
    height: 40px;
    width: 350px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 40px;
    background: var(--electric-yellow-2);
    border-radius: 8px;
    color: var(--white);
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 0 13px;
    cursor: pointer;
`

const Title = styled.p`
    font-family: 'Inter-Bold';
    font-size: 16px;
    padding: 13px 0;
`
const SubTitle = styled.span`
    font-family: 'Inter';
    font-size: 14px;
`

const ListServicesEmp = styled.div`
    width: 100%;
    background-color: white;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(./NoServices.png);
    border-radius: 9px;
    padding: 20px 0;
`

const ListServices = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: scroll;
    min-height: calc(100% - 260px);
`

const Content_Signup_Columns = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    height: calc(100% - 50px);
    width: 100%;
    margin-top: 30px;
`

const IconPlus = styled(FontAwesomeIcon)`
    font-size: 14px;
    margin-left: 8px
`;

const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0;
    font-size: 15px;
    font-family: 'Inter';
    background-color: transparent;
`;

export default Services