import styled from "styled-components";
import {
    TitleContent, SubText
} from "../../components/signupsetuphealer"
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ErrorField from "../BaseComponents/ErrorField";
import { getOrganizationInfo, updateLogoOrg, updateOrg, uploadImage } from "../../store/actions/orgsActions";
import { ProcessInterface, ProcessType } from "../../containers/SetupHealer/SetupHealer";
import { useDropzone } from "react-dropzone";
import { IUpdateOrganizationBody } from "@vibe/sdk/dist/interfaces/Admin/updateOrganization";
import AddProfilePhoto from "../BaseComponents/ProfilePhoto/AddProfilePhoto";
import Services from "./Services";
import { getIdOrgUser } from "../../helpers/userHelper";
import { ContextSuccess } from "../../Context";
import TextEditor from "../BaseComponents/TextEditor";
import { BaseFieldState, getFieldState } from "../BaseComponents/utils";
import ModalImageEditorCrop from "../ModalImageEditorCrop";
import ModalMedia, { ModalMediaTabsType } from "../signupsetuphealerind/ModalMedia";
import { faSpotify, faApple } from "@fortawesome/free-brands-svg-icons";
import { faPlus, faPen, faTrash, faLink } from "@fortawesome/pro-regular-svg-icons";
import ModalUnsaved from "../BaseComponents/Modals/ModalUnsaved";
import { RED } from "../SuperCenter/Modalities/Modals/components";
import { ClickableIcon } from "../SuperCenter/SuperAdminComponents/basicForSACenter";
import { MediaOption, Row2_70 } from "../signupsetuphealerind";
import { ContainerMediaIntro, AddMedia, IconDrop, TabsMedia, TabsContent, TableMedia, TableMediaRow, TableMediaNameCell, CellTitle, TableMediaActionsCell, CellLink, TableMediaLinkCell, CellName, TableMediaLinkCellIcons, Tab } from "../signupsetuphealerind/Introduction";
import { Header, ListServicesEmp, SubTitle, Title } from "../signupsetuphealerind/MyServices";
import { getUser } from "../../store/actions/userActions";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import UserMedia from "@vibe/sdk/dist/interfaces/Roster/userMedia";
import UserPodcast from "@vibe/sdk/dist/interfaces/Roster/userPodcast";
import UserSocialMedia from "@vibe/sdk/interfaces/Roster/userSocialMedia";

export interface LogoInterface {
    file: any;
    fileLink: string;
}

const Introduction = ({
    processData,
    isUpdate = false,
}: {
    processData: ProcessInterface
    isUpdate?: boolean
}) => {

    const dispatch = useDispatch();
    const currentSuccess = useContext(ContextSuccess);

    const user = useSelector((state: RootState) => state.getUser.user);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const username = useSelector((state: any) => state.getUser.userInfo.username);

    const [companyStatement, setCompanyStatement] = useState<BaseFieldState<string>>(getFieldState(''));
    const [logo, setLogo] = useState<BaseFieldState<LogoInterface>>(getFieldState({ file: '', fileLink: '' }));
    const [newLogo, setNewLogo] = useState<boolean>(false);
    const [showModalMedia, setShowModalMedia] = useState(false)
    const [showModalMediaEdit, setShowModalMediaEdit] = useState(false)
    const [userPublicationEdit, setUserPublicationEdit] = useState<UserMedia>()
    const [userPodcastEdit, setUserPodcastEdit] = useState<UserPodcast>()
    const [tabActive, setTabActive] = useState(1)
    const [modalMediaDefaultTab, setModalMediaDefaultTab] = useState<ModalMediaTabsType>(1);
    const [userPublications, setUserPublications] = useState<UserMedia[]>([])
    const [userPodcasts, setUserPodcasts] = useState<UserPodcast[]>([])
    const [userSocialMedia, setUserSocialMedia] = useState<UserSocialMedia[]>([])
    const [deleteModal, setdeleteModal] = useState(false);
    const [deleteType, setDeleteType] = useState<'pub' | 'pod' | 'sm'>();
    const [deleteId, setDeleteId] = useState<string>();

    const userSocialMediaTable = useMemo(() => userSocialMedia.filter(({platform}) => !platform.startsWith('Con')), [userSocialMedia])

    const contactInfo = useMemo(() => ({
        email: userSocialMedia.find(({platform}) => platform === 'ContactEmail')?.link ?? undefined,
        phone: userSocialMedia.find(({platform}) => platform === 'ContactPhone')?.link ?? undefined,
    }), [userSocialMedia])

    const reloadUserPublications = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserMediaByUsername(username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserPublications(response.body);
                }
            })
    }

    const reloadUserPodcasts = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserPodcastByUsername(username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserPodcasts(response.body);
                }
            })
    }

    const reloadUserSocial = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Roster.getUserSocialMedia(username!, dataSDK.token)
            })
            .then((response) => {
                if (response.statusCode === 200 && Array.isArray(response.body)) {
                    setUserSocialMedia(response.body);
                }
            })
    }

    const checkProcessed = (): boolean => {
        let complete = true;

        if (!organization.mission && companyStatement.value?.length === 0) {
            complete = false;
        }

        return complete;
    }

    const CheckDataStep = (): boolean => {
        let hasErrors = false;

        if (!companyStatement.value || companyStatement.value?.length === 0) {
            hasErrors = true;
            setCompanyStatement({
                ...companyStatement,
                showError: true,
                error: 'Company statement is required'
            })
        } else {
            setCompanyStatement({
                ...companyStatement,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleSaveImage = () => new Promise((resolve, reject) => {
        const dataFile = URL.createObjectURL(logo.value?.file as any);
        let contentType: any;
        let file: any;
        fetch(dataFile)
            .then(res => res.blob())
            .then(blob => {
                contentType = logo?.value?.file.type;
                // contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'Logo' + organization?.id, { type: contentType });

                if (file) {
                    return dispatch<any>(updateLogoOrg(
                        file.type,
                        file.name + '.' + extension,
                        "school_logo"
                    ))
                }
                else {
                    return Promise.reject(new Error("File not found"));
                }
            })
            .then((results: any) => {
                if (results.body) {

                    dispatch<any>(uploadImage(
                        results.body,
                        contentType,
                        file,
                    ))
                        .then((result: any) => {
                            if (result.status === 200) {

                                console.log('************** Dentro de upload image', result)
                                const urlImage = results.body.split('?')[0];
                                setLogo({
                                    ...logo,
                                    value: {
                                        ...logo.value as LogoInterface,
                                        fileLink: urlImage,
                                        file: urlImage
                                    }
                                })
                                resolve(urlImage);
                            }
                            else {
                                console.log(result)
                                processData.setProcess("off");
                                reject();
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            processData.setProcess("off");
                            reject();
                        })

                }
            })
    });

    const handleClick = async () => {

        if (CheckDataStep()) {
            processData.setProcess("off");
            return;
        }

        const updateData: IUpdateOrganizationBody = {
            mission: companyStatement.value,
        }

        let logoLink: string = '';

        if (newLogo && logo.value?.file !== '') {
            logoLink = await handleSaveImage() as string;
        }

        if (newLogo) {
            updateData.logo = logoLink || '';
        }

        dispatch<any>(updateOrg(
            updateData,
            organization.id,
            organization.status === 'ACTIVE' ? false : true,
        ))
            .then((results: any) => {
                dispatch(getUser(userInfo.username));
                dispatch(getOrganizationInfo(getIdOrgUser(user.rolesByOrg, user.organizations)))
            })
            .catch((error: any) => {
                currentSuccess?.setSuccessData(false, true, 'Error updating org', error)
            })
            .finally(() => {
                processData.setNewData(false);
                processData.setProcess("continue");
                processData.setTabsProcessed([...processData.tabsProcessed, 1])
            })
    }

    const handleDelete = (type: 'pub' | 'pod' | 'sm', id: string) => {
        setDeleteType(type);
        setDeleteId(id);
        setdeleteModal(true);
    }

    const onHideDelete = (type?: string) => {
        if (!type || type === 'discard' || !deleteType || !deleteId) {
            setDeleteType(undefined);
            setDeleteId(undefined);
            setdeleteModal(false);
            return;
        }
        if (deleteType === 'pub') {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.deleteUserMedia(deleteId, dataSDK.token);
                })
                .then((results) => {
                    if (Object.keys(results?.body || {}).length === 0) {
                        currentSuccess?.setSuccessData(true, true, 'Publication deleted succesfully');
                        setDeleteType(undefined);
                        setDeleteId(undefined);
                        setdeleteModal(false);
                        dispatch(getUser(userInfo.username));
                        reloadUserPublications();
                        return;
                    }
                    currentSuccess?.setSuccessData(false, true, 'Error deleting publication');
                    setDeleteType(undefined);
                    setDeleteId(undefined);
                    setdeleteModal(false);
                    return;
                })
        }
        if (deleteType === 'pod') {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.deleteUserPodcast(deleteId, dataSDK.token);
                })
                .then((results) => {
                    if (Object.keys(results?.body || {}).length === 0) {
                        currentSuccess?.setSuccessData(true, true, 'Podcast deleted succesfully');
                        setDeleteType(undefined);
                        setDeleteId(undefined);
                        setdeleteModal(false);
                        dispatch(getUser(userInfo.username));
                        reloadUserPodcasts();
                        return;
                    }
                    currentSuccess?.setSuccessData(false, true, 'Error deleting podcast');
                    setDeleteType(undefined);
                    setDeleteId(undefined);
                    setdeleteModal(false);
                    return;
                })
        }
        if (deleteType === 'sm') {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.deleteUserSocialMedia(username, deleteId as 'Facebook' | 'YouTube' | 'Instagram' | 'Twitter' | 'LinkedIn' | 'Pinterest' | 'Snapchat' | 'TikTok' | 'Zaahah', dataSDK.token)
                })
                .then((results) => {
                    if (Object.keys(results?.body || {}).length === 0) {
                        currentSuccess?.setSuccessData(true, true, `${deleteId.startsWith('C')? 'Contact Information' : 'Social media'} deleted succesfully`);
                        setDeleteType(undefined);
                        setDeleteId(undefined);
                        setdeleteModal(false);
                        dispatch(getUser(userInfo.username));
                        reloadUserSocial();
                        return;
                    }
                    currentSuccess?.setSuccessData(false, true, `Error deleting ${deleteId.startsWith('C')? 'Contact Information' : 'Social media'}`);
                    setDeleteType(undefined);
                    setDeleteId(undefined);
                    setdeleteModal(false);
                    return;
                })
        }
    }

    useEffect(() => {
        if (processData.process === 'loading' && processData.step === 1) handleClick();
    }, [processData.process])

    useEffect(() => {
        if (organization.mission) setCompanyStatement(getFieldState(organization.mission));
        if (organization.logo) setLogo(getFieldState({
            file: organization.logo,
            fileLink: organization.logo
        }));
        if (checkProcessed()) processData.setTabsProcessed([...processData.tabsProcessed, 1]);
    }, [organization])

    useEffect(() => {
        if (username) {
            reloadUserPodcasts();
            reloadUserPublications();
            reloadUserSocial();
        }
    }, [username])

    const showModal = (tab: ModalMediaTabsType) => {
        setModalMediaDefaultTab(tab);
        setShowModalMedia(true);
    }

    const showEdit = (tab: ModalMediaTabsType) => {
        setModalMediaDefaultTab(tab);
        setShowModalMediaEdit(true);
    }

    const showEditPublication = (userPublicationE: UserMedia) => {
        setUserPublicationEdit(userPublicationE);
        setUserPodcastEdit(undefined);
        showEdit(1);
    }

    const showEditPodcast = (userPodcastE: UserPodcast) => {
        setUserPublicationEdit(undefined);
        setUserPodcastEdit(userPodcastE);
        showEdit(2);
    }

    const showEditSocialMedia = () => {
        setUserPublicationEdit(undefined);
        setUserPodcastEdit(undefined);
        showEdit(3);
    }

    const showEditContact = () => {
        setUserPublicationEdit(undefined);
        setUserPodcastEdit(undefined);
        showEdit(4);
    }

    const hideModal = (tab?: number) => {
        setShowModalMedia(false);
        setShowModalMediaEdit(false);
        if (tab) {
            setTabActive(tab)
        }
        if (username) {
            reloadUserPodcasts();
            reloadUserPublications();
            reloadUserSocial();
        }
    }

    const handleOnBlurDescription = (e: string) => {
        setCompanyStatement({
            ...companyStatement,
            value: e
        })
        processData.setNewData(true);
    }

    const [showModalCrop, setShowModalCrop] = useState(false);
    const [activeCrop, setActiveCrop] = useState(false);

    function handleCloseModal(e: any, type: any) {
        setShowModalCrop(() => false);
    };

    function handleCropImage(e: any) {

        const dataFile = URL.createObjectURL(logo.value?.file as any);
        let contentType: any;
        let file: any;

        fetch(e)
            .then(res => res.blob())
            .then(blob => {

                // contentType = logo?.value?.file.type;
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'Logo' + organization?.id, { type: contentType });

                setActiveCrop(true)
                setLogo({
                    ...logo,
                    value: {
                        fileLink: '',
                        file: file,
                    }
                })
            })
    };

    const showModalCropHandle = (e: BaseFieldState<LogoInterface>, isDelete = false) => {
        setLogo(e);
        if (!isDelete) setShowModalCrop(true);
        setActiveCrop(false);
    }

    return (
        <Scroll_Content_Signup>
            <TitleContent theme={{ align: 'center' }}>
                {`Please share additional information to properly support ${organization.name || 'your organization'}`}
            </TitleContent>
            <WrapperRow>
                <ModalImageEditorCrop preview={logo} handleSaveImage={handleCropImage} show={showModalCrop} onHide={handleCloseModal} isProfilePhoto={true} />
                <AddProfilePhoto
                    title={'Add organization logo'}
                    logo={logo}
                    setLogo={(e: BaseFieldState<LogoInterface>, isDelete?: boolean) => showModalCropHandle(e, isDelete)}
                    setNewLogo={setNewLogo}
                    processData={processData}
                />
                <ContainerPhoto>
                    <Wrapper>
                        <SubText>Organization Description</SubText>
                        <Imp>*</Imp>
                    </Wrapper>
                    <WrapperDescription>
                        <TextEditor
                            value={companyStatement.value || ''}
                            setValue={handleOnBlurDescription}
                            charLimit={1000}
                            minHeight={152}
                            placeholder={'Please describe organization...'}
                            showError={companyStatement.showError}
                        />
                    </WrapperDescription>
                </ContainerPhoto>
            </WrapperRow>
            {(!isUpdate) && <Line />}
            <ContainerMediaIntro>
                <AddMedia>
                    <MediaOption onClick={() => showModal(1)}>
                        Add Publication
                        <IconDrop
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </MediaOption>
                    <MediaOption onClick={() => showModal(2)}>
                        Add Podcast
                        <IconDrop
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </MediaOption>
                    <MediaOption onClick={() => showModal(3)}>
                        Add Social Media
                        <IconDrop
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </MediaOption>
                    <MediaOption onClick={() => showModal(4)}>
                        <span style={{textAlign: 'left'}}>Add Contact Information</span>
                        <IconDrop
                            icon={faPlus}
                            size='lg'
                            color={"black"}
                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </MediaOption>
                </AddMedia>
                <Row2_70>
                    <TabsMedia>
                        <Tab theme={{ active: tabActive === 1 && 'active' }} onClick={() => setTabActive(1)} left>Publications</Tab>
                        <Tab theme={{ active: tabActive === 2 && 'active' }} onClick={() => setTabActive(2)}>Podcasts</Tab>
                        <Tab theme={{ active: tabActive === 3 && 'active' }} onClick={() => setTabActive(3)}>Social media</Tab>
                        <Tab theme={{ active: tabActive === 4 && 'active' }} onClick={() => setTabActive(4)} right>Contact</Tab>
                    </TabsMedia>
                    <TabsContent>
                        {tabActive === 1 ?
                            userPublications && userPublications.length > 0 ? (
                                <TableMedia>
                                    <TableMediaRow>
                                        <TableMediaNameCell><CellTitle>Name</CellTitle></TableMediaNameCell>
                                        <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                                    </TableMediaRow>
                                    {userPublications.map((p) => (
                                        <TableMediaRow>
                                            <TableMediaNameCell>
                                                <CellLink href={p.link} target="_blank" rel="noopener noreferrer">{p.name}</CellLink>
                                            </TableMediaNameCell>
                                            <TableMediaActionsCell>
                                                <ClickableIcon
                                                    icon={faPen}
                                                    onClick={() => showEditPublication(p)}
                                                />
                                                <ClickableIcon
                                                    icon={faTrash}
                                                    color={RED}
                                                    onClick={() => handleDelete('pub', p.id)}
                                                />
                                            </TableMediaActionsCell>
                                        </TableMediaRow>
                                    ))}
                                </TableMedia>
                            ) : (
                                <ListServicesEmp>
                                    <Header>
                                        <Title>No info yet.</Title>
                                        <SubTitle>Here you will see the titles you upload</SubTitle>
                                    </Header>
                                </ListServicesEmp>
                            )

                            : tabActive === 2 ?
                                userPodcasts && userPodcasts.length > 0 ? (
                                    <TableMedia>
                                        <TableMediaRow>
                                            <TableMediaNameCell><CellTitle>Name</CellTitle></TableMediaNameCell>
                                            <TableMediaNameCell><CellTitle>Links</CellTitle></TableMediaNameCell>
                                            <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                                        </TableMediaRow>
                                        {userPodcasts.map((p) => (
                                            <TableMediaRow>
                                                <TableMediaLinkCell>
                                                    <CellName>{p.name}</CellName>
                                                </TableMediaLinkCell>
                                                <TableMediaLinkCellIcons>
                                                    {p.spotifyLink && (
                                                        <ClickableIcon
                                                            color="#000"
                                                            icon={faSpotify}
                                                            onClick={() => window.open(p.spotifyLink, '_blank', 'rel=noopener noreferrer')}
                                                        />
                                                    )}
                                                    {p.appleLink && (
                                                        <ClickableIcon
                                                            color="#000"
                                                            icon={faApple}
                                                            onClick={() => window.open(p.appleLink, '_blank', 'rel=noopener noreferrer')}
                                                        />
                                                    )}
                                                    {p.otherLink && (
                                                        <ClickableIcon
                                                            color="#000"
                                                            icon={faLink}
                                                            onClick={() => window.open(p.otherLink, '_blank', 'rel=noopener noreferrer')}
                                                        />
                                                    )}
                                                </TableMediaLinkCellIcons>
                                                <TableMediaActionsCell>
                                                    <ClickableIcon
                                                        icon={faPen}
                                                        onClick={() => showEditPodcast(p)}
                                                    />
                                                    <ClickableIcon
                                                        icon={faTrash}
                                                        color={RED}
                                                        onClick={() => handleDelete('pod', p.id)}
                                                    />
                                                </TableMediaActionsCell>
                                            </TableMediaRow>
                                        ))}
                                    </TableMedia>
                                ) : (
                                    <ListServicesEmp>
                                        <Header>
                                            <Title>No info yet.</Title>
                                            <SubTitle>Here you will see the podcasts you upload</SubTitle>
                                        </Header>
                                    </ListServicesEmp>
                                )
                            : tabActive === 3 ?
                                (userSocialMediaTable.length > 0) ? (
                                    <TableMedia>
                                        <TableMediaRow>
                                            <TableMediaNameCell><CellTitle>Social Network</CellTitle></TableMediaNameCell>
                                            <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                                        </TableMediaRow>
                                        {userSocialMediaTable.map((p) => (
                                            <TableMediaRow>
                                                <TableMediaLinkCell>
                                                    <CellLink href={p.link} target="_blank" rel="noopener noreferrer">{p.platform !== 'Personal' ? p.platform : 'Website'}</CellLink>
                                                </TableMediaLinkCell>
                                                <TableMediaActionsCell>
                                                    <ClickableIcon
                                                        icon={faPen}
                                                        onClick={showEditSocialMedia}
                                                    />
                                                    <ClickableIcon
                                                        icon={faTrash}
                                                        color={RED}
                                                        onClick={() => handleDelete('sm', p.platform)}
                                                    />
                                                </TableMediaActionsCell>
                                            </TableMediaRow>
                                        ))}
                                    </TableMedia>
                                )
                                    :
                                    (
                                        <ListServicesEmp>
                                            <Header>
                                                <Title>No info yet.</Title>
                                                <SubTitle>Here you will see your social media links</SubTitle>
                                            </Header>
                                        </ListServicesEmp>
                                    )
                            : (contactInfo.email || contactInfo.phone) ? (
                                <TableMedia>
                                    <TableMediaRow>
                                        <TableMediaNameCell><CellTitle>Information</CellTitle></TableMediaNameCell>
                                        <TableMediaNameCell><CellTitle>Value</CellTitle></TableMediaNameCell>
                                        <TableMediaActionsCell><CellTitle>Actions</CellTitle></TableMediaActionsCell>
                                    </TableMediaRow>
                                    {contactInfo.email && <TableMediaRow>
                                        <TableMediaLinkCell>
                                            Email
                                        </TableMediaLinkCell>
                                        <TableMediaLinkCell>
                                            {contactInfo.email}
                                        </TableMediaLinkCell>
                                        <TableMediaActionsCell>
                                            <ClickableIcon
                                                icon={faPen}
                                                onClick={showEditContact}
                                            />
                                            <ClickableIcon
                                                        icon={faTrash}
                                                        color={RED}
                                                        onClick={() => handleDelete('sm', 'ContactEmail')}
                                                    />
                                        </TableMediaActionsCell>
                                    </TableMediaRow>}
                                    {contactInfo.phone && <TableMediaRow>
                                        <TableMediaLinkCell>
                                            Phone Number
                                        </TableMediaLinkCell>
                                        <TableMediaLinkCell>
                                            {contactInfo.phone}
                                        </TableMediaLinkCell>
                                        <TableMediaActionsCell>
                                            <ClickableIcon
                                                icon={faPen}
                                                onClick={showEditContact}
                                            />
                                            <ClickableIcon
                                                        icon={faTrash}
                                                        color={RED}
                                                        onClick={() => handleDelete('sm', 'ContactPhone')}
                                                    />
                                        </TableMediaActionsCell>
                                    </TableMediaRow>}
                                </TableMedia>
                            ) :
                            (
                                <ListServicesEmp>
                                    <Header>
                                        <Title>No info yet.</Title>
                                        <SubTitle>Here you will see your Contact Information</SubTitle>
                                    </Header>
                                </ListServicesEmp>
                            )
                        }

                    </TabsContent>
                </Row2_70>
            </ContainerMediaIntro>

            <ModalMedia
                showModal={showModalMedia}
                onHide={hideModal}
                defaultTab={modalMediaDefaultTab}
                userSocialMedia={userSocialMedia}
            />
            <ModalMedia
                showModal={showModalMediaEdit}
                onHide={hideModal}
                defaultTab={modalMediaDefaultTab}
                edit
                userPublication={userPublicationEdit}
                userPodcast={userPodcastEdit}
                userSocialMedia={userSocialMedia}
            />
            <ModalUnsaved show={deleteModal} onHide={onHideDelete} content="Do you want to delete?" deleteModal />
        </Scroll_Content_Signup>
    )
}

export const Line = styled.div`
  width: 100%;
  height: 1px;
  min-height: 1px;
  border-radius: 1px;
  background-color: var(--simple-gray);
`;

export const Scroll_Content_Signup = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
`;

const WrapperDescription = styled.div`
    margin-top: 5px;
`;

const WrapperServices = styled.div`
    margin-top: 18px;
`;

const ContainerPhoto = styled.div`
    text-align: initial;
    display: flex;
    flex-direction: column;
`

const WrapperRow = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 20px);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

const TextArea = styled.div`
    width: 100%;
    border-radius: 12px;
    background-color: white;
    border: 1px solid var(--simple-gray);
    margin-top: 7px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--onyx);
    margin-top: 4px;
    padding: 10px;
    height: 155px;
`

export const Imp = styled.p`
    color: red;
    margin-left: 5px;
`

export default Introduction