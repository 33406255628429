import { faPen, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modality, { ItemCreateExisting, ItemCreateNew, ModalityExtended } from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';
import { t } from "i18next";
import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from "react-router-dom";
import { ArticlesColumn, tabInterface } from '../../../../components/FeedCenterSection';
import { ContextSuccess } from "../../../../Context";
import { getDataSDK, getDataSDKResponse } from "../../../../helpers/backendHelper";
import {
    BtnReadMore,
    Descp,
    Header,
    InfoComm,
    InfoDetail,
    InfoModality,
    InfoTabActive,
    List,
    ListTags,
    Tab,
    Tabs,
    TabsEvents,
    TabsMembers,
    TabsMobile,
    Tags,
    Title,
    TitleTag,
    ToolTip,
    WrapperModality
} from "./styles";

import { EventsResponse, GetEventsResponse } from '@vibe/sdk/dist/interfaces/Events/getEvent';
import { ArticleType } from '@vibe/sdk/dist/interfaces/Modalities/articles/Article';
import IHealer from '@vibe/sdk/interfaces/Admin/IHealer';
import Contributor from '@vibe/sdk/interfaces/Modalities/contributors/Contributor';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { cleanStyleHTML } from '../../../../helpers/letterHelper';
import { mapCommunityHomeCard, mapHealerCard, mapPanelRightData } from '../../../../helpers/searchBarHelper';
import { DropdownIndicator, customStylesRSMultiNoDropdownWhite, customStylesRSMultiWhite } from '../../../../helpers/selectHelper';
import { formatNoTime, mapEventToCard } from '../../../../hooks/Events/useEventsPanel';
import useArticles from '../../../../hooks/Superadmin/Articles/useArticles';
import useBenefitsSA from '../../../../hooks/Superadmin/Modalities/useBESA';
import useModalitiesSA from '../../../../hooks/Superadmin/Modalities/useModalitiesSA';
import useTherapeuticAreasSA from '../../../../hooks/Superadmin/Modalities/useTASA';
import { ActivePanelDataType, ActivePanelType, IPanelRightHome, mockPanelRightData } from '../../../../interfaces/HomeSearch/ItemFilterHome';
import { RootState } from '../../../../store/store';
import ButtonSpinner from '../../../BaseComponents/ButtonSpinner';
import EventCard, { EventCardInterface } from '../../../BaseComponents/Events/EventCard';
import Filter, { IComFilter, IEventFilter, MockComFilter, MockEventFilter } from '../../../BaseComponents/Filter';
import CommunityHomeCard, { ICommunityHomeCard } from '../../../BaseComponents/HomeCards/CommunityHomeCard';
import { Loader } from '../../../BaseComponents/Loader';
import { onHideType } from '../../../BaseComponents/Modals/ModalUnsaved';
import { SelectMultiWrapper } from '../../../BaseComponents/SelectMultiWrapper';
import TextEditor from '../../../BaseComponents/TextEditor';
import { BaseFieldState, getFieldState } from '../../../BaseComponents/utils';
import { StyledFontAwesomeIcon } from '../../../HealerProfile/components';
import CardArticle from '../../../SuperCenter/Articles/CardArticle';
import ModalArticle from '../../../SuperCenter/Articles/ModalArticle';
import { CreatableSelectWrapper } from '../../../SuperCenter/Modalities/Modals/components';
import { IconClicker } from '../../HeaderHome/styles';
import { ModalityOptions } from '../../Pricing/ModalRefer';
import CardDetailComm from './CardDetailComm';
import HealerCard from "../../../HealerCard/HealerCard";

const tabs: tabInterface[] = [
    {
        id: 1,
        label: 'General',
        comingSoon: false,
    },
    {
        id: 2,
        label: 'Communities',
        comingSoon: false,
    },
    {
        id: 4,
        label: 'Articles',
        comingSoon: false,
    },
    {
        id: 5,
        label: 'Research',
        comingSoon: false,
    },
    {
        id: 3,
        label: 'What to expect',
        comingSoon: true,
    }
];

const tabsMobile: tabInterface[] = [
    {
        id: 1,
        label: 'General',
        comingSoon: false,
    },
    {
        id: 2,
        label: 'Communities',
        comingSoon: false,
    },
    {
        id: 4,
        label: 'Articles',
        comingSoon: false,
    },
    {
        id: 5,
        label: 'Research',
        comingSoon: false,
    },
    {
        id: 6,
        label: 'Upcoming Events',
        comingSoon: false,
    },
    // {
    //     id: 6,
    //     label: 'Top Healers',
    //     comingSoon: false,
    // },
    // {
    //     id: 7,
    //     label: 'Prof. Organization',
    //     comingSoon: false,
    // },
    {
        id: 3,
        label: 'What to expect',
        comingSoon: true,
    },
];

const tabsPO: tabInterface[] = [
    {
        id: 1,
        label: 'Upcoming Events',
        comingSoon: false,
    },
    {
        id: 2,
        label: 'Healers',
        comingSoon: false,
    }
];

const ViewMoreModality = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const currentSuccess = useContext(ContextSuccess);
    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);

    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const [modality, setModality] = useState<ModalityExtended>();
    const [POs, setPOs] = useState<IHealer[]>([]);
    const [TPs, setTPs] = useState<IHealer[]>([]);
    const [events, setEvents] = useState<EventCardInterface[]>([]);
    const [eventsFiltered, setEventsFiltered] = useState<EventCardInterface[]>([]);
    const [eventFilter, setEventFilter] = useState<IEventFilter>({ ...MockEventFilter });
    const [communities, setCommunities] = useState<ICommunityHomeCard[]>([]);
    const [tabActive, setTabActive] = useState<number>(1);
    const [tabActivePO, setTabActivePO] = useState<number>(1);
    const [isContributor, setIsContributor] = useState<boolean>(false);
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [panelRightInfo, setPanelRightInfo] = useState<IPanelRightHome>({ ...mockPanelRightData });
    const [showPanelRightInfo, setShowPanelRightInfo] = useState<boolean>(false);
    const [articleTypeCreate, setArticleTypeCreate] = useState<ArticleType>('ART');

    const [comFilter, setComFilter] = useState<IComFilter>({ ...MockComFilter });
    const [comsFiltered, setComsFiltered] = useState<ICommunityHomeCard[]>([]);

    //Edit Info
    const [showEditMod, setShowEditMod] = useState<boolean>(false);

    const [ModalityDescription, setModalityDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [ModalityParentsID, setModalityParentsID] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [ModalityAliases, setModalityAliases] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [ModalityTA, setModalityTA] = useState<BaseFieldState<(ItemCreateExisting | ItemCreateNew)[]>>(getFieldState([]));
    const [ModalityBE, setModalityBE] = useState<BaseFieldState<(ItemCreateExisting | ItemCreateNew)[]>>(getFieldState([]));

    const [ModalitySelectedTA, setModalitySelectedTA] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelectedBE, setModalitySelectedBE] = useState<ModalityOptions<string>[]>([]);
    const [ModalitySelectedParent, setModalitySelectedParent] = useState<ModalityOptions<string>[]>();
    const [ModalitySelectedAliases, setModalitySelectedAliases] = useState<ModalityOptions<string>[]>([]);

    const [ModalityOptionsTA, setModalityOptionsTA] = useState<ModalityOptions<string>[]>([]);
    const [ModalityOptionsBE, setModalityOptionsBE] = useState<ModalityOptions<string>[]>([]);
    const [ModalityOptionsParent, setModalityOptionsParent] = useState<ModalityOptions<string>[]>([]);

    const handleTabActive = (tab: tabInterface) => {
        if (!tab.comingSoon) setTabActive(tab.id)
    }
    const [showDetailComm, setShowDetailComm] = useState(false)

    const { ArticlesIDFiltered, reloadArticlesID } = useArticles({
        SearchTerm: '',
        type: 'Modality',
        id: id || '',
        artType: tabActive === 5 ? 'RES' : 'ART',
    });
    const { benefitsPages, reloadBenefits } = useBenefitsSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { taPages, reloadTherapeuticAreas } = useTherapeuticAreasSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { modalitiesPages, reloadModalities } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });

    const handleTabActivePO = (tab: tabInterface) => {
        setTabActivePO(tab.id)
    }

    const getModality = () => {
        setLoadingData(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return Promise.all([
                    dataSDK.sdk.Modalities.getModalityExtended(id || '', dataSDK.token),
                    dataSDK.sdk.Events.getEventsFiltered(false, { modality: id || '' }, undefined, undefined, dataSDK.token),
                ]);
            })
            .then(([results, eventsResults]) => {
                if (results.statusCode !== 200) {
                    currentSuccess?.setSuccessData(false, true, t('toast_errorGettingModality'));
                    console.error(results);
                    return;
                }

                let eventsList: EventsResponse[] = [];
                if (eventsResults.statusCode !== 200) {
                    currentSuccess?.setSuccessData(false, true, 'Error getting upcoming events');
                    console.error(results);
                }
                else {
                    eventsList = (eventsResults.body as GetEventsResponse).events as EventsResponse[];
                }

                const result = results.body as ModalityExtended

                //Display info
                console.log(result)
                setModality(result);
                setPOs(mapHealerCard(result?.professionalOrgsEngine || []))
                setTPs(mapHealerCard(result?.topPractitionersEngine || []))
                setEvents(eventsList.map(ev => mapEventToCard(ev)));
                setCommunities(mapCommunityHomeCard(result?.communitiesEngine || []))

                //Edit Info
                setModalityDescription({
                    showError: false,
                    error: '',
                    value: result?.description,
                });

                setModalityAliases({
                    showError: false,
                    error: '',
                    value: result?.aliases,
                });

                setModalitySelectedAliases((result?.aliases || []).map((a) => ({
                    value: a,
                    label: a,
                    __isNew__: false,
                })))

                setModalityTA({
                    showError: false,
                    error: '',
                    value: (result?.therapeuticAreas || []).map((item) => ({
                        type: 'EXISTING',
                        id: item.id
                    })),
                });

                setModalitySelectedTA((result?.therapeuticAreas || []).map((b) => ({
                    label: b.name,
                    value: b.id,
                    __isNew__: false,
                })))

                setModalityBE({
                    showError: false,
                    error: '',
                    value: (result?.benefits || []).map((item) => ({
                        type: 'EXISTING',
                        id: item.id
                    }))
                });

                setModalitySelectedBE((result?.benefits || []).map((b) => ({
                    label: b.name,
                    value: b.id,
                    __isNew__: false,
                })))

                if (result?.parentMSs) {
                    setModalitySelectedParent(result?.parentMSs?.map((parentMS) => ({
                        label: parentMS.name,
                        value: parentMS.id,
                        __isNew__: false,
                    })))
                }
            })
            .catch((error) => {
                currentSuccess?.setSuccessData(false, true, t('toast_errorGettingModality'), error);
                console.log(error);
            })
            .finally(() => setLoadingData(false));
    }

    const activeLeftPanel = (data: ActivePanelDataType, type: ActivePanelType) => {
        if (type !== 'EVENT') {
            setPanelRightInfo(mapPanelRightData(data, type));
            setShowPanelRightInfo(true);
        }
    }

    const checkContributor = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.getContributor(userInfo?.username || '', dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    const response = results.body as Contributor;
                    const mod = response.modalities.find(m => m.id === id);
                    if (mod) setIsContributor(true)
                    else setIsContributor(false)
                }
                else setIsContributor(false)
            })
            .catch((error) => {
                console.error(error)
                setIsContributor(false);
            });
    }

    const handleCreate = (type: ArticleType) => {
        setArticleTypeCreate(type);
        setShowCreate(true);
    }

    const getItemCreate = (updatedList: ModalityOptions<string>[]): (ItemCreateExisting | ItemCreateNew)[] => {
        return updatedList.map(item => {
            if (item.__isNew__) {
                return {
                    type: 'NEW',
                    name: item.label,
                }
            }
            return {
                type: 'EXISTING',
                id: item.value
            }
        })
    }

    const onChangeModalityDescription = (event: string) => {
        if (event) {
            setModalityDescription({
                ...ModalityDescription,
                value: event
            });
        }
    }

    const onChangeModalityTA = (updatedList: ModalityOptions<string>[]) => {
        setModalityTA({
            ...ModalityTA,
            value: getItemCreate(updatedList),
        });
        setModalitySelectedTA(updatedList);
    }

    const onChangeModalityBE = (updatedList: ModalityOptions<string>[]) => {
        setModalityBE({
            ...ModalityBE,
            value: getItemCreate(updatedList),
        });
        setModalitySelectedBE(updatedList);
    }

    const onChangeModalityAliases = (updatedList: ModalityOptions<string>[]) => {
        setModalityAliases({
            ...ModalityAliases,
            value: updatedList.map(item => item.value),
        });
        setModalitySelectedAliases(updatedList);
    }

    const onChangeModalityParents = (updatedList: ModalityOptions<string>[]) => {
        setModalityParentsID({
            ...ModalityParentsID,
            value: updatedList.map(item => item.value),
        })
        setModalitySelectedParent(updatedList);
    }

    const onHideUnsaved = (type: onHideType) => {
        switch (type) {
            case 'save':
                currentSuccess?.setLoadingModal(true);
                onUpdate();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                break;

            case 'discard':
                currentSuccess?.setModalsOff();
                currentSuccess?.setLoadingModal(false);
                setShowEditMod(false);
                break;

            default:
                break;
        }
    }

    const onCancel = () => {
        currentSuccess?.setModalWarning(
            'UNSAVED',
            (type) => onHideUnsaved(type),
            'Do you want to discard changes?'
        );
    }

    const onUpdate = () => {

        const toSend: Partial<Modality> = {
            description: ModalityDescription.value,
            parentMSIds: ModalityParentsID.value,
            aliases: ModalityAliases.value,
            therapeuticAreasCreate: ModalityTA.value || [],
            benefitsCreate: ModalityBE.value,
            updatedBy: userInfo?.username || '',
        }

        setLoadingUpdate(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Modalities.updateModality(id || modality?.id || '', toSend, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 201) {
                    getModality();
                    currentSuccess?.setSuccessData(true, true, t('toast_modalityUpdated'));
                    setShowEditMod(false);
                    return;
                }
                currentSuccess?.setSuccessData(false, true, t('toast_errorUpdatingModality'));
            })
            .catch(() => currentSuccess?.setSuccessData(false, true, t('toast_errorUpdatingModality')))
            .finally(() => setLoadingUpdate(false));
    }

    useEffect(() => {
        setModalityOptionsBE(benefitsPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [benefitsPages])

    useEffect(() => {
        setModalityOptionsTA(taPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [taPages])

    useEffect(() => {
        setModalityOptionsParent(modalitiesPages[0].map((b) => ({
            label: b.name,
            value: b.id,
            __isNew__: false,
        })))
    }, [modalitiesPages])

    useEffect(() => {
        getModality();
        checkContributor();
    }, []);

    useEffect(() => {
        getModality();
        checkContributor();
    }, [id]);

    useEffect(() => {
        setEventsFiltered(events.filter(
            (ev: EventCardInterface) =>
                (!eventFilter.cityID || ev.city_id === eventFilter.cityID) &&
                (!eventFilter.countryID || ev.country_id === eventFilter.countryID) &&
                (!eventFilter.stateID || ev.state_id === eventFilter.stateID) &&
                (!eventFilter.typeID || ev.typeID === eventFilter.typeID) &&
                (!eventFilter.start_date || formatNoTime(ev.start).getTime() >= formatNoTime(eventFilter.start_date).getTime()) &&
                (!eventFilter.delivery || ev.delivery === eventFilter.delivery) &&
                (!eventFilter.modID || (ev.modalitiesIDs || []).includes(eventFilter.modID))));
    }, [events, eventFilter]);

    useEffect(() => {
        setComsFiltered(communities.filter(
            (com: ICommunityHomeCard) =>
                (!comFilter.orgID || com.org_id === comFilter.orgID) &&
                (!comFilter.modID || (com.modalitiesIDs || []).includes(comFilter.modID))));
    }, [communities, comFilter]);

    return (
        <WrapperModality>
            <Header>
                <FlexRow>
                    {loadingData && <Loader color={"white"} />}
                    <Title>{modality?.name || ''}</Title>
                </FlexRow>
                <IconClicker
                    icon={faTimes}
                    size='xl'
                    color={'black'}
                    onClick={() => navigate(-1)}
                />
            </Header>

            <InfoModality>
                <InfoDetail>
                    <Tabs>
                        {tabs.map((tab) => (
                            <Tab
                                theme={{ main: tabActive === tab.id ? 'active' : 'inactive' }}
                                onClick={() => handleTabActive(tab)}
                            >
                                {tab.comingSoon && <ToolTip>Coming soon</ToolTip>}
                                {tab.label}
                            </Tab>
                        ))}
                    </Tabs>
                    <TabsMobile>
                        {tabsMobile.map((tab) => (
                            <Tab
                                theme={{ main: tabActive === tab.id ? 'active' : 'inactive' }}
                                onClick={() => handleTabActive(tab)}
                            >
                                {tab.comingSoon && <ToolTip>Coming soon</ToolTip>}
                                {tab.label}
                            </Tab>
                        ))}
                    </TabsMobile>
                    {tabActive === 1 ?
                        (showEditMod ? (
                            <InfoTabActive isUpdate>
                                <RowTop>
                                    <TitleTag>Description</TitleTag>
                                    <ActionWrapper>
                                        <CancelButton onClick={onCancel}>
                                            {t('button__cancel')}
                                        </CancelButton>
                                        <WrapperButton>
                                            <ButtonSpinner
                                                loading={loadingUpdate}
                                                handleClick={onUpdate}
                                                text={t('button__save')}
                                                hasIcon={false}
                                            />
                                        </WrapperButton>
                                    </ActionWrapper>
                                </RowTop>
                                <TextEditor
                                    value={ModalityDescription.value || ''}
                                    setValue={onChangeModalityDescription}
                                    charLimit={2500}
                                    placeholder='Please describe the modality...'
                                    showError={ModalityDescription.showError || false}
                                />
                                <TitleTag>Aliases</TitleTag>
                                <CreatableSelectWrapper
                                    options={undefined}
                                    styles={customStylesRSMultiNoDropdownWhite()}
                                    value={ModalitySelectedAliases || []}
                                    onChangeValue={onChangeModalityAliases}
                                    placeholder="Type and press enter"
                                    showError={ModalityBE.showError || false}
                                    error={ModalityBE.error || ''}
                                />
                                <TitleTag>Similar Modalities</TitleTag>
                                <SelectMultiWrapper
                                    options={ModalityOptionsParent}
                                    styles={customStylesRSMultiWhite()}
                                    components={{
                                        DropdownIndicator
                                    }}
                                    value={ModalitySelectedParent || []}
                                    onChangeValue={onChangeModalityParents}
                                    placeholder="Select similar modalities"
                                    showError={ModalityParentsID.showError || false}
                                    error={ModalityParentsID.error || ''}
                                />
                                <TitleTag>Benefits</TitleTag>
                                <CreatableSelectWrapper
                                    options={ModalityOptionsBE}
                                    styles={customStylesRSMultiNoDropdownWhite()}
                                    value={ModalitySelectedBE || []}
                                    onChangeValue={onChangeModalityBE}
                                    placeholder="Select or create one by pressing enter"
                                    showError={ModalityBE.showError || false}
                                    error={ModalityBE.error || ''}
                                />
                                <TitleTag>Conditions Treated</TitleTag>
                                <CreatableSelectWrapper
                                    options={ModalityOptionsTA}
                                    styles={customStylesRSMultiNoDropdownWhite()}
                                    value={ModalitySelectedTA || []}
                                    onChangeValue={onChangeModalityTA}
                                    placeholder="Select or create one by pressing enter"
                                    showError={ModalityTA.showError || false}
                                    error={ModalityTA.error || ''}
                                />
                            </InfoTabActive>
                        ) : (
                            <InfoTabActive>
                                <RowTop>
                                    <Descp
                                        dangerouslySetInnerHTML={{ __html: cleanStyleHTML(modality?.description ?? '') }}
                                    />
                                    {isContributor && <IconClicker
                                        icon={faPen}
                                        size='lg'
                                        color='#333'
                                        onClick={() => setShowEditMod(true)}
                                    />}
                                </RowTop>
                                <BtnReadMore>
                                    Read more
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        size='sm'
                                        color={"black"}
                                        style={{
                                            fontSize: '12px'
                                        }}
                                    />
                                </BtnReadMore>
                                {modality?.aliases &&
                                    <Tags theme={{ main: 'tgAlianses' }}>
                                        <TitleTag>Aliases</TitleTag>
                                        <ListTags>
                                            {
                                                (modality?.aliases).map((alias) => (
                                                    <List key={alias}>{alias}</List>
                                                ))
                                            }
                                        </ListTags>
                                    </Tags>
                                }
                                {(modality?.parentMSs || modality?.childrenMSs) &&
                                    <Tags theme={{ main: 'tgAreas' }}>
                                        <TitleTag>Similar Modalities</TitleTag>
                                        <ListTags>
                                            {modality?.parentMSs &&
                                                (modality?.parentMSs).map((benefit) => (
                                                    <List style={{ cursor: 'pointer' }} onClick={() => navigate(`../../../../modality/${benefit.id}`)} key={benefit.id}>{benefit.name}</List>
                                                ))
                                            }
                                            {modality?.childrenMSs &&
                                                (modality?.childrenMSs).map((benefit) => (
                                                    <List style={{ cursor: 'pointer' }} onClick={() => navigate(`../../../../modality/${benefit.id}`)} key={benefit.id}>{benefit.name}</List>
                                                ))
                                            }
                                        </ListTags>
                                    </Tags>
                                }
                                {modality?.benefits &&
                                    <Tags theme={{ main: 'tgBenefits' }}>
                                        <TitleTag>Benefits</TitleTag>
                                        <ListTags>
                                            {
                                                (modality?.benefits).map((benefit) => (
                                                    <List key={benefit.id}>{benefit.name}</List>
                                                ))
                                            }
                                        </ListTags>
                                    </Tags>
                                }

                                {modality?.therapeuticAreas &&
                                    <Tags theme={{ main: 'tgAreas' }}>
                                        <TitleTag>Conditions Treated</TitleTag>
                                        <ListTags>
                                            {
                                                (modality?.therapeuticAreas).map((area) => (
                                                    <List key={area.id}>{area.name}</List>
                                                ))
                                            }
                                        </ListTags>
                                    </Tags>
                                }
                            </InfoTabActive>
                        ))
                        : tabActive === 2 ?
                            <>
                                <WrapperFilterComms>
                                    <Filter from={'MODALITY'} type={'COM'} comFilter={comFilter} setComFilter={setComFilter} />
                                </WrapperFilterComms>
                                <InfoComm>
                                    {communities.map(com => (
                                        <CommunityHomeCard
                                            data={com}
                                            onClick={(data: ICommunityHomeCard) => activeLeftPanel(data, 'COMM')}
                                        />
                                    ))}
                                    {showDetailComm && <CardDetailComm handleClick={setShowDetailComm} />}
                                </InfoComm>
                            </>
                            : tabActive === 4 ?
                                <ArticlesColumn>
                                    {isContributor && <ButtonView onClick={() => handleCreate('ART')}>
                                        Create Article
                                        <StyledFontAwesomeIcon
                                            size='sm'
                                            color='white'
                                            icon='plus'
                                        />
                                    </ButtonView>}
                                    <ArticlesWrapModality>
                                        {ArticlesIDFiltered.map(ar => (
                                            <CardArticle article={ar} />
                                        ))}
                                    </ArticlesWrapModality>
                                </ArticlesColumn>
                                : tabActive === 5 ?
                                    <ArticlesColumn>
                                        {isContributor && <ButtonView onClick={() => handleCreate('RES')}>
                                            Create Research
                                            <StyledFontAwesomeIcon
                                                size='sm'
                                                color='white'
                                                icon='plus'
                                            />
                                        </ButtonView>}
                                        <ArticlesWrapModality>
                                            {ArticlesIDFiltered.map(ar => (
                                                <CardArticle article={ar} />
                                            ))}
                                        </ArticlesWrapModality>
                                    </ArticlesColumn>
                                    : tabActive === 6 ?
                                        <EventsWrapper>
                                            <WrapperFilter>
                                                <Filter from={'MODALITY'} type={'EV'} eventFilter={eventFilter} setEventFilter={setEventFilter} />
                                            </WrapperFilter>
                                            {eventsFiltered.map(event => (
                                                <EventCard
                                                    data={event}
                                                    reload={() => { }}
                                                    onClickHome={(data: EventCardInterface) => activeLeftPanel(data, 'EVENT')}
                                                    isHome
                                                    width={"315px"}
                                                />
                                            ))}
                                        </EventsWrapper>
                                        // <HealersWrapper>
                                        //     {TPs.map(heal => (
                                        //         <HealerCard
                                        //             data={heal}
                                        //             type={heal.type === 'ANY' ? 'TP' : heal.type}
                                        //             onClick={(data: IHealer) => activeLeftPanel(data, 'TP')}
                                        //         />
                                        //     ))}
                                        // </HealersWrapper>
                                        // : tabActive === 7 ?
                                        //     <HealersWrapper>
                                        //         {POs.map(heal => (
                                        //             <HealerCard
                                        //                 data={heal}
                                        //                 type={heal.type === 'ANY' ? 'TP' : heal.type}
                                        //                 onClick={(data: IHealer) => activeLeftPanel(data, 'PO')}
                                        //             />
                                        //         ))}
                                        //     </HealersWrapper>
                                        : <></>
                    }

                </InfoDetail>
                <TabsMembers>
                    <TabsEvents>
                        {
                            tabsPO.map((tabPO) => (
                                <Tab
                                    theme={{ main: tabActivePO === tabPO.id ? 'active' : 'inactive' }}
                                    onClick={() => handleTabActivePO(tabPO)}
                                >
                                    {tabPO.label}
                                </Tab>
                            ))}
                    </TabsEvents>
                    {
                        tabActivePO === 1 ?
                            <>
                                <WrapperFilter>
                                    <Filter from={'MODALITY'} type={'EV'} eventFilter={eventFilter} setEventFilter={setEventFilter} />
                                </WrapperFilter>
                                <EventsWrapper>

                                    {eventsFiltered.map(event => (
                                        <EventCard
                                            data={event}
                                            reload={() => { }}
                                            onClickHome={(data: EventCardInterface) => activeLeftPanel(data, 'EVENT')}
                                            isHome
                                            width={"315px"}
                                        />
                                    ))}
                                </EventsWrapper>
                            </>
                            : tabActivePO === 2 ?
                                <HealersWrapper>
                                    {TPs.map(heal => (
                                        <HealerCard
                                            healer={heal}
                                        />
                                    ))}
                                </HealersWrapper>
                                : tabActivePO === 3 ?
                                    <HealersWrapper>
                                        {POs.map(heal => (
                                            <HealerCard
                                                healer={heal}
                                                onClick={(data: IHealer) => activeLeftPanel(data, 'PO')}
                                            />
                                        ))}
                                    </HealersWrapper>
                                    : <></>
                    }
                </TabsMembers>
            </InfoModality>
            <ModalArticle
                showModal={showCreate}
                onHide={() => setShowCreate(false)}
                reload={reloadArticlesID}
                mod={id}
                type={articleTypeCreate}
                hl={userInfo?.username || ''}
                isIndividual={userRoles && userRoles.includes('INDIVIDUAL')}
            />
        </WrapperModality >
    )
}

export default ViewMoreModality;

const FlexRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: left;
`;

const WrapperButton = styled.div`
  width: 150px;
  height: 100%;
`;

const BaseButton = styled(Button)`
    border: 1px solid var(--t-blue);
    border-radius: 12px;
    width: 150px;
    cursor: pointer;
`;

const CancelButton = styled(BaseButton)`
    background: #FFFFFF;
    box-sizing: border-box;
    color: var(--t-blue);
`;

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
`;

const RowTop = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    text-align: left;
    padding-right: 10px;
`;

const WrapperFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 10px;
    padding-top: 10px;
`;

const WrapperFilterComms = styled(WrapperFilter)`
    margin-top: 10px;
`;

const ButtonView = styled.button`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    width: fit-content;
    min-width: 100px;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark);
    border: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;

    cursor: pointer;
`;

const EventsWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 250px);
    padding-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit,
      minmax(315px, 100%));
    grid-template-rows: repeat(auto-fit, 1fr);
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    padding-bottom: 50px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const HealersWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 200px);
    padding-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit,
      minmax(250px, 100%));
    grid-template-rows: repeat(auto-fit, 1fr);
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    padding: 20px;
    padding-bottom: 60px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const ArticlesWrapModality = styled.div`
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 300px);
    display: grid;
    grid-template-columns: repeat(auto-fit,
      minmax(250px, 300px));
    grid-template-rows: repeat(auto-fit, 1fr);
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
        max-height: calc(100vh - 250px);
    }
`;