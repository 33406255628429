import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import Delivery from "@vibe/sdk/interfaces/Modalities/hservices/Delivery";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import useArticles from "../../../hooks/Superadmin/Articles/useArticles";
import useBenefitsSA from "../../../hooks/Superadmin/Modalities/useBESA";
import useModalitiesSA from "../../../hooks/Superadmin/Modalities/useModalitiesSA";
import useTherapeuticAreasSA from "../../../hooks/Superadmin/Modalities/useTASA";
import { RootState } from "../../../store/store";
import { ArticlesColumn, HealersGrid, tabInterface } from "../../FeedCenterSection";
import HealerCard from "../../HealerCard/HealerCard";
import { IconClicker } from "../../Home/HeaderHome/styles";
import { List, ListTags, Tab, Tabs, Tags, TitleTag, ToolTip } from "../../Home/ResultsHome/ViewMoreModality/styles";
import CardArticle from "../../SuperCenter/Articles/CardArticle";
import BlankTable from "../BlankTable";
import Communities from "../Communities/Communities";
import { ModMapped } from "./ModalitiesDisplay";

const tabs: tabInterface[] = [
    {
        id: 1,
        label: 'General',
        comingSoon: false,
    },
    {
        id: 2,
        label: 'Communities',
        comingSoon: false,
    },
    {
        id: 4,
        label: 'Articles',
        comingSoon: false,
    },
    {
        id: 5,
        label: 'Research',
        comingSoon: false,
    }
];

const ModalModalityProfile = ({
    showModal,
    onHide,
    modality,
}: {
    showModal: boolean
    onHide: () => void;
    modality: ModMapped
}) => {

    const userProfile: IUserGradeResponse = useSelector((state: RootState) => state.getUser.userProfile);

    const [readMore, setReadMore] = useState<boolean>(false);
    const [tabActive, setTabActive] = useState<number>(1);
    const [listMS, setListMS] = useState<boolean>(false);

    const { modalitiesPages } = useModalitiesSA({
        pageSizeProp: 1000,
        searchName: '',
    });
    const { benefitsPages } = useBenefitsSA({
        pageSizeProp: 100,
        searchName: '',
    });
    const { taPages } = useTherapeuticAreasSA({
        pageSizeProp: 100,
        searchName: '',
    });

    const { ArticlesIDFiltered } = useArticles({
        SearchTerm: '',
        type: 'Healer',
        id: userProfile.username,
        artType: tabActive === 5 ? 'RES' : 'ART',
    });

    const handleTabActive = (tab: tabInterface) => {
        if (!tab.comingSoon) setTabActive(tab.id)
    }

    const getModalities = () => {
        return modalitiesPages[0].filter((mod) => (modality.service.modalityIds || []).includes(mod.id));
    }

    const getBenefits = () => {
        return benefitsPages[0].filter((mod) => (modality.service.BEIds || []).includes(mod.id));
    }

    const getTherapeutic = () => {
        return taPages[0].filter((mod) => (modality.service.TAIds || []).includes(mod.id));
    }

    const getDelivery = (delivery: Delivery): string => {
        if (delivery.combined) return 'Online & in person';
        if (delivery.inPerson && delivery.online) return 'Online & in person';
        if (delivery.inPerson) return 'In person';
        if (delivery.online) return 'Online';
        return 'Not specified';
    }

    const commonElements = (arr1: string[], arr2: string[]): boolean => {
        return arr1.some(element => arr2.includes(element));
    };

    const getArticles = () => {
        return ArticlesIDFiltered.filter(ar => commonElements(ar.modalities.map(m => m.id), modality.service.modalityIds || []))
    }

    useEffect(() => setTabActive(1), [modality])

    return (
        <Modal className="modalMod" show={showModal} onHide={onHide}>
            <Modal.Header>
                <Title>{modality.name} by {userProfile.firstname}</Title>
                <IconClicker
                    onClick={onHide}
                    icon="times"
                    size='xl'
                    color={'black'}
                />
            </Modal.Header>
            <Modal.Body>
                <TabsModal>
                    {tabs.map((tab) => (
                        <Tab
                            theme={{ main: tabActive === tab.id ? 'active' : 'inactive' }}
                            onClick={() => handleTabActive(tab)}
                        >
                            {tab.comingSoon && <ToolTip>Coming soon</ToolTip>}
                            {tab.label}
                        </Tab>
                    ))}
                    {(modality.healers && modality.healers.length > 0) && <Tab
                        theme={{ main: tabActive === 6 ? 'active' : 'inactive' }}
                        onClick={() => handleTabActive({ id: 6, comingSoon: false, label: '' })}
                    >
                        Healers
                    </Tab>}
                </TabsModal>
                {tabActive === 1 ?
                    <InfoTabActive>
                        <ColumnFlex>
                            <Description
                                active={cleanHTML(modality.service.description as string ?? '').length > 280 ? readMore : true}
                                dangerouslySetInnerHTML={{ __html: cleanStyleHTML(modality.service.description as string ?? '') }}>
                            </Description>
                            {cleanHTML(modality.service.description as string ?? '').length > 280 && <ReadMore onClick={() => setReadMore(!readMore)}>{readMore ? 'Hide info' : 'Read more'}</ReadMore>}
                        </ColumnFlex>
                        {modality.service.modalityIds &&
                            <Tags theme={{ main: 'tgAlianses' }}>
                                <TitleTag>Modalities Related</TitleTag>
                                <ListTags>
                                    {
                                        getModalities().map((alias) => (
                                            <List key={alias.id}>{alias.name}</List>
                                        ))
                                    }
                                </ListTags>
                            </Tags>
                        }
                        {modality.service.BEIds &&
                            <Tags theme={{ main: 'tgBenefits' }}>
                                <TitleTag>Benefits</TitleTag>
                                <ListTags>
                                    {
                                        getBenefits().map((benefit) => (
                                            <List key={benefit.id}>{benefit.name}</List>
                                        ))
                                    }
                                </ListTags>
                            </Tags>
                        }
                        {modality.service.TAIds &&
                            <Tags theme={{ main: 'tgAreas' }}>
                                <TitleTag>Conditions Treated</TitleTag>
                                <ListTags>
                                    {
                                        getTherapeutic().map((area) => (
                                            <List key={area.id}>{area.name}</List>
                                        ))
                                    }
                                </ListTags>
                            </Tags>
                        }
                        <TitleTag>Delivery</TitleTag>
                        <BlankTable
                            maxWidth={'100%'}
                            backgroundColor={'white'}
                            headers={[
                                'Mode of Service',
                                'Delivery',
                            ]}
                            items={modality.service.delivery.map((del) => ({
                                id: del.type,
                                labels: [
                                    del.type.slice(0, 1).toUpperCase() + del.type.slice(1, del.type.length),
                                    getDelivery(del),
                                ]
                            }))}
                        />
                    </InfoTabActive>
                    : tabActive === 2 ?
                        <CardPosts>
                            <Communities
                                onClickMS={setListMS}
                                from={'H-Profile'}
                                commsFiltered={modality.id}
                            />
                        </CardPosts>
                        : tabActive === 4 ?
                            <ArticlesColumn>
                                <ArticlesWrapModality>
                                    {getArticles().map(ar => (
                                        <CardArticle article={ar} />
                                    ))}
                                </ArticlesWrapModality>
                            </ArticlesColumn>
                            : tabActive === 5 ?
                                <ArticlesColumn>
                                    <ArticlesWrapModality>
                                        {getArticles().map(ar => (
                                            <CardArticle article={ar} />
                                        ))}
                                    </ArticlesWrapModality>
                                </ArticlesColumn>
                                : tabActive === 6 ?
                                    <HealersGrid>
                                        {modality.healers?.map(healer => (
                                            <HealerCard healer={healer} />
                                        ))}
                                    </HealersGrid> : <></>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ModalModalityProfile;

const CardPosts = styled.div`
    width: 100%;
    background-color: white;
    position: relative;
    height: 100%;
    overflow: scroll;
`;

const InfoTabActive = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    text-align: left;
    overflow: scroll;
    height: 100%;
`;

const ArticlesWrapModality = styled.div`
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 300px);
    display: grid;
    grid-template-columns: repeat(auto-fit,
      minmax(250px, 300px));
    grid-template-rows: repeat(auto-fit, 1fr);
    overflow: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
`;

const TabsModal = styled(Tabs)`
    width: 100%;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Title = styled.span`
    font-family: 'Inter-Bold';
    color: black;
    font-size: 20px;
`;

const ReadMore = styled.div`
  font-family: 'Inter-Bold';
  font-size: 14px;
  color: var(--absolute-zero);
  cursor: pointer;
`;

const Description = styled.div`
    display: inline-block;
    font-family: 'Inter';
    font-size: 14px;
    line-height: normal;
    color: black;
    max-height: ${({ active }: { active: boolean }) => active ? 'fit-content' : '40px'};
    width: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    text-align: left;

    &::after {
        content: "...";
        display: ${({ active }: { active: boolean }) => active ? 'none' : 'flex'};
        align-items: flex-end;
    }
`;