import { AnyAction } from 'redux';
import {
  START_GET_EVENT,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,

  START_GET_MY_EVENT,
  GET_MY_EVENT_SUCCESS,
  GET_MY_EVENT_ERROR,

  SAVE_EVENT_ERROR,
  SAVE_EVENT_SUCCESS,
  START_SAVE_EVENT,

  START_GET_CHECK_EVENT,
  GET_CHECK_EVENT_SUCCESS,
  GET_CHECK_EVENT_ERROR,
  LOGOUT_EVENT,
  START_GET_COMPLETE_EVENTS,
  GET_COMPLETE_EVENTS_ERROR,
  GET_COMPLETE_EVENTS_SUCCESS,
  GET_MY_EVENTS_RSVP_ERROR,
  GET_MY_EVENTS_RSVP_SUCCESS,
  GET_MY_FRIENDS_EVENTS_ERROR,
  GET_MY_FRIENDS_EVENTS_SUCCESS,
  START_GET_MY_EVENTS_RSVP,
  START_GET_MY_FRIENDS_EVENTS,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  START_GET_EVENTS,
  START_GET_ORG_EVENTS,
  GET_ORG_EVENTS_SUCCESS,
  GET_ORG_EVENTS_ERROR,
  START_GET_PERTINENT_EVENTS,
  GET_PERTINENT_EVENTS_SUCCESS,
  GET_PERTINENT_EVENTS_ERROR,
  START_GET_MY_EVENTS,
  GET_MY_EVENTS_SUCCESS,
  GET_MY_EVENTS_ERROR,
  START_GET_CLUB_EVENTS,
  GET_CLUB_EVENTS_SUCCESS,
  GET_CLUB_EVENTS_ERROR,
  START_GET_HEAL_ORG_EVENTS,
  GET_HEAL_ORG_EVENTS_SUCCESS,
  GET_HEAL_ORG_EVENTS_ERROR,
  START_GET_HEAL_IND_EVENTS,
  GET_HEAL_IND_EVENTS_SUCCESS,
  GET_HEAL_IND_EVENTS_ERROR,
  GET_ORG_PAST_EVENTS_ERROR,
  GET_ORG_PAST_EVENTS_SUCCESS,
  START_GET_ORG_PAST_EVENTS,
} from "../constants/eventConstants";
import EventStateInterface from "../../interfaces/store/eventState";
import { MockEventDetails } from '../../interfaces/Event/CreateEvent';

const initialState: EventStateInterface = {
  event: { ...MockEventDetails },
  loadingEvent: false,

  loadingSaveEvent: false,
  SaveEventError: undefined,
  SaveEvent: undefined,

  loadingGetCompleteEvents: false,
  getCompleteEventsError: undefined,
  getCompleteEvents: undefined,

  loadingMyEvent: false,
  myEventError: undefined,
  myEvent: undefined,

  loadingEvents: false,
  eventsError: undefined,
  events: [],

  loadingClubEvents: false,
  clubEventsError: undefined,
  clubEvents: [],

  loadingOrgEvents: false,
  orgEventsError: undefined,
  orgEvents: [],

  loadingOrgPastEvents: false,
  orgPastEventsError: undefined,
  orgPastEvents: [],

  loadingCheckEvent: false,
  checkEventError: undefined,
  checkEvent: undefined,

  loadingHealerOrgEvents: false,
  healerOrgEventsError: undefined,
  healerOrgEvents: [],

  loadingHealerIndEvents: false,
  healerIndEventsError: undefined,
  healerIndEvents: [],

  loadingGetMyFriendsEvents: false,
  getMyFriendsEventsError: undefined,
  getMyFriendsEvents: undefined,

  loadingGetPertinentEvents: false,
  getPertinentEventsError: undefined,
  getPertinentEvents: undefined,

  loadingGetMyEvents: false,
  getMyEventsError: undefined,
  getMyEvents: undefined,

  loadingGetMyEventsRSVP: false,
  getMyEventsRSVPError: undefined,
  getMyEventsRSVP: undefined,
};

export const getEventReducer = (state = initialState, action: AnyAction): EventStateInterface => {
  switch (action.type) {
    case START_SAVE_EVENT:
      return {
        ...state,
        loadingSaveEvent: true,
      }
    case SAVE_EVENT_SUCCESS:
      return {
        ...state,
        loadingSaveEvent: false,
        SaveEvent: action.payload,
      }
    case SAVE_EVENT_ERROR:
      return {
        ...state,
        loadingSaveEvent: false,
        SaveEventError: action.payload,
      }

    case START_GET_EVENTS:
      return {
        ...state,
        loadingEvents: true,
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loadingEvents: false,
      };
    case GET_EVENTS_ERROR:
      return {
        ...state,
        eventError: action.payload,
        loadingEvents: false,
      };

    case START_GET_CLUB_EVENTS:
      return {
        ...state,
        loadingClubEvents: true,
      };
    case GET_CLUB_EVENTS_SUCCESS:
      return {
        ...state,
        clubEvents: action.payload,
        loadingClubEvents: false,
      };
    case GET_CLUB_EVENTS_ERROR:
      return {
        ...state,
        clubEventsError: action.payload,
        loadingClubEvents: false,
      };

    case START_GET_EVENT:
      return {
        ...state,
        loadingEvent: true,
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        event: action.payload,
        loadingEvent: false,
      };
    case GET_EVENT_ERROR:
      return {
        ...state,
        eventError: action.payload,
        loadingEvent: false,
      };

    case START_GET_MY_EVENT:
      return {
        ...state,
        loadingMyEvent: true,
      };
    case GET_MY_EVENT_SUCCESS:
      return {
        ...state,
        myEvent: action.payload,
        loadingMyEvent: false,
      };
    case GET_MY_EVENT_ERROR:
      return {
        ...state,
        myEventError: action.payload,
        loadingMyEvent: false,
      };
    case START_GET_CHECK_EVENT:
      return {
        ...state,
        loadingCheckEvent: true,
      };
    case GET_CHECK_EVENT_SUCCESS:
      return {
        ...state,
        checkEvent: action.payload,
        loadingCheckEvent: false,
      };
    case GET_CHECK_EVENT_ERROR:
      return {
        ...state,
        checkEventError: action.payload,
        loadingCheckEvent: false,
      };

    case START_GET_COMPLETE_EVENTS:
      return {
        ...state,
        loadingGetCompleteEvents: true,
      };
    case GET_COMPLETE_EVENTS_SUCCESS:
      return {
        ...state,
        getCompleteEvents: action.payload,
        loadingGetCompleteEvents: false,
      };
    case GET_COMPLETE_EVENTS_ERROR:
      return {
        ...state,
        getCompleteEventsError: action.payload,
        loadingGetCompleteEvents: false,
      };

    case START_GET_MY_FRIENDS_EVENTS:
      return {
        ...state,
        loadingGetMyFriendsEvents: true,
      };
    case GET_MY_FRIENDS_EVENTS_SUCCESS:
      return {
        ...state,
        getMyFriendsEvents: action.payload,
        loadingGetMyFriendsEvents: false,
      };
    case GET_MY_FRIENDS_EVENTS_ERROR:
      return {
        ...state,
        getMyFriendsEventsError: action.payload,
        loadingGetMyFriendsEvents: false,
      };

    case START_GET_PERTINENT_EVENTS:
      return {
        ...state,
        loadingGetPertinentEvents: true,
      };
    case GET_PERTINENT_EVENTS_SUCCESS:
      return {
        ...state,
        getPertinentEvents: action.payload,
        loadingGetPertinentEvents: false,
      };
    case GET_PERTINENT_EVENTS_ERROR:
      return {
        ...state,
        getPertinentEventsError: action.payload,
        loadingGetPertinentEvents: false,
      };

    case START_GET_MY_EVENTS:
      return {
        ...state,
        loadingGetMyEvents: true,
      };
    case GET_MY_EVENTS_SUCCESS:
      return {
        ...state,
        getMyEvents: action.payload,
        loadingGetMyEvents: false,
      };
    case GET_MY_EVENTS_ERROR:
      return {
        ...state,
        getMyEventsError: action.payload,
        loadingGetMyEvents: false,
      };

    case START_GET_MY_EVENTS_RSVP:
      return {
        ...state,
        loadingGetMyEventsRSVP: true,
      };
    case GET_MY_EVENTS_RSVP_SUCCESS:
      return {
        ...state,
        getMyEventsRSVP: action.payload,
        loadingGetMyEventsRSVP: false,
      };
    case GET_MY_EVENTS_RSVP_ERROR:
      return {
        ...state,
        getMyEventsRSVPError: action.payload,
        loadingGetMyEventsRSVP: false,
      };

    case START_GET_ORG_EVENTS:
      return {
        ...state,
        loadingOrgEvents: true,
      };
    case GET_ORG_EVENTS_SUCCESS:
      return {
        ...state,
        orgEvents: action.payload,
        loadingOrgEvents: false,
      };
    case GET_ORG_EVENTS_ERROR:
      return {
        ...state,
        orgEventsError: action.payload,
        loadingOrgEvents: false,
      };

    case START_GET_ORG_PAST_EVENTS:
      return {
        ...state,
        loadingOrgPastEvents: true,
      };
    case GET_ORG_PAST_EVENTS_SUCCESS:
      return {
        ...state,
        orgPastEvents: action.payload,
        loadingOrgPastEvents: false,
      };
    case GET_ORG_PAST_EVENTS_ERROR:
      return {
        ...state,
        orgPastEventsError: action.payload,
        loadingOrgPastEvents: false,
      };

    case START_GET_HEAL_ORG_EVENTS:
      return {
        ...state,
        loadingHealerOrgEvents: true,
      };
    case GET_HEAL_ORG_EVENTS_SUCCESS:
      return {
        ...state,
        healerOrgEvents: action.payload,
        loadingHealerOrgEvents: false,
      };
    case GET_HEAL_ORG_EVENTS_ERROR:
      return {
        ...state,
        healerOrgEventsError: action.payload,
        loadingHealerOrgEvents: false,
      };

    case START_GET_HEAL_IND_EVENTS:
      return {
        ...state,
        loadingHealerIndEvents: true,
      };
    case GET_HEAL_IND_EVENTS_SUCCESS:
      return {
        ...state,
        healerIndEvents: action.payload,
        loadingHealerIndEvents: false,
      };
    case GET_HEAL_IND_EVENTS_ERROR:
      return {
        ...state,
        healerIndEventsError: action.payload,
        loadingHealerIndEvents: false,
      };

    case LOGOUT_EVENT:
      return initialState;

    default:
      return state;
  }
};
