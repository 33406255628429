import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "./styles.css";
import ClubViewInformation from "../../components/ClubViewInformation";
import ClubViewRequests from "../../components/ClubViewRequests";
import ClubEvents from "../../components/ClubEvents";
import ClubMembers from "../ClubMembers";
import ClubFollowers from "../../components/ClubFollowers";
import ClubFlags from "../../components/ClubFlags";
import ClubInvites from "../../components/ClubInvites";
import ClubResources from "../../components/ClubResources";
import ClubSettings from "../../components/ClubSettings";
import { getClubsImAdv } from "../../store/actions/clubActions";
import { useParams } from "react-router-dom";
import Posts from "../../containers/Posts";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import PostsNew from "../../containers/Posts/PostsNew";
import { tabInterface } from "../FeedCenterSection";
import { CenterInsideWrap, TabCenterInside, TabsCenterInside } from "../../containers/Bulletin";
import styled from "styled-components";
import { RootState } from "../../store/store";
import ModalComingSoon from "../BaseComponents/Modals/ModalComingSoon";
import { IUserGradeResponse } from "@vibe/sdk/interfaces/Admin/User";
import { getIdOrgUser } from "../../helpers/userHelper";
import { IClubExtended } from "@vibe/sdk/interfaces/Club/ClubInterface";
import ClubLinks from "../ClubLinks/ClubLinks";

interface ClubViewNavBarProps {
  //todo -> put types
  club: any;
  officers: any;
  reloadClub: any;
  reloadMembers: any;
  members: any;
  advisors: any;
  reloadOfficers: any;
  reloadAdvisors: any;
  clubCode: any;
  ChatServiceInstance: ChatServiceInterface;
}

function Index(props: ClubViewNavBarProps) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState<number>(0);
  const [isUserAdvisor, setIsUserAdvisor] = useState<boolean>(false);
  const [invitesAdded, setInvitesAdded] = useState<boolean>(false);
  const [soon, setSoon] = useState<boolean>(false);

  const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
  const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
  const user: IUserGradeResponse = useSelector((state: RootState) => state.getUser.user);
  const clubsGuide: IClubExtended[] = useSelector((state: RootState) => state.getClub.clubsImAdv);

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    setSelected(0);
    props.reloadClub();
    dispatch(getClubsImAdv(userInfo?.username || ''));
  }, []);

  useEffect(() => {
    setIsUserAdvisor(false);
  }, [id]);

  useEffect(() => {
    isClubAdvisor();
  }, [props.club]);

  const isClubAdvisor = () => {
    if (userRoles.includes("SUPER")) {
      setIsUserAdvisor(true);
      return;
    }

    const orgId = getIdOrgUser(user.rolesByOrg, user.organizations);

    if (userRoles.includes("ORGADMIN") && (props?.club?.org_id || '') === orgId) {
      setIsUserAdvisor(true);
      return;
    }

    for (let club of clubsGuide) {
      if (club.id === id) {
        setIsUserAdvisor(true);
        return;
      }
    }
  }

  const tabsAll: tabInterface[] = [
    {
      id: 0,
      label: 'Vibes',
      comingSoon: false,
    },
    {
      id: 1,
      label: 'Information',
      comingSoon: false,
    },
    {
      id: 2,
      label: 'Events',
      comingSoon: false,
    },
    {
      id: 3,
      label: 'Members',
      comingSoon: false,
    }
  ]

  const tabsAdvisor: tabInterface[] = [
    {
      id: 5,
      label: 'Requests',
      comingSoon: false,
    },
    {
      id: 6,
      label: 'Invites',
      comingSoon: false,
    },
    {
      id: 8,
      label: 'Links',
      comingSoon: false,
    },
    {
      id: 7,
      label: 'Settings',
      comingSoon: false,
    },
    // {
    //   id: 9,
    //   label: 'Flags',
    //   comingSoon: false,
    // },
    // {
    //   id: 10,
    //   label: 'Resources',
    //   comingSoon: false,
    // }
  ]

  function selectScreen(screen: number) {
    switch (screen) {
      case 0:
        return id && <PostsNew postRequired={'CLUB'} clubId={id} />

      case 1:
        return <ClubViewInformation
          infoClub={props.club}
          isClubAdvisor={isUserAdvisor}
          officers={props.officers}
          reloadClub={props.reloadClub}
          members={props.members}
          advisors={props.advisors}
          reloadOfficers={props.reloadOfficers}
          reloadAdvisors={props.reloadAdvisors}
        />;

      case 2:
        return <ClubEvents infoClub={props.club} isClubAdvisor={isUserAdvisor} />;

      case 3:
        return <ClubMembers
          infoClub={props.club}
          isClubAdvisor={isUserAdvisor}
          officers={props.officers}
          reloadClub={props.reloadClub}
          reloadMembers={props.reloadMembers}
          setInvitesAdded={setInvitesAdded}
          members={props.members}
          advisors={props.advisors}
          ChatServiceInstance={props.ChatServiceInstance} />;

      case 4:
        return <ClubFollowers infoClub={props.club} ChatServiceInstance={props.ChatServiceInstance} />;

      case 5:
        return <ClubViewRequests reloadClub={props.reloadClub} infoClub={props.club} isClubAdvisor={isUserAdvisor} />;

      case 6:
        return <ClubInvites infoClub={props.club} invitesAdded={invitesAdded} setInvitesAdded={setInvitesAdded} />;

      case 7:
        return <ClubSettings clubCode={props.clubCode} />;

      case 8:
        return <ClubLinks infoClub={props.club} reloadClub={props.reloadClub}/>;

      // case 9:
      //   return <ClubFlags />;
      //   break;
      // case 10:
      //   return <ClubResources />;
      //   break;

      default:
        return <></>;
    }
  }

  const handleTab = (id: number) => {
    if (id === 2) {
      if(process.env.REACT_APP_SHOW_EVENT !== 'true'){
        setSoon(true);
        return;
      }
      setSoon(false);
    }
    setSelected(id);
  }

  return (
    <CenterInsideWrap>
      <TitleView_Center>{props.club?.name || ''}</TitleView_Center>

      <TabsContainerClubs>
        {(soon) &&
          <ModalComingSoon
            title={'Coming Soon!'}
            onHide={setSoon}
            bottom={'calc(80vh - 40px)'}
            left={'50%'}
          />}
        <TabsCenterClubs>
          {tabsAll.map(t => (
            (t.id === 4 && props.club?.club_type === 'COMM') ? <></> :
              <TabCenterInside
                active={selected === t.id}
                onClick={() => handleTab(t.id)}
              >
                {t.label}
              </TabCenterInside>
          ))}
        </TabsCenterClubs>
        {isUserAdvisor &&
          <TabsCenterClubs>
            {tabsAdvisor.map(t => (
              (t.id === 5 && props.club?.club_type === 'COMM') ? <></> :
                <TabCenterInside
                  active={selected === t.id}
                  onClick={() => handleTab(t.id)}
                >
                  {t.label}
                </TabCenterInside>
            ))}
          </TabsCenterClubs>
        }
      </TabsContainerClubs>

      {selectScreen(selected)}
    </CenterInsideWrap>
  );
}

export default Index;

export const TitleView_Center = styled.div`
  font-family: 'Inter-bold';
  color: var(--t-blue);
  font-size: var(--font-size-xxxl);
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  gap: 10px;
`;

export const TabsContainerClubs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsCenterClubs = styled(TabsCenterInside)`
  border-bottom: ${({ isAdv = false }: { isAdv?: boolean }) => isAdv ? "1px solid var(--t-blue)" : "0px"};
`;