import { faArrowsRotate, faCircleCheck, faCheck, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ButtonSeeAll } from "../../HealerProfile/components";
import ButtonSpinner from "../ButtonSpinner";
import { cleanHTML, cleanStyleHTML } from "../../../helpers/letterHelper";
import SummaryGuide, { IGuideSummary, MockGuideSummary } from "../Healers/SummaryGuide";

export type JoinedType = 'PENDING' | 'MEMBER' | 'NO';

export interface isConnectionsInterface {
    joined: JoinedType;
    followed: boolean;
}

export interface GuideInfo {
    name: string;
    avatar?: string;
}

export interface GuideInfoExtended extends GuideInfo {
    username?: string;
    email?: string;
}

export interface CardInterface {
    id: string;
    name: string;
    description: string;
    joined: JoinedType;
    followed: boolean;
    guides: GuideInfoExtended[];
    members: number;
    followers: number;
    modalitiesIds: string[];
    orgId: string;
}

const CommunityCard = ({
    active,
    data,
    onClickButton,
    onActivation,
    loadingButtons = true,
    actionFinished,
    isSummary = false,
}: {
    active: boolean,
    data: CardInterface,
    onClickButton: (type: 'join' | 'follow', data: string, action: boolean) => void,
    onActivation: (id: string, action: boolean) => void,
    loadingButtons?: boolean,
    actionFinished?: boolean
    isSummary?: boolean
}) => {

    const [loadingJoin, setLoadingJoin] = useState<boolean>(false);
    const [loadingFollow, setLoadingFollow] = useState<boolean>(false);
    const [seeGuides, setSeeGuides] = useState<boolean>(false);
    const [seeMore, setSeeMore] = useState<boolean>(false);
    const [showGuideSummary, setShowGuideSummary] = useState<boolean>(false);
    const [summaryGuideInfo, setSummaryGuideInfo] = useState<IGuideSummary>({ ...MockGuideSummary });

    const handleClick = (type: 'join' | 'follow', action: boolean) => {
        switch (type) {
            case 'join':
                if (loadingButtons) setLoadingJoin(true);
                onClickButton(type, data.id, action);
                break;

            case 'follow':
                if (loadingButtons) setLoadingFollow(true);
                onClickButton(type, data.id, action);
                break;

            default:
                break;
        }
    }

    const handleShowGuideSummary = (guide: GuideInfoExtended) => {
        setSummaryGuideInfo({
            username: guide.username || '',
            name: guide.name || '',
            email: guide.email || '',
            avatar: guide.avatar || '',
        })
        setShowGuideSummary(true);
    }

    const getSliceGuides = () => {
        const guides = data.guides;
        const result = []
        for (let i = 0; i < guides.length; i++) {
            if (i === 2) {
                result.push(<FriendWhite>
                    <FontAwesomeIcon
                        icon={"plus"}
                        size="xs"
                    />
                    {guides.length - i}
                </FriendWhite>)
                break
            }
            result.push(<Friend
                key={guides[i].name}
                src={guides[i].avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
                onClick={() => handleShowGuideSummary(guides[i])}
            />)
        }
        return result;
    }

    const getJoinedName = (): string => {
        return data.joined === 'MEMBER' ? 'Member' : 'Requested';
    }

    useEffect(() => {
        if (data.joined) setLoadingJoin(false);
        if (data.followed) setLoadingFollow(false);
    }, [data])

    return (
        <Card theme={{ main: active ? 'active' : '', mains: seeGuides ? '250px' : '160px', isSummary }} key={data.id}>
            <CardItem>
                <TitleCard>{data.name}</TitleCard>
                <IconRotate
                    icon={faArrowsRotate}
                    color={"black"}
                    onClick={() => onActivation(data.id, true)}
                />
                <CenterWrap>
                    <MiddleCard>
                        <TypeCard>Guides:
                            <Friends>
                                {getSliceGuides()}
                            </Friends>
                        </TypeCard>
                        <CountM>
                            {/* {`${data.followers} followers | ${data.members} members`} */}
                            {`${data.members} members`}
                        </CountM>
                    </MiddleCard>
                    <FooterButtons>
                        {data.joined !== 'NO' ?
                            <ButtonWhite>
                                <IconButtonFtr
                                    icon={faCircleCheck}
                                    size='lg'
                                    color={"black"}
                                    style={{
                                        fontSize: '25px'
                                    }}
                                />
                                <IconButtonMbl
                                    icon={faCheck}
                                    size='lg'
                                    color={"black"}
                                    style={{
                                        fontSize: '10px'
                                    }}
                                />
                                {getJoinedName()}
                            </ButtonWhite>
                            :
                            <ButtonsJF>
                                <ButtonSpinner
                                    loading={loadingJoin}
                                    handleClick={() => handleClick('join', true)}
                                    text={'Join'}
                                    hasIcon={false}
                                />
                                <IconButtonMblJF
                                    icon={faPlus}
                                    size='lg'
                                    color={"var(--absolute-zero)"}
                                    style={{
                                        fontSize: '10px'
                                    }}
                                />
                            </ButtonsJF>

                        }
                        {/* {data.followed ?
                            <ButtonWhite>
                                <IconButtonFtr
                                    icon={'heart'}
                                    size='lg'
                                    color={"black"}
                                    style={{
                                        fontSize: '25px'
                                    }}
                                />
                                <IconButtonMbl
                                    icon={faCheck}
                                    size='lg'
                                    color={"black"}
                                    style={{
                                        fontSize: '10px'
                                    }}
                                />
                                Following
                            </ButtonWhite>
                            :
                            <ButtonsJF>
                                <ButtonSpinner
                                    loading={loadingFollow}
                                    handleClick={() => handleClick('follow', true)}
                                    text={'Follow'}
                                    hasIcon={false}
                                    color={"var(--simple-brown-light)"}
                                />
                                <IconButtonMblJF
                                    icon={faPlus}
                                    size='lg'
                                    color={"var(--absolute-zero)"}
                                    style={{
                                        fontSize: '10px'
                                    }}
                                />
                            </ButtonsJF>

                        } */}
                    </FooterButtons>
                </CenterWrap>
            </CardItem>
            <CardBack>
                <InfoCard theme={{ main: seeMore ? 'seeActive' : '' }}>
                    <TitleCardBack>{data.name}</TitleCardBack>
                    <IconRotate
                        icon={faArrowsRotate}
                        color={"black"}
                        onClick={() => { onActivation(data.id, false); setSeeGuides(false) }}
                    />
                    <DescCard
                        dangerouslySetInnerHTML={{ __html: cleanStyleHTML(data.description as string ?? '') }}
                    ></DescCard>

                    {/* {showSeeMore()} */}
                    {!seeGuides &&
                        <SeeAll onClick={() => setSeeGuides(true)}>
                            See all
                        </SeeAll>
                    }
                    <TypeCard>Guides</TypeCard>
                    <ScrollGuide>
                        {data.guides.map(guide => (
                            <GuideWrapper onClick={() => handleShowGuideSummary(guide)}>
                                <AvatarGuide src={guide.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                                <GuideName>{guide.name}</GuideName>
                            </GuideWrapper>
                        ))}
                    </ScrollGuide>
                </InfoCard>
                {(!seeMore && data.description.length > 155) &&
                    <SeeMore onClick={() => setSeeMore(true)}>
                        See more
                    </SeeMore>
                }
            </CardBack>

            {showGuideSummary && <SummaryGuide
                isOpen={showGuideSummary}
                close={() => setShowGuideSummary(false)}
                username={summaryGuideInfo.username}
                name={summaryGuideInfo.name}
                avatar={summaryGuideInfo.avatar}
                email={summaryGuideInfo.email}
            />}
        </Card>
    )
}

const InfoCard = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    height: 195px;
    overflow: hidden;

    &.seeActive{
        height: 0px;
        overflow-y: scroll;
        scroll-behavior: smooth;
        transition: all 0.3s ease;
    }
`

const SeeAll = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
    display: none;
    @media only screen and (max-width: 900px) {
        display: block;
        padding: 5px 0;
    }
`

const SeeMore = styled.div`
    font-family: 'Inter-Bold';
    font-size: 14px;
    line-height: 17px;
    color: var(--absolute-zero);
    text-align: left;
    cursor: pointer;
    display: block;
    padding: 5px 0;
    @media only screen and (max-width: 900px) {
        display: none;
    }
`

const ButtonsJF = styled.div`
    display: flex;
    justify-content: space-between;
    gap:5px;
    align-items: center;
`

const IconButtonMbl = styled(FontAwesomeIcon)`
    display: none;
    
    @media only screen and (max-width: 900px) {
        padding-right: 10px;
        display: block
    }
`;
const IconButtonMblJF = styled(FontAwesomeIcon)`
    display: none;
    
    @media only screen and (max-width: 900px) {
        display: block
    }
`;

const ScrollGuide = styled.div`
    flex-direction: column;
    gap: 5px;
    overflow: scroll;
    display: none;

    @media only screen and (max-width: 900px) {
        display: none;
    }
`;

const CenterWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;

const MiddleCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

const FooterButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    @media only screen and (max-width: 900px) {
        width: auto;
        position: static;
        bottom: 17px;
        right: 17px;
    }
`;

const GuideWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const ButtonWhite = styled.div`
    background-color: #ffffff;
    border: 1px solid #29313b;
    padding: 5px 0;
    border-radius: 8px;
    color: black;
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 900px) {
        font-weight: 100;
        font-family: 'Inter';
        border: 0px;
        font-size: 16px;
        color: black;
        justify-content: flex-end;
    }
`

const TitleCard = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align:left;
`

const TitleCardBack = styled(TitleCard)`
    margin-bottom: 15px;
`;

const CountM = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
`

const Friends = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 18px;
`

const Friend = styled.img`
    border: 1px solid white;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
    object-fit: cover;
    cursor: pointer;
`

const FriendWhite = styled.div`
    border: 1px solid white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    margin-left: -8px;
    background-color: white;
    border: 1px solid black;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
`

const AvatarGuide = styled(Friend)`
    margin-left: 0px;
    object-fit: cover;
`;

const DescCard = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--default-dark);
    text-align: left;
`

const GuideName = styled(DescCard)`
    margin-top: 0px;
`;

export const CardItem = styled.div`
    width: 203px;
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    color: black;
    text-align: center;
    position: relative;
    transform: perspective(500px) rotateY(0deg);
`

export const CardBack = styled.div`
    border: 1px solid var(--mountain-mist);
    background-color: white;
    border-radius: 10px;
    padding: 17px;
    color: black;
    transform: perspective(500px) rotateY(180deg);
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
`

export const Card = styled.div.attrs(
    props => ({
        className: props.theme.main,
    })
)`
    position: relative;
    width: ${props => props.theme.isSummary ? '195px' : '100%'};
    min-height: 250px;
    min-width: 250px;
    height: ${props => props.theme.mains || '160px'};
    
    & > div{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        backface-visibility: hidden;
        transition: 0.5s;
        flex-direction: column;

        gap: 5px;
        justify-content: space-between;
    }

    &.active ${CardItem}{
        transform: perspective(500px) rotateY(180deg)
    }

    &.active ${CardBack}{
        transform: perspective(500px) rotateY(360deg);
    }

    &.active ${CardBack} ${ScrollGuide}{
        display: flex;
    }

    &.active .seeActive{
        height: 245px;
    }

    @media only screen and (max-width: 900px) {
        width: 100%;
        height: ${props => props.theme.mains || '160px'};

        &.active ${ScrollGuide}{
            display: flex;
        }
    }

`

const TypeCard = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 10px 0;

    & span{
        color: black;
        font-weight: bold;
        font-family: 'Inter-Bold';
    }
`

const IconButtonFtr = styled(FontAwesomeIcon)`
    padding: 0 10px;

    @media only screen and (max-width: 900px) {
        display: none
    }
`;


const IconRotate = styled(FontAwesomeIcon)`
    position: absolute;
    top: 11px;
    font-size: 15px;
    right: 11px;
    background-color: var(--simple-gray-2);
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
`;

export default CommunityCard