import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { DividerLine } from "../SuperCenter/Articles/ViewArticle";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ContextSuccess } from "../../Context";
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import { RootState } from "../../store/store";
import { deliveryEvent, deliverySimple, rsvp } from "../BaseComponents/Events/EventCard";
import { Loader } from "../BaseComponents/Loader";
import IResponseError from "@vibe/sdk/dist/interfaces/IResponseError";

export interface OptionRsvp {
    id: number
    label: string
    handle: () => void
    enabled: boolean
}

const SelectorRSVP = ({
    reload,
    id,
    delivery,
    rsvpSelector,
    name,
    amountOnline,
    amountInPerson,
    isFullOnline,
    isFullInPerson,
    orgId,
}: {
    reload: () => void
    id: string
    delivery: deliveryEvent
    rsvpSelector: rsvp
    name: string,
    amountOnline: number
    amountInPerson: number
    isFullOnline: boolean
    isFullInPerson: boolean
    orgId?: string;
}) => {
    const currentSuccess = useContext(ContextSuccess);

    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
    const username = useSelector((state: RootState) => state.getUser.userInfo?.username);

    const [showOptionsInterested, setShowOptionsInterested] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [meanRSVP, setMeanRSVP] = useState<rsvp>(rsvpSelector);

    const handleClick = () => {
        if (!isLoggedIn) {
            currentSuccess?.setLoginModal(true, false, undefined, undefined, orgId);
            return;
        }

        setShowOptionsInterested(!showOptionsInterested);
    }

    const handleInterested = () => {
        if (isLoggedIn && username) {
            setLoading(true);
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Events.interestedInEvent(id, username, dataSDK.token);
                })
                .then((response) => {
                    if (response.statusCode === 201) {
                        reload();
                        currentSuccess?.setSuccessData(true, true, `Response sent`);
                    } else {
                        currentSuccess?.setSuccessData(true, true, 'Try again');
                    }
                })
                .catch((error) => {
                    console.log('error interested', error)
                    currentSuccess?.setSuccessData(false, true, 'Try again');
                })
                .finally(() => setLoading(false))
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const handleNotInterested = () => {
        if (isLoggedIn && username) {
            setLoading(true);
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Events.notInterestedInEvent(id, username, dataSDK.token);
                })
                .then((response) => {
                    if (response.statusCode === 201) {
                        reload();
                        currentSuccess?.setSuccessData(true, true, `Response sent`);
                    } else {
                        currentSuccess?.setSuccessData(true, true, 'Try again');
                    }
                })
                .catch((error) => {
                    console.log('error not interested', error)
                    currentSuccess?.setSuccessData(false, true, 'Try again');
                })
                .finally(() => setLoading(false))
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const handleGoing = (type: deliverySimple, idRSVP: number) => {
        if (meanRSVP === 'Going In Person' || meanRSVP === 'Going Online') {
            currentSuccess?.setSuccessData(false, true, 'Ops!', " You are already registered for the event");
            return;
        }

        if (isFull(idRSVP)) return;

        if (isPaid(idRSVP)) {
            currentSuccess?.openModalPurchaseEvent(
                id,
                name,
                type,
                type === 'online' ? amountOnline : amountInPerson,
                reload);
            return;
        }

        if (isLoggedIn && username) {
            setLoading(true);
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Events.RSVP(id, username, type, dataSDK.token);
                })
                .then((response) => {
                    if (response.statusCode === 201) {
                        reload();
                        setMeanRSVP(type === 'online' ? 'Going Online' : 'Going In Person');
                        currentSuccess?.setSuccessData(true, true, `Response sent`);
                    }
                    else if (response.statusCode === 400) {
                        // Error when is full
                        currentSuccess?.setSuccessData(false, true, 'Ops!', (response.body as IResponseError).error);
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, 'Try again', (response.body as IResponseError).error);
                    }
                })
                .catch((error) => {
                    console.log('error RSVP', error)
                    currentSuccess?.setSuccessData(false, true, 'Try again');
                })
                .finally(() => setLoading(false))
        }
        else {
            currentSuccess?.setLoginModal(true, false);
        }
    }

    const isFull = (RSVPId: number) => {
        if (RSVPId === 1) return isFullOnline;
        return isFullInPerson;
    }

    const isDisabled = (): boolean => {
        if (meanRSVP === 'Going In Person' || meanRSVP === 'Going Online') return true;
        return false;
    }

    const isPaid = (RSVPId: number) => {
        if (RSVPId === 1) return amountOnline > 0;
        return amountInPerson > 0;
    }

    const GoingRSVPOptions: OptionRsvp[] = [{
        id: 1,
        label: 'Going Online',
        handle: () => handleGoing('online', 1),
        enabled: delivery !== 'inPerson',
    },
    {
        id: 2,
        label: 'Going In Person',
        handle: () => handleGoing('inPerson', 2),
        enabled: delivery !== 'online',
    }];

    const MaybeRSVPOptions: OptionRsvp[] = [{
        id: 3,
        label: 'Interested',
        handle: handleInterested,
        enabled: true,
    },
    {
        id: 4,
        label: 'Not Interested',
        handle: handleNotInterested,
        enabled: true,
    }];

    useEffect(() => {
        setMeanRSVP(rsvpSelector);
    }, [rsvpSelector])

    return (
        <ButtonSeeAll onClick={handleClick}>
            {loading ? <Loader color={'white'} /> : rsvpSelector}
            <IconChevronRight
                icon={faChevronDown}
                size='lg'
                color={"gray"}
                style={{
                    fontSize: '18px'
                }}
            />
            {showOptionsInterested &&
                <OptionsList>
                    {GoingRSVPOptions.map(Going => (
                        Going.enabled ? (
                            <OptionList onClick={Going.handle} isFull={isDisabled()}>
                                {Going.label}

                                {isFull(Going.id) ? (
                                    <div>Full</div>
                                ) : (
                                    isPaid(Going.id) && (
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            size='sm'
                                            color='black'
                                        />
                                    ))}
                            </OptionList>
                        ) : (<></>)
                    ))}

                    <DividerLine />

                    {MaybeRSVPOptions.map(maybe => (
                        <OptionList onClick={maybe.handle}>
                            {maybe.label}
                        </OptionList>
                    ))}
                </OptionsList>
            }
        </ButtonSeeAll>
    )
}

export default SelectorRSVP;

const ButtonSeeAll = styled.div`
    padding: 4px 24px;
    width: 100%;
    height: 40px;
    border: 1px solid var(--t-blue);
    border-radius: 8px;
    font-family: 'Inter-Bold';
    font-size: 16px; 
    color: var(--t-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`;

const IconChevronRight = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
`;

const OptionsList = styled.div`
    position: absolute;
    background-color: white;
    width: calc(100% + 2px);
    z-index: 100;
    left: -1px;
    top: 33px;
    border: 1px solid #bfbfbf;
    padding: 7px 0px;
    border-radius: 0 0 9px 9px;
`
const OptionList = styled.div`
    font-family: 'Inter';
    padding: 8px 15px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    cursor: ${({ isFull = false }: { isFull?: boolean }) => isFull ? 'default' : 'pointer'};
    opacity: ${({ isFull = false }: { isFull?: boolean }) => isFull ? '0.5' : '1'};

    &:hover{
        background-color: ${({ isFull = false }: { isFull?: boolean }) => isFull ? 'transparent' : '#ebe8e6'};
    }

`;