import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown, faCalendar, faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row1, Row2, Imp } from "../Website/Form"
import { useDispatch, useSelector } from 'react-redux';
import useActiveCountry, { CountryOption } from '../../hooks/Locations/useActiveCountry';
import useGenders, { GenderOption } from '../../hooks/users/useGenders';
import { StateOption } from '../../hooks/Locations/useActiveState';
import { BaseFieldState, getFieldState } from '../../components/BaseComponents/utils';
import { ISignUpUserBody, genderType } from '@vibe/sdk/dist/interfaces/AccessManager/signUpUser';
import ErrorField from '../../components/BaseComponents/ErrorField';
import { formatGender, isValidBirthdate, isValidUsername, validatePassword, isValidZipCode } from '../../helpers/userHelper';
import EmailSent from '../../components/Website/EmailSent';
import PasswordToolTip from '../../components/Website/PasswordToolTip';
import ButtonSpinner from '../../components/BaseComponents/ButtonSpinner';
import DatePicker from "react-datepicker";
import { createUserByInvite, getInvitesByToken } from '../../store/actions/inviteActions';
import { ContextSuccess } from '../../Context';
import SelectorCountry from '../../components/BaseComponents/Location/SelectorCountry';
import { CodeInfo } from '../SignUpCode/hooks';
import { rolesType } from '@vibe/sdk/interfaces/AccessManager/signUpUser';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { Form_signup } from './SignupHealer';
import { PostalCodeClean } from '@vibe/sdk/dist/interfaces/Roster/postalCodes';

const SignupSeeker = ({
    codeInfo,
}: {
    codeInfo?: CodeInfo
}) => {
    const { token, code, orgId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => console.log({ token, code, orgId }), [token, code, orgId])

    const { GendersReactSelect } = useGenders();

    const currentSuccess = useContext(ContextSuccess);
    const datepickerRef = useRef<any>(null);

    const invite = useSelector((state: any) => state.getInvites.inviteByToken);

    const [showOptions, setShowOptions] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [loadingSignup, setLoadingSignup] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

    const [searchCountry, setSearchCountry] = useState('');

    const [userFirstName, setUserFirstName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userLastName, setUserLastName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userEmail, setUserEmail] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userBirthday, setUserBirthday] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userGender, setUserGender] = useState<BaseFieldState<GenderOption>>(getFieldState({ label: 'Select a gender', value: 'P' }));
    const [username, setUsername] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userPassword, setUserPassword] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userCountry, setUserCountry] = useState<BaseFieldState<CountryOption>>(getFieldState({ label: '', value: '' }));
    const [userZip, setUserZip] = useState<BaseFieldState<string>>(getFieldState(''));
    const [userToken, setUserToken] = useState<string>('');
    const [parentOrgId, setParentOrgId] = useState<string>('');

    const [zipcodeLengths, setZipcodeLengths] = useState<PostalCodeClean>({ maxLength: 5, minLength: 5 })

    const clickCalendar = () => {
        if (datepickerRef) {
            datepickerRef.current.setFocus(true)
        }
    }

    const cleanFields = () => {
        setUserCountry(getFieldState({ label: '', value: '' }));
        setUserZip(getFieldState(''));
        setUserFirstName(getFieldState(''));
        setUserLastName(getFieldState(''));
        setUserEmail(getFieldState(''));
        setUserBirthday(getFieldState(''));
        setUserGender(getFieldState({ label: 'Select a gender', value: 'P' }));
        setUsername(getFieldState(''));
        setUserPassword(getFieldState(''));
        setUserToken('');
        setParentOrgId('');
    }

    const updateInvite = () => {
        setUserFirstName({
            ...userFirstName,
            value: invite.firstName || ''
        });
        setUserLastName({
            ...userLastName,
            value: invite.lastName || ''
        });
        setUserEmail({
            ...userEmail,
            value: invite.email || ''
        });
        setUsername({
            ...username,
            value: invite.username || ''
        });
        if (invite.gender) {
            setUserGender({
                ...userGender,
                value: {
                    label: formatGender(invite.gender || ''),
                    value: invite.gender || ''
                }
            });
        }
        setParentOrgId(invite.org_id || '');
    }

    const checkData = (): boolean => {
        let hasErrors = false;

        if (!userFirstName.value || userFirstName.value?.length === 0) {
            hasErrors = true;
            setUserFirstName({
                ...userFirstName,
                showError: true,
                error: 'First name is required'
            })
        } else {
            setUserFirstName({
                ...userFirstName,
                showError: false,
                error: ''
            })
        }

        if (!userLastName.value || userLastName.value?.length === 0) {
            hasErrors = true;
            setUserLastName({
                ...userLastName,
                showError: true,
                error: 'Last name is required'
            })
        } else {
            setUserLastName({
                ...userLastName,
                showError: false,
                error: ''
            })
        }

        if (!userEmail.value || userEmail.value?.length === 0) {
            hasErrors = true;
            setUserEmail({
                ...userEmail,
                showError: true,
                error: 'Email is required'
            })
        } else {
            setUserEmail({
                ...userEmail,
                showError: false,
                error: ''
            })
        }

        if (!userBirthday.value || userBirthday.value?.length === 0) {
            hasErrors = true;
            setUserBirthday({
                ...userBirthday,
                showError: true,
                error: 'Date of Birth is required'
            })
        } else {
            setUserBirthday({
                ...userBirthday,
                showError: false,
                error: ''
            })
        }

        if (!isValidBirthdate(userBirthday.value)) {
            hasErrors = true;
            setUserBirthday({
                ...userBirthday,
                showError: true,
                error: 'You must be at least 18 years old'
            })
        } else {
            setUserBirthday({
                ...userBirthday,
                showError: false,
                error: ''
            })
        }

        if (
            !userGender.value?.label || userGender.value?.label.length === 0 ||
            !userGender.value?.value || userGender.value?.value.length === 0
        ) {
            hasErrors = true;
            setUserGender({
                ...userGender,
                showError: true,
                error: 'Gender is required'
            })
        } else {
            setUserGender({
                ...userGender,
                showError: false,
                error: ''
            })
        }

        const errorUsername = isValidUsername(username.value || '');
        if (errorUsername !== '') {
            hasErrors = true;
            setUsername({
                ...username,
                showError: true,
                error: errorUsername
            })
        } else {
            setUsername({
                ...username,
                showError: false,
                error: ''
            })
        }

        const errorPassword = validatePassword(userPassword.value || '');
        if (errorPassword.lastError !== '') {
            hasErrors = true;
            setUserPassword({
                ...userPassword,
                showError: true,
                error: errorPassword.lastError === 'Password is required' ?
                    errorPassword.lastError : `Password needs ${errorPassword.lastError.toLowerCase()}`
            })
        } else {
            setUserPassword({
                ...userPassword,
                showError: false,
                error: ''
            })
        }

        if (
            !userCountry.value?.value || userCountry.value?.value.length === 0 ||
            !userCountry.value?.label || userCountry.value?.label.length === 0
        ) {
            hasErrors = true;
            setUserCountry({
                ...userCountry,
                showError: true,
                error: 'Country is required'
            })
        } else {
            setUserCountry({
                ...userCountry,
                showError: false,
                error: ''
            })
        }

        const errorZipCode = isValidZipCode(userZip.value || '', zipcodeLengths, userCountry.value?.label);
        if (errorZipCode !== '') {
            hasErrors = true;
            setUserZip({
                ...userZip,
                showError: true,
                error: errorZipCode
            })
        } else {
            setUserZip({
                ...userZip,
                showError: false,
                error: ''
            })
        }

        return hasErrors;
    }

    const handleSignup = () => {
        setLoadingSignup(true);

        if (checkData()) {
            setLoadingSignup(false);
            return;
        }

        const toSend: ISignUpUserBody = {
            user: {
                email: userEmail.value || '',
                givenName: userFirstName.value || '',
                familyName: userLastName.value || '',
                birthdate: userBirthday.value || '',
                gender: userGender.value?.value as genderType || 'P',
                userName: username.value || '',
                password: userPassword.value || '',
                zipcode: userZip.value || '',
                countryCode: userCountry.value?.value || '',
                organizations: [parentOrgId || ''],
                roles: [['SEEKER']],
                preferredLanguage: 'english',
                clubs: [],
                clubsAdvisor: [],
                clubsFollower: [],
                phoneNumber: 30000000000,
            },
        }

        if (codeInfo) signup_Code(toSend);
        if (orgId) signup_Org(toSend);
        else {
            dispatch<any>(createUserByInvite(userToken, toSend))
                .then((results: boolean) => {
                    if (results) {
                        cleanFields();
                        setEmailSent(true);
                    }
                    else {
                        currentSuccess?.setSuccessData(false, true, "Error adding user org.");
                    }
                })
                .catch((error: any) => {
                    currentSuccess?.setSuccessData(false, true, "Error creating user.", error);
                    console.log(error)
                })
                .finally(() => setLoadingSignup(false));
        }

    }

    const signup_Code = (toSend: ISignUpUserBody) => {
        if (!codeInfo) return;
        toSend.user.roles = [['SEEKER']];
        toSend.user.organizations = [codeInfo.clubInfo?.org_id || ''];
        toSend.user.organizationsNames = [];

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.signUpUserCode(
                    { user: toSend.user } as ISignUpUserBody,
                    { code },
                    dataSDK.token);
            })
            .then((response) => {
                if (response.statusCode === 201) {
                    cleanFields();
                    setEmailSent(true);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error creating user by code.");
                    return Promise.resolve(false);
                }
            })
            .catch((error: any) => {
                currentSuccess?.setSuccessData(false, true, "Error creating user.", error);
                console.log(error)
            })
            .finally(() => setLoadingSignup(false))
    }

    const signup_Org = (toSend: ISignUpUserBody) => {
        if (!orgId) return;
        toSend.user.roles = [['SEEKER']];
        toSend.user.organizations = [orgId];
        toSend.user.organizationsNames = [];

        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.AccessManager.createUser(
                    { user: toSend.user } as ISignUpUserBody,
                    dataSDK.token,
                );
            })
            .then((response) => {
                if (response.statusCode === 201) {
                    cleanFields();
                    setEmailSent(true);
                }
                else {
                    currentSuccess?.setSuccessData(false, true, "Error creating user by code.");
                    return Promise.resolve(false);
                }
            })
            .catch((error: any) => {
                currentSuccess?.setSuccessData(false, true, "Error creating user.", error);
                console.log(error)
            })
            .finally(() => setLoadingSignup(false))
    }

    const getMaxDate = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        return date;
    }

    const handleOnBlur = (e: any) => {
        const date = new Date(e.target.value);
        const date2 = new Date(userBirthday?.value || '');

        if (date.toDateString() !== date2.toDateString()) {
            currentSuccess?.setSuccessData(false, true, 'You must be 18 years old or higher');
        }
    };

    useEffect(() => {
        if (token) {
            setUserToken(token);
            dispatch(getInvitesByToken({ token: token }));
        }
    }, [token]);

    useEffect(() => {
        if (invite.status === 'USED') {
            navigate("/");
            currentSuccess?.setSuccessData(false, true, 'Invite already used');
        }
        else updateInvite();
    }, [invite]);

    useEffect(() => {
        if (userCountry.value?.value) {
            getDataSDK()
                .then((dataSDK: getDataSDKResponse) => {
                    return dataSDK.sdk.Roster.postalCodes(userCountry.value?.value, dataSDK.token)
                })
                .then((results) => {
                    if (results.body && 'maxLength' in results.body) {
                        setZipcodeLengths(results.body)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [userCountry])

    return (
        <>
            {!emailSent ? (
                <Form_signup>
                    <TitleForm>Please, register</TitleForm>
                    {codeInfo && <SubTitleForm>{`You are registering to ${codeInfo.clubInfo?.club_name} of ${codeInfo.clubInfo?.org_name}`}</SubTitleForm>}
                    <Inputs>
                        <Row2>
                            <Label>First name<Imp>*</Imp></Label>
                            <Input
                                value={userFirstName.value}
                                onChange={(e) => setUserFirstName({ ...userFirstName, value: e.currentTarget.value })}
                            />
                            {(userFirstName.showError) && (<ErrorField error={userFirstName.error} />)}
                        </Row2>
                        <Row2>
                            <Label>Last name<Imp>*</Imp></Label>
                            <Input
                                value={userLastName.value}
                                onChange={(e) => setUserLastName({ ...userLastName, value: e.currentTarget.value })}
                            />
                            {(userLastName.showError) && (<ErrorField error={userLastName.error} />)}
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row1>
                            <Label>Email<Imp>*</Imp></Label>
                            {(invite.email) ? (
                                <DisabledInput>{userEmail.value}</DisabledInput>
                            ) : (
                                <Input
                                    value={userEmail.value}
                                    onChange={(e) => setUserEmail({ ...userEmail, value: e.currentTarget.value })}
                                />
                            )}
                            {(userEmail.showError) && (<ErrorField error={userEmail.error} />)}
                        </Row1>
                    </Inputs>
                    <Inputs>
                        <Row2>
                            <Label>Date of Birth<Imp>*</Imp></Label>
                            <InputIcon theme={{ main: 'datePickerYearMonth' }}>
                                <DatePicker
                                    ref={datepickerRef}
                                    placeholderText={'MM  /  DD  /  YYYY'}
                                    selected={userBirthday.value ? new Date(userBirthday.value || '') : null}
                                    onChange={(date: Date) => setUserBirthday({
                                        ...userBirthday,
                                        value: date.toLocaleDateString()
                                    })}
                                    onBlur={handleOnBlur}
                                    maxDate={getMaxDate()}
                                    className="inputDatePicker"
                                    showYearDropdown
                                    showMonthDropdown
                                />
                                <StyledFontAwesomeIcon
                                    icon={faCalendar}
                                    size='lg'
                                    onClick={clickCalendar}
                                />
                            </InputIcon>
                            {(userBirthday.showError) && (<ErrorField error={userBirthday.error} />)}
                        </Row2>
                        <Row2>
                            <Label>Gender<Imp>*</Imp></Label>

                            <Select onClick={() => setShowOptions(!showOptions)}>
                                {userGender.value?.label !== '' ? userGender.value?.label : 'Select an option'}
                                <StyledFontAwesomeIcon
                                    icon={faChevronDown}
                                    size='sm'
                                />
                            </Select>
                            {showOptions &&
                                <Items>
                                    {GendersReactSelect.map((gender, index) => (
                                        <Item onClick={() => {
                                            setShowOptions(!showOptions)
                                            setUserGender({
                                                ...userGender,
                                                value: gender
                                            })
                                        }
                                        }
                                            key={index}>
                                            {gender.label}
                                        </Item>
                                    ))}
                                </Items>
                            }
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row2>
                            <Label>Country<Imp>*</Imp></Label>
                            <SelectorCountry
                                country={userCountry}
                                setCountry={setUserCountry}
                                searchCountry={searchCountry}
                                setSearchCountry={setSearchCountry}
                                onlyActive={false}
                            />
                        </Row2>
                        <Row2>
                            <Label>Zip / Postal Code<Imp>*</Imp></Label>
                            <Input
                                value={userZip.value}
                                onChange={(e) => setUserZip({ ...userZip, value: e.currentTarget.value })}
                            />
                            {(userZip.showError) && (<ErrorField error={userZip.error} />)}
                        </Row2>
                    </Inputs>
                    <Inputs>
                        <Row2>
                            <Label>Username<Imp>*</Imp></Label>
                            <Input
                                value={username.value}
                                onChange={(e) => setUsername({ ...username, value: e.currentTarget.value })}
                                autoComplete={"unInput"}
                            />
                            {(username.showError) && (<ErrorField error={username.error} />)}
                        </Row2>
                        <Row2>
                            <Label>Password<Imp>*</Imp></Label>
                            <InputIcon_Seeker
                                onClick={() => setShowPasswordTooltip(true)}
                            >
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    value={userPassword.value}
                                    onChange={(e) => setUserPassword({ ...userPassword, value: e.currentTarget.value })}
                                    autoComplete={"new-password"}
                                />
                                <StyledFontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    size='sm'
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </InputIcon_Seeker>
                            {(showPasswordTooltip) && (<PasswordToolTip password={userPassword.value || ''} setShowModal={setShowPasswordTooltip} />)}
                            {(userPassword.showError) && (<ErrorField error={userPassword.error} />)}
                        </Row2>
                    </Inputs>
                    <TextTerms>
                        If you are already part of vibe, <LoginBtn onClick={() => currentSuccess?.setLoginModal(true, true, token, true)}>log in</LoginBtn>
                    </TextTerms>
                    <TextTerms>
                        By clicking Sign up, you agree to our <NavLink to="/terms">Terms of Service</NavLink> and <NavLink to="/privacy">Privacy Policy</NavLink>
                    </TextTerms>
                    <WrapperButton>
                        <ButtonSpinner
                            loading={loadingSignup}
                            handleClick={handleSignup}
                            text={'Sign Up'}
                            hasIcon={true}
                            icon={'arrow-right'}
                        />
                    </WrapperButton>
                </Form_signup>
            ) : (
                <EmailSent type={"seeker"} from={"individual"} />
            )}
        </>
    )
}
const WrapperCenter = styled.div`
    display: flex;
    justify-content: center;
`;

const WrapperButton = styled.div`
    margin-top: 10px;
    width: 100%;
`;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
`;

const ContainerForm = styled.div`
    width: calc(100% - 410px);
    background-color: var(--background-light);
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 135px;
    align-items: center;
    overflow-y: scroll;
`;

const ContainerInfo = styled.div`
    width: 410px;
    background-color: var(--simple-blue-dark);
    height: 100vh;
    text-align: left;
    color: white;
    padding: 33px 135px 33px 33px;
    background-image: url(../slideSignUp.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
`;

const Title = styled.h1`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 35px;
`;

const Subtitle = styled.h2`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    padding: 20px 0;
`;

const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
`;

const Form = styled.div`
   width: 410px;
   margin-top: 50px;
`;

const TitleForm = styled.div`
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
`;

export const Logo = styled.div`
    width: 100%;
    height: 50px;
    background-image: url(../logoVibe.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 32px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;

const TextTerms = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%; 
    text-align: initial;
`

const NavLink = styled(Link)`
  text-decoration: underline;
`;

const StyledFontAwesomeIconSend = styled(FontAwesomeIcon)`
    color: white;
    margin-left: 15px;
    font-size: 16px;
`;

const ButtonSend = styled.div`
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    border-radius: 11px;
    background-color: var(--simple-blue-dark-2);
    border: 0;
    color: white;
    font-weight: 700;
    margin-top: 25px;
    margin-right: 26px;
    position: relative;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`
export const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

export const DisabledInput = styled.div`
    width: 100%;
    height: 40px;
    border-radius: 9px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
    background-color: rgba(0,0,0,0.09);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
`;

const SubTitleForm = styled(TitleForm)`
    font-weight: 400;
    font-family: 'Inter';
    font-size: 16px;
`;

const LoginBtn = styled.span`
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
`;

const InputIcon = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;
    
    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

export const InputPlaceholder = styled.input`
    outline: none;
    width: 100%;
    height: 38px;
    border-radius: 9px;
    border: 0;
    padding: 0 0px 0 20px;
    font-size: 15px;
    font-family: 'Inter';
`;

const Select = styled.div`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial;
    border: 1px solid var(--simple-gray);
`

const Label = styled.div`    
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`
const Inputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }
`

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 54px;
    z-index: 1;
`

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const LoginTitle = styled.p`
    font-size: 24px;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`;

const Select_Input = styled.input`
    position: relative;
    outline: none;
    width: 100%;
    height: 45px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial;
    font-size: 16px;
    border: 1px solid var(--simple-gray);
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder{
        color: var(--simple-gray);
        font-family: Arial;
    }
`;

const IconDropdownInput = styled(StyledFontAwesomeIcon)`
    position: absolute;
    z-index: 2;
    top: 55%;
    left:78%;
`;

const InputIcon_Seeker = styled(InputIcon)`
    position: relative;
`;

export default SignupSeeker;