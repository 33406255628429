import SignupSeeker from "./SignupSeeker";
import { Container_Signup, ContainerForm_Signup, ContainerInfo_Signup, SubtitleSignupWrappers, TitleSignupWrappers } from "./WrapBusiness";

const WrapSeekerNoInvite = () => {
    return (
        <Container_Signup>
            <ContainerForm_Signup>
                <SignupSeeker />
            </ContainerForm_Signup>
            <ContainerInfo_Signup>
                <TitleSignupWrappers>Welcome!</TitleSignupWrappers>
                <SubtitleSignupWrappers>Please complete your registration to begin your journey.<br />Your personal information will not be shared with anyone, but online communities are more positive and safer when people register with their real identity.</SubtitleSignupWrappers>
            </ContainerInfo_Signup>
        </Container_Signup >
    )
}

export default WrapSeekerNoInvite;