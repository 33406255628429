import "./styles.scoped.css";
import React, { useEffect, useState, useContext } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import ModalInviteAttendess from './ModalInviteAttendees';
import { useSelector } from "react-redux";
import { getEventRSVP, getEventInterested, getCheckEvent, getEventNotInterested } from "../../store/actions/eventActions";
import { applyTimeOffsetForLocal } from "../../helpers/dateHelper";
import { getClubsImAdv } from "../../store/actions/clubActions";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER, VIOLET } from "../ClubIconsNavBar";
import { cleanHTML, cleanStyleHTML, onlyFirstLetterCapitalized } from "../../helpers/letterHelper";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { ContextSuccess } from "../../Context";
import { ClubCenterWrap } from "../ClubViewInformation";
import styled from "styled-components";
import { IconClicker } from "../Home/HeaderHome/styles";
import { GetEventModeratorsResponse } from "@vibe/sdk/dist/interfaces/Events/getEventModerators/getEventModeratorsResponse";
import {
  AvatarCreatorHome, AvatarHealerCardHome, CreatorNoAvatar, DividerLineHealerHome,
  NoAvatarHealerCardHome
} from '../../components/BaseComponents/HomeCards/styles';
import { faTimes, faCopy, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { EventCardInterface, LabelCost, LabelFree, mockEventCard } from '../../components/BaseComponents/Events/EventCard';
import { formatCompactNumber } from '../../helpers/numberHelper';
import { getColorsInitials, getInitials } from '../../helpers/letterHelper';
import { getDataSDK, getDataSDKResponse } from '../../helpers/backendHelper';
import { ClickableIcon } from '../../components/SuperCenter/SuperAdminComponents/basicForSACenter';
import IResponseError from '@vibe/sdk/dist/interfaces/IResponseError';
import { mapEventToCard } from '../../hooks/Events/useEventsPanel';
import { EventsResponse, IGetEvent } from '@vibe/sdk/dist/interfaces/Events/getEvent';
import SelectorRSVP from "../Events/SelectorRSVP";
import OnSale from "../BaseComponents/Events/OnSale";
import ModalCreateEvents from "../Events/ModalCreateEvents";

function Index({
  moderators,
  getEventModerators,
  setModerators,
  reloadEvent
}: {
  moderators: GetEventModeratorsResponse[]
  getEventModerators: () => void
  setModerators: (data: GetEventModeratorsResponse[]) => void
  reloadEvent: () => void
}) {
  const { t, i18n } = useTranslation();
  const currentSuccess = useContext(ContextSuccess);
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userRoles = useSelector((state: RootState) => state.getUser.userRoles)
  const user = useSelector((state: RootState) => state.getUser.userInfo);
  const event = useSelector((state: RootState) => state.getEvent.event);
  const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);
  const username = useSelector((state: RootState) => state.getUser.userInfo?.username || '');

  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  const timezones = useSelector(
    (state: RootState) =>
      state.getLocation.timezones || [
        {
          continent: "America",
          city: "Bogota",
          country: "CO",
          DST: "-05:00",
          STD: "-05:00",
        },
      ]
  );
  const [eventLoaded, setEventLoaded] = useState<EventCardInterface>({ ...mockEventCard });

  const perfectTimeSplit = (): string[] | undefined => {
    if (event.start && event.timezone) {
      return applyTimeOffsetForLocal(
        event.start.split('-'), event.timezone.split('#')[2].split(':'))
    }
    return ['0', '0', '0', '0', '0'];
  }

  const getCurrentTimezone = (): string => {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const continent = (zone || '').split("/")[0];
    const city = (zone || '').split("/")[1];
    const tz = timezones.find(
      (item: any) => item.continent === continent && item.city === city
    );

    if (tz) return `${tz.continent}#${tz.city}#${tz.STD}`;
    else return "America#Bogota#-05:00";
  };

  const [canEditEvent, setCanEditEvent] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [timezone, setTimezone] = useState(
    event.timezone !== "" ? event.timezone : getCurrentTimezone()
  );
  const [eventUsersRelatedCount, setEventUsersRelatedCount] = useState<{
    RSVP: number | undefined, I: number | undefined, NI: number | undefined
  }>({
    RSVP: undefined,
    I: undefined,
    NI: undefined
  })

  useEffect(() => {
    canEdit();
  }, [event, user]);

  useEffect(() => {
    canEdit();
  }, []);

  useEffect(() => {
    Backend(
      MicroServices.Events,
      `/event/${id}/related-users`)
      .then((response) => response.json())
      .then((results) => {
        setEventUsersRelatedCount({
          RSVP: results.RSVP ? results.RSVP.length : 0,
          I: results.I ? results.I.length : 0,
          NI: results.NI ? results.NI.length : 0,
        })
      })
      .catch((e) => {
        setEventUsersRelatedCount({
          RSVP: undefined,
          I: undefined,
          NI: undefined
        })
        console.log(e)
      })
  }, [id])

  const canEdit = () => {
    // Edit by creator
    if (event.creator === user?.username) {
      setCanEditEvent(true);
      return;
    }

    // Ensure canEditEvent won't stay active
    setCanEditEvent(false);
  }

  function handleCloseModal() {
    setShowModal(false)
  };

  const getHealerNoAvatar = () => {
    const num = [...eventLoaded.guide.name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
    const colors = getColorsInitials(num);
    return <CreatorNoAvatar
      background_color={colors.background}
      text_color={colors.color}
    >
      {getInitials(eventLoaded.guide.name)}
    </CreatorNoAvatar>;
  }

  const getDeliveryInPerson = () => {

    if (eventLoaded.delivery === 'online') return <></>;

    return (
      <RowFlex2>
        <FontAwesomeIcon
          icon='location-dot'
          size='sm'
          color='var(--t-blue)'
        />
        <TitlePost
          theme={{ main: '' }}
          isEvent={true}
          dangerouslySetInnerHTML={{ __html: eventLoaded.locationComplete || '' }}
        />
        {eventLoaded.fee_person > 0 ? <LabelCost>${eventLoaded.fee_person}</LabelCost> : <LabelFree>Free</LabelFree>}
      </RowFlex2>
    );
  }

  const getDeliveryOnline = () => {

    if (eventLoaded.delivery === 'inPerson') return <></>;

    return (
      <RowFlex2>
        <FontAwesomeIcon
          icon='video'
          size='sm'
          color='var(--t-blue)'
        />
        <TitlePost
          theme={{ main: 'link', color: '#2A4CD3' }}
          isEvent={false}
        >
          <a href={`${eventLoaded.link}`} target="_blank" rel="noreferrer">
            Link
          </a>
          <ClickableIcon
            icon={faCopy}
            size='sm'
            color={'var(--t-blue)'}
            onClick={() => copyToClipboard(`${eventLoaded.link}`)}
          />
        </TitlePost>
        {eventLoaded.fee_online > 0 ? <LabelCost>${eventLoaded.fee_online}</LabelCost> : <LabelFree>Free</LabelFree>}
      </RowFlex2>
    )
  }

  const copyToClipboard = (toCopy: string) => {
    navigator.clipboard.writeText(toCopy);
    currentSuccess?.setSuccessData(true, true, 'Copied to clipboard');
  }

  const getEventDetail = () => {
    getDataSDK()
      .then((dataSDK: getDataSDKResponse) => {
        return dataSDK.sdk.Events.getEvent({ id: id || '', username }, dataSDK.token);
      })
      .then((response) => {
        if (response.statusCode === 200) {
          setEventLoaded(mapEventToCard(response.body as EventsResponse));
        }
        else {
          currentSuccess?.setSuccessData(false, true, 'Get Error', 'Error getting event data.');
          console.log((response.body as IResponseError).error || (response.body as IResponseError).message);
        }
      })
      .catch((error) => {
        currentSuccess?.setSuccessData(false, true, 'Get Error', 'Error getting event data.');
        console.log(error);
      })
  }

  const showRSVPSelector = (): boolean => {
    const now = new Date();
    const startDate = new Date(`${eventLoaded.startDate} ${eventLoaded.startTime}`);
    return eventLoaded.creator !== username && now.getTime() < startDate.getTime();
  }

  useEffect(() => {
    setTimezone(event.timezone !== "" ? event.timezone : getCurrentTimezone())
    getEventDetail()
  }, [event])

  return (
    <EventCenterWrap>
      <div className="information-title-row">
        {/* <h1>{t('event__timeAndLocation')}</h1> */}

        <Wrapper>
          <HeaderEvent>
            <TextRowPointer onClick={() => navigate(`../../../../../../h/${eventLoaded.guide.username || ''}`)}>
              {eventLoaded.guide.avatar ? (
                <AvatarCreatorHome src={eventLoaded.guide.avatar} />
              ) : (
                getHealerNoAvatar()
              )}
              {eventLoaded.guide.name}
            </TextRowPointer>
            {(canEditEvent) &&
              (
                <div className="GridEventInfo">
                  <IconClicker
                    icon="pen"
                    size='lg'
                    color={'var(--t-blue)'}
                    className="pointer "
                    onClick={() => setEditEvent(true)}
                  />
                  {/* <button className="edit-info-buttonGrid2" onClick={() => setShowModal(true)}>
                    {t('event__inviteAttendees')}
                  </button> */}
                </div>
              )}
          </HeaderEvent>

          <DateStyle>
            {eventLoaded.startDate || ''} | {eventLoaded.startTime || ''}
          </DateStyle>

          <Counts>
            <FontAwesomeIcon
              icon={faUser}
              size='sm'
              color={'var(--t-blue)'}
            />{formatCompactNumber(eventLoaded.interested || 0)} Interested | {formatCompactNumber(eventLoaded.attending || 0)} Attendees
          </Counts>

          {(eventLoaded.delivery !== 'inPerson') && <Delivery>
            {getDeliveryOnline() || ''}
          </Delivery>}

          {(eventLoaded.delivery !== 'online') && <Delivery>
            {getDeliveryInPerson() || ''}
          </Delivery>}

          {eventLoaded.eventBriteUrl && <OnSale url={eventLoaded.eventBriteUrl} />}

          {showRSVPSelector() && <SelectorRSVP
            reload={reloadEvent}
            id={id}
            delivery={eventLoaded.delivery}
            rsvpSelector={eventLoaded.rsvp}
            name={eventLoaded.name}
            amountOnline={eventLoaded.fee_online}
            amountInPerson={eventLoaded.fee_person}
            isFullInPerson={eventLoaded.isFullInPerson}
            isFullOnline={eventLoaded.isFullOnline}
          />}

          <DividerLineHealerHome />
          <Description dangerouslySetInnerHTML={{ __html: cleanStyleHTML(eventLoaded.description) }} />

          <LabelLink
            onClick={() => setShowInstructions(!showInstructions)}
          >{showInstructions ? "Hide Details" : "View Details"}</LabelLink>

          {(showInstructions && eventLoaded.delivery !== 'inPerson') && <Delivery>
            Online Instructions
            <Description dangerouslySetInnerHTML={{ __html: cleanStyleHTML(eventLoaded.instructionsOnline || '') }} />
          </Delivery>}

          {(eventLoaded.instructionsPerson && showInstructions) && <DividerLineHealerHome />}

          {(showInstructions && eventLoaded.delivery !== 'online') && <Delivery>
            In person Instructions
            <Description dangerouslySetInnerHTML={{ __html: cleanStyleHTML(eventLoaded.instructionsPerson || '') }} />
          </Delivery>}
        </Wrapper>
      </div>
      {/* <div className="infoLocation">
        <div className="infoDate">
          <FontAwesomeIcon
            icon="calendar"
            size='lg'
            color={WHITE}
            className="  iconInfoLocation"
          />
          {`${(dateTimeSplit) ? dateTimeSplit[0] : '0'}-${(dateTimeSplit) ? dateTimeSplit[1] : '0'}-${(dateTimeSplit) ? dateTimeSplit[2] : '0'}`}
        </div>
        <div className="infoTime">
          <FontAwesomeIcon
            icon="clock"
            size='lg'
            color={WHITE}
            className="  iconInfoLocation"
          />
          {`${parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') > 12 ? parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') - 12 : (parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') === 0) ? 12 : ((dateTimeSplit) ? dateTimeSplit[3] : '0')}:${(dateTimeSplit) ? dateTimeSplit[4] : '0'} ${parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') > 12 ? 'PM' : 'AM'}`}
        </div>
        <div className="infoAddress">
          <FontAwesomeIcon
            icon="map-marker-alt"
            size='lg'
            color={WHITE}
            className="  iconInfoLocation"
          />
          {`${event.meeting_place}`}
        </div>
      </div>
      <div className="information-title-row">
        <h1>{t('event__description')}</h1>
      </div>
      <p className="robotocondensed-normal-white-16px" dangerouslySetInnerHTML={{ __html: cleanHTML(event.description || '' )}}>
      </p>

      <div className="moderators-row">
        <div className="information-title-row">
          <h1>{t('event__moderators')}</h1>
        </div>
        <div className="moderatorsCenter">
          {moderators.map((officer: GetEventModeratorsResponse) => (
            <div className="moder-row">
              <img
                className="officer-avatar"
                src={officer.avatar || `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
                alt=""
              />
              <div className="officer-info">
                <div className="officer-name robotocondensed-normal-lavender-16px">
                  {officer.fullname}
                </div>
                <div className="officer-role robotocondensed-normal-white-16px">
                  {officer.userType === 'SEEKER' && officer.classYear ? `Class of ${officer.classYear}` : officer.userType ? onlyFirstLetterCapitalized(officer.userType) : ''}
                </div>
              </div>

            </div>
          ))}
        </div>
      </div> */}

      {/* <ModalInviteAttendess
        show={showModal}
        onHide={handleCloseModal}
      /> */}
      <ModalCreateEvents
        showModal={editEvent}
        setShowModal={setEditEvent}
        reloadEvents={reloadEvent}
        edit
      />
    </EventCenterWrap>
  );
}

export default Index;

const HeaderEvent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const TitlePost = styled.p.attrs(
  props => ({
    className: props.theme.main,
  })
)`
  font-family: ${({ isEvent }: { isEvent: boolean }) => isEvent ? 'Inter' : 'Inter-bold'};
  font-style: normal;
  font-weight: ${({ isEvent }: { isEvent: boolean }) => isEvent ? '400' : '700'};
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: ${props => props.theme.color || 'black'};
  display: flex;
  gap: 3px;

  &.noShowAll{
      -webkit-line-clamp: 1;
  }

  &.link{
      display: flex;
      align-items: center;
      gap: 8px;
  }
  &.link a{
      font-family: 'Inter';
      font-weight: 400;
      color: #2A4CD3;
  }
  
`

const LabelLink = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #2A4CD3;
  cursor: pointer;
`;

const RowFlex2 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
`

const OptionsList = styled.div`
    position: absolute;
    background-color: white;
    width: calc(100% + 2px);
    z-index: 1;
    left: -1px;
    top: 33px;
    border: 1px solid #bfbfbf;
    padding: 7px 0px;
    border-radius: 0 0 9px 9px;
`

const OptionList = styled.div`
    font-family: 'Inter';
    padding: 8px 15px;
    text-align: left;
    cursor: pointer;

    &:hover{
        background-color: #ebe8e6;
    }

`

const Description = styled.div`
    color: var(--default-dark);
    font-family: 'Inter';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
`

const IconChevronRight = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
`;

const ButtonSeeAll = styled.div`
    width: 100%;
    height: 40px;
    border: 1px solid var(--t-blue);
    border-radius: 8px;
    font-family: 'Inter-Bold';
    font-size: 16px; 
    color: var(--t-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
`

const Delivery = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`

const Counts = styled.div`
    color: var(--default-dark);
    font-family: 'Inter';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    gap: 5px;
    align-items: center;
`

const DateStyle = styled.div`
    color: var(--default-dark);
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: normal;
`

const TextRow = styled.div`
    display: flex;
    gap: 5px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.2px;
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;

    & a{
        font-family: 'Inter';
        font-weight: 400;
        color: var(--absolute-zero);
        text-decoration: underline !important;
    }
`

const TextRowPointer = styled(TextRow)`
    cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 16px;
  align-items: flex-start;
`;

const EventCenterWrap = styled.div`
  overflow: initial;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;