import { useContext, useEffect, useState } from "react";
import ButtonSpinner from "../ButtonSpinner";
import { getDataSDK, getDataSDKResponse } from "../../../helpers/backendHelper";
import { ContextSuccess } from "../../../Context";
import { onHideType } from "../Modals/ModalUnsaved";
import { FollowedOrganization } from "@vibe/sdk/dist/interfaces/Connections/followOrg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const BtnFollow = ({
    username,
    orgID,
}: {
    username: string
    orgID: string
}) => {
    const currentSuccess = useContext(ContextSuccess);
    const isLoggedIn = useSelector((state: RootState) => state.getUser.isLoggedIn);

    const [isFollower, setIsFollower] = useState<boolean>(false);
    const [loadingFollow, setLoadingFollow] = useState<boolean>(false);

    const checkFollowing = () => {
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.getFollowedOrgs(username, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) {
                    setIsFollower(false);
                    const response = results.body as FollowedOrganization[];
                    for (let org = 0; org < response.length; org++) {
                        if (orgID === response[org].orgId) {
                            setIsFollower(true);
                            break;
                        }
                    }
                }
                else console.error('Error trying to get followed orgs.');
            })
            .catch((error) => console.error('Error trying to get followed orgs.', error))
    }

    const handleFollow = () => {
        setLoadingFollow(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.followOrg(username, orgID, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) setIsFollower(true);
                else currentSuccess?.setSuccessData(false, true, 'Error trying to follow user.');
            })
            .catch((error) => {
                console.error('Follow org', error);
                currentSuccess?.setSuccessData(false, true, 'Error trying to follow user.');
            })
            .finally(() => setLoadingFollow(false));
    }

    const handleUnFollow = () => {
        setLoadingFollow(true);
        getDataSDK()
            .then((dataSDK: getDataSDKResponse) => {
                return dataSDK.sdk.Connections.unfollowOrg(username, orgID, dataSDK.token);
            })
            .then((results) => {
                if (results.statusCode === 200) setIsFollower(false);
                else currentSuccess?.setSuccessData(false, true, 'Error trying to unfollow user.');
            })
            .catch((error) => {
                console.error('Unfollow Org', error)
                currentSuccess?.setSuccessData(false, true, 'Error trying to unfollow user.');
            })
            .finally(() => setLoadingFollow(false));
    }

    const onHideDiscard = (type: onHideType) => {
        switch (type) {
            case 'discard':
                currentSuccess?.setModalsOff();
                break;

            case 'close':
                currentSuccess?.setModalsOff();
                break;

            case 'save':
                currentSuccess?.setModalsOff();
                handleUnFollow();
                break;

            default:
                break;
        }
    };

    useEffect(() => checkFollowing(), [username, orgID]);

    const handleClick = () => {
        if (!isLoggedIn) {
            currentSuccess?.setLoginModal(true, false, undefined, undefined, orgID);
            return;
        }

        if (isFollower) {
            currentSuccess?.setModalWarning('DELETE', onHideDiscard, 'Do you want to unfollow this user?', 'Unfollow');
            return;
        }

        handleFollow();
    }

    return (<ButtonSpinner
        loading={loadingFollow}
        handleClick={handleClick}
        text={isFollower ? 'Following' : 'Follow'}
        hasIcon={false}
        color={isFollower ? "white" : "#e59b50"}
        fontSize={14}
    />)
}

export default BtnFollow;
