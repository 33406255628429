import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getClubEvents } from "../../store/actions/eventActions";
import { IGetEventSpecialFilters } from "@vibe/sdk/dist/interfaces/Events/getEvent";
import { mapEventToCard } from "./useEventsPanel";

const useEventsClub = () => {
  const dispatch = useDispatch();
  const [clubId, setClubId] = useState<string>();

  const username = useSelector((state: RootState) => state.getUser.userInfo?.username);

  const events = useSelector((state: RootState) => state.getEvent.clubEvents ?? []);
  const eventsLoading = useSelector((state: RootState) => state.getEvent.loadingClubEvents ?? []);

  const cardEvents = useMemo(() => events.map(mapEventToCard), [events]);

  useEffect(() => {
    reloadClubEvents();
  }, [clubId, username]); // Possible event filters here

  const reloadClubEvents = () => {
    if (!username || !clubId) return;

    dispatch(getClubEvents(clubId));
  }

  return {
    setClubId,
    events,
    cardEvents,
    eventsLoading,
    reloadClubEvents,
  }
};

export default useEventsClub;