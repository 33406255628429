import React, { useEffect, useState, useContext, useRef } from 'react'
import styled from "styled-components";
import { Imp } from "../../containers/Website/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-thin-svg-icons";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import TextEditor from "../../components/BaseComponents/TextEditor";
import { BaseFieldState, getFieldState } from '../BaseComponents/utils';
import { EventGeneralInfo, categoryTypeEvent, clubReachTypeEvent, reachTypeEvent } from '../../interfaces/Event/CreateEvent';
import Selector from '../BaseComponents/Selector';
import SelectItem from '../../interfaces/SelectItem';
import useClubsList, { getRole } from '../../hooks/Clubs/useClubsList';
import { ClubType } from '../../interfaces/club/ClubList';
import SelectorCheckBoxes from '../BaseComponents/SelectorCheckBoxes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import AddPhotoEvent from '../BaseComponents/ProfilePhoto/AddPhotoEvent';
import { Line, LogoInterface } from '../signupsetuphealer/Introduction';
import { TZ } from '@vibe/sdk/dist/interfaces/Roster/TZ';
import DatePicker from "react-datepicker";
import MyTimePicker from '../MyTimePicker';
import moment, { Moment } from "moment";
import { faCalendar, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { ContextSuccess } from '../../Context';
import { useTranslation } from 'react-i18next';
import { EventDateInfo, deliveryTypeEvent } from '../../interfaces/Event/CreateEvent';
import { ContentTab_Event, DisabledFieldEvent } from './ModalCreateEvent';
import useClubs from '../../hooks/Clubs/useClubs';
import { IClubExtended } from '@vibe/sdk/interfaces/Club/ClubInterface';
import ModalImageEditorCrop from '../ModalImageEditorCrop';
import { getDataSDK, getDataSDKResponse } from "../../helpers/backendHelper";
import useModalitiesSA from '../../hooks/Superadmin/Modalities/useModalitiesSA';
import Modality from '@vibe/sdk/interfaces/Modalities/modalities/Modality/Modality';

const prefillEventClubs = (eventGeneral: EventGeneralInfo, all: IClubExtended[]): SelectItem[] => {
    const partials = eventGeneral?.clubIds?.reduce((res, club) => {
        all.find((item) => {
            item.id === club &&
                res.push({ label: item.name, value: item.id });
        })
        return res;
    }, [] as SelectItem[]);

    return eventGeneral.clubIds?.includes('FollowOrg') ? [{ label: 'Organization Followers', value: 'FollowOrg' }].concat(partials ?? []) : partials ?? []
}

const prefillEventReach = (eventGeneral: EventGeneralInfo, reachEvent: SelectItem[]): SelectItem[] => {
    const partials = eventGeneral?.reach?.reduce((res, reach) => {
        reachEvent.find((item) => {
            item.value === reach &&
                res.push(item);
        })
        return res;
    }, [] as SelectItem[]);

    return partials ?? []
}

const prefillEventGender = (eventGeneral: EventGeneralInfo, reachGenderEvent: SelectItem[]): SelectItem[] => {
    const partials = eventGeneral?.genderReach?.reduce((res, reach) => {
        reachGenderEvent.find((item) => {
            item.value === reach &&
                res.push(item);
        })
        return res;
    }, [] as SelectItem[]);

    return partials ?? []
}

const prefillEventMS = (eventGeneral: EventGeneralInfo, eventMS: SelectItem[]): SelectItem[] => {
    const partials = eventGeneral?.eventMS?.reduce((res, reach) => {
        eventMS.find((item) => {
            item.value === reach &&
                res.push(item);
        })
        return res;
    }, [] as SelectItem[]);

    return partials ?? []
}

const prefillEventType = (eventGeneral: EventGeneralInfo, eventTypes: SelectItem[]): SelectItem => {
    const type = eventTypes.find(t => t.value === eventGeneral.eventType);
    return type ? type : { label: '', value: '' }
}

const TabGeneral = ({
    eventGeneral,
    setEventGeneral,
    setTabOk,
    cleanData,
    logo,
    setLogo,
    nextPressed,
    isUpdate = false,
    eventType,
    modalities,
}: {
    eventGeneral: EventGeneralInfo
    setEventGeneral: (data: EventGeneralInfo) => void
    setTabOk: (data: boolean) => void
    cleanData: boolean,
    logo: BaseFieldState<LogoInterface>
    setLogo: (data: BaseFieldState<LogoInterface>) => void
    nextPressed: boolean
    isUpdate?: boolean
    eventType: SelectItem[],
    modalities: SelectItem[],
}) => {

    const { all } = useClubs({});

    const categories: SelectItem[] = [
        {
            value: 'ORG',
            label: "Organization event",
        },
        {
            value: 'COMM',
            label: "Internal Team event",
        },
        {
            value: 'CLUB',
            label: "Community event",
        },
    ]

    const reachOrg: SelectItem[] = [
        {
            value: 'ADVISOR',
            label: "Guides",
        },
        {
            value: 'HEALER',
            label: "Healers",
        },
        {
            value: 'SEEKER',
            label: "Seekers",
        },
        {
            value: 'ADVISORY',
            label: "Advisory Body",
        },
        {
            value: 'COMITEE',
            label: "Committee Group",
        },
        {
            value: 'STAFF',
            label: "Staff",
        }
    ]

    const reachEvent: SelectItem[] = [
        {
            value: 'ADVISOR', //Take care about the SDK information related
            label: "Guides",
        },
        {
            value: 'HEALER', //Take care about the SDK information related
            label: "Healers",
        },
        {
            value: 'SEEKER', //Take care about the SDK information related
            label: "Seekers",
        },
        // {
        //     value: 'MEMBER', //Take care about the SDK information related
        //     label: "Members",
        // },
        // {
        //     value: 'FOLLOWER', //Take care about the SDK information related
        //     label: "Followers",
        // }
    ]

    const reachClub: SelectItem[] = [
        {
            value: 'ADVISOR', //Take care about the SDK information related
            label: "Guides",
        },
        {
            value: 'MEMBER', //Take care about the SDK information related
            label: "Members",
        },
        {
            value: 'FOLLOWER', //Take care about the SDK information related
            label: "Followers",
        }
    ]

    const optionsGender: SelectItem[] = [
        {
            value: 'F', //Take care about the SDK information related
            label: "Female",
        },
        {
            value: 'M', //Take care about the SDK information related
            label: "Male",
        },
        {
            value: 'P', //Take care about the SDK information related
            label: "Prefer not to disclose",
        },
        {
            value: 'O', //Take care about the SDK information related
            label: "Non binary",
        },
    ]

    const currentSuccess = useContext(ContextSuccess);
    const userRoles = useSelector((state: RootState) => state.getUser.userRoles);
    const { t, i18n } = useTranslation();

    const ref = useRef<null | HTMLDivElement>(null);

    const [categorySelector_Search, setCategorySelector_Search] = useState<string>('');
    const [eventCategory, setEventCategory] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: 'Community event', value: 'CLUB' }));
    const [clubSelector_Search, setClubSelector_Search] = useState<string>('');
    const [eventClub, setEventClub] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [clubs, setClubs] = useState<SelectItem[]>([]);

    const [eventReach, setEventReach] = useState<BaseFieldState<SelectItem[]>>(getFieldState(prefillEventReach(eventGeneral, reachEvent)));
    const [eventGender, setEventGender] = useState<BaseFieldState<SelectItem[]>>(getFieldState(prefillEventGender(eventGeneral, optionsGender)));
    const [eventClubs, setEventClubs] = useState<BaseFieldState<SelectItem[]>>(getFieldState(prefillEventClubs(eventGeneral, all)));
    const [eventClubReach, setEventClubReach] = useState<BaseFieldState<SelectItem[]>>(getFieldState([]));
    const [eventTypeSle, setEventTypeSle] = useState<BaseFieldState<SelectItem>>(getFieldState(prefillEventType(eventGeneral, eventType)));
    const [searchType, setSearchType] = useState<string>('');
    const [eventMS, setEventMS] = useState<BaseFieldState<SelectItem[]>>(getFieldState(prefillEventMS(eventGeneral, modalities)));

    const tz: TZ[] = useSelector((state: RootState) => state.getLocation.timezones);
    const [timezones, setTimezones] = useState<SelectItem[]>([]);
    const [timezone_search, setTimezone_search] = useState<string>('');
    const [timezone, setTimezone] = useState<BaseFieldState<SelectItem>>(getFieldState({ label: '', value: '' }));
    const [checkDateEnd, setCheckDateEnd] = useState<boolean>(false);

    const [endSelected, setEndSelected] = useState<boolean>(false);
    const [startSelected, setStartSelected] = useState<boolean>(false);
    const [start, setStart] = useState<Moment>(moment());
    const [end, setEnd] = useState<Moment>(moment());

    const { reloadList, allFilteredClubs } = useClubsList({
        searchName: clubSelector_Search,
        role: getRole(userRoles),
        clubType: eventCategory.value?.value as ClubType || 'CLUB',
    });

    const modalCreateEventEndRef: any = useRef(null);
    const [isOpenDate, setIsOpenDate] = useState<boolean>(false);

    const [clubType, setClubType] = useState<boolean>(true);

    useEffect(() => {
        if (isUpdate) {
            const type = eventType.find(t => t.value === eventGeneral.eventType);

            if (type) setEventTypeSle({
                ...eventTypeSle,
                value: type,
            })
        }
    }, [eventType])

    useEffect(() => {
        const resultado = all.filter((club) => club.club_type === "COMM");

        setClubType(true)

        if (eventClubs.value) {
            const listType = eventClubs.value.map((eventClub: any) => {
                if (!resultado.find((person) => person.id === eventClub.value)) {
                    setClubType(false)
                }
            })
        }
    }, [eventClubs])

    useEffect(() => {
        modalCreateEventEndRef.current?.scrollIntoView()
    }, [isOpenDate])

    const getMomentDate = (mom: Moment | undefined): Date | undefined => {
        return mom ? mom.toDate() : undefined;
    };

    const cleanFields = () => {
        setCategorySelector_Search('');
        setEventCategory(getFieldState({ label: '', value: '' }));
        setClubSelector_Search('');
        setEventClub(getFieldState({ label: '', value: '' }));
        setEventReach(getFieldState([]));
        setEventGender(getFieldState([]));
        setEventClubReach(getFieldState([]));

        setEndSelected(false);
        setStartSelected(false);
        setStart(moment());
        setEnd(moment());
        setTimezone_search('');
        setTimezone(getFieldState({ label: '', value: '' }));
        setCheckDateEnd(false);
    }

    const getEventCategory = () => {

        return (
            isUpdate ? (
                <ColumnRow>
                    <Row1>
                        <Label>
                            {(eventCategory.value || {}).value === 'COMM' ? "Internal Team" : "Teams & Communities"}
                            <Imp>*</Imp>
                        </Label>
                        <DisabledFieldEvent>
                            {(eventClubs.value || []).map(select => select.label).join(", ")}
                        </DisabledFieldEvent>
                    </Row1>

                    <Row1>
                        <Label>Member Reach<Imp>*</Imp></Label>
                        <DisabledFieldEvent>
                            {(eventReach.value || []).map(select => select.label).join(", ")}
                        </DisabledFieldEvent>
                    </Row1>

                    <Row1>
                        <Label>Gender Reach<Imp>*</Imp></Label>
                        <DisabledFieldEvent>
                            {(eventGender.value || []).map(select => select.label).join(", ")}
                        </DisabledFieldEvent>
                    </Row1>
                </ColumnRow>
            ) : (
                <ColumnRow>
                    <Row1>
                        <Label>
                            {(eventCategory.value || {}).value === 'COMM' ? "Internal Team" : "Teams & Communities"}
                            <Imp>*</Imp>
                        </Label>
                        <SelectorCheckBoxes
                            options={clubs}
                            selected={eventClubs}
                            setSelected={setEventClubs}
                            placeholder={'Select club'}
                            selectAllLabel='My Entire Organization'
                            labelAll={"My Entire Organization"}
                            groupItems={'club_type'}
                        />
                    </Row1>
                    <Row1 theme={{ main: 'selectorLast' }} onClick={() => handleClick(ref)}>
                        <Label ref={ref}>Member Reach<Imp>*</Imp></Label>
                        <SelectorCheckBoxes
                            options={clubType ? reachEvent.filter((club) => club.value !== "SEEKER") : reachEvent}
                            selected={eventReach}
                            setSelected={setEventReach}
                            placeholder={'Select member reach'}
                        />
                    </Row1>
                    <Row1 theme={{ main: 'selectorLast' }} onClick={() => handleClick(ref)}>
                        <Label ref={ref}>Gender Reach</Label>
                        <SelectorCheckBoxes
                            options={optionsGender}
                            selected={eventGender}
                            setSelected={setEventGender}
                            placeholder={'Select gender reach'}
                        />
                    </Row1>
                </ColumnRow>
            ))

        // if ((eventCategory.value || {}).value === 'ORG') {
        //     return (
        //         isUpdate ? (
        //             <Row1>
        //                 <Label>Reach<Imp>*</Imp></Label>
        //                 <DisabledFieldEvent>
        //                     {(eventReach.value || []).map(select => select.label).join(", ")}
        //                 </DisabledFieldEvent>
        //             </Row1>
        //         ) : (
        //             <Row1>
        //                 <Label>Reach<Imp>*</Imp></Label>
        //                 <SelectorCheckBoxes
        //                     options={reachOrg}
        //                     selected={eventReach}
        //                     setSelected={setEventReach}
        //                     placeholder={'Select event reach'}
        //                 />
        //             </Row1>
        //         ))
        // }
        // else if ((eventCategory.value || {}).value === 'COMM') {
        //     return (
        //         isUpdate ? (
        //             <ColumnRow>
        //                 <Row1>
        //                     <Label>
        //                         {(eventCategory.value || {}).value === 'COMM' ? "Internal Team" : "Community"}
        //                         <Imp>*</Imp>
        //                     </Label>
        //                     <DisabledFieldEvent>
        //                         {eventClub.value}
        //                     </DisabledFieldEvent>
        //                 </Row1>
        //                 <Row1>
        //                     <Label>User Reach<Imp>*</Imp></Label>
        //                     <DisabledFieldEvent>
        //                         {(eventClubReach.value || []).map(select => select.label).join(", ")}
        //                     </DisabledFieldEvent>
        //                 </Row1>
        //             </ColumnRow>
        //         ) : (
        //             <ColumnRow>
        //                 <Row1>
        //                     <Label>
        //                         {(eventCategory.value || {}).value === 'COMM' ? "Internal Team" : "Community"}
        //                         <Imp>*</Imp>
        //                     </Label>
        //                     <Selector
        //                         options={clubs}
        //                         selected={eventClub}
        //                         setSelected={setEventClub}
        //                         searchItem={clubSelector_Search}
        //                         setSearchItem={setClubSelector_Search}
        //                         placeholder={'Select club'}
        //                     />
        //                 </Row1>
        //                 <Row1>
        //                     <Label>User Reach<Imp>*</Imp></Label>
        //                     <SelectorCheckBoxes
        //                         options={reachClub}
        //                         selected={eventClubReach}
        //                         setSelected={setEventClubReach}
        //                         placeholder={'Select user reach'}
        //                     />
        //                 </Row1>
        //             </ColumnRow>
        //         ))
        // }
        // else if ((eventCategory.value || {}).value === 'CLUB') {
        //     return (
        //         isUpdate ? (
        //             <ColumnRow>
        //                 <Row1>
        //                     <Label>
        //                         {(eventCategory.value || {}).value === 'COMM' ? "Internal Team" : "Teams & Communities"}
        //                         <Imp>*</Imp>
        //                     </Label>
        //                     <DisabledFieldEvent>
        //                         {(eventClubs.value || []).map(select => select.label).join(", ")}
        //                     </DisabledFieldEvent>
        //                 </Row1>

        //                 <Row1>
        //                     <Label>Member Reach<Imp>*</Imp></Label>
        //                     <DisabledFieldEvent>
        //                         {(eventReach.value || []).map(select => select.label).join(", ")}
        //                     </DisabledFieldEvent>
        //                 </Row1>
        //             </ColumnRow>
        //         ) : (
        //             <ColumnRow>
        //                 <Row1>
        //                     <Label>
        //                         {(eventCategory.value || {}).value === 'COMM' ? "Internal Team" : "Teams & Communities"}
        //                         <Imp>*</Imp>
        //                     </Label>
        //                     <SelectorCheckBoxes
        //                         options={clubs}
        //                         selected={eventClubs}
        //                         setSelected={setEventClubs}
        //                         placeholder={'Select club'}
        //                         selectAllLabel='My Entire Organization'
        //                         labelAll={"My Entire Organization"}
        //                         groupItems={'club_type'}
        //                     />
        //                 </Row1>
        //                 <Row1 theme={{ main: 'selectorLast' }} onClick={() => handleClick(ref)}>
        //                     <Label ref={ref}>Member Reach<Imp>*</Imp></Label>
        //                     <SelectorCheckBoxes
        //                         options={clubType ? reachEvent.filter((club) => club.value !== "SEEKER") : reachEvent}
        //                         selected={eventReach}
        //                         setSelected={setEventReach}
        //                         placeholder={'Select member reach'}
        //                     />
        //                 </Row1>
        //             </ColumnRow>
        //         ))
        // }
        // return <></>
    }

    const checkDataComplete = () => {
        let isComplete = true;

        if (eventGeneral.category.length === 0) isComplete = false;
        if (eventGeneral.name.length === 0) isComplete = false;
        if (eventGeneral.description.length === 0) isComplete = false;

        if ((eventGeneral.clubIds || []).length === 0) { isComplete = false; }
        if (eventGeneral.reach.length === 0) { isComplete = false; }

        if (eventGeneral.eventType.length === 0) { isComplete = false; }
        // if ((eventGeneral.eventMS || []).length === 0) { isComplete = false; }

        setTabOk(isComplete);
    }

    const editMoment = (mom: Moment, date: Date): Moment => {
        let year: number | string = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let day: number | string = date.getDate();
        let hours: number | string = mom.hours();
        let minutes: number | string = mom.minutes();
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
        minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
        return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
    };

    const formatEventDate = (date: string) => {
        const dateS = date.split('-');
        return `${dateS[0]}-${dateS[1]}-${dateS[2]} ${dateS[3]}:${dateS[4]}`;
    }

    const getMomentTime = (mom: Moment): string => {
        if (mom === null) return "";
        let year: number | string = mom.year();
        let month: number | string = mom.month() + 1;
        let day: number | string = mom.toDate().getDate();
        let hours: number | string = mom.hours();
        let minutes: number | string = mom.minutes();
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
        minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
        return `${year}-${month}-${day}-${hours}-${minutes}`;
    };

    const editMomentClock = (mom1: Moment, mom2: Moment): Moment => {
        if (mom1 === null || mom2 === null) return moment();
        let year: number | string = mom1.year();
        let month: number | string = mom1.month() + 1;
        let day: number | string = mom1.toDate().getDate();
        let hours: number | string = mom2.hours();
        let minutes: number | string = mom2.minutes();
        month < 10 ? (month = `0${month}`) : (month = `${month}`);
        day < 10 ? (day = `0${day}`) : (day = `${day}`);
        hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
        minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
        return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
    };

    const handleOnStartDateChange = (e: any) => {
        const new_moment_e = editMoment(end, e);
        setEnd(new_moment_e)
        const new_moment_s = editMoment(start, e);
        setStart(new_moment_s);
        setEventGeneral({
            ...eventGeneral,
            start: getMomentTime(new_moment_s),
            end: getMomentTime(new_moment_e),
        });
    }

    const handleOnEnterDate = () => {
        if (eventGeneral.start) {
            const new_start_moment = moment(formatEventDate(eventGeneral.start));
            setStart(new_start_moment);
            setStartSelected(true);
        }
        else {
            setStart(moment())
        }

        if (eventGeneral.end) {
            const new_End_moment = moment(formatEventDate(eventGeneral.end));
            setEnd(new_End_moment);
            setEndSelected(true);
        }
        else {
            setEnd(moment())
        }
    }

    const handleOnStartTimeChange = (e: any) => {
        setStartSelected(e !== null);
        const new_moment = editMomentClock(start, e);
        if (new_moment < moment()) {
            currentSuccess?.setSuccessData(false, true, t('toast_invalidStartTime'), 'Please set a start time in the future');
            return;
        }

        if (!endSelected) {
            handleOnEndTimeChange(editMomentClock(start, e).add(1, 'hours'), true)
        }
        setStart(new_moment);

        setEventGeneral({
            ...eventGeneral,
            start: getMomentTime(e !== null ? new_moment : e),
            ...(endSelected ? {} : {
                end: getMomentTime(e !== null ? editMomentClock(start, e).add(1, 'hours') : e),
            })
        });
    }

    const handleOnEndTimeChange = (e: any, triggeredFromStartTime = false) => {

        setEndSelected(e !== null);
        let new_moment = editMomentClock(end, e);

        if (!triggeredFromStartTime) {
            if (new_moment < moment() || new_moment < start) {
                currentSuccess?.setSuccessData(false, true, t('toast_invalidEndTime'), 'Please set an end time in the future');
                new_moment = start.add(1, 'hours');
            }
        }

        setEnd(new_moment);
        setEventGeneral({
            ...eventGeneral,
            end: getMomentTime(e !== null ? new_moment : e),
        });
    }

    const getCurrentTimezone = (): SelectItem => {
        const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const continent = (zone || '').split("/")[0];
        const city = (zone || '').split("/")[1];
        const tzone = tz.find(
            (item: any) => item.continent === continent && item.city === city
        );
        let timezone_string = "";
        let timezone_label = "";
        if (tzone) {
            timezone_string = `${tzone.continent}#${tzone.city}#${tzone.STD}`;
            timezone_label = `${tzone.continent}, ${tzone.city} (${tzone.STD})`;
        }
        if (tzone) return {
            value: timezone_string,
            label: timezone_label
        }
        else return {
            label: "America, Bogota (-05:00)",
            value: "America#Bogota#-05:00",
        }
    };

    useEffect(() => {
        const timezoneCurrent = getCurrentTimezone();
        setTimezones([{
            label: `My Current Time zone (${timezoneCurrent.label})`,
            value: timezoneCurrent.value
        },
        ...tz.map(t => ({
            label: `${t.continent}, ${t.city} (${t.STD})`,
            value: `${t.continent}#${t.city}#${t.STD}`,
        }))])
    }, [tz])

    useEffect(() => {
        if (eventGeneral.timezone.length !== 0) {
            const tm = timezones.find(t => t.value === eventGeneral.timezone)
            setTimezone({
                ...timezone,
                value: {
                    label: tm ? tm.label : eventGeneral.timezone,
                    value: eventGeneral.timezone
                }
            });
        }
    }, [timezones])

    useEffect(() => {
        setClubs([{ label: 'Organization Followers', value: 'FollowOrg' }].concat(
            all.map(c => ({
                label: c.name + (c.orgName !== undefined ? ' - ' + c.orgName : ''),
                value: c.id,
                club_type: c.club_type
            }))))
    }, [allFilteredClubs])

    useEffect(() => {
        if (cleanData) cleanFields();
    }, [cleanData])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            cover: logo.value?.file || '',
        })
    }, [logo])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            clubIds: (eventClubs.value ?? []).map(({ value }) => value),
        })
    }, [eventClubs])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            category: (eventCategory.value || {}).value as categoryTypeEvent || 'ANY'
        })
    }, [eventCategory])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            reach: (eventReach.value || []).map(s => s.value) as reachTypeEvent[]
        })
    }, [eventReach])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            genderReach: (eventGender.value || []).map(s => s.value) as ('O' | 'P' | 'M' | 'F')[]
        })
    }, [eventGender])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            eventMS: (eventMS.value || []).map(s => s.value) as any[]
        })
    }, [eventMS])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            clubReach: (eventClubReach.value || []).map(s => s.value) as clubReachTypeEvent[]
        })
    }, [eventClubReach])

    useEffect(() => {
        setEventGeneral({
            ...eventGeneral,
            eventType: eventTypeSle.value?.value || '',
        })
    }, [eventTypeSle])


    const divRef = useRef(null);

    const onClick = (ref: any, top: number) => {
        if (ref.current) {
            ref.current.scrollTo({ top: top, behavior: 'smooth' });
        }
    };

    const onFocus = (e: string, ref: any) => {
        if (e === 'down') {
            if (ref.current) {
                const timeout = setTimeout(() => {
                    const lastChildElement = ref.current?.lastElementChild;
                    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
                }, 200)
            }
        }
    };

    const getReachLabels = (reach: clubReachTypeEvent[]): SelectItem[] => {
        return reach.map(c => ({
            value: c as string,
            label: (reachClub.find(reach => reach.value === c) || { label: '', value: '' }).label
        }))
    }

    const [showModal, setShowModal] = useState(false);
    const [activeCrop, setActiveCrop] = useState(false);

    function handleCloseModal(e: any, type: any) {
        setShowModal(() => false);
    };

    function handleCropImage(e: any) {

        const dataFile = URL.createObjectURL(logo.value?.file as any);
        let contentType: any;
        let file: any;

        fetch(e)
            .then(res => res.blob())
            .then(blob => {

                // contentType = logo?.value?.file.type;
                contentType = dataFile.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                file = new File([blob], 'Avatar' + eventGeneral?.name || '', { type: contentType });

                setActiveCrop(true)
                setLogo({
                    ...logo,
                    value: {
                        fileLink: '',
                        file: file,
                    }
                })
            })
    };

    const showModalCrop = (e: BaseFieldState<LogoInterface>, isDelete = false) => {
        setLogo(e);
        if (!isDelete) setShowModal(true);
        setActiveCrop(false);
    }

    const handleClick = (refC: any) => {
        const timerAct = setTimeout(() => {
            refC.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    };

    useEffect(() => {
        onClick(divRef, 0)
    }, [divRef])

    useEffect(() => {
        checkDataComplete();
        if (eventGeneral.clubReach && nextPressed && eventClubReach.value?.length === 0)
            setEventClubReach(getFieldState(getReachLabels(eventGeneral.clubReach)))

        if (nextPressed) handleOnEnterDate()
    }, [])

    useEffect(() => {
        checkDataComplete();
    }, [eventGeneral])

    return (
        <ContentTab_Events ref={divRef}>
            <Row1>
                <Label>Event Name<Imp>*</Imp></Label>
                <Input
                    value={eventGeneral.name || ''}
                    onChange={(e) => setEventGeneral({
                        ...eventGeneral,
                        name: e.currentTarget.value
                    })}
                />
            </Row1>
            <InfoEvent>
                <WrapperPhoto>
                    <ModalImageEditorCrop
                        preview={logo}
                        handleSaveImage={handleCropImage}
                        show={showModal}
                        onHide={handleCloseModal}
                        isProfilePhoto={true}
                        cropSize={{ width: 400, height: 150 }}
                    />
                    <AddPhotoEvent
                        title={''}
                        logo={logo}
                        setLogo={(e: BaseFieldState<LogoInterface>, isDelete?: boolean) => showModalCrop(e, isDelete)}
                        vertical
                        notSetup
                        hasTitle_notSetup
                    />
                </WrapperPhoto>
                <Row2>
                    <Label>Description<Imp>*</Imp></Label>
                    <TextEditor
                        value={eventGeneral.description || ''}
                        setValue={(e) => setEventGeneral({
                            ...eventGeneral,
                            description: e
                        })}
                        charLimit={1000}
                        minHeight={120}
                        maxHeight={'120px'}
                        placeholder='Please describe the event you are creating...'
                    />
                </Row2>

            </InfoEvent>
            <Column2>
                <Column>
                    <TitleModal>Reach</TitleModal>
                    <WrapUp>
                        {getEventCategory()}
                    </WrapUp>
                </Column>
                <Column>
                    <TitleModal>Event Details</TitleModal>
                    <WrapUp>
                        <ColumnRow>
                            <Row1>
                                <Label>
                                    Event Type
                                    <Imp>*</Imp>
                                </Label>
                                <Selector
                                    options={eventType}
                                    selected={eventTypeSle}
                                    setSelected={setEventTypeSle}
                                    placeholder={'Select'}
                                    searchItem={searchType}
                                    setSearchItem={setSearchType}
                                />
                            </Row1>
                            <Row1 theme={{ main: 'selectorLast' }} onClick={() => handleClick(ref)}>
                                <Label ref={ref}>Modalities & Services</Label>
                                <SelectorCheckBoxes
                                    options={modalities}
                                    selected={eventMS}
                                    setSelected={setEventMS}
                                    placeholder={'Select'}
                                    notApplicable
                                />
                            </Row1>
                        </ColumnRow>
                    </WrapUp>
                </Column>
            </Column2>
            <div ref={modalCreateEventEndRef} />
        </ContentTab_Events>
    )
}

export default TabGeneral;

const ContentTab_Events = styled.div`
    gap: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`

const Column2 = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;

    & > div{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`
const Column = styled.div``

const Focus = styled.div`
    height: 40px;
`

const InfoTime = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
`;

const WrapperPhoto = styled.div`
    grid-area: 1 / 1 / 3 / 2;
`;

const Row1 = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 62px;
    min-height: 62px;

    &.selectorLast ul{
        padding-bottom: 20px;
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,0);
    }
    &.selectorLast ul li{
        border-left: 3px solid #0000001a;
        border-right: 3px solid #0000001a;
    }
`;

const ColumnRow = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    gap: 10px;
    flex-direction: column;
`;

const ColumnRow2 = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    gap: 10px;

    & ${Row1}{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 62px;
        min-height: 62px;
    }
`;

const WrapUp = styled.div`
    display: flex;
    width: 100%;
    height: 62px;
    gap: 10px;
    flex-direction: column;
`;

const ListCheckbox = styled.div`
    display: flex;
    gap: 16px;
`



const Row2 = styled(Row1)`
    grid-area: 1 / 2 / 3 / 3;
`;

const Label = styled.div`    
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    display: flex;
    font-size: 14px;
`

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    font-size: 16px;
    font-family: 'Inter';
    border: 1px solid var(--simple-gray);
`

const ItemList = styled.div`
    display: flex;
    justify-content: start;
    //padding: 3px 0px 3px 0;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    gap: 8px;
`

const InfoEvent = styled.div`
    display: grid;
    grid-template-columns: 30% calc(70% - 25px);
    grid-template-rows: 60px calc(100% - 70px);
    grid-column-gap: 25px;
    grid-row-gap: 10px;
`

const InputsR1 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${Input}{
        height: 38px;
    }
`

const InputIcon = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    align-items: center;
    padding-right: 15px;
    border: 1px solid var(--simple-gray);
    height: 40px;

    & ${Input}{
        border: 0;
        height: 38px;
    } 
`

const InputSelect = styled(Inputs)`
    ${Row1}{
        height: 65px;
    }
`

const TitleModal = styled.div`
    color: var(--default-dark);
    font-family: Inter-Bold;
    font-size: 16px;
    text-align: left;
    display: flex;
`

const RadioIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
`;

const Inputs211 = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 0px;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: black;
    cursor: pointer;
`;

const Row21 = styled(Row1)`grid-area: 1 / 1 / 2 / 2;`
const Row212 = styled(Row1)`grid-area: 1 / 2 / 2 / 3;`

const Inputs4211 = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    display: flex;
    gap:24px;

    /* display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr;
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 0px; */

    /* &.row211{
        grid-template-columns: 0.5fr 0.5fr;
    } */
`
const IconCheck = styled.div.attrs(props => ({
    className: props.theme.main,
}))`
    border-radius: 4px;
    border: 2px solid var(--default-dark);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.iconCheck{
        background-color: var(--default-dark);
    }
`