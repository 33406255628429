import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { getFiltered, sortAlphabeticallyOnProperty } from "../../../containers/Signup/SignupHealerInd"
import { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import useActiveState, { StateOption } from "../../../hooks/Locations/useActiveState";
import useStateCities, { CityOption } from "../../../hooks/Locations/useStateCities";
import { BaseFieldState, getFieldState } from "../utils";
import OutsideClickHandler from "react-outside-click-handler";

export const prefillCity = (cityCode: string, city: string, cities: CityOption[]): CityOption => {
    const type = cities.find(t => t.value === cityCode);
    return type ? type : { label: city || '', value: city || '' }
}

const SelectorCity = ({
    state,
    city,
    setCity,
    searchCity,
    setSearchCity,
    backgroundColor = "white",
    border = "1px solid var(--simple-gray)",
    deactivateCreate = false,
}: {
    state?: string,
    city: BaseFieldState<CityOption>,
    setCity: (data: BaseFieldState<CityOption>) => void,
    searchCity: string,
    setSearchCity: (data: string) => void,
    backgroundColor?: string,
    border?: string,
    deactivateCreate?: boolean
}) => {
    const {
        citiesReactSelect,
        reloadState,
        isNewState
    } = useStateCities();

    const [showOptionsCity, setShowOptionsCity] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [citiesFiltered, setCitiesFiltered] = useState<CityOption[]>([])

    useEffect(() => {
        console.log({ state })
        if (state) {
            reloadState(state);
            if (firstLoad) {
                setFirstLoad(false);
            } else {
                setCity(getFieldState({ label: '', value: '' }));
                setSearchCity('');
            }
        }
    }, [state]);

    useEffect(() => {
        const itemFound = citiesReactSelect.find((country) =>
            country.label &&
            (country.label.toLocaleLowerCase() === searchCity ||
                country.label.toLocaleUpperCase() === searchCity ||
                country.label === searchCity)
        )
        if (itemFound) {
            setSearchCity(itemFound.label)
            setShowOptionsCity(false)
            setCity({
                ...city,
                value: itemFound
            })
        }
    }, [searchCity])

    useEffect(() => {
        if (city.value?.value) {
            setSearchCity(city.value?.label || city.value.value)
        }
    }, [])

    useEffect(() => {
        if (city.value?.value) {
            setSearchCity(city.value?.label || city.value.value)
        }
    }, [city])

    const getFilteredOptions = () => {
        if (searchCity !== city.value?.label) {
            return setCitiesFiltered(getFiltered(sortAlphabeticallyOnProperty(citiesReactSelect, 'label'), searchCity))
        }
        return setCitiesFiltered(sortAlphabeticallyOnProperty(citiesReactSelect, 'label'));
    }

    useEffect(() => {
        getFilteredOptions();
    }, [searchCity, citiesReactSelect])

    return (
        <WrapperSelect>
            <Select_Input
                onClick={() => setShowOptionsCity(true)}
                value={searchCity}
                placeholder='Select a City'
                onChange={(e) => setSearchCity(e.currentTarget.value)}
                backgroundColor={backgroundColor}
                border={border}
                onFocus={() => setShowOptionsCity(true)}
                autoComplete={"unInput"}
            />
            <IconDropdownInput
                icon={faChevronDown}
                size='sm'
                color={backgroundColor === 'white' ? 'black' : 'white'}
                onClick={() => setShowOptionsCity(!showOptionsCity)}
            />
            {showOptionsCity &&
                <OutsideClickHandler onOutsideClick={() => setShowOptionsCity(false)}>
                    <Items>
                        {(citiesReactSelect.length === 0 && !isNewState) ? (
                            <ItemDisabled>
                                Select state first...
                            </ItemDisabled>
                        ) : (
                            citiesFiltered.length > 0 ? citiesFiltered.map((activeCity, index) => (
                                <Item onClick={() => {
                                    setSearchCity(activeCity.label)
                                    setShowOptionsCity(false)
                                    setCity({
                                        ...city,
                                        value: activeCity
                                    })
                                }
                                }
                                    key={index}
                                >
                                    {activeCity.label}
                                </Item>
                            )) : (
                                (searchCity.length > 0 && !deactivateCreate)&& <Item onClick={() => {
                                    setShowOptionsCity(false)
                                    setCity({
                                        ...city,
                                        value: {
                                            label: searchCity,
                                            value: searchCity,
                                        }
                                    })
                                }
                                }
                                    key={'new'}
                                >
                                    Create new one
                                </Item>
                            ))}
                    </Items>
                </OutsideClickHandler>
            }
        </WrapperSelect >
    )
}

const WrapperSelect = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

interface Select_InputProps {
    backgroundColor: string
    border: string
}

const Select_Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 9px;
    border: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter';
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: ${(props: Select_InputProps) => props.backgroundColor};
    border: ${(props: Select_InputProps) => props.border};
    color: ${(props: Select_InputProps) => props.backgroundColor === 'white' ? 'black' : 'white'};

    &::placeholder{
        color: var(--simple-gray);
        font-family: 'Inter';
    }
`;

const IconDropdownInput = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    z-index: 0;
    top: 37%;
    right: 20px;
`;

const Item = styled.li`
    background-color: white;
    padding: 10px 20px;
    text-align: left;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:last-child {
        border-radius: 0 0 10px 10px;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        -moz-box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
        box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
    }

    &:hover {
        background-color: #e8e8e8;
    }
`;

const ItemDisabled = styled(Item)`
    background-color: #e8e8e8;
    cursor: default;
`;

const Items = styled.ul`
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 10;
    overflow-y: scroll;
    max-height: 200px;
    border-radius: 12px;
    border: 1px solid var(--simple-gray);
    left: 0px;
    box-shadow: 0px 3px 5px -1px rgba(168,168,168,1);
`;

export default SelectorCity;